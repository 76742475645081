import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostOrganizationUserParams = {
  name: string
  email: string
  organizationId: number
  permission: number
}

export const postOrganizationUserPath = '/admin/v1/organization-user'

export const postOrganizationUser = async (
  params: PostOrganizationUserParams,
) => {
  return await http<APIResponse<null>>(postOrganizationUserPath, {
    method: 'POST',
    body: JSON.stringify({
      name: params.name,
      email: params.email,
      organization_id: params.organizationId,
      permission: params.permission,
    }),
  })
}
