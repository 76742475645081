import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getPointPurchase,
  getPointPurchasePath,
} from '@/features/histories/api/getPointPurchase'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ポイント購入一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      fanId: fanId,
      fanName: fanName,
    },
  })

  const { data, isValidating, mutate } = useSWR(getPointPurchasePath, () =>
    getPointPurchase({
      page: page,
      id: id || undefined,
      fanId: fanId || undefined,
      fanName: fanName || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
      ])
      return prev
    })
    mutate(
      getPointPurchase({
        page: page,
        id: getValues('id') || undefined,
        fanId: getValues('fanId') || undefined,
        fanName: getValues('fanName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      unifiedOrderId: item.unifiedOrderId,
      fanId: item.fanId,
      fanName: item.fanName,
      point: item.point,
      usedPoint: item.usedPoint,
      expiredAt: item.expiredAt.length > 0 ? item.expiredAt : '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goDetail = (id = '') => {
    navigate('/histories/payment/:paymentId', {
      params: {
        paymentId: id,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3} alignItems={'center'}>
          <Grid item>
            <FormText
              control={control}
              name='id'
              label='ID'
              fullWidth={false}
              hint='カンマ区切りで複数指定可能'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='fanId'
              label='ファンID'
              fullWidth={false}
              hint='カンマ区切りで複数指定可能'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='fanName'
              label='ファン名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 100,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 180,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'point',
              headerName: '購入ポイント数',
              width: 120,
            },
            {
              field: 'usedPoint',
              headerName: '消費ポイント数',
              width: 120,
            },
            {
              field: 'expiredAt',
              headerName: '有効期限',
              width: 150,
            },
            {
              field: 'createdAt',
              headerName: '購入日時',
              width: 180,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 180,
            },
          ]}
          idPropertyName={'unifiedOrderId'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'unifiedOrderId'}
          onEditClick={goDetail}
          editHeaderName='詳細'
        />
      </Box>
    </Box>
  )
}

export default Page
