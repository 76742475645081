import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import { Fab } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import useSWR from 'swr'

import { getMe, getMePath } from '@/api/getMe'
import CommonDrawer from '@/components/layouts/CommonDrawer/CommonDrawer'
import { useNavigate } from '@/router'

const drawerWidth = 280

export default function DefaultLayout() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const navigate = useNavigate()

  // FIXME: 認証が必要なページのガード処理
  // NOTE:  contextとproviderを使って実装する
  const { error } = useSWR(getMePath, () => getMe())
  if (error) {
    navigate('/auth/signin')
  }

  // メニュー開閉
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Box id='default-layout' sx={{ display: 'flex' }}>
      <CssBaseline />

      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        <CommonDrawer
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          toggleDrawer={handleDrawerToggle}
        />
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* ページ */}
        <Outlet />
      </Box>
      {/* スクロールボタン */}
      <Fab
        color='primary'
        size='small'
        aria-label='Drawer Toggle'
        sx={{
          display: { xs: 'flex', sm: 'none' },
          justify: 'center',
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: 1000,
        }}
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </Fab>
    </Box>
  )
}
