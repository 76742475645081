import { red, blue, yellow, green } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const INPUT_BASE_HEIGHT = '40px'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#E78CB7',
      contrastText: '#fefefe',
    },
    secondary: {
      main: '#FFA79B',
      contrastText: '#fefefe',
    },
    error: {
      main: red.A400,
      contrastText: '#fefefe',
    },
    info: {
      main: blue.A400,
      contrastText: '#fefefe',
    },
    warning: {
      main: yellow.A400,
      contrastText: '#fefefe',
    },
    success: {
      main: green.A700,
      contrastText: '#fefefe',
    },
  },
  typography: {
    fontFamily: [
      '"Noto Sans JP"',
      '-apple-system',
      '"Helvetica"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightBold: 700,
  },
  mixins: {
    toolbar: {
      minHeight: 50,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          flexShrink: 0,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: 'none',
          color: 'inherit',
          cursor: 'pointer',
          '&:visited': {
            color: 'inherit',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: INPUT_BASE_HEIGHT,
          backgroundColor: 'white',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          height: 45,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          height: 35,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '1.5em',
          flexShrink: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'block',
          marginBottom: '0.7em',
          color: 'black',
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          marginBottom: '0.5em',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'block',
          margin: '0 auto',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: '45px',
        },
        track: {
          borderRadius: '9999px',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: { padding: 1 },
        sizeSmall: {
          fontSize: 12,
        },
      },
    },
  },
})

export default theme
