import React from 'react'

import { Box, FormControl, FormLabel, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ja from 'date-fns/locale/ja'
import { Controller } from 'react-hook-form'

import type { DateOrTimeView } from '@mui/x-date-pickers'
import type { FieldValues, UseControllerProps } from 'react-hook-form'

import './FormDate.css'

type Props<T extends FieldValues> = {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url'
  label?: string
  placeholder?: string
  autocomplete?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  startAdornment?: React.ReactNode
  sx?: React.CSSProperties
  fullWidth?: boolean
  onChange?: () => void
  views?: DateOrTimeView[]
} & UseControllerProps<T>

function FormDate<T extends FieldValues>({
  name,
  label,
  required = false,
  disabled = false,
  control,
  startAdornment,
  sx,
  fullWidth = true,
  onChange,
  views = ['year', 'month', 'day'],
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl fullWidth={fullWidth} variant='outlined' sx={sx}>
          <FormLabel required={required}>{label}</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ja}
            dateFormats={{ monthAndYear: 'yyyy年M月', year: 'yyyy年' }}
            localeText={{
              previousMonth: '前月を表示', // < のツールチップ
              nextMonth: '次月を表示', // > のツールチップ
              cancelButtonLabel: 'キャンセル', // スマホ画面のCANCELボタン
              clearButtonLabel: '選択をクリア', // スマホ画面のCLEARボタン
              okButtonLabel: '選択',
            }}
          >
            <Box display={'flex'} alignItems={'flex-start'}>
              {startAdornment}
              <DateTimePicker
                views={views}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  if (onChange) onChange()
                }}
                disabled={disabled}
                slotProps={{
                  actionBar: {
                    actions: ['clear', 'cancel', 'accept'],
                  },
                  toolbar: { toolbarFormat: 'yyyy年MM月dd日', hidden: false },
                }}
              />
            </Box>
          </LocalizationProvider>
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]!.message)}</small>
            </Typography>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormDate
