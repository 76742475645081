import type { MessageStatusType, MessageTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMessageBoxTemplateParams = {
  page: number
  inquiryStatus?: MessageStatusType
  fanId?: number
  fanName?: string
}

export type GetMessageBoxTemplateResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    messageBoxId: string
    fanId: number
    fanName: string
    title: string
    body: string
    messageType: MessageTypeType
    status: MessageStatusType
    lastMessagedAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getMessageBoxPath = '/admin/v1/message-box'

export const getMessageBox = async (params: GetMessageBoxTemplateParams) => {
  return await http<GetMessageBoxTemplateResponse>(getMessageBoxPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      inquiry_status: params.inquiryStatus,
      fan_id: params.fanId,
      fan_name: params.fanName,
    },
  })
}
