import { httpRaw } from '@/lib/httpRaw'

export type GetArtistCoinHistoryDownloadCsvParams = {
  artistId?: string
  artistName?: string
  type?: string
  dateFrom?: string
  dateTo?: string
}

export const getArtistCoinHistoryDownloadCsvPath =
  '/admin/v1/artist-coin/history/download-csv'

export const getArtistCoinHistoryDownloadCsv = async (
  params: GetArtistCoinHistoryDownloadCsvParams,
) => {
  return await httpRaw(getArtistCoinHistoryDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      artist_id: params.artistId,
      artist_name: params.artistName,
      type: params.type,
      date_from: params.dateFrom,
      date_to: params.dateTo,
    },
  })
}
