import React from 'react'

import {
  FormControl,
  OutlinedInput,
  FormLabel,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url'
  label?: string
  placeholder?: string
  autocomplete?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  multiline?: boolean
  endAdornment?: React.ReactNode
  hint?: string
  onBlur?: () => void
  fullWidth?: boolean
} & UseControllerProps<T>

function FormText<T extends FieldValues>({
  name,
  type = 'text',
  label,
  placeholder,
  autocomplete = 'off',
  required = false,
  disabled = false,
  control,
  size = 'small',
  multiline = false,
  endAdornment,
  hint,
  onBlur,
  fullWidth = true,
}: Props<T>) {
  // キーボードの上下で値が変更されるのを防ぐ
  // テキストエリアには適用しない
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (multiline) return
    const key = e.key.toLocaleLowerCase()
    if (key === 'arrowup' || key === 'arrowdown') {
      e.preventDefault()
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl fullWidth={fullWidth} variant='outlined'>
          <FormLabel required={required}>{label}</FormLabel>
          <OutlinedInput
            name={field.name}
            ref={field.ref}
            type={type}
            autoComplete={autocomplete}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onBlur={() => {
              field.onBlur()
              if (onBlur) onBlur()
            }}
            onChange={field.onChange}
            value={field.value}
            size={size}
            multiline={multiline}
            minRows={multiline ? 5 : undefined}
            sx={{
              height: multiline ? 'auto' : undefined,
            }}
            endAdornment={endAdornment}
            onKeyDown={handleKeyDown}
            onWheel={() => {
              // スクロールで値が変更されるのを防ぐ
              try {
                ;(document.activeElement as HTMLElement).blur()
              } catch (_e) {
                /* empty */
              }
            }}
          />
          {hint && (
            <Typography variant='body1' sx={{ opacity: 0.7 }}>
              <small>{hint}</small>
            </Typography>
          )}
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]!.message)}</small>
            </Typography>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormText
