import type { ReportReasonType, ReportTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetReportParams = {
  page: number
  fanId?: string
  fanName?: string
  targetFanId?: string
  targetFanName?: string
  type?: string
  reason?: string
}

export type GetReportResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    targetFanId: number
    targetFanName: string
    type: ReportTypeType
    reason: ReportReasonType
    detail: {
      reasonDetail?: string
      artistId?: number
      postId?: number
      liveId?: number
      commentNo?: number
      comment?: string
    }
    createdAt: string
    updatedAt: string
  }>
>

export const GetReportPath = '/admin/v1/fan-notice'

export const getReport = async (params: GetReportParams) => {
  return await http<GetReportResponse>(GetReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      target_fan_id: params.targetFanId,
      target_fan_name: params.targetFanName,
      type: params.type,
      reason: params.reason,
    },
  })
}
