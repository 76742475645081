import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Box, Divider, FormLabel, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { AggregationTarget, EntryFilterGender } from '../types'
import { calcDefaultDate } from '../utils'

import EventDescriptionsForm from './EventDescriptionsForm'
import EventRewardForm from './EventRewardForm'

import type { AggregationTargetType } from '../types'

import { INPUT_BASE_HEIGHT } from '@/theme'
import { EventType } from '@/types'

type EventLooseFormValues = {
  name: string
  bannerFileUrl?: string
  iconFileUrl?: string
  bannerFile: File | null
  iconFile: File | null
  eventDisplayStartAt: Date
  eventDisplayEndAt: Date | null
  eventStartAt: Date
  eventEndAt: Date
  aggregationTarget: AggregationTargetType
  entryStartAt: Date
  entryEndAt: Date
  entryFilterGender: string
  entryFilterBirthMonth: number | ''
  rewards: { line: number; title: string }[]
  // スコアロジックは全て少数
  scoreLogicFactor: {
    cheer: number
    viewersCount: number
    viewDuration: number
    postCount: number
    postUu: number
  }
  descriptions: { title: string; description: string }[]
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface EventLooseFormProps {}
export interface EventLooseFormRef {
  getFormValues(): EventLooseFormValues
  setFormValue(key: keyof EventLooseFormValues, value: string): void
  formReset(values: EventLooseFormValues): void
}

const EventLooseForm = forwardRef<EventLooseFormRef, EventLooseFormProps>(
  (_, ref) => {
    //表示、募集、イベント開始終了期間のデフォルト取得
    const defaultDate = calcDefaultDate()

    const { control, getValues, setValue, reset } =
      useForm<EventLooseFormValues>({
        defaultValues: {
          name: '',
          bannerFile: null,
          iconFile: null,
          eventDisplayStartAt: defaultDate.displayStartAt,
          eventDisplayEndAt: defaultDate.displayEndAt,
          eventStartAt: defaultDate.startEventAt,
          eventEndAt: defaultDate.endEventAt,
          aggregationTarget: AggregationTarget.ALL.value,
          entryStartAt: defaultDate.startEntryAt,
          entryEndAt: defaultDate.endEntryAt,
          entryFilterGender: EntryFilterGender.FEMALE.value,
          entryFilterBirthMonth: '',
          rewards: [],
          scoreLogicFactor: {
            cheer: 1.0,
            viewersCount: 0.0,
            viewDuration: 1.0,
            postCount: 1.4,
            postUu: 1.4,
          },
          descriptions: [{ title: '', description: '' }],
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    // mapの後にconcatすると狭い範囲で推論されてしまうので、型を明示的に指定
    const entryFilterGenderOption = () => {
      const filterMap: { value: string; text: string }[] = Object.entries(
        EntryFilterGender,
      ).map(([, item]) => ({
        value: item.value,
        text: item.text,
      }))
      return filterMap.concat([
        {
          value: 'NONE',
          text: '制限しない',
        },
      ])
    }

    const monthOptions = [
      { value: '', text: '選択して下さい' },
      ...Array.from({ length: 12 }, (_, i) => {
        const num = i + 1
        return { value: num.toString(), text: num.toString() }
      }),
    ]

    return (
      <FormBox onSubmit={() => false}>
        <Grid item>
          {/* イベント基本情報 */}
          <Box mb={6}>
            <Typography>イベント基本情報</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <FormText
              control={control}
              name='name'
              label='イベント名'
              hint='255文字以内'
              required
            />
            <Grid container spacing={4}>
              <Grid item md={5}>
                <FormFile
                  label='バナー画像'
                  control={control}
                  name='bannerFile'
                  showImagePreview
                  initialImageSrc={getValues('bannerFileUrl')}
                  required
                />
              </Grid>
              <Grid item md={5}>
                <FormFile
                  label='アイコン画像'
                  control={control}
                  name='iconFile'
                  showImagePreview
                  initialImageSrc={getValues('iconFileUrl')}
                  required
                />
              </Grid>
            </Grid>

            <FormLabel>表示期間</FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'1em'}>
              <FormDate
                control={control}
                name='eventDisplayStartAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
              <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
              <FormDate
                control={control}
                name='eventDisplayEndAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
            </Box>
            <FormLabel>募集期間</FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'1em'}>
              <FormDate
                control={control}
                name='entryStartAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
              <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
              <FormDate
                control={control}
                name='entryEndAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
            </Box>
            <FormLabel>イベント期間</FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'1em'}>
              <FormDate
                control={control}
                name='eventStartAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
              <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
              <FormDate
                control={control}
                name='eventEndAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
            </Box>
            <Box>
              <FormRadio
                label='集計対象'
                control={control}
                name='aggregationTarget'
                required
                options={Object.entries(AggregationTarget).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                }))}
                hint='親イベとサブイベの両方に適用されます。イベント開始後は変更できません'
                fullWidth={false}
              />
            </Box>
          </Box>
          <Box mb={6} mt={6}>
            <Typography>エントリー条件</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <Box>
              <FormRadio
                label='性別'
                control={control}
                name='entryFilterGender'
                required
                options={entryFilterGenderOption()}
                fullWidth={false}
              />
            </Box>
            <Box>
              <FormSelect
                control={control}
                name='entryFilterBirthMonth'
                label='誕生月'
                fullWidth={false}
                options={monthOptions}
              />
            </Box>
          </Box>
          {/* スコア特典 */}
          <Box mb={6} mt={6}>
            <Typography>スコア特典</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <EventRewardForm
              control={control}
              name='rewards'
              eventType={EventType.YURU.value}
            />
          </Box>
          {/* スコアロジック */}
          <Box mb={6} mt={6}>
            <Typography>スコアロジック</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
            <Typography color='textSecondary'>
              <small>イベント開始後は変更できません</small>
            </Typography>
          </Box>
          <Box pl={2}>
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              justifyContent={'space-between'}
            >
              <FormText
                control={control}
                name='scoreLogicFactor.cheer'
                fullWidth={false}
                type='number'
                label='CHEER'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                +
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.viewersCount'
                fullWidth={false}
                type='number'
                label='視聴人数'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                &times;
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.viewDuration'
                fullWidth={false}
                type='number'
                label='視聴時間'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                +
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.postCount'
                fullWidth={false}
                type='number'
                label='投稿数'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                &times;
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.postUu'
                fullWidth={false}
                type='number'
                label='CHEER UU'
              />
            </Box>
          </Box>
          {/* スコアランキング特典 */}
          <Box mb={6} mt={6}>
            <Typography>イベント概要</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <EventDescriptionsForm control={control} name='descriptions' />
          </Box>
        </Grid>
      </FormBox>
    )
  },
)

export default EventLooseForm
