import { http } from '@/lib/http'

export type PatchNgWordParams = {
  id: number
  word: string
}

export const patchNgWordPath = '/admin/v1/ng-word/:id'

export const patchNgWord = async (params: PatchNgWordParams) => {
  return await http<null>(patchNgWordPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      word: params.word,
    }),
  })
}
