import type { InvoiceNumberStatusType } from "@/types"

export const OrganizationStatus = {
  ENABLED: { value: 'ENABLED', text: '有効', color: 'success' },
  DISABLED: { value: 'DISABLED', text: '無効', color: 'error' },
} as const
export type OrganizationStatusType = keyof typeof OrganizationStatus

export const PrefectureList = [
  { text: '北海道', value: '北海道' },
  { text: '青森県', value: '青森県' },
  { text: '岩手県', value: '岩手県' },
  { text: '宮城県', value: '宮城県' },
  { text: '秋田県', value: '秋田県' },
  { text: '山形県', value: '山形県' },
  { text: '福島県', value: '福島県' },
  { text: '茨城県', value: '茨城県' },
  { text: '栃木県', value: '栃木県' },
  { text: '群馬県', value: '群馬県' },
  { text: '埼玉県', value: '埼玉県' },
  { text: '千葉県', value: '千葉県' },
  { text: '東京都', value: '東京都' },
  { text: '神奈川県', value: '神奈川県' },
  { text: '新潟県', value: '新潟県' },
  { text: '富山県', value: '富山県' },
  { text: '石川県', value: '石川県' },
  { text: '福井県', value: '福井県' },
  { text: '山梨県', value: '山梨県' },
  { text: '長野県', value: '長野県' },
  { text: '岐阜県', value: '岐阜県' },
  { text: '静岡県', value: '静岡県' },
  { text: '愛知県', value: '愛知県' },
  { text: '三重県', value: '三重県' },
  { text: '滋賀県', value: '滋賀県' },
  { text: '京都府', value: '京都府' },
  { text: '大阪府', value: '大阪府' },
  { text: '兵庫県', value: '兵庫県' },
  { text: '奈良県', value: '奈良県' },
  { text: '和歌山県', value: '和歌山県' },
  { text: '鳥取県', value: '鳥取県' },
  { text: '島根県', value: '島根県' },
  { text: '岡山県', value: '岡山県' },
  { text: '広島県', value: '広島県' },
  { text: '山口県', value: '山口県' },
  { text: '徳島県', value: '徳島県' },
  { text: '香川県', value: '香川県' },
  { text: '愛媛県', value: '愛媛県' },
  { text: '高知県', value: '高知県' },
  { text: '福岡県', value: '福岡県' },
  { text: '佐賀県', value: '佐賀県' },
  { text: '長崎県', value: '長崎県' },
  { text: '熊本県', value: '熊本県' },
  { text: '大分県', value: '大分県' },
  { text: '宮崎県', value: '宮崎県' },
  { text: '鹿児島県', value: '鹿児島県' },
  { text: '沖縄県', value: '沖縄県' },
] as const

export const BankAccountType = {
  SAVING: { value: 'SAVING', text: '普通預金' },
  CHECKING: { value: 'CHECKING', text: '当座預金' },
} as const
export type BankAccountTypeType = keyof typeof BankAccountType

export const AgencyRevenueShareRateThreshold = {
  ABOVE: { value: 'ABOVE', text: '以上' },
  BELOW: { value: 'BELOW', text: '以下' },
} as const
export type AgencyRevenueShareRateThresholdType =
  keyof typeof AgencyRevenueShareRateThreshold

export const InvoiceNumberEnabled = {
  ENABLED: { value: 'ENABLED', text: '有効' },
  DISABLED: { value: 'DISABLED', text: '無効' },
} as const
export type InvoiceNumberEnabledType = keyof typeof InvoiceNumberEnabled

export type OrganizationItem = {
  id: number
  name: string
  addRate: number
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string
  department: string
  person: string
  phoneNumber: string
  notificationEmail: string
  invoiceNumberStatus: InvoiceNumberStatusType
  invoiceNumber: string
  invoiceNumberEnabled: string
  bankName: string
  bankCode: string
  branchName: string
  branchCode: string
  bankAccountType: BankAccountTypeType
  bankAccountNumber: string
  bankAccountHolder: string
  inviteUrl: string
  status: OrganizationStatusType
  defaultIsEntryRestricted: boolean
  createdAt: string
  updatedAt: string
}

export type OrganizationUserItem = {
  id: number
  name: string
  organizationId: number
  email: string
  permission: number
  deletedAt: string
  createdAt: string
  updatedAt: string
}

export type OrganizationUserPermissionItem = {
  name: string,
  permission: number
}