import type { EventEntryListItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetEventEntryParams = {
  page: number
  id?: string
  eventId?: string
  eventName?: string
  artistId?: string
  artistName?: string
  agencyId?: string
  agencyName?: string
  isDeleted?: boolean
  isNewcomer?: boolean
  isFirstGachi?: boolean
}

export type GetEventEntryResponse = APIResponse<
  APIResponseResultPagination<EventEntryListItem>
>

export const GetEventEntryPath = '/admin/v1/event-entry'

export const getEventEntry = async (params: GetEventEntryParams) => {
  return await http<GetEventEntryResponse>(GetEventEntryPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      event_id: params.eventId,
      event_name: params.eventName,
      artist_id: params.artistId,
      artist_name: params.artistName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
      is_deleted: params.isDeleted,
      is_newcomer: params.isNewcomer,
      is_first_gachi: params.isFirstGachi,
    },
  })
}
