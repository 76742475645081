import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventTypeType } from '@/types';

import {
  getDailyEventReport,
  getDailyEventReportPath,
} from '@/features/report/api/getDailyEventsReport'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '累計イベントレポート'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const eventId = searchParams.get('eventId') || ''
  const eventName = searchParams.get('eventName') || ''
  const dateFromParam = searchParams.get('dateFrom')
  const dateFrom = dateFromParam ? newDate(dateFromParam) : null
  const dateToParam = searchParams.get('dateTo')
  const dateTo = dateToParam ? newDate(dateToParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      eventId: eventId,
      eventName: eventName,
    },
  })

  const { data, isValidating, mutate } = useSWR(getDailyEventReportPath, () =>
    getDailyEventReport({
      page: page,
      eventId: eventId || undefined,
      eventName: eventName || undefined,
      dateFrom: dateFrom
        ? formatDateTime(dateFrom, Format.dateRequest)
        : undefined,
      dateTo: dateTo ? formatDateTime(dateTo, Format.dateRequest) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'eventId', value: getValues('eventId') },
        { key: 'eventName', value: getValues('eventName') },
        {
          key: 'dateFrom',
          value: formatDateTime(getValues('dateFrom'), Format.dateRequest),
        },
        {
          key: 'dateTo',
          value: formatDateTime(getValues('dateTo'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getDailyEventReport({
        page: page,
        eventId: getValues('eventId') || undefined,
        eventName: getValues('eventName') || undefined,
        dateFrom: dateFrom
          ? formatDateTime(getValues('dateFrom'), Format.dateRequest)
          : undefined,
        dateTo: dateTo
          ? formatDateTime(getValues('dateTo'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: `${item.date}-${item.eventId}`,
      date: item.date,
      eventId: item.eventId,
      eventName: item.eventName,
      eventType: {
        text: EventType[item.eventType].text,
        color: EventType[item.eventType].color,
        value: item.eventType,
      },
      activityUU: item.activityUU,
      postUU: item.postUU,
      liveUU: item.liveUU,
      postCount: item.postCount,
      liveCount: item.liveCount,
      liveDuration: item.liveDuration,
      cheerCount: item.cheerCount,
      pointCheerCount: item.pointCheerCount,
      freeCheerCount: item.freeCheerCount,
      limitedCheerCount: item.limitedCheerCount,
      cheerUU: item.cheerUU,
      pointCheerUU: item.pointCheerUU,
      freeCheerUU: item.freeCheerUU,
      limitedCheerUU: item.limitedCheerUU,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goEventDetail = (eventId: number, type: EventTypeType) => {
    navigate(`/events/${EventType[type].page}/:eventId`, {
      params: {
        eventId: String(eventId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='dateFrom'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='開始日'
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='dateTo'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='終了日'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventName'
              label='イベント名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'date',
              headerName: '日付',
              width: 100,
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 100,
              headerAlign: 'right',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'eventName',
              headerName: 'イベント名',
              width: 250,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() =>
                      goEventDetail(
                        params.row.eventId,
                        params.row.eventType.value,
                      )
                    }
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'eventType',
              headerName: '種別',
              width: 100,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'postUU',
              headerName: '投稿UU',
              width: 80,
              headerAlign: 'right',
            },
            {
              field: 'liveUU',
              headerName: 'ライブ配信UU',
              width: 120,
              headerAlign: 'right',
            },
            {
              field: 'activityUU',
              headerName: '活動UU',
              width: 120,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: '#f8f8f8',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'postCount',
              headerName: '投稿数',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'liveCount',
              headerName: 'ライブ数',
              width: 100,
              headerAlign: 'right',
            },
            {
              field: 'liveDuration',
              headerName: 'ライブ時間',
              width: 100,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'pointCheerCount',
              headerName: 'ポイントCHEER数',
              width: 220,
              headerAlign: 'right',
            },
            {
              field: 'freeCheerCount',
              headerName: 'ボーナスCHEER数',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'limitedCheerCount',
              headerName: 'イベント限定CHEER数',
              width: 170,
              headerAlign: 'right',
            },
            {
              field: 'cheerCount',
              headerName: 'CHEER総数',
              width: 130,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: '#f8f8f8',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'pointCheerUU',
              headerName: 'ポイントCHEER UU',
              width: 220,
              headerAlign: 'right',
            },
            {
              field: 'freeCheerUU',
              headerName: 'ボーナスCHEER UU',
              width: 150,
              headerAlign: 'right',
            },
            {
              field: 'limitedCheerUU',
              headerName: 'イベント限定CHEER UU',
              width: 180,
              headerAlign: 'right',
            },
            {
              field: 'cheerUU',
              headerName: 'CHEER UU数',
              width: 130,
              headerAlign: 'right',
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: '#f8f8f8',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
