import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostEventEntryParams = {
  eventId: number
  artistId: number
}

export const PostEventEntryPath = '/admin/v1/event-entry'

export const postEventEntry = async (params: PostEventEntryParams) => {
  return await http<APIResponse<null>>(PostEventEntryPath, {
    method: 'POST',
    body: JSON.stringify({
      event_id: params.eventId,
      artist_id: params.artistId,
    }),
  })
}
