import { http } from '@/lib/http'

export const getCsrfPath = '/sanctum/csrf-cookie'

export const getCsrf = async () => {
  return await http<null>(getCsrfPath, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': '',
    },
    credentials: 'include',
  })
}
