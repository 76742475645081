import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetDailyArtistReportParams = {
  page: number
  dateFrom?: string
  dateTo?: string
  artistId?: string
  artistName?: string
}

export type GetDailyArtistReportResponse = APIResponse<
  APIResponseResultPagination<{
    date: string
    artistId: number
    artistName: string
    fanId: number
    artistIconUrl: string
    postCount: number
    liveCount: number
    liveDuration: string
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUu: number
    pointCheerUu: number
    freeCheerUu: number
    limitedCheerUu: number
    createdAt: string
    updatedAt: string
  }>
>

export const getDailyArtistReportPath = '/admin/v1/daily-artist-report'

export const getDailyArtistReport = async (params: GetDailyArtistReportParams) => {
  return await http<GetDailyArtistReportResponse>(getDailyArtistReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      date_from: params.dateFrom,
      date_to: params.dateTo,
      artist_id: params.artistId,
      artist_name: params.artistName,
    },
  })
}
