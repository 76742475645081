import { Box, Divider, Typography } from '@mui/material'

function FormSectionContainer({
  sectionTitle,
  children,
}: {
  sectionTitle: string
  children: React.ReactNode
}) {
  return (
    <Box mb={6}>
      <Box mb={4}>
        <Typography fontWeight={'bold'}>{sectionTitle}</Typography>
        <Divider
          component={'hr'}
          sx={{ backgroundColor: 'black', marginTop: '6px' }}
        />
      </Box>
      <Box pl={2}>{children}</Box>
    </Box>
  )
}

export default FormSectionContainer
