import type { RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type PostArtistRevenueShareParams = {
  artistId: number
  revenueShareRate: number
  organizationId?: number
  agencyId?: number
  agencyRevenueShareType?: RevenueShareTypeType
  agencyRevenueShareRate?: number
  startAt: string
}

export const postArtistRevenueSharePath = '/admin/v1/artist-history'

export const postArtistRevenueShare = async (params: PostArtistRevenueShareParams) => {
  return await http<null>(postArtistRevenueSharePath, {
    method: 'POST',
    body: JSON.stringify({
      artist_id: params.artistId,
      organization_id: params.organizationId,
      agency_id: params.agencyId,
      revenue_share_rate: params.revenueShareRate,
      agency_revenue_share_type: params.agencyRevenueShareType,
      agency_revenue_share_rate: params.agencyRevenueShareRate,
      start_at: params.startAt,
    }),
  })
}
