import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid } from '@mui/material'

import type { BankFormRef } from '@/components/bank/BankForm';
import type { OrganizationFormRef } from '@/features/organization/components/OrganizationForm'

import BankForm from '@/components/bank/BankForm'
import { postOrganization } from '@/features/organization/api/postOrganization'
import OrganizationForm from '@/features/organization/components/OrganizationForm'
import { useNavigate } from '@/router'
import { BankAccountType, InvoiceNumberStatus } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = '事務所登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const organizationFormRef = useRef<OrganizationFormRef>(null)
  const bankFormRef = useRef<BankFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = organizationFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      const formBankValues = bankFormRef.current?.getFormValues()
      if (!formBankValues) {
        setIsLoading(false)
        return
      }
      await postOrganization({
        name: formValues.name,
        addRate: formValues.addRate,
        country: formValues.country,
        zipCode: formValues.zipCode,
        province: formValues.province,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        department: formValues.department,
        person: formValues.person,
        phoneNumber: formValues.phoneNumber,
        notificationEmail: formValues.notificationEmail,
        invoiceNumberStatus: formValues.invoiceNumberStatus,
        invoiceNumber:
          formValues.invoiceNumberStatus ===
          InvoiceNumberStatus.REGISTERED.value
            ? formValues.invoiceNumber
            : null,
        status: formValues.status,
        bankName: formBankValues.bankName,
        bankCode: formBankValues.bankCode,
        branchName: formBankValues.branchName,
        branchCode: formBankValues.branchCode,
        bankAccountType: formBankValues.bankAccountType
          ? BankAccountType[formBankValues.bankAccountType].value
          : undefined,
        bankAccountNumber: formBankValues.bankAccountNumber,
        bankAccountHolder: formBankValues.bankAccountHolder,
        defaultIsEntryRestricted: formValues.flags.includes(
          'defaultIsEntryRestricted',
        ),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/organizations')
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Grid container columnSpacing={12} width={'80%'} mt={10} ml={5}>
        <Grid item md={6}>
          <OrganizationForm ref={organizationFormRef} />
        </Grid>
        <Grid item md={6}>
          <BankForm ref={bankFormRef} />
        </Grid>
      </Grid>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
