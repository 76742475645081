import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid, Typography } from '@mui/material'

import type { BankFormRef } from '@/components/bank/BankForm';
import type { ArtistFormRef } from '@/features/fan/components/ArtistForm'
import type { FanFormRef } from '@/features/fan/components/FanForm'
import type { ArtistDetail } from '@/features/fan/types'

import BankForm from '@/components/bank/BankForm'
import { getFanAccountBan } from '@/features/fan/api/getFanAccountBan'
import { getFanDetail } from '@/features/fan/api/getFanDetail'
import { getFanDevice } from '@/features/fan/api/getFanDevice'
import { getFanDeviceBan } from '@/features/fan/api/getFanDeviceBan'
import { patchFan } from '@/features/fan/api/patchFan'
import { patchFanArtist } from '@/features/fan/api/patchFanArtist'
import { postFanForceWithdrawal } from '@/features/fan/api/postFanForceWithdrawal'
import { postFanWithdrawal } from '@/features/fan/api/postFanWithdrawal'
import ArtistForm from '@/features/fan/components/ArtistForm'
import FanForm from '@/features/fan/components/FanForm'
import {
  InvoiceNumberEnabled,
  NotificationSetting,
} from '@/features/fan/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { InvoiceNumberStatus, RevenueShareType } from '@/types'
import {
  isEmptyObj,
  pickErrorMessages,
  postUploadMedia,
} from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/fans/:fanId')
  const pageTitle = 'ファン詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const FanFormRef = useRef<FanFormRef>(null)
  const ArtistFormRef = useRef<ArtistFormRef>(null)
  const bankFormRef = useRef<BankFormRef>(null)
  const [artistData, setArtistData] = useState<ArtistDetail | null>(null)

  // 現在データの反映
  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getFanDetail({
        fanId: params.fanId,
      })

      const formatPoint = result.fan.points.map((point, index) => {
        return {
          id: index + 1,
          platform: point.platform,
          expiredAt: point.expiredAt
            ? formatDateTime(point.expiredAt, Format.dateJa)
            : '-',
          deposit: point.deposit,
        }
      })
      const formatFreeCheer = result.fan.cheer.freeCheer.map((cheer, index) => {
        return {
          id: index + 1,
          cheer: cheer.cheer,
          expiredAt: cheer.expiredAt
            ? formatDateTime(cheer.expiredAt, Format.dateJa)
            : '-',
        }
      })
      const formatLimitedCheer = result.fan.cheer.limitedCheer.map(
        (limitedCheer, index) => {
          return {
            id: index + 1,
            cheer: limitedCheer.cheer,
            eventId: limitedCheer.eventId,
            eventName: limitedCheer.eventName,
            expiredAt: limitedCheer.expiredAt
              ? formatDateTime(limitedCheer.expiredAt, Format.dateJa)
              : '-',
          }
        },
      )

      // ブロック中の項目抽出
      const permissions: number[] = NotificationSetting.filter(
        (val) => Number(val.key) & result.fan.pushNotificationBlockSetting,
      ).map((valObj) => valObj.key)
      const formatPermissionBlock = NotificationSetting.map((setting) => {
        return {
          text: setting.text,
          isBlock: permissions.includes(setting.key),
        }
      })

      // デバイス
      const { result: deviceList } = await getFanDevice({
        page: 1,
        fanId: params.fanId,
      })

      // BANデバイス
      const { result: deviceBanList } = await getFanDeviceBan({
        page: 1,
        fanId: Number(params.fanId),
      })

      // BANアカウント
      const { result: accountBanList } = await getFanAccountBan({
        page: 1,
        fanId: Number(params.fanId),
      })

      FanFormRef.current?.formReset({
        fanId: result.fan.id,
        artistId: result.fan.artistId,
        name: result.fan.name,
        profile: result.fan.profile,
        iconUrl: result.fan.iconUrl,
        iconFile: null,
        level: result.fan.level,
        birthdayVisibility: result.fan.birthdayVisibility,
        birthday: result.fan.birthday ? newDate(result.fan.birthday) : null,
        gender: result.fan.gender,
        loginCount: result.fan.loginCount,
        deletedAt: result.fan.deletedAt,
        status: result.fan.status,
        links: result.fan.links,
        createdAt: result.fan.createdAt,
        lastLoginAt: result.fan.lastLoginAt,
        points: formatPoint,
        freeCheer: formatFreeCheer,
        limitedCheer: formatLimitedCheer,
        authProviders: result.fan.authProviders,
        permissions: formatPermissionBlock,
        deviceList: deviceList.data,
        deviceBanList: deviceBanList.data,
        accountBanList: accountBanList.data,
      })

      // アーティストの場合ArtistFormの初期化
      if (!isEmptyObj(result.artist)) {
        // 別途useEffectするトリガーになる変数をセット
        setArtistData(result.artist)
      }
    }
    fetchData()
  }, [params])

  useEffect(() => {
    // アーティストフォームが描画されてからでないとArtistFormRefが取れないので別途useEffect
    if (artistData) {
      const flags = []
      if (artistData.isFirstGachi) flags.push('isFirstGachi')
      if (artistData.isNewcomer) flags.push('isNewcomer')
      if (artistData.isEntryRestricted) flags.push('isEntryRestricted')

      const formatJoinEvent = artistData.events.map((event) => {
        return {
          id: event.id,
          name: event.name,
          artistRevenueShareRate: `${event.artistRevenueShareRate}%`,
          agencyRevenueShareType: event.agencyRevenueShareType
            ? RevenueShareType[event.agencyRevenueShareType].text
            : '-',
          agencyRevenueShareRate: event.agencyRevenueShareRate
            ? `${event.agencyRevenueShareRate}%`
            : '-',
          createdAt: event.createdAt,
        }
      })
      ArtistFormRef.current?.formReset({
        artistId: artistData.id,
        revenueShareRate: artistData.revenueShareRate,
        flags: flags,
        fullName: artistData.fullName || '',
        country: artistData.country || '',
        zipCode: artistData.zipCode || '',
        province: artistData.province || '',
        city: artistData.city || '',
        address1: artistData.address1 || '',
        address2: artistData.address2 || '',
        invoiceNumberStatus: artistData.invoiceNumberStatus,
        invoiceNumberEnabled:
          artistData.invoiceNumberEnabled ??
          InvoiceNumberEnabled.DISABLED.value,
        invoiceNumber: artistData.invoiceNumber,
        createdAt: artistData.createdAt,
        updatedAt: artistData.updatedAt,
        organizationName: !isEmptyObj(artistData.organization)
          ? artistData.organization.name
          : '所属なし',
        agency: artistData.agency,
        events: formatJoinEvent,
      })
      bankFormRef.current?.formReset({
        bankName: artistData.bankName || '',
        bankCode: artistData.bankCode || '',
        branchName: artistData.branchName || '',
        branchCode: artistData.branchCode || '',
        bankAccountType: artistData.bankAccountType || '',
        bankAccountNumber: artistData.bankAccountNumber || '',
        bankAccountHolder: artistData.bankAccountHolder || '',
      })
    }
  }, [artistData])

  // 遷移
  const goList = () => {
    navigate('/fans')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = FanFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      const { result } = await patchFan({
        fanId: formValues.fanId,
        name: formValues.name,
        birthdayVisibility: formValues.birthdayVisibility,
        birthday: formatDateTime(formValues.birthday, Format.dateRequest),
        gender: formValues.gender,
        profile: formValues.profile ? formValues.profile : undefined,
        iconFileName: formValues.iconFile?.name
          ? formValues.iconFile.name
          : undefined,
      })

      // アイコンファイルアップロード
      if (formValues.iconFile) {
        const res = await postUploadMedia({
          url: result.iconFileUrl,
          file: formValues.iconFile,
        })
        if (!res) {
          await swal.messages({
            messages: ['アイコンファイルのアップロードに失敗しました。'],
          })
          return
        }
      }

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // アーティスト情報更新
  const submitArtist = async () => {
    try {
      setIsLoading(true)
      const artistFormValues = ArtistFormRef.current?.getFormValues()
      if (!artistFormValues) {
        setIsLoading(false)
        return
      }
      const formBankValues = bankFormRef.current?.getFormValues()
      if (!formBankValues) {
        setIsLoading(false)
        return
      }
      await patchFanArtist({
        artistId: artistFormValues.artistId,
        fullName: artistFormValues.fullName,
        country: artistFormValues.country,
        zipCode: artistFormValues.zipCode,
        province: artistFormValues.province,
        city: artistFormValues.city,
        address1: artistFormValues.address1,
        address2: artistFormValues.address2,
        isEntryRestricted: artistFormValues.flags.includes('isEntryRestricted'),
        invoiceNumberStatus: artistFormValues.invoiceNumberStatus,
        invoiceNumber:
          artistFormValues.invoiceNumberStatus ===
          InvoiceNumberStatus.REGISTERED.value
            ? artistFormValues.invoiceNumber
            : null,
        bankName: formBankValues.bankName ? formBankValues.bankName : undefined,
        bankCode: formBankValues.bankCode ? formBankValues.bankCode : undefined,
        branchName: formBankValues.branchName
          ? formBankValues.branchName
          : undefined,
        branchCode: formBankValues.branchCode
          ? formBankValues.branchCode
          : undefined,
        bankAccountType: formBankValues.bankAccountType
          ? formBankValues.bankAccountType
          : undefined,
        bankAccountNumber: formBankValues.bankAccountNumber
          ? formBankValues.bankAccountNumber
          : undefined,
        bankAccountHolder: formBankValues.bankAccountHolder
          ? formBankValues.bankAccountHolder
          : undefined,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 退会処理
  const submitWithdrawal = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '通常退会させますか？',
        text: '退会させると元に戻せません。',
      })

      if (!isConfirmed) return

      await postFanWithdrawal({
        fanId: params.fanId,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const submitForceWithdrawal = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '強制退会させますか？',
        text: '退会させると元に戻せません。デバイスもBANされます。',
      })

      if (!isConfirmed) return

      await postFanForceWithdrawal({
        fanId: params.fanId,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <FanForm ref={FanFormRef}></FanForm>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
      {artistData && (
        <>
          <Box mt={5}>
            <Typography variant='h6'>アーティスト情報</Typography>
          </Box>
          <Grid container columnSpacing={6} mt={10} ml={0}>
            <Grid item md={5}>
              <ArtistForm ref={ArtistFormRef}></ArtistForm>
            </Grid>
            <Grid item md={5}>
              <BankForm ref={bankFormRef} />
            </Grid>
          </Grid>
          <ActionButton
            size='large'
            isLoading={isLoading}
            fullWidth
            onClick={submitArtist}
          >
            更新する
          </ActionButton>
        </>
      )}
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        color='error'
        variant='outlined'
        sx={{ marginTop: 15 }}
        onClick={submitWithdrawal}
      >
        通常退会
      </ActionButton>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        color='error'
        sx={{ marginTop: 5 }}
        onClick={submitForceWithdrawal}
      >
        強制退会
      </ActionButton>
    </Box>
  )
}

export default Page
