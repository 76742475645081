import type { RsCategoryType } from '../types'

import { httpRaw } from '@/lib/httpRaw'

export type GetDailyRevenueReportDownloadCsvParams = {
  dateFrom?: string
  dateTo?: string
  artistId?: string
  artistName?: string
  eventId?: string
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
  category?: RsCategoryType
}

export const getDailyRevenueReportDownloadCsvPath =
  '/admin/v1/daily-revenue-report/download-csv'

export const getDailyRevenueReportDownloadCsv = async (
  params: GetDailyRevenueReportDownloadCsvParams,
) => {
  return await httpRaw(getDailyRevenueReportDownloadCsvPath, {
      method: 'GET',
      searchParams: {
        date_from: params.dateFrom,
        date_to: params.dateTo,
        event_id: params.eventId,
        artist_id: params.artistId,
        artist_name: params.artistName,
        organization_id: params.organizationId,
        organization_name: params.organizationName,
        agency_id: params.agencyId,
        agency_name: params.agencyName,
        category: params.category,
      },
    },
  )
}
