import type { TermListItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetTermsParams = {
  page: number
  category?: string
}

export type GetTermsResponse = APIResponse<
  APIResponseResultPagination<TermListItem>
>

export const getTermPath = '/admin/v1/term'

export const getTerm = async (params: GetTermsParams) => {
  return await http<GetTermsResponse>(getTermPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      category: params.category,
    },
  })
}
