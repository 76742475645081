import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import useSWR from 'swr'

import {
  getArtistDetail,
  getArtistDetailPath,
} from '@/features/artists/api/getArtistDetail'
import {
  getEventDetail,
  getEventDetailPath,
} from '@/features/event/api/getEventDetail'
import {
  getEventRankingFan,
  getEventRankingFanPath,
} from '@/features/event/api/getEventRankingFan'
import { useNavigate, useParams } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const params = useParams('/events/:eventId/ranking/artists/:artistId/fans')
  const { data: eventData } = useSWR(getEventDetailPath, () =>
    getEventDetail({
      eventId: Number(params.eventId),
    }),
  )
  const { data: artistData } = useSWR(getArtistDetailPath, () =>
    getArtistDetail({
      id: Number(params.artistId),
    }),
  )
  const eventName = eventData?.result.name ?? ''
  const artistName = artistData?.result.artistName ?? ''
  const pageTitle =
    eventName && artistName
      ? `[${eventName}] ${artistName}のファンランキング`
      : 'イベントファンランキング'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    eventDetailId: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const eventDetailId = searchParams.get('eventDetailId') || ''

  const { data, isValidating, mutate } = useSWR(getEventRankingFanPath, () =>
    getEventRankingFan({
      page: page,
      eventDetailId: Number(eventDetailId),
      artistId: Number(params.artistId),
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [{ key: 'page', value: String(page) }])
      return prev
    })
    mutate(
      getEventRankingFan({
        page: page,
        eventDetailId: Number(eventDetailId),
        artistId: Number(params.artistId),
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      rank: item.rank,
      score: item.score,
      cheerCount: item.cheerCount,
      fanId: item.fan.id,
      fanName: item.fan.name,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goEventEntry = () => {
    navigate('/events/:eventId/entries', {
      params: {
        eventId: String(params.eventId),
      },
    })
  }

  const goEventArtistRanking = () => {
    navigate(
      {
        pathname: '/events/:eventId/ranking/artists',
        search: `?eventDetailId=${eventDetailId}`,
      },
      {
        params: {
          eventId: params.eventId,
        },
      },
    )
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          {
            text: 'アーティストランキングへ戻る',
            onClick: goEventArtistRanking,
          },
          { text: 'エントリー一覧へ戻る', onClick: goEventEntry },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <Box>
        <DataGrid
          columns={[
            { field: 'rank', headerName: '順位', width: 100 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 100,
              renderCell: (params) => (
                <CopyClipboard text={params.value} isIcon={false} />
              ),
            },
            {
              field: 'fanName',
              headerName: 'ファン',
              width: 300,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            { field: 'score', headerName: 'スコア', width: 130 },
            { field: 'cheerCount', headerName: '総CHEER数', width: 140 },
          ]}
          idPropertyName={'rank'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'rank'}
        />
      </Box>
    </Box>
  )
}

export default Page
