import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { GetFollowPath, getFollow } from '@/features/fan/api/getFollow'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'フォロー一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
    artistId: '',
    artistName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName: fanName,
      artistId: artistId,
      artistName: artistName,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetFollowPath, () =>
    getFollow({
      page: page,
      fanId: fanId || undefined,
      fanName: fanName || undefined,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
      ])
      return prev
    })
    mutate(
      getFollow({
        page: page,
        fanId: getValues('fanId') || undefined,
        fanName: getValues('fanName') || undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      fanName: item.fanName,
      artistId: item.artistId,
      artistFanId: item.artistFanId,
      artistName: item.artistName,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='fanId'
            label='フォローしているファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='fanName'
            label='フォローしているファン名'
            fullWidth={false}
            hint={'部分一致'}
          />
          <FormText
            control={control}
            name='artistId'
            label='フォローされているアーティストID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='artistName'
            label='フォローされているアーティスト名'
            fullWidth={false}
            hint={'部分一致'}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 80,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: 'フォローしているファン名',
              width: 300,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistName',
              headerName: 'フォローされているアーティスト名',
              width: 300,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.artistId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'createdAt',
              headerName: 'フォロー日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
