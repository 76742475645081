import type { ArtistHistoryItemType } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetArtistLatestRevenueShareDetailParams = {
  artistId: number
}

export type GetArtistLatestRevenueShareDetailResponse =
  APIResponse<ArtistHistoryItemType>

export const getArtistLatestRevenueShareDetailPath = '/admin/v1/artist-history/:artistId/latest'

export const getArtistLatestRevenueShareDetail = async (
  params: GetArtistLatestRevenueShareDetailParams,
) => {
  return await http<GetArtistLatestRevenueShareDetailResponse>(
    getArtistLatestRevenueShareDetailPath,
    {
      method: 'GET',
      params: {
        artistId: params.artistId,
      },
    },
  )
}
