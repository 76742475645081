import type { FaqCategoryStatus } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostFaqCategoryParams = {
  name: string
  target: string
  sort?: number
  status?: FaqCategoryStatus
}

export type PostFaqCategoryResponse = APIResponse<null>

export const postFaqCategoryPath = '/admin/v1/faq-category'

export const postFaqCategory = async (params: PostFaqCategoryParams) => {
  return await http<PostFaqCategoryResponse>(postFaqCategoryPath, {
    method: 'POST',
    body: JSON.stringify({
      name: params.name,
      target: params.target,
      sort: params.sort,
      status: params.status,
    }),
  })
}
