import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined'
import { Box, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { SortTargetType, SortType } from '@/features/artists/types';

import { getFanRankPath, getFanRank } from '@/features/artists/api/getFanRank'
import { Sort, SortTarget } from '@/features/artists/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ファンランク一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
    artistId: '',
    artistName: '',
    sortTarget: '',
    sort: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const sortTarget = searchParams.get('sortTarget') || ''
  const sort = searchParams.get('sort') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName: fanName,
      artistId: artistId,
      artistName: artistName,
      sortTarget: sortTarget,
      sort: sort,
    },
  })

  const { data, isValidating, mutate } = useSWR(getFanRankPath, () =>
    getFanRank({
      page: page,
      fanId: fanId || undefined,
      fanName: fanName || undefined,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
      sortTarget: sortTarget as SortTargetType | undefined,
      sort: sort as SortType | undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'sortTarget', value: getValues('sortTarget') },
        { key: 'sort', value: getValues('sort') },
      ])
      return prev
    })
    mutate(
      getFanRank({
        page: page,
        fanId: getValues('fanId') || undefined,
        fanName: getValues('fanName') || undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        sortTarget: getValues('sortTarget') as SortTargetType | undefined,
        sort: getValues('sort') as SortType | undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  // 遷移
  const goHistory = (artistId = '', fanId = '') => {
    navigate({
      pathname: '/artists/fan-ranks/histories',
      search: `?artistId=${artistId}&fanId=${fanId}`,
    },
  )}
  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      artist: {
        id: item.artistId,
        name: item.artistName,
      },
      fan: {
        id: item.fanId,
        name: item.fanName,
      },
      rank: item.rank,
      cheerCountA: item.cheerCountA,
      cheerCountB: item.cheerCountB,
      watchingDuration: item.watchingDuration,
      monthlyCheerCount: item.monthlyCheerCount,
      rankUpdatedAt: item.rankUpdatedAt,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const sortTargetOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(SortTarget).map(([, value]) => ({
      value: value.value,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const sortOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(Sort).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        gap={4}
        mb={3}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='artistId'
            label='アーティストID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='artistName'
            label='アーティスト名'
            fullWidth={false}
            hint='部分一致'
          />
          <FormText
            control={control}
            name='fanId'
            label='ファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='fanName'
            label='ファン名'
            fullWidth={false}
            hint='部分一致'
          />
        </Box>
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormSelect
            control={control}
            name='sortTarget'
            label='並び替え対象'
            fullWidth={false}
            options={sortTargetOptions()}
          />
          <FormSelect
            control={control}
            name='sort'
            label='並び順'
            fullWidth={false}
            options={sortOptions()}
          />
        </Box>
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: '_history',
              headerName: '履歴',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <NotesOutlinedIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      goHistory(params.row.artist.id.toString(), params.row.fan.id.toString())
                    }
                  ></NotesOutlinedIcon>
                )
              },
            },
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'artist',
              headerName: 'アーティスト',
              width: 280,
              renderCell: (params) => (
                <Box>
                  <Typography component='span'>
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'fan',
              headerName: 'ファン',
              width: 280,
              renderCell: (params) => (
                <Box>
                  <Typography component='span'>
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            { field: 'rank', headerName: 'ランク', width: 100 },
            {
              field: 'cheerCountA',
              headerName: 'ルートA CHEER数',
              width: 150,
            },
            {
              field: 'cheerCountB',
              headerName: 'ルートB CHEER数',
              width: 150,
            },
            {
              field: 'watchingDuration',
              headerName: 'ライブ配信の視聴時間(秒)',
              width: 200,
            },
            {
              field: 'monthlyCheerCount',
              headerName: '月間CHEER数',
              width: 120,
            },
            {
              field: 'rankUpdatedAt',
              headerName: 'ランク更新日時',
              width: 160,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 160,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 160,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editHeaderName='履歴'
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
