import type { FaqItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFaqParams = {
  page: number
  faqCategoryId?: string
  title?: string
  status?: string
  categoryTarget?: string
}

export type GetFaqResponse = APIResponse<APIResponseResultPagination<FaqItem>>

export const getFaqPath = '/admin/v1/faq'

export const getFaq = async (params: GetFaqParams) => {
  return await http<GetFaqResponse>(getFaqPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      faq_category_id: params.faqCategoryId,
      title: params.title,
      status: params.status,
      category_target: params.categoryTarget,
    },
  })
}
