import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteAgencyArtistParams = {
  agencyArtistId: number
}

export const deleteAgencyArtistPath =
  '/admin/v1/artist/:agencyArtistId/detach-agency'

export const deleteAgencyArtist = async (
  params: DeleteAgencyArtistParams,
) => {
  return await http<APIResponse<null>>(deleteAgencyArtistPath, {
    method: 'DELETE',
    params: {
      agencyArtistId: params.agencyArtistId,
    },
  })
}
