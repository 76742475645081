import type { AccountBanListItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanDeviceAccountParams = {
  page: number
  fanId?: number
}

export type GetFanDeviceAccountResponse = APIResponse<
  APIResponseResultPagination<AccountBanListItem>
>

export const getFanDeviceAccountPath = '/admin/v1/fan-account-ban'

export const getFanAccountBan = async (params: GetFanDeviceAccountParams) => {
  return await http<GetFanDeviceAccountResponse>(getFanDeviceAccountPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
    },
  })
}
