import type { APIResponse, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetEventEntryDetailParams = {
  entryId: number
}

export type EventEntryItem = {
  id: number
  eventId: number
  eventName: string
  fanId: number
  artistId: number
  artistName: string
  agencyId: number | null
  agencyName: string | null
  artistRevenueShareRate: number
  agencyRevenueShareType: {
    key: RevenueShareTypeType | null
    value: string | null
  }
  agencyRevenueShareRate: number | null
  isDeleted: boolean
  isNewcomer: boolean
  isFirstGachi: boolean
  entryAt: string
  updatedAt: string
}

export type GetEventEntryDetailResponse = APIResponse<EventEntryItem>

export const GetEventEntryDetailPath = '/admin/v1/event-entry/:entryId'

export const getEventEntryDetail = async (
  params: GetEventEntryDetailParams,
) => {
  return await http<GetEventEntryDetailResponse>(GetEventEntryDetailPath, {
    method: 'GET',
    params: {
      entryId: params.entryId,
    },
  })
}
