import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetRankingFanDailyParams = {
  artistId: number
  page: number
  date? : string
}

export type GetRankingFanDailyResponse = APIResponse<
  APIResponseResultPagination<{
    rank: number
    score: number
    cheerCount: number
    fan: {
      id: number
      name: string
      iconUrl: string
    }
  }>
>

export const getRankingFanDailyPath = '/admin/v1/ranking/daily/fan-ranking/:artistId'

export const getRankingFanDaily = async (params: GetRankingFanDailyParams) => {
  return await http<GetRankingFanDailyResponse>(getRankingFanDailyPath, {
    method: 'GET',
    params: {
      artistId: params.artistId,
    },
    searchParams: {
      page: params.page,
      date: params.date,
    },
  })
}
