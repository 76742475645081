import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFollowParams = {
  page: number
  fanId?: string
  fanName?: string
  artistId?: string
  artistName?: string
}

export type GetFollowResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    artistId: number
    artistFanId: number
    artistName: string
    createdAt: string
    updatedAt: string
  }>
>

export const GetFollowPath = '/admin/v1/follow'

export const getFollow = async (params: GetFollowParams) => {
  return await http<GetFollowResponse>(GetFollowPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      artist_id: params.artistId,
      artist_name: params.artistName,
    },
  })
}
