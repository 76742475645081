import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteArtistPickupParams = {
  id: number
}

export type DeleteArtistPickupResponse = APIResponse<null>

export const deleteArtistPickupPath = '/admin/v1/pickup-artist/:id'

export const deleteArtistPickup = async (params: DeleteArtistPickupParams) => {
  return await http<DeleteArtistPickupResponse>(deleteArtistPickupPath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
