import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMailBounceParams = {
  page: number
  email?: string
}

export type GetMailBounceResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    bounceType: string
    bounceSubType: string
    bounceTypeJp: string
    email: string
    detail: string
    createdAt: string
    updatedAt: string
  }>
>

export const getMailBouncePath = '/admin/v1/mail-bounce'

export const getMailBounce = async (params: GetMailBounceParams) => {
  return await http<GetMailBounceResponse>(getMailBouncePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      email: params.email,
    },
  })
}
