import type {
  ArtistDetail,
  AuthProviderItem,
  BirthdayVisibilityType,
  FanLinkItem,
  GenderType,
  PointHistoryItem,
} from '../types'
import type { APIResponse, FanStatusType } from '@/types'

import { http } from '@/lib/http'

export type GetEventDetailParams = {
  fanId: string
}

export type GetEventDetailResponse = APIResponse<{
  fan: {
    id: number
    artistId: number
    iconUrl: string
    name: string
    profile: string
    birthday: string
    birthdayVisibility: BirthdayVisibilityType
    gender: GenderType
    level: number
    pushNotificationBlockSetting: number
    status: FanStatusType
    points: PointHistoryItem[]
    cheer: {
      freeCheer: {
        cheer: number
        expiredAt: string
      }[]
      limitedCheer: {
        cheer: number
        eventId: number
        eventName: string
        expiredAt: string
      }[]
    }
    authProviders: AuthProviderItem[]
    links: FanLinkItem[]
    loginCount: number
    lastLoginAt: string
    deletedAt: string
    createdAt: string
    updatedAt: string
  }
  artist: ArtistDetail
}>

export const getFanDetailPath = '/admin/v1/fan/:fanId'

export const getFanDetail = async (params: GetEventDetailParams) => {
  return await http<GetEventDetailResponse>(getFanDetailPath, {
    method: 'GET',
    params: {
      fanId: params.fanId,
    },
  })
}
