import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteOrganizationArtistParams = {
  organizationArtistId: number
}

export const deleteOrganizationArtistPath =
  '/admin/v1/artist/:organizationArtistId/detach-organization'

export const deleteOrganizationArtist = async (
  params: DeleteOrganizationArtistParams,
) => {
  return await http<APIResponse<null>>(deleteOrganizationArtistPath, {
    method: 'DELETE',
    params: {
      organizationArtistId: params.organizationArtistId,
    },
  })
}
