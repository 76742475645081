import { addDays } from 'date-fns'

import { newDate } from '@/lib/dateFns'

//下記の表示期間・募集期間・イベント期間の開始終了日時の計算結果を返す
// 表示期間：現在日時の翌日〜表示開始+60日
// 募集期間：表示開始+1日後〜募集開始+30日
// イベント期間：募集終了+1日〜表示終了-1日
export const calcDefaultDate = () => {
  const displayStartAt = addDays(newDate(), 1)
  const displayEndAt = addDays(displayStartAt, 60)
  const startEntryAt = addDays(displayStartAt, 1)
  const endEntryAt = addDays(startEntryAt, 30)
  const startEventAt = addDays(endEntryAt, 1)
  const endEventAt = addDays(displayEndAt, -1)

  return {
    displayStartAt,
    displayEndAt,
    startEntryAt,
    endEntryAt,
    startEventAt,
    endEventAt,
  }
}
