import { http } from '@/lib/http'

export type PatchFanIdGroupParams = {
  id: number
  name: string
  fanIds?: number[]
}

export const patchFanIdGroupPath = '/admin/v1/fan-id-group/:id'

export const patchFanIdGroup = async (params: PatchFanIdGroupParams) => {
  return await http<null>(patchFanIdGroupPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      name: params.name,
      fan_ids: params.fanIds,
    }),
  })
}
