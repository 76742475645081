import { useState } from 'react'

import MediaModal from '@cz_frontend/ui/components/common/MediaModal/MediaModal'
import { Avatar, Box, Typography } from '@mui/material'

import { MediaType, type InquiryFile, type MediaTypeType } from '../types'

export default function MessageReplyItem({
  fromUserIconUrl,
  fromUserName,
  body,
  inquiryFiles,
}: {
  fromUserIconUrl: string
  fromUserName: string
  body: string
  inquiryFiles: InquiryFile[]
}) {
  const [isShowModal, setIsShowModal] = useState(false)
  const [fileData, setFileData] = useState<{
    type: MediaTypeType
    url: string
  }>({
    type: MediaType.IMAGE.value,
    url: '',
  })
  const openModal = (type: MediaTypeType, url: string) => {
    setFileData({
      type: type,
      url: url,
    })
    setIsShowModal(true)
  }
  const closeModal = () => {
    setIsShowModal(false)
  }
  return (
    <>
      <Box borderColor={'primary.main'} border={1} borderRadius={1} p={1}>
        <Box display={'flex'} gap={1} alignItems={'center'} mb={1}>
          <Avatar src={fromUserIconUrl} />
          <Typography>{fromUserName}</Typography>
        </Box>
        <Typography whiteSpace={'pre-wrap'}>{body}</Typography>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          {inquiryFiles.map((file) => (
            <Box sx={{ width: '30%', p: 2 }} key={file.url}>
              <img
                src={file.type === 'IMAGE' ? file.url : file.thumbnailUrl}
                alt=''
                width={'100%'}
                height={'100%'}
                onClick={() => openModal(file.type, file.url)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <MediaModal
        isShow={isShowModal}
        onCallback={closeModal}
        type={fileData.type}
        url={fileData.url}
        allowOrigin={import.meta.env.VITE_API_BASE_URL}
      ></MediaModal>
    </>
  )
}
