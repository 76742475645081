import sweetalert from 'sweetalert2'

import type { SweetAlertOptions } from 'sweetalert2'

// ベースとなるスタイルを調整
const base = sweetalert.mixin({
  confirmButtonColor: 'var(--color-primary)',
  confirmButtonText: 'OK',
  cancelButtonText: 'キャンセル',
})

const fire = (options: SweetAlertOptions) => {
  return sweetalert.fire(options)
}

/**
 * 確認
 * @returns
 */
const confirm = (options: {
  title?: string
  text?: string
  confirmButtonText?: string
}) => {
  return base.fire({
    title: options?.title || '確認',
    text: options?.text || '操作を続行します。よろしいですか？',
    confirmButtonText: options?.confirmButtonText || 'OK',
    showCancelButton: true,
  })
}

/**
 * アラート
 * @returns
 */
const alert = (options: { text: string }) => {
  return base.fire({
    title: ' ',
    confirmButtonText: '閉じる',
    text: options.text,
  })
}

/**
 * 入力エラー
 * @returns
 */
const invalid = () => {
  return base.fire({
    title: ' ',
    confirmButtonText: 'OK',
    text: '入力内容を確認してください。',
  })
}

/**
 * エラーメッセージ
 * @returns
 */
const messages = (options: { messages: Array<string> }) => {
  return base.fire({
    title: ' ',
    confirmButtonText: 'OK',
    html: options.messages.join('<br>'),
  })
}

// トースト
const Toast = base.mixin({
  toast: true,
  position: 'top',
  padding: '0.5rem 1rem',
  showConfirmButton: false,
  timer: 3000,
})

/**
 * 正常完了トースト
 * @returns
 */
const toastSuccess = ({ title = '登録に成功しました' } = {}) => {
  return Toast.fire({
    icon: 'success',
    title: title,
  })
}

export const swal = {
  fire,
  confirm,
  alert,
  invalid,
  messages,
  toastSuccess,
}
