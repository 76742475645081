import { http } from '@/lib/http'

export interface PostPaymentReportUploadCsvFormData extends FormData {
  append(
    name: keyof {
      _method: string
      file?: Blob
    },
    value: string | number | boolean | Blob | null,
    fileName?: string,
  ): void
}

export const postPaymentReportUploadCsvPath =
  '/admin/v1/payment-report/upload-csv'

export const postPaymentReportUploadCsv = async (
  formData: PostPaymentReportUploadCsvFormData,
) => {
  return await http(postPaymentReportUploadCsvPath, {
    method: 'POST',
    body: formData,
    headers: {}, // デフォルトのヘッダーを使わない
  })
}
