import { forwardRef, useImperativeHandle, useState } from 'react'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormSwitch from '@cz_frontend/ui/components/forms/FormSwitch/FormSwitch'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { Box, FormLabel } from '@mui/material'
import { useForm } from 'react-hook-form'

import {
  InvoiceNumberEnabled,
} from '../types'

import type {
  FanDetailArtistAgency,
  InvoiceNumberEnabledType,
} from '../types'
import type { InvoiceNumberStatusType} from '@/types';

import { PrefectureList } from '@/features/organization/types'
import { useNavigate } from '@/router'
import { InvoiceNumberStatus, RevenueShareType } from '@/types'
import { isEmptyObj } from '@/utils/functions'

type ArtistFormValues = {
  artistId: number
  revenueShareRate: number
  flags: string[]
  fullName: string
  country: string
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string
  invoiceNumberStatus: InvoiceNumberStatusType
  invoiceNumberEnabled: InvoiceNumberEnabledType
  invoiceNumber: string
  createdAt: string
  updatedAt: string
  organizationName: string
  agency: FanDetailArtistAgency | []
  events: {
    id: number
    name: string
    artistRevenueShareRate: string
    agencyRevenueShareType: string
    agencyRevenueShareRate: string
    createdAt: string
  }[]
}

interface ArtistFormProps {}
export interface ArtistFormRef {
  getFormValues(): ArtistFormValues
  setFormValue(key: keyof ArtistFormValues, value: string): void
  formReset(values: ArtistFormValues): void
}

const ArtistForm = forwardRef<
  ArtistFormRef,
  ArtistFormProps
  // eslint-disable-next-line no-empty-pattern
>(({}, ref) => {
  const { control, getValues, setValue, reset } = useForm<ArtistFormValues>({
    defaultValues: {
      artistId: 0,
      organizationName: '所属なし',
      revenueShareRate: 0,
      agency: [],
      flags: [],
      fullName: '',
      country: '',
      zipCode: '',
      province: '',
      city: '',
      address1: '',
      address2: '',
      createdAt: '',
      invoiceNumberStatus: InvoiceNumberStatus.UNSET.value,
      invoiceNumberEnabled: InvoiceNumberEnabled.DISABLED.value,
      invoiceNumber: '',
    },
  })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const [isInputInvoice, setIsInputInvoice] = useState(false)

  const navigate = useNavigate()

  const goArtistRevenueShare = (artistId = '') => {
    navigate({
      pathname: '/artists/revenue-share',
      search: `?artistId=${artistId}`,
    })
  }

  const goArtistRevenueShareCreate = (artistId = '') => {
    navigate({
      pathname: '/artists/revenue-share/create',
      search: `?artistId=${artistId}`,
    })
  }

  const provinces = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(PrefectureList).map(([, value]) => ({
      value: value.value,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <>
      <FormSectionContainer sectionTitle='基本情報'>
        <FormValue label={'アーティストID'}>{getValues('artistId')}</FormValue>
        <FormValue label={'事務所'}>{getValues('organizationName')}</FormValue>
        <FormLabel>レベニューシェア</FormLabel>
        <Box pl={4}>
          <FormValue label='基本RS率(%)'>
            {getValues('revenueShareRate')}%
          </FormValue>
          {!isEmptyObj(getValues('agency')) && (
            <>
              <FormValue label='代理店名'>{getValues('agency.name')}</FormValue>
              <FormValue label='代理店RSタイプ'>
                {RevenueShareType[getValues('agency.revenueShareType')].text}
              </FormValue>
              <FormValue label='代理店RS率(%)'>
                {getValues('agency.revenueShareRate')}
              </FormValue>
            </>
          )}
        </Box>
        <Box display={'flex'} gap={1} mb={2}>
          <ActionButton
            onClick={() => {
              goArtistRevenueShare(String(getValues('artistId')))
            }}
          >
            履歴
          </ActionButton>
          <ActionButton
            onClick={() => {
              goArtistRevenueShareCreate(String(getValues('artistId')))
            }}
          >
            更新
          </ActionButton>
        </Box>
        <Box mt={5}>
          <FormText control={control} name='fullName' label='氏名' />
          <FormText
            control={control}
            name='country'
            label='国'
            hint='設定しない場合は"JP"が自動で設定されます。'
          />

          <FormText
            control={control}
            name='zipCode'
            label='郵便番号'
            hint='ハイフンなし'
          />

          <FormSelect
            control={control}
            fullWidth={false}
            name='province'
            label='都道府県'
            options={provinces()}
          />

          <FormText control={control} name='city' label='市町村' />

          <FormText control={control} name='address1' label='住所1' />

          <FormText control={control} name='address2' label='住所2（建物名）' />

          <FormSelect
            control={control}
            fullWidth={false}
            name='invoiceNumberStatus'
            label='インボイス登録番号ステータス'
            options={Object.entries(InvoiceNumberStatus).map(([, value]) => ({
              value: value.value,
              text: value.text,
            }))}
            onChange={() => {
              if (
                getValues('invoiceNumberStatus') ===
                InvoiceNumberStatus.REGISTERED.value
              ) {
                setIsInputInvoice(true)
              } else {
                setIsInputInvoice(false)
              }
            }}
            required
          />
          <FormText
            control={control}
            name='invoiceNumber'
            label='インボイス登録番号'
            hint='T + 13桁の番号 / インボイス登録番号ステータスで未設定・登録しない場合は値がクリアされます。'
            disabled={!isInputInvoice}
          />
        </Box>
        <FormSwitch
          control={control}
          name='flags'
          disabled
          options={[
            {
              value: 'isNewcomer',
              text: '新人アーティスト',
            },
            {
              value: 'isFirstGachi',
              text: '初挑戦',
            },
          ]}
        />
        <FormSwitch
          control={control}
          name='flags'
          options={[
            {
              value: 'isEntryRestricted',
              text: 'イベントへのエントリー制限',
            },
          ]}
        />
        <FormValue label='登録日時'>{getValues('createdAt')}</FormValue>
      </FormSectionContainer>
      <FormSectionContainer sectionTitle='参加イベント（最新5件） ※削除含む'>
        {getValues('events') && (
          <DataGrid
            columns={[
              {
                field: 'id',
                headerName: 'イベントID',
                width: 100,
              },
              {
                field: 'name',
                headerName: 'イベント名',
                width: 200,
              },
              {
                field: 'artistRevenueShareRate',
                headerName: 'アーティストRS率',
                width: 140,
              },
              {
                field: 'agencyRevenueShareType',
                headerName: 'RSタイプ',
                width: 100,
              },
              {
                field: 'agencyRevenueShareRate',
                headerName: '代理店RS率',
                width: 100,
              },
              {
                field: 'createdAt',
                headerName: 'イベント作成日時',
                width: 200,
              },
            ]}
            rows={getValues('events') ?? []}
          />
        )}
      </FormSectionContainer>
    </>
  )
})

export default ArtistForm
