import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import { CircularProgress } from '@mui/material'
import { Box, Pagination, Typography } from '@mui/material'
import { DataGrid as MuiDataGrid, gridClasses } from '@mui/x-data-grid'

import type { GridColDef, GridRowsProp } from '@mui/x-data-grid'

function DataGrid({
  columns,
  rows,
  idPropertyName = 'id',
  total,
  page = 1,
  lastPage = 0,
  onPageChange,
  isLoading = false,
  isSticky = true,
  editReferProp = 'id',
  onEditClick,
  editHeaderName = '編集',
  rowHeight = 52,
}: {
  columns: GridColDef[]
  rows: GridRowsProp
  idPropertyName?: string
  /** 現在のページ */
  page?: number
  /** 全件 */
  total?: number
  /** ページ総数 */
  lastPage?: number
  /** ページ変更 */
  onPageChange?: (value: number) => void
  /** 読み込み中の表示をする場合はtrue */
  isLoading?: boolean
  /** ヘッダーを固定しない場合はfalse */
  isSticky?: boolean
  /** 編集画面に遷移時に参照するプロパティ（デフォルトはid）  */
  editReferProp?: string
  /** 編集画面に遷移するボタンを押した（editReferPropを指定しない場合は必ずid列が必要）  */
  onEditClick?: (id: string) => void
  editHeaderName?: string
  /** 行の高さ */
  rowHeight?: number
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowId = (row: any) => {
    return row[idPropertyName]
  }
  if (onEditClick && !columns.find((c) => c.field === '_edit')) {
    columns.unshift({
      field: '_edit',
      headerName: editHeaderName,
      width: 50,
      align: 'center',
      renderCell: (params) => {
        return (
          <EditNoteOutlinedIcon
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => onEditClick(params.row[editReferProp].toString())}
          />
        )
      },
    })
  }

  // biome-ignore lint/complexity/noForEach: <explanation>
  columns.forEach((column) => {
    column.sortable = false
    column.disableColumnMenu = true
    column.resizable = false
    if (!column.align) {
      column.align =
        rows.length > 0 && typeof rows[0][column.field] === 'number'
          ? 'right'
          : 'left'
    }

    // 列のデータ型が数値の場合、値を3桁ごとにカンマ区切りにする（ID系のカラムは対象外）
    const originalRenderCell = column.renderCell
    column.renderCell = (params) => {
      let formattedValue = params.value
      if (
        typeof params.value === 'number' &&
        !column.field.toLowerCase().includes('id')
      ) {
        formattedValue = params.value.toLocaleString()
      }
      if (originalRenderCell) {
        return originalRenderCell({ ...params, value: formattedValue })
      }
      return formattedValue
    }
  })

  const handleChange = (_e: React.ChangeEvent<unknown>, value: number) => {
    if (!onPageChange) return
    onPageChange(value)
  }

  return (
    <Box>
      {page && lastPage && total && onPageChange ? (
        <Box pb={1} display='flex' alignItems='center'>
          <Pagination count={lastPage} page={page} onChange={handleChange} />
          <Typography fontSize={12} ml={1}>
            {rows.length}件を表示 / 全{total}件
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      {isLoading ? (
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      ) : (
        <Box maxWidth={'96vw'}>
          <MuiDataGrid
            columns={columns}
            rows={rows}
            hideFooter
            hideFooterSelectedRowCount
            hideFooterPagination
            autoHeight
            rowHeight={rowHeight}
            getRowId={getRowId}
            sx={
              isSticky
                ? (theme) => ({
                    [`.${gridClasses.main}`]: {
                      overflow: 'unset',
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: 'sticky',
                      top: 0,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1,
                    },
                    [`.${gridClasses.virtualScroller}`]: {
                      marginTop: '0 !important',
                    },
                  })
                : () => ({})
            }
          />
        </Box>
      )}
    </Box>
  )
}

export default DataGrid
