import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchTermParams = {
  id: number
  category: string
  content: string
  publishedAt?: string
}

export type PatchTermResponse = APIResponse<null>

export const patchTermPath = '/admin/v1/term/:id'

export const patchTerm = async (params: PatchTermParams) => {
  return await http<PatchTermResponse>(patchTermPath, {
    method: 'PATCH',
    params: {
      id: params.id,
    },
    body: JSON.stringify({
      category: params.category,
      content: params.content,
      published_at: params.publishedAt,
    }),
  })
}
