import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getTerm, getTermPath } from '@/features/terms/api/getTerm'
import { TermCategory } from '@/features/terms/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '利用規約'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    category: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const category = searchParams.get('category') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      category: category,
    },
  })

  const { data, isValidating, mutate } = useSWR(getTermPath, () =>
    getTerm({
      page: page,
      category: category || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'category', value: getValues('category') },
      ])
      return prev
    })
    mutate(
      getTerm({
        page: page,
        category: getValues('category') || undefined,
      }),
    )
  }

  const handleSearch = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  const goDetail = (id = '') => {
    navigate('/terms/:termId', {
      params: {
        termId: id,
      },
    })
  }

  const categories = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(TermCategory).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const goCreate = () => {
    navigate('/terms/create')
  }


  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      category: TermCategory[item.category.key].text,
      content: item.content,
      publishedAt: item.publishedAt,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        width={1 / 4}
        display={'flex'}
        gap={1}
        alignItems={'center'}
        onSubmit={handleSearch}
        component='form'
      >
        <FormSelect
          control={control}
          name='category'
          label='カテゴリー'
          options={categories()}
        />
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'category',
              headerName: 'カテゴリー',
              width: 280,
            },
            { field: 'content', headerName: '内容', width: 200 },
            { field: 'publishedAt', headerName: '公開日時', width: 150 },
            { field: 'createdAt', headerName: '作成日時', width: 150 },
            { field: 'updatedAt', headerName: '更新日時', width: 150 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
