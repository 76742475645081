import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetArtistPickUpParams = {
  page: number
  artistId?: number
  isDisplayed?: string
}

export type GetArtistPickUpResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    artist: {
      id: number
    }
    fan: {
      name: string
    }
    startAt: string
    endAt: string
    memo: string
    createdAt: string
    updatedAt: string
  }>
>

export const getArtistPickUpPath = '/admin/v1/pickup-artist'

export const getArtistPickUp = async (params: GetArtistPickUpParams) => {
  return await http<GetArtistPickUpResponse>(getArtistPickUpPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      artist_id: params.artistId,
      is_displayed: params.isDisplayed,
    },
  })
}
