import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid } from '@mui/material'

import type { BankFormRef } from '@/components/bank/BankForm';
import type { OrganizationFormRef } from '@/features/organization/components/OrganizationForm'

import BankForm from '@/components/bank/BankForm'
import { getOrganizationDetail } from '@/features/organization/api/getOrganizationDetail'
import { putOrganization } from '@/features/organization/api/putOrganization'
import OrganizationForm from '@/features/organization/components/OrganizationForm'
import { useNavigate, useParams } from '@/router'
import { BankAccountType, InvoiceNumberStatus } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/organizations/:organizationId')
  const pageTitle = '事務所詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const organizationFormRef = useRef<OrganizationFormRef>(null)
  const bankFormRef = useRef<BankFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getOrganizationDetail({
        id: Number(params.organizationId),
      })

      const flags = []
      if (result.defaultIsEntryRestricted) {
        flags.push('defaultIsEntryRestricted')
      }
      organizationFormRef.current?.formReset({
        name: result.name,
        addRate: result.addRate,
        country: 'JP',
        zipCode: result.zipCode || '',
        province: result.province || '',
        city: result.city || '',
        address1: result.address1 || '',
        address2: result.address2 || '',
        department: result.department || '',
        person: result.person || '',
        phoneNumber: result.phoneNumber || '',
        notificationEmail: result.notificationEmail || '',
        invoiceNumberStatus: result.invoiceNumberStatus,
        invoiceNumber: result.invoiceNumber || '',
        status: result.status,
        flags: flags,
      })
      bankFormRef.current?.formReset({
        bankName: result.bankName || '',
        bankCode: result.bankCode || '',
        branchName: result.branchName || '',
        branchCode: result.branchCode || '',
        bankAccountType: result.bankAccountType || '',
        bankAccountNumber: result.bankAccountNumber || '',
        bankAccountHolder: result.bankAccountHolder || '',
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/organizations')
  }

  const goArtistList = () => {
    navigate({
      pathname: '/organizations/artists',
      search: `?organizationId=${params.organizationId}`,
    })
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = organizationFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      const formBankValues = bankFormRef.current?.getFormValues()
      if (!formBankValues) {
        setIsLoading(false)
        return
      }

      await putOrganization({
        id: Number(params.organizationId),
        name: formValues.name,
        addRate: formValues.addRate,
        country: formValues.country,
        zipCode: formValues.zipCode,
        province: formValues.province,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        department: formValues.department,
        person: formValues.person,
        phoneNumber: formValues.phoneNumber,
        notificationEmail: formValues.notificationEmail,
        invoiceNumberStatus: formValues.invoiceNumberStatus,
        invoiceNumber:
          formValues.invoiceNumberStatus ===
          InvoiceNumberStatus.REGISTERED.value
            ? formValues.invoiceNumber
            : null,
        status: formValues.status,
        bankName: formBankValues.bankName,
        bankCode: formBankValues.bankCode,
        branchName: formBankValues.branchName,
        branchCode: formBankValues.branchCode,
        bankAccountType: formBankValues.bankAccountType
          ? BankAccountType[formBankValues.bankAccountType].value
          : undefined,
        bankAccountNumber: formBankValues.bankAccountNumber,
        bankAccountHolder: formBankValues.bankAccountHolder,
        defaultIsEntryRestricted: formValues.flags.includes(
          'defaultIsEntryRestricted',
        ),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle
        actions={[
          { text: '所属アーティスト一覧へ', onClick: goArtistList },
          { text: '事務所一覧に戻る', onClick: goList },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <Grid container columnSpacing={3} ml={3}>
        <Grid item md={5}>
          <OrganizationForm ref={organizationFormRef} />
        </Grid>
        <Grid item md={5}>
          <BankForm ref={bankFormRef} />
        </Grid>
      </Grid>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
