import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getDailyRevenueReport,
  getDailyRevenueReportPath,
} from '@/features/report/api/getDailyRevenueReport'
import { getDailyRevenueReportDownloadCsv } from '@/features/report/api/getDailyRevenueReportDownloadCsv'
import { RsCategory } from '@/features/report/types'
import { Format, formatDateTime } from '@/lib/dateFns'
import { RevenueShareType } from '@/types'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '日別アーティスト収益（通常）一覧'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const dateFrom = searchParams.get('dateFrom') || null
  const dateTo = searchParams.get('dateTo') || null
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const organizationId = searchParams.get('organizationId') || ''
  const organizationName = searchParams.get('organizationName') || ''
  const agencyId = searchParams.get('agencyId') || ''
  const agencyName = searchParams.get('agencyName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      artistId: artistId,
      artistName: artistName,
      organizationId: organizationId,
      organizationName: organizationName,
      agencyId: agencyId,
      agencyName: agencyName,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    `${getDailyRevenueReportPath}-${RsCategory.BASIC.value}`,
    () =>
      getDailyRevenueReport({
        page: page,
        dateFrom: dateFrom
          ? formatDateTime(dateFrom, Format.dateRequest)
          : undefined,
        dateTo: dateTo ? formatDateTime(dateTo, Format.dateRequest) : undefined,
        artistId: artistId || undefined,
        artistName: artistName || undefined,
        organizationId: organizationId || undefined,
        organizationName: organizationName || undefined,
        agencyId: agencyId || undefined,
        agencyName: agencyName || undefined,
        category: RsCategory.BASIC.value,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'dateFrom',
          value: formatDateTime(getValues('dateFrom'), Format.dateRequest),
        },
        {
          key: 'dateTo',
          value: formatDateTime(getValues('dateTo'), Format.dateRequest),
        },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'organizationId', value: getValues('organizationId') },
        { key: 'organizationName', value: getValues('organizationName') },
        { key: 'agencyId', value: getValues('agencyId') },
        { key: 'agencyName', value: getValues('agencyName') },
      ])
      return prev
    })
    mutate(
      getDailyRevenueReport({
        page: page,
        dateFrom: getValues('dateFrom')
          ? formatDateTime(getValues('dateFrom'), Format.dateRequest)
          : undefined,
        dateTo: getValues('dateTo')
          ? formatDateTime(getValues('dateTo'), Format.dateRequest)
          : undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        organizationId: getValues('organizationId') || undefined,
        organizationName: getValues('organizationName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        category: RsCategory.BASIC.value,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getDailyRevenueReportDownloadCsv({
        dateFrom: getValues('dateFrom')
          ? formatDateTime(getValues('dateFrom'), Format.dateRequest)
          : undefined,
        dateTo: getValues('dateTo')
          ? formatDateTime(getValues('dateTo'), Format.dateRequest)
          : undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        organizationId: getValues('organizationId') || undefined,
        organizationName: getValues('organizationName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        category: RsCategory.BASIC.value,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition ? contentDisposition.split('filename=')[1] : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      date: item.date,
      artistId: item.artistId,
      artistName: item.artistName,
      organizationId: item.organizationId || '-',
      organizationName: item.organizationName || '-',
      agencyId: item.agencyId || '-',
      agencyName: item.agencyName || '-',
      artistRevenueShareRate: `${item.artistRevenueShareRate}%`,
      agencyRevenueShareType: item.agencyRevenueShareType
        ? RevenueShareType[item.agencyRevenueShareType].text
        : '-',
      agencyRevenueShareRate: item.agencyRevenueShareRate !== null
        ? `${item.agencyRevenueShareRate}%`
        : '-',
      pointCheerCount: item.pointCheerCount,
      artistRevenue: `¥${item.artistRevenue.toLocaleString()}`,
      agencyRevenue: item.agencyRevenue !== null
        ? `¥${item.agencyRevenue.toLocaleString()}`
        : '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'CSVダウンロード', onClick: handleDownloadCsv }]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='dateFrom'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='開始日'
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='dateTo'
              views={['year', 'month', 'day']}
              fullWidth={false}
              label='終了日'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationId'
              label='事務所ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationId'
              label='事務所名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyId'
              label='代理店ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyName'
              label='代理店名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'date',
              headerName: '日付',
              width: 100,
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
            },
            {
              field: 'organizationId',
              headerName: '事務所ID',
              width: 120,
            },
            {
              field: 'organizationName',
              headerName: '事務所名',
              width: 200,
            },
            {
              field: 'agencyId',
              headerName: '代理店ID',
              width: 120,
            },
            {
              field: 'agencyName',
              headerName: '代理店名',
              width: 200,
            },
            {
              field: 'artistRevenueShareRate',
              headerName: 'アーティストRS率',
              width: 150,
            },
            {
              field: 'agencyRevenueShareType',
              headerName: '代理店RSタイプ',
              width: 130,
            },
            {
              field: 'agencyRevenueShareRate',
              headerName: '代理店RS率',
              width: 130,
            },
            {
              field: 'pointCheerCount',
              headerName: '有料CHEER数',
              width: 120,
            },
            {
              field: 'artistRevenue',
              headerName: 'アーティスト収益',
              width: 150,
              align: 'right',
            },
            {
              field: 'agencyRevenue',
              headerName: '代理店収益',
              width: 150,
              align: 'right',
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
