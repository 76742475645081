import type { APIResponse, APIResponseResultPagination, FanStatusType, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type getArtistParams = {
  page: number
  id?: string
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
  agencyRevenueShareType?: string
  agencyRevenueShareRate?: number
  agencyRevenueShareRateThreshold?: string
  fanId?: string
  artistName?: string
  isNewcomer?: boolean
  isFirstGachi?: boolean
  isEntryRestricted?: boolean
  status?: string
  isBelongOrganization?: boolean
  isBelongAgency?: boolean
  createdFrom?: string
  createdTo?: string
}

export type GetArtistResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    artistName: string
    status: FanStatusType
    revenueShareRate: number
    isNewcomer: boolean
    isFirstGachi: boolean
    organizationId: number | null
    organizationName: string | null
    agencyId: number | null
    agencyName: string | null
    agencyRevenueShareType: RevenueShareTypeType | null
    agencyRevenueShareRate: string | null
    isEntryRestricted: boolean
    createdAt: string
    updatedAt: string
  }>
>

export const getArtistPath = '/admin/v1/artist'

export const getArtist = async (params: getArtistParams) => {
  return await http<GetArtistResponse>(getArtistPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      fan_id: params.fanId,
      artist_name: params.artistName,
      is_newcomer: params.isNewcomer,
      is_first_gachi: params.isFirstGachi,
      is_entry_restricted: params.isEntryRestricted,
      status: params.status,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
      agency_revenue_share_type: params.agencyRevenueShareType,
      agency_revenue_share_rate: params.agencyRevenueShareRate,
      agency_revenue_share_rate_threshold:
        params.agencyRevenueShareRateThreshold,
      is_belong_organization: params.isBelongOrganization,
      is_belong_agency: params.isBelongAgency,
      created_from: params.createdFrom,
      created_to: params.createdTo,
    },
  })
}
