import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostTermParams = {
  category: string
  content: string
  publishedAt?: string
}

export type PostTermResponse = APIResponse<null>

export const postTermPath = '/admin/v1/term'

export const postTerm = async (params: PostTermParams) => {
  return await http<PostTermResponse>(postTermPath, {
    method: 'POST',
    body: JSON.stringify({
      category: params.category,
      content: params.content,
      published_at: params.publishedAt,
    }),
  })
}
