import type { SalesReportDetail } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetSalesReportDetailParams = {
  id: number
}

export type GetSalesReportDetailResponse = APIResponse<SalesReportDetail>

export const getSalesReportDetailPath = '/admin/v1/sales-report/:id'

export const getSalesReportDetail = async (
  params: GetSalesReportDetailParams,
) => {
  return await http<GetSalesReportDetailResponse>(getSalesReportDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
