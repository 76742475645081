import type { ArtistHistoryItemType } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetArtistRevenueShareDetailParams = {
  id: number
}

export type GetArtistRevenueShareDetailResponse = APIResponse<ArtistHistoryItemType>

export const getArtistRevenueShareDetailPath = '/admin/v1/artist-history/:id'

export const getArtistRevenueShareDetail = async (
  params: GetArtistRevenueShareDetailParams,
) => {
  return await http<GetArtistRevenueShareDetailResponse>(
    getArtistRevenueShareDetailPath,
    {
      method: 'GET',
      params: {
        id: params.id,
      },
    },
  )
}
