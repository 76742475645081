import type { ArtistCoinHistoryTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetArtistCoinHistoryParams = {
  page: number
  artistId?: string
  artistName?: string
  type?: string
  dateFrom?: string
  dateTo?: string
}

export type GetArtistCoinHistoryResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    artistId: number
    fanId: number
    artistName: string
    coin: number
    type: ArtistCoinHistoryTypeType
    description: string
    createdAt: string
    updatedAt: string
  }>
>

export const getArtistCoinHistoryPath = '/admin/v1/artist-coin/history'

export const getArtistCoinHistory = async (params: GetArtistCoinHistoryParams) => {
  return await http<GetArtistCoinHistoryResponse>(getArtistCoinHistoryPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      artist_id: params.artistId,
      artist_name: params.artistName,
      type: params.type,
      date_from: params.dateFrom,
      date_to: params.dateTo,
    },
  })
}
