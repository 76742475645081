import type { EntryTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetSubEventParams = {
  page: number
  subEventId?: string
  name?: string
  type?: string
  startAt?: string
  endAt?: string
}

export type GetSubEventResponse = APIResponse<
  APIResponseResultPagination<{
    subEventId: number
    name: string
    isMain: boolean
    eventStartAt: string
    eventEndAt: string
    entryCount: number
    previewUrl: string
    mainEvent: {
      eventId: number
      name: string
      type: EventTypeType
      entryType: EntryTypeType
    }
    createdAt: string
    updatedAt: string
  }>
>

export const getSubEventPath = '/admin/v1/sub-event'

export const getSubEvent = async (params: GetSubEventParams) => {
  return await http<GetSubEventResponse>(getSubEventPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      sub_event_id: params.subEventId,
      name: params.name,
      type: params.type,
      start_at: params.startAt,
      end_at: params.endAt,
    },
  })
}
