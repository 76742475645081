import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Link, Typography } from '@mui/material'
import useSWR from 'swr'

import { deletePostComment } from '@/features/post/api/deletePostComment'
import {
  getPostComment,
  getPostCommentPath,
} from '@/features/post/api/getPostComment'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'コメント一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()
  const params = useParams('/posts/:postId/comments')
  const [isLoading, setIsLoading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
  })

  const page = Number(searchParams.get('page') || '1')

  const { data, isValidating, mutate } = useSWR(getPostCommentPath, () =>
    getPostComment({
      page: page,
      id: params.postId,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [{ key: 'page', value: String(page) }])
      return prev
    })
    mutate(
      getPostComment({
        page: page,
        id: params.postId,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      postId: item.postId,
      comment: item.comment,
      fan: {
        id: item.fanId,
        name: item.fanName,
      },
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      deleteId: item.id,
    }
  })

  const submitCommentDelete = async (commentId: number) => {
    try {
      setIsLoading(true)
      const { isConfirmed } = await swal.confirm({
        text: `コメントID：${commentId}のコメントを削除します。削除すると元に戻せません`,
      })
      if (!isConfirmed) {
        setIsLoading(false)
        return
      }

      await deletePostComment({
        postId: Number(params.postId),
        commentId: commentId,
      })

      swal.toastSuccess()
      mutate()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  //遷移
  const goPostDetail = () => {
    navigate('/posts/:postId', {
      params: {
        postId: String(params.postId),
      },
    })
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '投稿詳細に戻る', onClick: goPostDetail }]}>
        {pageTitle}
      </PageTitle>

      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'postId',
              headerName: '投稿ID',
              width: 100,
            },
            {
              field: 'comment',
              headerName: 'コメント',
              width: 300,
            },
            {
              field: 'fan',
              headerName: 'ファン',
              width: 200,
              renderCell: (params) => (
                <Box>
                  <Typography
                    component='span'
                    onClick={() => {
                      navigator.clipboard.writeText(String(params.value.id))
                    }}
                  >
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
            {
              field: 'action',
              headerName: '操作',
              width: 200,
              renderCell: (params) => {
                return (
                  <ActionButton
                    type='button'
                    size='small'
                    color='error'
                    variant='outlined'
                    onClick={() => {
                      submitCommentDelete(params.row.id)
                    }}
                    disabled={isLoading}
                  >
                    コメント削除
                  </ActionButton>
                )
              },
            },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
