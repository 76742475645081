import type { PlatFormType } from '@/types'

export type FanPaymentStatementItem = {
  unifiedOrderId: number
  orderId: string
  subOrderId: string
  fanId: number
  fanName: string
  taxIncludedPrice: number
  type: PaymentTypeType
  platform: PlatFormType
  paymentMethod: PaymentMethodType | null
  latestStatus: PaymentStatusType
  createdAt: string
  updatedAt: string
}

export type FanPaymentStatementItemDetail = {
  unifiedOrderId: number
  orderId: string
  subOrderId: string
  fanId: number
  fanName: string
  taxIncludedPrice: number
  taxRate: number
  taxExcludedPrice: number
  salesTax: number
  platform: PlatFormType
  paymentFeeRate: number
  taxExcludedPaymentFee: number
  netPrice: number
  type: PaymentTypeType
  latestStatus: PaymentStatusType
  isDebug: boolean
  paymentMethod: PaymentMethodType | null
  items: Array<PaymentItem>
  pointBalanceLogs: Array<PointBalanceLog>
  createdAt: string
  updatedAt: string
}

export type PaymentItem = {
  name: string
  description: string
  taxIncludedPrice: number
  quantity: number
  type: string
}

export type PointBalanceLog = {
  id: number
  point: number
  usedPoint: number
  expiredAt: string
  updatedAt: string
}

export type UnifiedOrderIdItem = {
  unifiedOrderId: number
  orderId: string
  subOrderId: string
  fanId: number
  fanName: string
  taxIncludedPrice: number
  type: PaymentTypeType
  platform: PlatFormType
  paymentMethod: PaymentMethodType | null
  latestStatus: PaymentStatusType
  gmoStatus: GmoStatusType | null
  createdAt: string
  updatedAt: string
}

export type UnifiedOrderIdItemDetail = {
  unifiedOrderId: number
  orderId: string
  subOrderId: string
  fanId: number
  fanName: string
  taxIncludedPrice: number
  taxRate: number
  taxExcludedPrice: number
  salesTax: number
  platform: PlatFormType
  paymentFeeRate: number
  taxExcludedPaymentFee: number
  netPrice: number
  type: PaymentTypeType
  latestStatus: PaymentStatusType
  isDebug: boolean
  paymentMethod: PaymentMethodType | null
  gmoStatus: GmoStatusType | null
  items: Array<PaymentItem>
  pointBalanceLogs: Array<PointBalanceLog>
  createdAt: string
  updatedAt: string
}

export type PointDetail = {
  id: number
  fanPointBalanceId: number
  point: number
  usedPoint: number
  expiredAt: string
}

//ポイント利用種別
export const PointUseType = {
  CHEER: { value: 'CHEER', text: 'CHEER' },
  EXPIRED: { value: 'EXPIRED', text: '有効期限切れ' },
} as const
export type PointUseTypeType = keyof typeof PointUseType

//決済ステータス
export const PaymentStatus = {
  UNPROCESSED: { value: 'UNPROCESSED', text: '未確定', color: 'info' },
  SALES: { value: 'SALES', text: '確定(実売上)', color: 'success' },
  RETURN: { value: 'RETURN', text: '返品', color: 'error' },
  RETURNX: { value: 'RETURNX', text: '月跨ぎ返品', color: 'error' },
} as const
export type PaymentStatusType = keyof typeof PaymentStatus

//決済種別
export const PaymentType = {
  POINT: { value: 'POINT', text: 'ポイント' },
  SUBSCRIPTION: { value: 'SUBSCRIPTION', text: '月額' },
  ARTIST_SUBSCRIPTION: { value: 'SUBSCRIPTION', text: 'アーティスト月額' },
} as const
export type PaymentTypeType = keyof typeof PaymentType

export const PaymentMethodType = {
  TEMP: { value: 'TEMP', text: '仮番号' },
  IOS: { value: 'IOS', text: 'iOS' },
  ANDROID: { value: 'ANDROID', text: 'Android' },
  CREDIT: { value: 'CREDIT', text: 'クレジットカード' },
  KONBINI: { value: 'KONBINI', text: 'コンビニ' },
  AU_KANTAN: { value: 'AU_KANTAN', text: 'auかんたん' },
  DOCOMO_PAY: { value: 'DOCOMO_PAY', text: 'd払い' },
  SB_MATOMETE: { value: 'SB_MATOMETE', text: 'ソフトバンクまとめて支払い' },
  RAKUTEN_PAY: { value: 'RAKUTEN_PAY', text: '楽天ペイ' },
  LINE_PAY: { value: 'LINE_PAY', text: 'LINE Pay' },
  AUTO_PAY: { value: 'AUTO_PAY', text: '自動売上' },
  MERPAY: { value: 'MERPAY', text: 'メルペイ' },
  PAYPAY: { value: 'PAYPAY', text: 'PayPay' },
} as const
export type PaymentMethodType = keyof typeof PaymentMethodType

export const GmoStatus = {
  UNPROCESSED: { value: 'UNPROCESSED', text: '未決済', color: 'default' },
  AUTHENTICATED: {
    value: 'AUTHENTICATED',
    text: '未決済(3Dセキュア登録済み)',
    color: 'default',
  },
  CHECK: { value: 'CHECK', text: '有効性チェック', color: 'default' },
  SALES: { value: 'SALES', text: '実売上', color: 'success' },
  CAPTURE: { value: 'CAPTURE', text: '即時売上', color: 'default' },
  AUTH: { value: 'AUTH', text: '仮売上', color: 'default' },
  VOID: { value: 'VOID', text: '取り消し', color: 'error' },
  RETURN: { value: 'RETURN', text: '返品', color: 'error' },
  RETURNX: { value: 'RETURNX', text: '月跨り返品', color: 'error' },
  SAUTH: { value: 'SAUTH', text: '簡易オーソリ', color: 'default' },
  REQSUCCESS: { value: 'REQSUCCESS', text: '要求成功', color: 'default' },
  PAYSUCCESS: { value: 'PAYSUCCESS', text: '支払成功', color: 'default' },
  PAYFAIL: { value: 'PAYFAIL', text: '支払失敗', color: 'default' },
  EXPIRED: { value: 'EXPIRED', text: '期限切れ', color: 'default' },
  CANCEL: { value: 'CANCEL', text: 'キャンセル', color: 'default' },
  AUTHPROCESS: {
    value: 'AUTHPROCESS',
    text: 'オーソリ処理中',
    color: 'default',
  },
} as const
export type GmoStatusType = keyof typeof GmoStatus

export const ArtistCoinHistoryType = {
  REVENUE: { value: 'REVENUE', text: '収益', color: 'success' },
  REWARD: { value: 'REWARD', text: '報酬', color: 'info' },
  CASH_OUT: { value: 'CASH_OUT', text: '換金', color: 'success' },
  EXCHANGE: { value: 'EXCHANGE', text: 'ポイント交換', color: 'primary' },
  EXCHANGE_EXPIRED: {
    value: 'EXCHANGE_EXPIRED',
    text: 'ポイント交換(有効期限切れ)',
    color: 'primary',
  },
} as const
export type ArtistCoinHistoryTypeType = keyof typeof ArtistCoinHistoryType
