import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

type NgWordFormValues = {
  word: string
  createdAt: string
  updatedAt: string
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface NgWordFormProps {
  isCreate?: boolean
}
export interface NgWordFormRef {
  getFormValues(): NgWordFormValues
  setFormValue(key: keyof NgWordFormValues, value: string): void
  formReset(values: NgWordFormValues): void
}

const NgWordForm = forwardRef<NgWordFormRef, NgWordFormProps>(
  (props, ref) => {
    const isCreate = props.isCreate || false
    const { control, getValues, setValue, reset } =
      useForm<NgWordFormValues>({
        defaultValues: {
          word: '',
          createdAt: '',
          updatedAt: '',
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item md={8}>
          <FormText control={control} name='word' label='NGワード' required />
          {!isCreate && (
            <>
              <FormValue label='登録日時'>{getValues('createdAt')}</FormValue>
              <FormValue label='更新日時'>{getValues('updatedAt')}</FormValue>
            </>
          )}
        </Grid>
      </FormBox>
    )
  },
)

export default NgWordForm
