import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

type OrganizationJoinFormValues = {
  artistId: string
  organizationId: number
}

interface OrganizationJoinFormProps {}
export interface OrganizationJoinFormRef {
  getFormValues(): OrganizationJoinFormValues
  setFormValue(key: keyof OrganizationJoinFormValues, value: number): void
  formReset(values: OrganizationJoinFormValues): void
}

const OrganizationJoinForm = forwardRef<
  OrganizationJoinFormRef,
  OrganizationJoinFormProps
>((_, ref) => {
  const { control, getValues, setValue, reset } =
    useForm<OrganizationJoinFormValues>({
      defaultValues: {
        artistId: '',
        organizationId: 0,
      },
    })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={5}>
        <FormText
          control={control}
          name='organizationId'
          label='事務所ID'
          type='number'
          required
        />
        <FormText
          control={control}
          name='artistId'
          label='アーティストID'
          hint='カンマ区切りで複数指定可能'
          required
        />
      </Grid>
    </FormBox>
  )
})

export default OrganizationJoinForm
