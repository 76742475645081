import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostPurchaseMenuParams = {
  name: string
  description: string
  type: string
  platform: string
  productId?: string
  taxIncludedPrice: number
  point: number
  publishedAt: string
  closedAt: string
}

export const postPointPurchaseMenuPath = '/admin/v1/point-purchase-menu'

export type PostPointPurchaseMenuResponse = APIResponse<null>

export const postPointPurchaseMenu = async (params: PostPurchaseMenuParams) => {
  return await http<PostPointPurchaseMenuResponse>(postPointPurchaseMenuPath, {
    method: 'POST',
    body: JSON.stringify({
      name: params.name,
      description: params.description,
      type: params.type,
      platform: params.platform,
      product_id: params.productId,
      tax_included_price: params.taxIncludedPrice,
      point: params.point,
      published_at: params.publishedAt,
      closed_at: params.closedAt,
    }),
  })
}