type DataType = {
  [key: string]:
    | string
    | number
    | boolean
    | Array<string | number | boolean>
    | undefined
}

// 整形せずにresをそのまま返す
// ファイルDL等、resからjson変換できない場合に使用
export const httpRaw = async (
  path: string,
  options?: {
    /**
     * リクエストメソッド
     * Default:
     * 'GET'
     * */
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
    /**
     * ヘッダー
     * Default:
     * headers = {
     *   'Content-Type': 'application/json',
     * }
     * */
    headers?: { [key: string]: string | undefined }
    /** パスのidと置換する */
    params?: { [key: string]: string | number }
    /** 送信データ */
    body?: BodyInit
    /** URLクエリ文字 */
    searchParams?: DataType
    credentials?: RequestCredentials
  },
): Promise<Response> => {
  const {
    method = 'GET',
    body,
    params,
    searchParams,
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials = 'include',
  } = options ?? {}

  const url = new URL(import.meta.env.VITE_API_BASE_URL as string)

  url.pathname = path

  // URLクエリ文字列
  if (searchParams) {
    Object.keys(searchParams).forEach((k) => {
      const val = searchParams[k]
      if (Array.isArray(val)) {
        val.forEach((v) => {
          url.searchParams.append(`${k}[]`, String(v))
        })
      } else if (val !== null && val !== undefined) {
        url.searchParams.append(k, String(val))
      }
    })
  }

  // パスパラメータ置換
  if (params) {
    Object.keys(params).forEach((k) => {
      const val = params[k]
      url.pathname = url.pathname.replace(`:${k}`, String(val))
    })
  }

  // トークン
  const cookies = document.cookie.split('; ')
  const cookie = cookies.find((_) => _.startsWith('XSRF-TOKEN')) || ''
  const csrfToken = decodeURIComponent(cookie.split('=')[1])

  // リクエスト
  return await fetch(url.toString(), {
    method: method,
    body: body,
    headers: {
      'X-XSRF-TOKEN': csrfToken,
      ...headers,
    },
    credentials,
  })
}
