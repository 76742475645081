import { useEffect, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { deleteAdminUser } from '@/features/admin/api/deleteAdminUser'
import {
  getAdminUserDetail,
  getAdminUserDetailPath,
} from '@/features/admin/api/getAdminUserDetail'
import { patchAdminUser } from '@/features/admin/api/patchAdminUser'
import { postAdminUser } from '@/features/admin/api/postAdminUser'
import { yup, yupResolver } from '@/lib/yup'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/settings/admin/:adminId')
  const isCreate = params.adminId === 'create'
  const pageTitle = `管理ユーザー${isCreate ? '新規作成' : '編集'}`
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  // 取得
  const { data } = useSWR(isCreate ? null : getAdminUserDetailPath, () =>
    getAdminUserDetail({
      id: Number(params.adminId),
    }),
  )

  // バリデーション
  const formScheme = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    password: yup
      .string()
      .nullable()
      .transform((o, c) => (o === '' ? null : c))
      .min(8),
  })

  // フォーム
  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formScheme),
  })

  // データ反映
  useEffect(() => {
    reset({
      name: data?.result.name,
      email: data?.result.email,
    })
  }, [isCreate, reset, data])

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)

      const requestParams = {
        name: getValues('name'),
        email: getValues('email'),
        password: getValues('password') || undefined,
      }

      if (isCreate) {
        await postAdminUser({
          ...requestParams,
        })
      } else {
        await patchAdminUser({
          id: Number(params.adminId),
          ...requestParams,
        })
      }

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 削除
  const deleteAction = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteAdminUser({
        id: Number(params.adminId),
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/settings/admin')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box pb={2}>
        {data?.result.deletedAt && (
          <Alert severity='error'>
            このデータは
            <strong>{data?.result.deletedAt}</strong>
            に削除されています。
          </Alert>
        )}
      </Box>
      <FormBox onSubmit={() => false}>
        <Grid item md={6}>
          <FormText control={control} required name='name' label='名前' />
          <FormText
            control={control}
            required
            name='email'
            label='メールアドレス'
            type='email'
          />
          <FormText
            control={control}
            required={isCreate}
            name='password'
            label='パスワード'
            type='password'
            placeholder={isCreate ? '' : '変更する場合のみ入力'}
            autocomplete='new-password'
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item md={6} mt={2}>
          <ActionButton
            size='large'
            isLoading={isLoading}
            fullWidth
            onClick={handleSubmit(submit)}
          >
            登録する
          </ActionButton>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item md={6} mt={4}>
          {!isCreate && (
            <ActionButton
              size='small'
              fullWidth
              color='error'
              variant='outlined'
              onClick={deleteAction}
            >
              削除する
            </ActionButton>
          )}
        </Grid>
      </FormBox>
    </Box>
  )
}

export default Page
