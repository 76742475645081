// クエリパラメータをセットする
export function setUrlSearchParams(
  searchParam: URLSearchParams,
  setObjectArr: { key: string; value: string | undefined | null }[],
) {
  setObjectArr.forEach((setObject) => {
    if (setObject.value) {
      searchParam.set(setObject.key, setObject.value)
    } else {
      searchParam.delete(setObject.key)
    }
  })
}
