import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { EventLooseFormRef } from '@/features/event/components/EventLooseForm'

import { getEventDetail } from '@/features/event/api/getEventDetail'
import { patchEvent } from '@/features/event/api/patchEvent'
import EventLooseForm from '@/features/event/components/EventLooseForm'
import { EntryFilterGender, EntryType } from '@/features/event/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { EventType } from '@/types'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/events/loose/:eventId')
  const pageTitle = 'ゆるイベ編集'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const EventLooseFormRef = useRef<EventLooseFormRef>(null)

  // 現在データの反映
  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getEventDetail({
        eventId: Number(params.eventId),
      })
      EventLooseFormRef.current?.formReset({
        name: result.name,
        bannerFileUrl: result.bannerUrl,
        iconFileUrl: result.iconUrl,
        bannerFile: null,
        iconFile: null,
        eventDisplayStartAt: newDate(result.eventDisplayStartAt),
        eventDisplayEndAt: newDate(result.eventDisplayEndAt),
        eventStartAt: newDate(result.eventStartAt),
        eventEndAt: newDate(result.eventEndAt),
        aggregationTarget: result.aggregationTarget,
        entryStartAt: newDate(result.entryStartAt),
        entryEndAt: newDate(result.entryEndAt),
        entryFilterGender: result.entryFilterGender
          ? result.entryFilterGender
          : 'NONE',
        entryFilterBirthMonth: result.entryFilterBirthMonth ?? '',
        rewards: result.rewards,
        scoreLogicFactor: {
          cheer: result.cheerFactor,
          viewersCount: result.viewersCountFactor,
          viewDuration: result.durationFactor,
          postCount: result.postCountFactor,
          postUu: result.postUuFactor,
        },
        descriptions: result.descriptions,
      })
    }
    fetchData()
  }, [params])

  // radio要素の値チェック
  const entryFilterGenderParam = (gender: string) => {
    // 設定なし
    if (!Object.keys(EntryFilterGender).includes(gender)) return null
    return gender
  }

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const formValues = EventLooseFormRef.current!.getFormValues()
      // rewardsとdescriptionsは空のものを除外
      const rewardsParam = formValues.rewards.filter(
        (reward) => reward.line && reward.title,
      )
      const descriptionsParam = formValues.descriptions.filter(
        (description) => description.title && description.description,
      )
      const { result } = await patchEvent({
        eventId: Number(params.eventId),
        name: formValues.name,
        bannerFileName: formValues.bannerFile
          ? formValues.bannerFile.name
          : null,
        iconFileName: formValues.iconFile ? formValues.iconFile.name : null,
        type: EventType.YURU.value,
        entryType: EntryType.GENERAL.value,
        entryStartAt: formatDateTime(
          formValues.entryStartAt,
          Format.dateTimeRequest,
        ),
        entryEndAt: formatDateTime(
          formValues.entryEndAt,
          Format.dateTimeRequest,
        ),
        eventStartAt: formatDateTime(
          formValues.eventStartAt,
          Format.dateTimeRequest,
        ),
        eventEndAt: formatDateTime(
          formValues.eventEndAt,
          Format.dateTimeRequest,
        ),
        eventDisplayStartAt: formatDateTime(
          formValues.eventDisplayStartAt,
          Format.dateTimeRequest,
        ),
        eventDisplayEndAt: formValues.eventDisplayEndAt
          ? formatDateTime(formValues.eventDisplayEndAt, Format.dateTimeRequest)
          : undefined,
        aggregationTarget: formValues.aggregationTarget,
        cheerFactor: formValues.scoreLogicFactor.cheer,
        viewersCountFactor: formValues.scoreLogicFactor.viewersCount,
        durationFactor: formValues.scoreLogicFactor.viewDuration,
        postCountFactor: formValues.scoreLogicFactor.postCount,
        postUuFactor: formValues.scoreLogicFactor.postUu,
        entryFilterGender: entryFilterGenderParam(formValues.entryFilterGender),
        entryFilterBirthMonth: formValues.entryFilterBirthMonth || null,
        entryFilterFirstGachi: false,
        rewards: rewardsParam.length > 0 ? rewardsParam : null,
        descriptions:
          descriptionsParam.length > 0 ? descriptionsParam : undefined,
      })

      // ファイルアップロード。必須パラメータだが、型ガードの意味でif文噛ませてます
      // バナー
      if (formValues.bannerFile) {
        const bannerUploadRes = await postUploadMedia({
          url: result.bannerFileUrl,
          file: formValues.bannerFile,
        })
        if (!bannerUploadRes) {
          await swal.messages({
            messages: ['バナーファイルのアップロードに失敗しました。'],
          })
          return
        }
      }
      // アイコン
      if (formValues.iconFile) {
        const iconUploadRes = await postUploadMedia({
          url: result.iconFileUrl,
          file: formValues.iconFile,
        })
        if (!iconUploadRes) {
          await swal.messages({
            messages: ['アイコンファイルのアップロードに失敗しました。'],
          })
          return
        }
      }

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/events/loose')
  }
  const goEntryList = () => {
    navigate(
      {
        pathname: '/events/:eventId/entries',
        search: `?eventType=${EventType.YURU.value}`,
      },
      {
        params: {
          eventId: params.eventId,
        },
      },
    )
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'エントリー一覧へ', onClick: goEntryList },
          { text: '一覧に戻る', onClick: goList },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <EventLooseForm ref={EventLooseFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
