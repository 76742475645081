import { useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'

import { ErrorBoundary } from 'react-error-boundary'

import CommonError from '@/components/layouts/CommonError/CommonError'
import { initSentry } from '@/utils/initSentry'

function RootLayout() {
  const isSentryInitRef = useRef(false)
  useEffect(() => {
    if (isSentryInitRef.current) return
    initSentry()
    isSentryInitRef.current = true
  }, [])

  return (
    <ErrorBoundary FallbackComponent={CommonError}>
      <Outlet />
    </ErrorBoundary>
  )
}

export default RootLayout
