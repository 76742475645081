import type { FaqDetailItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetFaqDetailParams = {
  id: number
}

export type GetFaqDetailResponse = APIResponse<FaqDetailItem>

export const getFaqDetailPath = '/admin/v1/faq/:id'

export const getFaqDetail = async (params: GetFaqDetailParams) => {
  return await http<GetFaqDetailResponse>(getFaqDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
