import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetLiveCommentParams = {
  id: string
  commentNo?: number
}

export type GetLiveCommentResponse = APIResponse<{
  hasMore: boolean
  hasPrev: boolean
  latestCommentNo: number
  perCount: number
  comments: {
    type: number
    comment: string
    commentNo: number
    createdAt: string
    fan?: {
      id: number
      name: string
      iconUrl: string
      level: number
      fanRank: string
    }
    artist?: {
      id: number
      fanId: number
      name: string
      iconUrl: string
    }
  }[]
}>

export const getLiveCommentPath = '/admin/v1/post/:postId/live-comment'

export const getLiveComment = async (params: GetLiveCommentParams) => {
  return await http<GetLiveCommentResponse>(getLiveCommentPath, {
    method: 'GET',
    params: {
      postId: params.id,
    },
    searchParams: {
      comment_no: params.commentNo,
    },
  })
}
