import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'

import { Link } from '@/router'

function Page() {
  return (
    <Box className='page'>
      <PageTitle>ダッシュボード</PageTitle>
      <div>
        <Link to='/auth/signin'>サインイン（仮）</Link>
      </div>
    </Box>
  )
}

export default Page
