import type { RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type PatchArtistRevenueShareParams = {
  id: number
  revenueShareRate: number
  organizationId?: number
  agencyId?: number
  agencyRevenueShareType?: RevenueShareTypeType
  agencyRevenueShareRate?: number
  startAt: string
}

export const patchArtistRevenueSharePath = '/admin/v1/artist-history/:id'

export const patchArtistRevenueShare = async (params: PatchArtistRevenueShareParams) => {
  return await http<null>(patchArtistRevenueSharePath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      organization_id: params.organizationId,
      agency_id: params.agencyId,
      revenue_share_rate: params.revenueShareRate,
      agency_revenue_share_type: params.agencyRevenueShareType,
      agency_revenue_share_rate: params.agencyRevenueShareRate,
      start_at: params.startAt,
    }),
  })
}
