export type PurchaseMenuItem = {
  id: number
  name: string
  description: string
  type: PurchaseMenuTypeType
  price: number
  platform: string
  productId: string
  taxIncludedPrice: number
  point: number
  publishedAt: string
  closedAt: string
  createdAt: string
  updatedAt: string
}

export const PurchaseMenuType = {
  NORMAL: { value: 'NORMAL', text: '通常' },
  ONE_TIME: { value: 'ONE_TIME', text: '購入1回きり' },
} as const
export type PurchaseMenuTypeType = keyof typeof PurchaseMenuType
