import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { FormLabel, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

import type { ArtistIdGroupArtistItem } from '../types'

type ArtistIdGroupFormValues = {
  name: string
  artistIds: string
  artistCount?: number
  artists?: ArtistIdGroupArtistItem[]
}

interface ArtistIdGroupFormProps {}
export interface ArtistIdGroupFormRef {
  getFormValues(): ArtistIdGroupFormValues
  setFormValue(key: keyof ArtistIdGroupFormValues, value: string): void
  formReset(values: ArtistIdGroupFormValues): void
}

const ArtistIdGroupForm = forwardRef<
  ArtistIdGroupFormRef,
  ArtistIdGroupFormProps
  // eslint-disable-next-line no-empty-pattern
>(({}, ref) => {
  const { control, getValues, setValue, reset } =
    useForm<ArtistIdGroupFormValues>({
      defaultValues: {
        name: '',
        artistIds: '',
      },
    })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={10}>
        <FormText control={control} name='name' label='アーティストIDグループ名' />
        <FormText
          control={control}
          name='artistIds'
          label='アーティストID'
          hint='カンマ区切りで複数指定可能'
        />
        {getValues('artistCount') && (
          <FormValue label='紐づいているアーティスト総数'>
            {getValues('artistCount')}
          </FormValue>
        )}
        {getValues('artists') && (
          <>
            <FormLabel>紐づいているアーティスト詳細</FormLabel>
            {getValues('artists')?.map((artist) => (
              <FormValue label=''>{`#${artist.artistId} ${artist.name}`}</FormValue>
            ))}
          </>
        )}
      </Grid>
    </FormBox>
  )
})

export default ArtistIdGroupForm
