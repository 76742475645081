import type { BirthdayVisibilityType, GenderType } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchFanParams = {
  fanId: number
  name: string
  birthdayVisibility: BirthdayVisibilityType
  birthday: string
  gender: GenderType
  profile?: string
  iconFileName?: string
}

export type PatchFanResponse = APIResponse<{
  iconFileUrl: string
}>

export const patchFanPath = '/admin/v1/fan/:fanId'

export const patchFan = async (params: PatchFanParams) => {
  return await http<PatchFanResponse>(patchFanPath, {
    method: 'PATCH',
    params: {
      fanId: params.fanId,
    },
    body: JSON.stringify({
      name: params.name,
      birthday_visibility: params.birthdayVisibility,
      birthday: params.birthday,
      gender: params.gender,
      profile: params.profile,
      icon_file_name: params.iconFileName,
    }),
  })
}
