import type { PublishStatusType, SendToTypeType } from '../types'
import type { APIResponse, CheerTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetMessageBoxTemplateDetailParams = {
  id: number
}

export type GetMessageBoxTemplateDetailResponse = APIResponse<{
  id: number
  sendToType: SendToTypeType
  fanIds: string
  fanGroup: {
    id: number
    name: string
  }
  artist: {
    id: number
    name: string
  }
  artistSendType: string
  title: string
  body: string
  imageUrl: string
  cheer: number
  cheerExpiredAt: string
  cheerType: CheerTypeType
  event: {
    id: number
    name: string
  }
  publishStatus: PublishStatusType
  templateStatus: string
  sendStatus: string
  sentAt: string
  reservedAt: string
  isReply: boolean
  importantFlag: boolean
  createdAt: string
  updatedAt: string
}>

export const getMessageBoxTemplatePath = '/admin/v1/message-box-template/:id'

export const getMessageBoxTemplateDetail = async (
  params: GetMessageBoxTemplateDetailParams,
) => {
  return await http<GetMessageBoxTemplateDetailResponse>(
    getMessageBoxTemplatePath,
    {
      method: 'GET',
      params: {
        id: params.id,
      },
    },
  )
}
