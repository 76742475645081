import type { NgWordItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetNgWordParams = {
  page: number
  word?: string
}

export type GetNgWordResponse = APIResponse<
  APIResponseResultPagination<NgWordItem>
>

export const getNgWordPath = '/admin/v1/ng-word'

export const getNgWord = async (params: GetNgWordParams) => {
  return await http<GetNgWordResponse>(getNgWordPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      word: params.word,
    },
  })
}
