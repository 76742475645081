import { useEffect, useState } from 'react'

import MediaPlayer from '@cz_frontend/ui/components/common/MediaPlayer/MediaPlayer'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import type {
  LiveStatusType,
  LiveTargetAudienceType,
  PostContentsTypeType,
  PostStatusType,
} from '@/features/post/types'

import { getPostDetail } from '@/features/post/api/getPostDetail'
import { putLiveForceStop } from '@/features/post/api/putLiveForceStop'
import { putPostStatusChange } from '@/features/post/api/putPostStatusChange'
import {
  LiveStatus,
  LiveTargetAudience,
  PostContentsType,
  PostStatus,
  type PostContentsItem,
} from '@/features/post/types'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/lives/:liveId')
  const pageTitle = 'ライブ配信詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  // フォーム
  const { getValues, reset } = useForm({
    defaultValues: {
      id: 0,
      artistId: 0,
      artistName: '',
      eventId: null as number | null,
      eventName: '',
      body: '',
      targetAudience: LiveTargetAudience.ALL.value as LiveTargetAudienceType,
      publishedAt: '',
      status: PostStatus.DRAFT.value as PostStatusType,
      liveStatus: LiveStatus.PREPARING.value as LiveStatusType,
      imageUrl: '',
      movieUrl: '',
      width: 0,
      height: 0,
      createdAt: '',
      updatedAt: '',
      contents: [] as PostContentsItem[],
    },
    mode: 'onChange',
  })

  // データ反映
  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getPostDetail({
        id: Number(params.liveId),
      })
      reset({
        id: result.id,
        artistId: result.artistId,
        artistName: result.artistName,
        eventId: result.eventId,
        eventName: result.eventName,
        body: result.body,
        targetAudience: result.targetAudience,
        publishedAt: result.publishedAt,
        status: result.status,
        liveStatus: result.liveStatus || LiveStatus.PREPARING.value,
        imageUrl: result.imageUrl,
        movieUrl: result.movieUrl,
        width: result.width,
        height: result.height,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
        contents: result.contents,
      })
    }
    fetchData()
  }, [params, reset])

  const isStatusChangeable = (status: PostStatusType) => {
    return (
      status === PostStatus.PRIVATE.value || status === PostStatus.PUBLIC.value
    )
  }

  const isForceStoppable = (status: LiveStatusType) => {
    return status === LiveStatus.STREAMING.value
  }

  // ステータス変更
  const onClickChangeStatus = async () => {
    try {
      setIsLoading(true)
      if (!isStatusChangeable(getValues('status'))) {
        await swal.alert({
          text: 'ステータスを変更できるのは非公開状態か公開状態のライブ配信のみです',
        })
        setIsLoading(false)
        return
      }
      const { isConfirmed } = await swal.confirm({
        text:
          getValues('status') === PostStatus.PRIVATE.value
            ? '公開します。よろしいですか？'
            : '非公開にします。よろしいですか？',
      })

      if (!isConfirmed) {
        setIsLoading(false)
        return
      }

      await putPostStatusChange({
        id: Number(params.liveId),
        status:
          getValues('status') === PostStatus.PRIVATE.value
            ? PostStatus.PUBLIC.value
            : PostStatus.PRIVATE.value,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 配信強制停止
  const onClickForceStop = async () => {
    try {
      setIsLoading(true)
      if (!isForceStoppable(getValues('liveStatus'))) {
        await swal.alert({
          text: '強制停止できるのは配信状態のライブ配信のみです',
        })
        setIsLoading(false)
        return
      }
      const { isConfirmed } = await swal.confirm({
        text: 'ライブ配信を強制停止します。操作後元には戻せません。よろしいですか？',
      })

      if (!isConfirmed) {
        setIsLoading(false)
        return
      }

      await putLiveForceStop({
        id: Number(params.liveId),
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/lives')
  }
  const goComment = () => {
    navigate('/lives/:liveId/comments', { params: { liveId: params.liveId } })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'コメント一覧へ', onClick: goComment },
          { text: '一覧に戻る', onClick: goList },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <FormBox onSubmit={() => false}>
        <Grid item md={5}>
          <FormSectionContainer sectionTitle='基本情報'>
            <Grid container>
              <Grid item md={4}>
                <FormValue label='公開ステータス'>
                  <Typography
                    color={`${PostStatus[getValues('status')].color}.main`}
                  >
                    {PostStatus[getValues('status')].text}
                  </Typography>
                </FormValue>
              </Grid>
              {isStatusChangeable(getValues('status')) && (
                <Grid item md={6}>
                  <ActionButton
                    type='button'
                    onClick={onClickChangeStatus}
                    isLoading={isLoading}
                  >
                    ステータス変更
                  </ActionButton>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item md={4}>
                <FormValue label='配信ステータス'>
                  <Typography
                    color={`${LiveStatus[getValues('liveStatus')].color}.main`}
                  >
                    {LiveStatus[getValues('liveStatus')].text}
                  </Typography>
                </FormValue>
              </Grid>
              {isForceStoppable(getValues('liveStatus')) && (
                <Grid item md={6}>
                  <ActionButton
                    type='button'
                    onClick={onClickForceStop}
                    isLoading={isLoading}
                    color='error'
                  >
                    強制停止
                  </ActionButton>
                </Grid>
              )}
            </Grid>

            <FormValue label='ライブ配信ID'>{getValues('id')}</FormValue>
            <FormValue label='アーティスト名'>
              #{getValues('artistId')} {getValues('artistName')}
            </FormValue>
            {getValues('eventId') && (
              <FormValue label='イベント名'>
                #{getValues('eventId')} {getValues('eventName')}
              </FormValue>
            )}
            <FormValue label='本文'>{getValues('body')}</FormValue>
            <FormValue label='配信対象'>
              {LiveTargetAudience[getValues('targetAudience')].text}
            </FormValue>
            <FormValue label='公開日時'>{getValues('publishedAt')}</FormValue>
            <FormValue label='作成日時'>{getValues('createdAt')}</FormValue>
            <FormValue label='更新日時'>{getValues('updatedAt')}</FormValue>
          </FormSectionContainer>
        </Grid>
        {/* コンテンツ一覧 */}
        <Grid item md={5}>
          <FormSectionContainer sectionTitle='コンテンツ'>
            <Box display={'flex'} flexDirection={'column'} gap={4}>
              <Box>
                {/* 一個目のコンテンツ */}
                {contentsItem(
                  getValues('movieUrl'),
                  getValues('imageUrl'),
                  getValues('width'),
                  getValues('height'),
                )}
              </Box>
              {getValues('contents').map((content) => (
                <Box key={content.id}>
                  {contentsItem(
                    content.movieUrl,
                    content.imageUrl,
                    content.width,
                    content.height,
                  )}
                </Box>
              ))}
            </Box>
          </FormSectionContainer>
        </Grid>
      </FormBox>
    </Box>
  )
}

function contentsItem(
  movieUrl: string,
  imageUrl: string,
  width: number,
  height: number,
  type?: PostContentsTypeType,
) {
  return (
    <>
      <Typography>
        サイズ：{width} x {height}
      </Typography>
      {movieUrl || type === PostContentsType.MOVIE.value ? (
        /* 動画 */
        <MediaPlayer
          type={'MOVIE'}
          src={movieUrl}
          allowOrigin={import.meta.env.VITE_API_BASE_URL}
        />
      ) : (
        /* 画像 */
        <img src={imageUrl} alt='' width={'100%'} />
      )}
    </>
  )
}

export default Page
