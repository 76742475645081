import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { AccountCircleOutlined, People } from '@mui/icons-material'
import { Box, Chip } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getOrganization,
  getOrganizationPath,
} from '@/features/organization/api/getOrganization'
import { OrganizationStatus } from '@/features/organization/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '事務所一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    name: '',
    status: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const name = searchParams.get('name') || ''
  const status = searchParams.get('status') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      name: name,
      status: status,
    },
  })

  const { data, isValidating, mutate } = useSWR(getOrganizationPath, () =>
    getOrganization({
      page: page,
      name: name || undefined,
      status: status || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'name', value: getValues('name') },
        { key: 'status', value: getValues('status') },
      ])
      return prev
    })
    mutate(
      getOrganization({
        page: page,
        name: getValues('name') || undefined,
        status: getValues('status') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/organizations/:organizationId', {
      params: {
        organizationId: id,
      },
    })
  }

  const goOrganizationArtists = (organizationId = '') => {
    navigate({
      pathname: '/organizations/artists',
      search: `?organizationId=${organizationId}`,
    })
  }

  const goOrganizationAccounts = (organizationId = '') => {
    navigate({
      pathname: '/organizations/accounts',
      search: `?organizationId=${organizationId}`,
    })
  }

  const goCreate = () => {
    navigate('/organizations/create')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      addRate: item.addRate,
      zipCode: item.zipCode,
      province: item.province,
      city: item.city,
      address1: item.address1,
      address2: item.address2,
      department: item.department,
      person: item.person,
      phoneNumber: item.phoneNumber,
      notificationEmail: item.notificationEmail,
      invoiceNumberStatus: item.invoiceNumberStatus,
      invoiceNumber: item.invoiceNumber,
      invoiceNumberEnabled: item.invoiceNumberEnabled,
      bankName: item.bankName,
      bankCode: item.bankCode,
      branchName: item.branchName,
      branchCode: item.branchCode,
      bankAccountType: item.bankAccountType,
      bankAccountNumber: item.bankAccountNumber,
      bankAccountHolder: item.bankAccountHolder,
      inviteUrl: item.inviteUrl,
      status: {
        text: OrganizationStatus[item.status].text,
        color: OrganizationStatus[item.status].color,
      },
      defaultIsEntryRestricted: item.defaultIsEntryRestricted ? '◯' : '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const statusOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(OrganizationStatus).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='name'
            label='事務所名'
            fullWidth={false}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='status'
            label='ステータス'
            options={statusOptions()}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: '_accounts',
              headerName: '所属ユーザー',
              width: 110,
              align: 'center',
              renderCell: (params) => {
                return (
                  <People
                    onClick={() => {
                      goOrganizationAccounts(params.row.id)
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                )
              },
            },
            {
              field: '_artists',
              headerName: '所属アーティスト',
              width: 140,
              align: 'center',
              renderCell: (params) => {
                return (
                  <AccountCircleOutlined
                    onClick={() => {
                      goOrganizationArtists(params.row.id)
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                )
              },
            },
            {
              field: 'name',
              headerName: '名前',
              width: 250,
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 110,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'inviteUrl',
              headerName: '招待URL',
              width: 150,
              renderCell: (params) => {
                return (
                  <CopyClipboard
                    text={params.value}
                    displayText='URLをコピー'
                  />
                )
              },
            },
            {
              field: 'defaultIsEntryRestricted',
              headerName: '基本エントリー制限',
              width: 150,
              align: 'center',
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
