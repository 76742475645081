import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetRankingArtistMonthlyParams = {
  page: number
  year?: number
  month?: number
}

export type GetRankingArtistMonthlyResponse = APIResponse<
  APIResponseResultPagination<{
    rank: number
    score: number
    cheerCount: number
    watchingDuration: number
    postCount: number
    postCheerFanCount: number
    artist: {
      id: number
      fanId: number
      name: string
      iconUrl: string
    }
  }>
>

export const getRankingArtistMonthlyPath = '/admin/v1/ranking/monthly'

export const getRankingArtistMonthly = async (params: GetRankingArtistMonthlyParams) => {
  return await http<GetRankingArtistMonthlyResponse>(getRankingArtistMonthlyPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
    },
  })
}
