import { httpRaw } from '@/lib/httpRaw'

export type GetArtistRevenueShareDownloadCsvParams = {
  artistId?: number
  artistName?: string
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
  getType?: string
}

export const getArtistRevenueShareDownloadCsvPath =
  '/admin/v1/artist-history/download-csv'

export const getArtistRevenueShareDownloadCsv = async (
  params: GetArtistRevenueShareDownloadCsvParams,
) => {
  return await httpRaw(getArtistRevenueShareDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      artist_id: params.artistId,
      artist_name: params.artistName,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
      get_type: params.getType,
    },
  })
}
