import type { DeviceTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetDeviceParams = {
  page: number
  fanId?: string
  fanName?: string
  deviceCode?: string
  deviceId?: string
  deviceType?: string
  osVersion?: string
  appVersion?: string
}

export type GetDeviceResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    deviceId: number
    deviceCode: string
    deviceType: DeviceTypeType
    osVersion: string
    appVersion: string
    pushToken: string
    createdAt: string
    updatedAt: string
  }>
>

export const GetDevicePath = '/admin/v1/fan-device'

export const getDevice = async (params: GetDeviceParams) => {
  return await http<GetDeviceResponse>(GetDevicePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      device_code: params.deviceCode,
      device_id: params.deviceId,
      device_type: params.deviceType,
      os_version: params.osVersion,
      app_version: params.appVersion,
    },
  })
}
