import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { Box, Typography } from '@mui/material'

function PageTitle({
  children,
  actions = [],
  isLoading = false,
}: {
  children: React.ReactNode
  actions?: {
    text: string
    variant?: 'text' | 'outlined' | 'contained'
    onClick: () => void
    disabled?: boolean
  }[]
  isLoading?: boolean
}) {
  return (
    <Box mb={2} display='flex' p={1}>
      <Typography component='h1' variant='h6'>
        {children}
      </Typography>
      <Box ml='auto'></Box>
      <Box display={'flex'} gap={1}>
        {actions.map((action, index) => (
          <ActionButton
            key={index}
            size='small'
            variant={action.variant}
            onClick={action.onClick}
            disabled={action.disabled ?? isLoading}
          >
            {action.text}
          </ActionButton>
        ))}
      </Box>
    </Box>
  )
}

export default PageTitle
