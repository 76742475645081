import { DataGrid } from '@cz_frontend/ui/components/data'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip, Grid, Link, Typography } from '@mui/material'
import useSWR from 'swr'

import type { PaymentItem, PointBalanceLog } from '@/features/histories/types'

import {
  getFanPaymentStatementDetail,
  getFanPaymentStatementDetailPath,
} from '@/features/histories/api/getFanPaymentStatementDetail'
import {
  PaymentMethodType,
  PaymentStatus,
  PaymentType,
} from '@/features/histories/types'
import { useNavigate, useParams } from '@/router'
import { PlatFormType } from '@/types'
import { pathReplace } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/histories/payment/:paymentId')
  const pageTitle = '決済履歴詳細'

  usePageInfo({
    title: pageTitle,
  })

  const { data } = useSWR(
    pathReplace(getFanPaymentStatementDetailPath, {
      unifiedOrderId: Number(params.paymentId),
    }),
    () =>
      getFanPaymentStatementDetail({
        unifiedOrderId: Number(params.paymentId),
      }),
  )

  // 遷移
  const goList = () => {
    navigate('/histories/payment')
  }

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Grid container spacing={4}>
        {data && (
          <>
            <Grid item md={5}>
              <FormValue label={'◼️ 総合オーダーID'}>
                {data.result.unifiedOrderId}
              </FormValue>
              <FormValue label={'◼️ 注文ID'}>{data.result.orderId}</FormValue>
              <FormValue label={'◼️ サブ注文ID'}>
                {data.result.subOrderId}
              </FormValue>
              <FormValue label={'◼️ ファンID'}>{data.result.fanId}</FormValue>
              <FormValue label={'◼️ ファン名'}>
                <Link
                  onClick={() => goFanDetail(String(data.result.fanId))}
                  color='inherit'
                  underline='hover'
                >
                  {data.result.fanName}
                </Link>
              </FormValue>
              <FormValue label={'◼️ 更新日時'}>
                {data.result.updatedAt}
              </FormValue>
            </Grid>
            <Grid item md={5}>
              <FormValue label={'◼️ 税込価格'}>
                {data.result.taxIncludedPrice} 円
              </FormValue>
              <FormValue label={'◼️ 消費税(四捨五入)'}>
                {data.result.salesTax} 円
              </FormValue>
              <FormValue label={'◼️ プラットフォーム'}>
                {PlatFormType[data.result.platform].text}
              </FormValue>
              <FormValue label={'◼️ 決済手数料率'}>
                {data.result.paymentFeeRate} %
              </FormValue>
              <FormValue label={'◼️ 税抜決済手数料(四捨五入)'}>
                {data.result.taxExcludedPaymentFee} 円
              </FormValue>
              <FormValue
                label={'◼️ 税抜決済手数料控除後金額(税抜価格 - 税抜決済手数料)'}
              >
                {data.result.netPrice} 円
              </FormValue>
              <FormValue label={'◼️ タイプ'}>
                {data.result.type && PaymentType[data.result.type].text}
              </FormValue>
              <FormValue label={'◼️ 最新ステータス'}>
                <Chip
                  size='small'
                  color={PaymentStatus[data.result.latestStatus].color}
                  variant='outlined'
                  label={PaymentStatus[data.result.latestStatus].text}
                />
              </FormValue>
              <FormValue label={'◼️ 決済手段'}>
                {data.result.paymentMethod &&
                  PaymentMethodType[
                    data.result.paymentMethod as PaymentMethodType
                  ].text}
              </FormValue>
            </Grid>
            <Grid item md={10}>
              <Box mt={4} mb={4}>
                <Typography>◼️ 商品情報</Typography>
                {itemList(data.result.items)}
              </Box>

              <Box mt={4} mb={4}>
                <Typography>◼️ ポイント購入ログ</Typography>
                {logList(data.result.pointBalanceLogs)}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

function itemList(items: Array<PaymentItem>) {
  const formatData = items.map((item, index) => {
    return {
      id: index + 1,
      name: item.name,
      description: item.description,
      taxIncludedPrice: item.taxIncludedPrice,
      quantity: item.quantity,
      type: getType(item),
    }
  })

  return (
    <Box>
      <DataGrid
        columns={[
          { field: 'id', headerName: 'ID', width: 100 },
          {
            field: 'name',
            headerName: '名前',
            width: 300,
          },
          {
            field: 'description',
            headerName: '説明',
            width: 300,
          },
          {
            field: 'taxIncludedPrice',
            headerName: '金額（税込）',
            width: 300,
          },
          {
            field: 'quantity',
            headerName: '数量',
            width: 300,
          },
          {
            field: 'type',
            headerName: 'タイプ',
            width: 300,
          },
        ]}
        idPropertyName={'id'}
        rows={formatData ?? []}
      />
    </Box>
  )
}

function logList(items: Array<PointBalanceLog>) {
  const formatData = items.map((item) => {
    return {
      id: item.id,
      point: item.point,
      usedPoint: item.usedPoint,
      expiredAt: item.expiredAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box>
      <DataGrid
        columns={[
          { field: 'id', headerName: 'ポイント購入ログID', width: 200 },
          {
            field: 'point',
            headerName: '購入ポイント',
            width: 200,
          },
          {
            field: 'usedPoint',
            headerName: '消費ポイント',
            width: 200,
          },
          {
            field: 'expiredAt',
            headerName: '有効期限',
            width: 300,
          },
          {
            field: 'updatedAt',
            headerName: '更新日',
            width: 300,
          },
        ]}
        idPropertyName={'id'}
        rows={formatData ?? []}
      />
    </Box>
  )
}

function getType(item: PaymentItem) {
  return item.type === PaymentType.POINT.value ||
    item.type === PaymentType.SUBSCRIPTION.value ||
    item.type === PaymentType.ARTIST_SUBSCRIPTION.value
    ? PaymentType[item.type].text
    : ''
}

export default Page
