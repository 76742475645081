import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { GetMutePath, getMute } from '@/features/fan/api/getMute'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ミュート一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    muterFanId: '',
    muterName: '',
    mutedFanId: '',
    mutedName: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const muterFanId = searchParams.get('muterFanId') || ''
  const muterName = searchParams.get('muterName') || ''
  const mutedFanId = searchParams.get('mutedFanId') || ''
  const mutedName = searchParams.get('mutedName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      muterFanId: muterFanId,
      muterName: muterName,
      mutedFanId: mutedFanId,
      mutedName: mutedName,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetMutePath, () =>
    getMute({
      page: page,
      muterFanId: muterFanId || undefined,
      muterName: muterName || undefined,
      mutedFanId: mutedFanId || undefined,
      mutedName: mutedName || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'muterFanId', value: getValues('muterFanId') },
        { key: 'muterName', value: getValues('muterName') },
        { key: 'mutedFanId', value: getValues('mutedFanId') },
        { key: 'mutedName', value: getValues('mutedName') },
      ])
      return prev
    })
    mutate(
      getMute({
        page: page,
        muterFanId: getValues('muterFanId') || undefined,
        muterName: getValues('muterName') || undefined,
        mutedFanId: getValues('mutedFanId') || undefined,
        mutedName: getValues('mutedName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      muter: {
        id: item.muterFanId,
        value: item.muterName,
      },
      muted: {
        id: item.mutedFanId,
        value: item.mutedName,
      },
      createdAt: item.createdAt,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='muterFanId'
            label='ミュートしたファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='muterName'
            label='ミュートしたファン名'
            fullWidth={false}
            hint={'部分一致'}
          />
          <FormText
            control={control}
            name='mutedFanId'
            label='ミュートされたファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='mutedName'
            label='ミュートされたファン名'
            fullWidth={false}
            hint={'部分一致'}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: 'muter',
              headerName: 'ミュートしたファン',
              width: 300,
              renderCell: (params) => (
                <Box>
                  <Typography component='span'>
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.value}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'muted',
              headerName: 'ミュートされたファン',
              width: 300,
              renderCell: (params) => (
                <Box>
                  <Typography
                    component='span'
                    onClick={() => {
                      navigator.clipboard.writeText(String(params.value.id))
                    }}
                  >
                    #{params.value.id} &nbsp;
                  </Typography>
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.value}
                  </Link>
                </Box>
              ),
            },
            {
              field: 'createdAt',
              headerName: 'ミュート日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
