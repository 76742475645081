import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormHtml from '@cz_frontend/ui/components/forms/FormHtml/FormHtml'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

import { TermCategory } from '../types'

type TermFormValues = {
  category: string
  content: string
  publishedAt: Date | null
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface TermFormProps {}
export interface PostTermFormRef {
  getFormValues(): TermFormValues
  setFormValue(key: keyof TermFormValues, value: string): void
  formReset(values: TermFormValues): void
}

const TermForm = forwardRef<
  PostTermFormRef,
  TermFormProps
  // eslint-disable-next-line no-empty-pattern
>(({}, ref) => {
  const { control, getValues, setValue, reset } = useForm<TermFormValues>({
    defaultValues: {
      category: TermCategory.FAN_TERM.value,
      content: '',
      publishedAt: null,
    },
  })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const categories = () => {
    return Object.entries(TermCategory).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
  }

  return (
    <FormBox onSubmit={() => false}>
      <Grid item>
        <FormSelect
          control={control}
          name='category'
          label='カテゴリー'
          fullWidth={true}
          options={categories()}
          required
        />

        <FormDate
          control={control}
          name='publishedAt'
          label='公開日時'
          views={['year', 'month', 'day', 'hours', 'minutes']}
          fullWidth
          sx={{ width: '100%' }}
          required
        />

        <FormHtml
          control={control}
          name='content'
          label='内容'
          // multiline={true}
          required
        />
      </Grid>
    </FormBox>
  )
})

export default TermForm
