import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { SpeakerNotesOutlined } from '@mui/icons-material'
import { Box, Chip, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventTypeType } from '@/types';

import { getPost, getPostPath } from '@/features/post/api/getPost'
import {
  LiveStatus,
  LiveStopReason,
  LiveTargetAudience,
  PostStatus,
  PostType,
} from '@/features/post/types'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ライブ配信一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const eventId = searchParams.get('eventId') || ''
  const eventName = searchParams.get('eventName') || ''
  const status = searchParams.get('status') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      artistId: artistId,
      artistName: artistName,
      eventId: eventId,
      eventName: eventName,
      status: status,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    `${getPostPath}-${PostType.LIVE.value}`,
    () =>
      getPost({
        page: page,
        id: id || undefined,
        type: PostType.LIVE.value,
        artistId: artistId || undefined,
        artistName: artistName || undefined,
        eventId: eventId || undefined,
        eventName: eventName || undefined,
        status: status || undefined,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'eventId', value: getValues('eventId') },
        { key: 'eventName', value: getValues('eventName') },
        { key: 'status', value: getValues('status') },
      ])
      return prev
    })
    mutate(
      getPost({
        page: page,
        id: getValues('id') || undefined,
        type: PostType.LIVE.value,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        eventId: getValues('eventId') || undefined,
        eventName: getValues('eventName') || undefined,
        status: getValues('status') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  const goDetail = (id = '') => {
    navigate('/lives/:liveId', {
      params: {
        liveId: id,
      },
    })
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goEventDetail = (eventId: number, type: EventTypeType) => {
    navigate(`/events/${EventType[type].page}/:eventId`, {
      params: {
        eventId: String(eventId),
      },
    })
  }

  const goLiveComments = (liveId = '') => {
    navigate(
      {
        pathname: '/lives/:liveId/comments',
      },
      {
        params: {
          liveId: liveId,
        },
      },
    )
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      artistId: item.artistId,
      artistName: item.artistName,
      eventId: item.eventId ?? '-',
      eventName: item.eventName ? item.eventName : '-',
      eventType: item.eventType,
      targetAudience: LiveTargetAudience[item.targetAudience].text,
      publishedAt: item.publishedAt,
      status: {
        text: PostStatus[item.status].text,
        color: PostStatus[item.status].color,
      },
      imageUrl: item.imageUrl,
      liveStatus: item.liveStatus ? {
        text: LiveStatus[item.liveStatus].text,
        color: LiveStatus[item.liveStatus].color,
      } : { text: '-', color: 'default'
      },
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      liveStartAt: item.liveDetail?.liveStartAt ?? '-',
      liveEndAt: item.liveDetail?.liveEndAt ?? '-',
      liveDuration: item.liveDetail?.liveDuration ?? '-',
      uniqueViewerCount: item.liveDetail?.uniqueViewerCount ?? '-',
      isRadioMode: item.liveDetail?.isRadioMode ?? '-',
      stopReason: item.liveDetail?.stopReason
        ? LiveStopReason[item.liveDetail?.stopReason].text
        : '-',
    }
  })

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='id'
              label='ライブ配信ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventName'
              label='イベント名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='status'
              label='ステータス'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(PostStatus).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: '_comments',
              headerName: 'コメント',
              width: 80,
              align: 'center',
              renderCell: (params) => {
                return (
                  <SpeakerNotesOutlined
                    onClick={() => {
                      goLiveComments(params.row.id)
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                )
              },
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 100,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'targetAudience',
              headerName: '配信対象',
              width: 100,
            },
            {
              field: 'liveStartAt',
              headerName: '配信開始',
              width: 160,
            },
            {
              field: 'liveEndAt',
              headerName: '配信終了',
              width: 160,
            },
            {
              field: 'liveDuration',
              headerName: '配信時間(秒)',
              width: 100,
            },
            {
              field: 'liveStatus',
              headerName: 'ライブ配信ステータス',
              width: 180,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 100,
              renderCell: (params) => {
                return params.value ? (
                  <CopyClipboard text={params.value} />
                ) : (
                  '-'
                )
              },
            },
            {
              field: 'eventName',
              headerName: 'イベント名',
              width: 200,
              renderCell: (params) => {
                return params.value ? (
                  <Link
                    onClick={() =>
                      goEventDetail(params.row.eventId, params.row.eventType)
                    }
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                )
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editHeaderName='詳細'
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
