import type { ArtistIdGroupArtistItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetArtistIdGroupDetailParams = {
  id: number
}

export type GetArtistIdGroupDetailResponse = APIResponse<{
  id: number
  name: string
  artistCount: number
  artists: ArtistIdGroupArtistItem[]
  createdAt: string
  updatedAt: string
}>

export const getArtistIdGroupDetailPath = '/admin/v1/artist-id-group/:id'

export const getArtistIdGroupDetail = async (
  params: GetArtistIdGroupDetailParams,
) => {
  return await http<GetArtistIdGroupDetailResponse>(getArtistIdGroupDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
