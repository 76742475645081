import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMuteParams = {
  page: number
  muterFanId?: string
  muterName?: string
  mutedFanId?: string
  mutedName?: string
}

export type GetMuteResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    muterFanId: number
    muterName: string
    mutedFanId: number
    mutedName: string
    createdAt: string
  }>
>

export const GetMutePath = '/admin/v1/mute'

export const getMute = async (params: GetMuteParams) => {
  return await http<GetMuteResponse>(GetMutePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      muter_fan_id: params.muterFanId,
      muter_name: params.muterName,
      muted_fan_id: params.mutedFanId,
      muted_name: params.mutedName,
    },
  })
}
