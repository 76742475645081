import { httpRaw } from '@/lib/httpRaw'

export type GetMonthlyCoinCheerReportDownloadCsvParams = {
  year?: number
  month?: number
}

export const getMonthlyCoinCheerReportDownloadCsvPath =
  '/admin/v1/monthly-coin-cheer-report/download-csv'

export const getMonthlyCoinCheerReportDownloadCsv = async (
  params: GetMonthlyCoinCheerReportDownloadCsvParams,
) => {
  return await httpRaw(getMonthlyCoinCheerReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      year: params.year,
      month: params.month,
    },
  })
}
