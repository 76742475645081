import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'
import { endOfHour, startOfHour } from 'date-fns'

import type { EventSeriousFormRef } from '@/features/event/components/EventSeriousForm'

import { postEvent } from '@/features/event/api/postEvent'
import EventSeriousForm from '@/features/event/components/EventSeriousForm'
import { EntryFilterGender, EntryType } from '@/features/event/types'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'がちイベ作成'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const EventSeriousFormRef = useRef<EventSeriousFormRef>(null)

  // チェックボックスを鑑みてparamを返す
  const entryFilterRegDateParam = (
    date: Date | null,
    noFilterFlag: boolean,
    isGeneral: boolean,
  ) => {
    if (!isGeneral) return null
    if (noFilterFlag) return null
    return formatDateTime(date, Format.dateRequest)
  }

  // radio要素の値チェック
  const entryFilterGenderParam = (gender: string, isGeneral: boolean) => {
    if (!isGeneral) return null
    // 設定なし
    if (!Object.keys(EntryFilterGender).includes(gender)) return null
    return gender
  }

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = EventSeriousFormRef.current!.getFormValues()
      const isGeneral = formValues.entryType === EntryType.GENERAL.value

      // rewardsとdescriptionsは空のものを除外
      const rewardsParam = formValues.rewards.filter(
        (reward) => reward.line && reward.title,
      )
      const descriptionsParam = formValues.descriptions.filter(
        (description) => description.title && description.description,
      )
      const { result } = await postEvent({
        name: formValues.name,
        bannerFileName: formValues.bannerFile
          ? formValues.bannerFile.name
          : null,
        iconFileName: formValues.iconFile ? formValues.iconFile.name : null,
        artistGroupId: formValues.artistGroupId,
        type: EventType.GACHI.value,
        entryType: formValues.entryType,
        entryStartAt: isGeneral
          ? formatDateTime(
              startOfHour(formValues.entryStartAt),
              Format.dateTimeRequest,
            )
          : undefined,
        entryEndAt: isGeneral
          ? formatDateTime(
              endOfHour(formValues.entryEndAt),
              Format.dateTimeRequest,
            )
          : undefined,
        eventStartAt: formatDateTime(
          startOfHour(formValues.eventStartAt),
          Format.dateTimeRequest,
        ),
        eventEndAt: formatDateTime(
          endOfHour(formValues.eventEndAt),
          Format.dateTimeRequest,
        ),
        eventDisplayStartAt: formatDateTime(
          startOfHour(formValues.eventDisplayStartAt),
          Format.dateTimeRequest,
        ),
        eventDisplayEndAt: formValues.eventDisplayEndAt
          ? formatDateTime(
              endOfHour(formValues.eventDisplayEndAt),
              Format.dateTimeRequest,
            )
          : undefined,
        aggregationTarget: formValues.aggregationTarget,
        cheerFactor: formValues.scoreLogicFactor.cheer,
        viewersCountFactor: formValues.scoreLogicFactor.viewersCount,
        durationFactor: formValues.scoreLogicFactor.viewDuration,
        postCountFactor: formValues.scoreLogicFactor.postCount,
        postUuFactor: formValues.scoreLogicFactor.postUu,
        entryFilterGender: entryFilterGenderParam(
          formValues.entryFilterGender,
          isGeneral,
        ),
        entryFilterAgeUpperLimit: isGeneral
          ? formValues.entryFilterAgeUpperLimit
          : undefined,
        entryFilterAgeLowerLimit: isGeneral
          ? formValues.entryFilterAgeLowerLimit
          : undefined,
        entryFilterBirthMonth: formValues.entryFilterBirthMonth || null,
        entryFilterAgeCalculationDate: isGeneral
          ? formatDateTime(
              formValues.entryFilterAgeCalculationDate,
              Format.dateRequest,
            )
          : undefined,
        entryFilterFirstGachi: isGeneral
          ? formValues.flags.includes('entryFilterFirstGachi')
          : false,
        entryFilterRegStartDate: entryFilterRegDateParam(
          formValues.entryFilterRegStartDate,
          formValues.noFilterRegDate.length > 0,
          isGeneral,
        ),
        entryFilterRegEndDate: entryFilterRegDateParam(
          formValues.entryFilterRegEndDate,
          formValues.noFilterRegDate.length > 0,
          isGeneral,
        ),
        rewards: rewardsParam.length > 0 ? rewardsParam : undefined,
        descriptions:
          descriptionsParam.length > 0 ? descriptionsParam : undefined,
      })

      // ファイルアップロード。必須パラメータだが、型ガードの意味でif文噛ませてます
      // バナー
      if (formValues.bannerFile) {
        const bannerUploadRes = await postUploadMedia({
          url: result.bannerFileUrl,
          file: formValues.bannerFile,
        })
        if (!bannerUploadRes) {
          await swal.messages({
            messages: ['バナーファイルのアップロードに失敗しました。'],
          })
          return
        }
      }
      // アイコン
      if (formValues.iconFile) {
        const iconUploadRes = await postUploadMedia({
          url: result.iconFileUrl,
          file: formValues.iconFile,
        })
        if (!iconUploadRes) {
          await swal.messages({
            messages: ['アイコンファイルのアップロードに失敗しました。'],
          })
          return
        }
      }

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/events/serious')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <EventSeriousForm ref={EventSeriousFormRef}></EventSeriousForm>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
