import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { FormLabel, Grid } from '@mui/material'
import { endOfDay } from 'date-fns'
import { useForm } from 'react-hook-form'

import { newDate } from '@/lib/dateFns'


type BanAccountFormValues = {
  fanId: number
  expiredAt: Date
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface BanAccountFormProps {}
export interface BanAccountFormRef {
  getFormValues(): BanAccountFormValues
  setFormValue(key: keyof BanAccountFormValues, value: number | Date): void
  formReset(values: BanAccountFormValues): void
}

const BanAccountForm = forwardRef<BanAccountFormRef, BanAccountFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } =
      useForm<BanAccountFormValues>({
        defaultValues: {
          fanId: 0,
          expiredAt: endOfDay(newDate()),
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item md={5}>
          <FormText
            control={control}
            name='fanId'
            label='ファンID'
            required
            type='number'
          />
          <FormLabel required>BAN期限</FormLabel>
          <FormDate
            control={control}
            name='expiredAt'
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            fullWidth={false}
          />
        </Grid>
      </FormBox>
    )
  },
)

export default BanAccountForm
