import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { FaqFormRef } from '@/features/faq/components/FaqForm'

import { getFaqDetail } from '@/features/faq/api/getFaqDetail'
import { putFaq } from '@/features/faq/api/putFaq'
import FaqForm from '@/features/faq/components/FaqForm'
import { FaqCategoryStatus } from '@/features/faq/types'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/faqs/:faqId')
  const pageTitle = 'FAQ詳細'
  const [isLoading, setIsLoading] = useState(false)
  const [defaultCategoryId, setDefaultCategoryId] = useState<number>()

  usePageInfo({
    title: pageTitle,
  })

  const createFaqFormRef = useRef<FaqFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getFaqDetail({
        id: Number(params.faqId),
      })

      setDefaultCategoryId(result.categoryId)

      createFaqFormRef.current?.formReset({
        title: result.title,
        status: result.status.key,
        sort: result.sort,
        description: result.description,
        target: result.categoryTarget.key,
        categoryId: result.categoryId,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/faqs')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = createFaqFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await putFaq({
        id: Number(params.faqId),
        title: formValues.title,
        sort: formValues.sort || 0,
        faqCategoryId: formValues.categoryId,
        status: formValues.status || FaqCategoryStatus.PUBLIC.value,
        description: formValues.description,
      })
      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      {/* categoryIdを外から指定するためにdefaultCategoryIdを追加している: 別途方法が可能であれば変更すること */}
      <FaqForm ref={createFaqFormRef} defaultCategoryId={defaultCategoryId} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
