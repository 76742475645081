import { useEffect, useState } from 'react'

import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, FormLabel } from '@mui/material'

import { getMessageBoxTemplateDetail } from '@/features/message-boxes/api/getMessageBoxTemplateDetail'
import { useNavigate, useParams } from '@/router'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/message-boxes/artist/:messageId')
  const pageTitle = 'メッセージボックス（アーティスト）詳細'

  const [messageData, setMessageData] = useState<{
    title: string
    body: string
    fileUrl: string
  }>({
    title: '',
    body: '',
    fileUrl: '',
  })

  usePageInfo({
    title: pageTitle,
  })

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getMessageBoxTemplateDetail({
        id: Number(params.messageId),
      })
      setMessageData({
        title: result.title,
        body: result.body,
        fileUrl: result.imageUrl,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/message-boxes/artist')
  }

  // アーティストは編集不要
  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <FormLabel>◼️ 添付画像</FormLabel>
      <img src={messageData.fileUrl}></img>
      <FormValue label='◼️ タイトル'>{messageData.title}</FormValue>
      <FormValue label='◼️ 本文'>{messageData.body}</FormValue>
    </Box>
  )
}

export default Page
