import type { UnifiedOrderIdItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetUnifiedOrderIdParams = {
  page: number
  fanId?: string
  fanName?: string
  platform?: Array<string>
  status?: Array<string>
  type?: Array<string>
  orderId?: string
  gmoStatus?: Array<string>
  startAt?: string
  endAt?: string
}

export type GetUnifiedOrderIdResponse = APIResponse<
  APIResponseResultPagination<UnifiedOrderIdItem>
>

export const GetUnifiedOrderIdPath = '/admin/v1/unified-order-id'

export const getUnifiedOrderId = async (params: GetUnifiedOrderIdParams) => {
  return await http<GetUnifiedOrderIdResponse>(GetUnifiedOrderIdPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      platform: params.platform,
      status: params.status,
      type: params.type,
      order_id: params.orderId,
      gmo_status: params.gmoStatus,
      start_at: params.startAt,
      end_at: params.endAt,
    },
  })
}
