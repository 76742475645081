import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { PostPurchaseMenuFormRef } from '@/features/purchase/components/PointPurchaseMenuForm'

import { postPointPurchaseMenu } from '@/features/purchase/api/postPointPurchaseMenu'
import PointPurchaseMenuForm from '@/features/purchase/components/PointPurchaseMenuForm'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { PlatFormType } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = '課金メニュー登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const createPointPurchaseMenuFormRef = useRef<PostPurchaseMenuFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = createPointPurchaseMenuFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      if (formValues.taxIncludedPrice * 2 <= formValues.point) {
        swal.alert({
          text: 'ポイントが税込価格の2倍より小さくなるように設定してください',
        })
        setIsLoading(false)
        return
      }
      await postPointPurchaseMenu({
        name: formValues.name,
        description: formValues.description,
        type: formValues.type,
        platform: formValues.platform,
        productId:
          formValues.platform !== PlatFormType.WEB.value
            ? formValues.productId
            : undefined,
        taxIncludedPrice: formValues.taxIncludedPrice,
        point: formValues.point,
        publishedAt: formatDateTime(
          formValues.publishedAt,
          Format.dateTimeRequest,
        ),
        closedAt: formatDateTime(formValues.closedAt, Format.dateTimeRequest),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/purchase-menus')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <PointPurchaseMenuForm ref={createPointPurchaseMenuFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
