import { httpRaw } from '@/lib/httpRaw'

export type GetPaymentReportDownloadCsvParams = {
  targetType?: string
}

export const getPaymentReportDownloadCsvPath =
  '/admin/v1/payment-report/download-csv'

export const getPaymentReportDownloadCsv = async (
  params?: GetPaymentReportDownloadCsvParams,
) => {
  return await httpRaw(getPaymentReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      target_type: params?.targetType,
    },
  })
}
