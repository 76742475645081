import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormCheckbox from '@cz_frontend/ui/components/forms/FormCheckbox/FormCheckbox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormSwitch from '@cz_frontend/ui/components/forms/FormSwitch/FormSwitch'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Box, Divider, FormLabel, Grid, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'

import {
  AggregationTarget,
  EntryFilterGender,
  EntryType,
  type EntryTypeType,
} from '../types'
import { calcDefaultDate } from '../utils'

import EventDescriptionsForm from './EventDescriptionsForm'
import EventRewardForm from './EventRewardForm'

import type { AggregationTargetType } from '../types'

import { INPUT_BASE_HEIGHT } from '@/theme'
import { EventType } from '@/types'

type EventSeriousFormValues = {
  entryType: EntryTypeType
  name: string
  bannerFileUrl?: string
  iconFileUrl?: string
  bannerFile: File | null
  iconFile: File | null
  eventDisplayStartAt: Date
  eventDisplayEndAt: Date | null
  eventStartAt: Date
  eventEndAt: Date
  aggregationTarget: AggregationTargetType
  artistGroupId: string
  entryStartAt: Date
  entryEndAt: Date
  entryFilterGender: string
  entryFilterBirthMonth: number | ''
  entryFilterAgeLowerLimit?: number
  entryFilterAgeUpperLimit?: number
  entryFilterAgeCalculationDate: Date | null
  flags: string[]
  entryFilterRegStartDate: Date | null
  entryFilterRegEndDate: Date | null
  noFilterRegDate: number[]
  rewards: { line: number; title: string }[]
  // スコアロジックは全て少数
  scoreLogicFactor: {
    cheer: number
    viewersCount: number
    viewDuration: number
    postCount: number
    postUu: number
  }
  descriptions: { title: string; description: string }[]
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface EventSeriousFormProps {}
export interface EventSeriousFormRef {
  getFormValues(): EventSeriousFormValues
  setFormValue(key: keyof EventSeriousFormValues, value: string): void
  formReset(values: EventSeriousFormValues): void
}

const EventSeriousForm = forwardRef<EventSeriousFormRef, EventSeriousFormProps>(
  (_, ref) => {
    //表示、募集、イベント開始終了期間のデフォルト取得
    const defaultDate = calcDefaultDate()

    const { control, getValues, setValue, reset } =
      useForm<EventSeriousFormValues>({
        defaultValues: {
          entryType: EntryType.GENERAL.value,
          name: '',
          bannerFile: null,
          iconFile: null,
          eventDisplayStartAt: defaultDate.displayStartAt,
          eventDisplayEndAt: defaultDate.displayEndAt,
          eventStartAt: defaultDate.startEventAt,
          eventEndAt: defaultDate.endEventAt,
          aggregationTarget: AggregationTarget.ALL.value,
          artistGroupId: '',
          entryStartAt: defaultDate.startEntryAt,
          entryEndAt: defaultDate.endEntryAt,
          entryFilterGender: EntryFilterGender.FEMALE.value,
          entryFilterBirthMonth: '',
          entryFilterAgeLowerLimit: undefined ,
          entryFilterAgeUpperLimit: undefined,
          entryFilterAgeCalculationDate: null,
          flags: [],
          entryFilterRegStartDate: null,
          entryFilterRegEndDate: null,
          noFilterRegDate: [],
          rewards: [],
          scoreLogicFactor: {
            cheer: 1.0,
            viewersCount: 0.0,
            viewDuration: 1.0,
            postCount: 1.4,
            postUu: 1.4,
          },
          descriptions: [{ title: '', description: '' }],
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    // mapの後にconcatすると狭い範囲で推論されてしまうので、型を明示的に指定
    const entryFilterGenderOption = () => {
      const filterMap: { value: string; text: string }[] = Object.entries(
        EntryFilterGender,
      ).map(([, item]) => ({
        value: item.value,
        text: item.text,
      }))
      return filterMap.concat([
        {
          value: 'NONE',
          text: '制限しない',
        },
      ])
    }

    const [isGeneral, setIsGeneral] = useState(true)
    const watchEntryType = useWatch({ control, name: 'entryType' })
    useEffect(() => {
      setIsGeneral(watchEntryType === EntryType.GENERAL.value)
    }, [watchEntryType])

    const monthOptions = [
      { value: '', text: '選択して下さい' },
      ...Array.from({ length: 12 }, (_, i) => {
        const num = i + 1
        return { value: num.toString(), text: num.toString() }
      }),
    ]

    return (
      <FormBox onSubmit={() => false}>
        <Grid item>
          {/* イベント基本情報 */}
          <Box mb={6}>
            <Typography>イベント基本情報</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <FormRadio
              label='募集の公開／非公開'
              control={control}
              name='entryType'
              required
              fullWidth={false}
              options={Object.entries(EntryType).map(([, item]) => ({
                value: item.value,
                text: item.text,
              }))}
            />
            <FormText
              control={control}
              name='name'
              label='イベント名'
              hint='255文字以内'
              required
            />
            <Grid container spacing={4}>
              <Grid item md={5}>
                <FormFile
                  label='バナー画像'
                  control={control}
                  name='bannerFile'
                  showImagePreview
                  initialImageSrc={getValues('bannerFileUrl')}
                  required
                />
              </Grid>
              <Grid item md={5}>
                <FormFile
                  label='アイコン画像'
                  control={control}
                  name='iconFile'
                  showImagePreview
                  initialImageSrc={getValues('iconFileUrl')}
                  required
                />
              </Grid>
            </Grid>

            <FormLabel>表示期間</FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'1em'}>
              <FormDate
                control={control}
                name='eventDisplayStartAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
              <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
              <FormDate
                control={control}
                name='eventDisplayEndAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
            </Box>
            {isGeneral && (
              <>
                <FormLabel>募集期間</FormLabel>
                <Box display={'flex'} alignItems={'center'} gap={'1em'}>
                  <FormDate
                    control={control}
                    name='entryStartAt'
                    views={['year', 'month', 'day', 'hours']}
                    fullWidth={false}
                  />
                  <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
                  <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
                  <FormDate
                    control={control}
                    name='entryEndAt'
                    views={['year', 'month', 'day', 'hours']}
                    fullWidth={false}
                  />
                  <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
                </Box>
              </>
            )}
            <FormLabel>イベント期間</FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'1em'}>
              <FormDate
                control={control}
                name='eventStartAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
              <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
              <FormDate
                control={control}
                name='eventEndAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
            </Box>
            <Box>
              <FormRadio
                label='集計対象'
                control={control}
                name='aggregationTarget'
                required
                options={Object.entries(AggregationTarget).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                }))}
                hint='親イベとサブイベの両方に適用されます。イベント開始後は変更できません'
                fullWidth={false}
              />
            </Box>
            <FormText
              control={control}
              name='artistGroupId'
              label='アーティストIDグループ'
              fullWidth={false}
              hint='グループ名 または グループIDを入力。指定したグループに含まれるアーティストのみがエントリーできるようになります。'
            />
          </Box>
          {/* 募集公開イベントの情報入力 */}
          {/* 一般募集の場合のみ */}
          {isGeneral && (
            <>
              <Box mb={6} mt={6}>
                <Typography>募集公開イベントのエントリー条件</Typography>
                <Divider
                  component={'hr'}
                  sx={{ backgroundColor: 'black', marginTop: '6px' }}
                />
              </Box>
              <Box pl={2}>
                <FormRadio
                  label='性別'
                  control={control}
                  name='entryFilterGender'
                  required
                  options={entryFilterGenderOption()}
                  fullWidth={false}
                />
                <FormLabel>年齢</FormLabel>
                <Box display={'flex'} gap={'1em'} alignItems={'center'}>
                  <FormText
                    control={control}
                    name='entryFilterAgeLowerLimit'
                    fullWidth={false}
                    type='number'
                  />
                  <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
                  <FormText
                    control={control}
                    name='entryFilterAgeUpperLimit'
                    fullWidth={false}
                    type='number'
                  />
                  <Typography ml={2} height={INPUT_BASE_HEIGHT}>
                    年齢確認タイミング：
                  </Typography>
                  <FormDate
                    control={control}
                    name='entryFilterAgeCalculationDate'
                    views={['year', 'month', 'day']}
                    fullWidth={false}
                  />
                  <Typography height={INPUT_BASE_HEIGHT}>
                    時点での年齢
                  </Typography>
                </Box>
                <Box>
                  <FormSelect
                    control={control}
                    name='entryFilterBirthMonth'
                    label='誕生月'
                    fullWidth={false}
                    options={monthOptions}
                  />
                </Box>
                <FormSwitch
                  control={control}
                  name='flags'
                  fullWidth={false}
                  options={[
                    {
                      value: 'entryFilterFirstGachi',
                      text: '初挑戦のみエントリー可能',
                    },
                  ]}
                />
                <FormLabel>登録期間</FormLabel>
                <Box
                  display={'flex'}
                  gap={'1em'}
                  alignItems={'center'}
                  width={'100%'}
                >
                  <FormDate
                    control={control}
                    name='entryFilterRegStartDate'
                    views={['year', 'month', 'day']}
                    fullWidth={false}
                  />
                  <Typography height={INPUT_BASE_HEIGHT}>から</Typography>
                  <FormDate
                    control={control}
                    name='entryFilterRegEndDate'
                    views={['year', 'month', 'day']}
                    fullWidth={false}
                  />
                  <Typography height={INPUT_BASE_HEIGHT}>
                    に登録したアーティスト
                  </Typography>
                  <FormCheckbox
                    control={control}
                    name='noFilterRegDate'
                    options={[{ value: 1, text: '制限しない' }]}
                    fullWidth={false}
                  />
                </Box>
              </Box>
            </>
          )}

          {/* スコアランキング特典 */}
          <Box mb={6} mt={6}>
            <Typography>スコアランキング特典</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <EventRewardForm
              control={control}
              name='rewards'
              eventType={EventType.GACHI.value}
            />
          </Box>
          {/* スコアロジック */}
          <Box mb={6} mt={6}>
            <Typography>スコアロジック</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
            <Typography color='textSecondary'>
              <small>イベント開始後は変更できません</small>
            </Typography>
          </Box>
          <Box pl={2}>
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              justifyContent={'space-between'}
            >
              <FormText
                control={control}
                name='scoreLogicFactor.cheer'
                fullWidth={false}
                type='number'
                label='CHEER'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                +
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.viewersCount'
                fullWidth={false}
                type='number'
                label='視聴人数'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                &times;
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.viewDuration'
                fullWidth={false}
                type='number'
                label='視聴時間'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                +
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.postCount'
                fullWidth={false}
                type='number'
                label='投稿数'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                &times;
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.postUu'
                fullWidth={false}
                type='number'
                label='CHEER UU'
              />
            </Box>
          </Box>
          {/* スコアランキング特典 */}
          <Box mb={6} mt={6}>
            <Typography>イベント概要</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <EventDescriptionsForm control={control} name='descriptions' />
          </Box>
        </Grid>
      </FormBox>
    )
  },
)

export default EventSeriousForm
