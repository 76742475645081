import type { APIResponse, APIResponseResultPagination, FanStatusType } from '@/types'

import { http } from '@/lib/http'

export type GetFanParams = {
  page: number
  fanIds?: number[]
  name?: string
  artistIds?: number[]
  status?: string
  isArtist?: boolean
}

export type GetFanResponse = APIResponse<
  APIResponseResultPagination<{
    fanId: number
    iconUrl: string
    name: string
    status: FanStatusType
    birthday: string
    artistId: number
    deletedAt: string|null
    createdAt: string
    updatedAt: string
  }>
>

export const getFanPath = '/admin/v1/fan'

export const getFan = async (params: GetFanParams) => {
  return await http<GetFanResponse>(getFanPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_ids: params.fanIds,
      name: params.name,
      artist_ids: params.artistIds,
      status: params.status,
      is_artist: params.isArtist,
    },
  })
}
