import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetDailyServiceKpiReportParams = {
  page: number
  dateFrom?: string
  dateTo?: string
}

export type GetDailyServiceKpiReportResponse = APIResponse<
  APIResponseResultPagination<{
    date: string
    artistActiveCount: number
    artistNewCount: number
    activityUU: number
    postUU: number
    liveUU: number
    fanActiveCount: number
    fanNewCount: number
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUU: number
    pointCheerUU: number
    freeCheerUU: number
    limitedCheerUU: number
    totalRevenue: number
    revenueIos: number
    revenueAndroid: number
    revenueWeb: number
    payingUU: number
    arppu: number
    createdAt: string
    updatedAt: string
  }>
>

export const getDailyServiceKpiReportPath = '/admin/v1/daily-service-kpi-report'

export const getDailyServiceKpiReport = async (params: GetDailyServiceKpiReportParams) => {
  return await http<GetDailyServiceKpiReportResponse>(getDailyServiceKpiReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      date_from: params.dateFrom,
      date_to: params.dateTo,
    },
  })
}
