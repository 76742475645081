import { http } from '@/lib/http'

export type PostFanIdGroupParams = {
  name: string
  fanIds?: number[]
}

export const postFanIdGroupPath = '/admin/v1/fan-id-group'

export const postFanIdGroup = async (params: PostFanIdGroupParams) => {
  return await http<null>(postFanIdGroupPath, {
    method: 'POST',
    body: JSON.stringify({
      name: params.name,
      fan_ids: params.fanIds,
    }),
  })
}
