import type { BankCodeClientBanksType } from '@/types'

type BanksResponse = {
  banks: BankCodeClientBanksType[]
  hasNext: boolean
  nextCursor?: string // hasNextがfalseの場合はundefined
  hasPrev: boolean
  prevCursor?: string // hasPrevがfalseの場合はundefined
}

export const banksHttp = async (
  name?: string,
  cursor?: string,
): Promise<BanksResponse> => {
  const baseUrl = import.meta.env.VITE_BANKCODEJP_BASE_URL
  let url = `${baseUrl}/banks?fields=code,name&limit=100`
  if (name) {
    url += `&filter=name==*${name}*`
  }
  if (cursor) {
    url += `&cursor=${cursor}`
  }

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      apiKey: import.meta.env.VITE_BANKCODEJP_API_KEY,
    },
  })

  const responseJson = await res.json()

  // レスポンスが200でない、レスポンスにmessageが含まれていればエラーを投げる
  if (res.status !== 200 || responseJson.message) {
    throw new Error(responseJson.message)
  }

  return responseJson
}
