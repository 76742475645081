import { http } from '@/lib/http'

export type PostLoginParams = {
  email: string
  password: string
  remember_me: boolean
}

export const postLoginPath = '/admin/v1/login'

export const postLogin = async (params: PostLoginParams) => {
  return await http<null>(postLoginPath, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}
