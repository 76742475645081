import { forwardRef, useImperativeHandle, useState } from 'react'

import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormSwitch from '@cz_frontend/ui/components/forms/FormSwitch/FormSwitch'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { useForm } from 'react-hook-form'

import {
  OrganizationStatus,
  PrefectureList,
} from '../types'

import { InvoiceNumberStatus } from '@/types'

type OrganizationFormValues = {
  name: string
  addRate: number
  country: string
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string
  department: string
  person: string
  phoneNumber: string
  notificationEmail: string
  invoiceNumberStatus: string
  invoiceNumber: string
  status: string
  flags: string[]
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface OrganizationFormProps {}
export interface OrganizationFormRef {
  getFormValues(): OrganizationFormValues
  setFormValue(key: keyof OrganizationFormValues, value: string): void
  formReset(values: OrganizationFormValues): void
}

const OrganizationForm = forwardRef<OrganizationFormRef, OrganizationFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } =
      useForm<OrganizationFormValues>({
        defaultValues: {
          name: '',
          addRate: 0,
          country: 'JP',
          zipCode: '',
          province: '',
          city: '',
          address1: '',
          address2: '',
          department: '',
          person: '',
          phoneNumber: '',
          notificationEmail: '',
          invoiceNumberStatus: InvoiceNumberStatus.UNSET.value,
          invoiceNumber: '',
          status: OrganizationStatus.ENABLED.value,
          flags: [],
        },
      })

    const [isInputInvoice, setIsInputInvoice] = useState(false)

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
        if (
          values['invoiceNumberStatus'] === InvoiceNumberStatus.REGISTERED.value
        ) {
          setIsInputInvoice(true)
        }
      },
    }))

    return (
      <>
        <FormSectionContainer sectionTitle='基本情報'>
          <FormText
            control={control}
            name='name'
            label='名前'
            required
            hint='100文字以内'
          />

          <FormText
            control={control}
            name='addRate'
            label='上乗せ率'
            type='number'
            hint='0以上100以下の数字を入力してください'
            required
          />

          <FormText control={control} name='country' label='国' />

          <FormText
            control={control}
            name='zipCode'
            label='郵便番号'
            hint='ハイフンなし'
          />

          <FormSelect
            control={control}
            fullWidth={false}
            name='province'
            label='都道府県'
            options={Object.entries(PrefectureList).map(([, value]) => ({
              value: value.value,
              text: value.text,
            }))}
          />

          <FormText control={control} name='city' label='市町村' />

          <FormText control={control} name='address1' label='住所1' />

          <FormText control={control} name='address2' label='住所2' />

          <FormText control={control} name='person' label='担当者名' />

          <FormText
            control={control}
            name='phoneNumber'
            label='連絡先電話番号'
            hint='ハイフンなし / 10桁から11桁の間で入力してください'
          />

          <FormText
            control={control}
            name='notificationEmail'
            label='通知メールアドレス'
          />

          <FormSelect
            control={control}
            fullWidth={false}
            name='invoiceNumberStatus'
            label='インボイス登録番号ステータス'
            options={Object.entries(InvoiceNumberStatus).map(([, value]) => ({
              value: value.value,
              text: value.text,
            }))}
            onChange={() => {
              if (
                getValues('invoiceNumberStatus') ===
                InvoiceNumberStatus.REGISTERED.value
              ) {
                setIsInputInvoice(true)
              } else {
                setIsInputInvoice(false)
              }
            }}
            required
          />

          <FormText
            control={control}
            name='invoiceNumber'
            label='インボイス登録番号'
            hint='T + 13桁の番号 / インボイス登録番号ステータスで未設定・登録しない場合は値がクリアされます。'
            disabled={!isInputInvoice}
          />

          <FormRadio
            label='ステータス'
            control={control}
            required
            name='status'
            options={Object.entries(OrganizationStatus).map(([, item]) => ({
              value: item.value,
              text: item.text,
            }))}
          />

          <FormSwitch
            control={control}
            name='flags'
            options={[
              {
                value: 'defaultIsEntryRestricted',
                text: '基本エントリー制限',
              },
            ]}
          />
        </FormSectionContainer>
      </>
    )
  },
)

export default OrganizationForm
