import type { OrganizationUserItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetOrganizationUserDetailParams = {
  id: number
}

export type GetOrganizationUserDetailResponse =
  APIResponse<OrganizationUserItem>

export const getOrganizationDetailPath = '/admin/v1/organization-user/:id'

export const getOrganizationUserDetail = async (
  params: GetOrganizationUserDetailParams,
) => {
  return await http<GetOrganizationUserDetailResponse>(
    getOrganizationDetailPath,
    {
      method: 'GET',
      params: {
        id: params.id,
      },
    },
  )
}
