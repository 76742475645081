import type { APIResponse, APIResponseResultPagination, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetTotalEventReportParams = {
  page: number
  eventId?: string
  eventName?: string
}

export type GetTotalEventReportResponse = APIResponse<
  APIResponseResultPagination<{
    eventId: number
    eventName: string
    eventType: EventTypeType
    activityUU: number
    postUU: number
    liveUU: number
    postCount: number
    liveCount: number
    liveDuration: string
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUU: number
    pointCheerUU: number
    freeCheerUU: number
    limitedCheerUU: number
    createdAt: string
    updatedAt: string
  }>
>

export const getTotalEventReportPath = '/admin/v1/total-event-report'

export const getTotalEventReport = async (params: GetTotalEventReportParams) => {
  return await http<GetTotalEventReportResponse>(getTotalEventReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      event_id: params.eventId,
      event_name: params.eventName,
    },
  })
}
