import type { APIResponse, APIResponseResultPagination, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type getArtistRevenueShareParams = {
  page: number
  artistId?: number
  artistName?: string
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
  getType?: string
}

export type GetArtistRevenueShareResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    artistId: number
    artistName: string
    revenueShareRate: number
    organizationId: number | null
    organizationName: string | null
    agencyId: number | null
    agencyName: string | null
    agencyRevenueShareType: {
      key: RevenueShareTypeType | null
      value: string | null
    }
    agencyRevenueShareRate: number | null
    startAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getArtistRevenueSharePath = '/admin/v1/artist-history'

export const getArtistRevenueShare = async (params: getArtistRevenueShareParams) => {
  return await http<GetArtistRevenueShareResponse>(getArtistRevenueSharePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      artist_id: params.artistId,
      artist_name: params.artistName,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
      get_type: params.getType,
    },
  })
}
