import React from 'react'

import {
  FormControl,
  Select,
  FormLabel,
  Typography,
  MenuItem,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  label?: string
  options?: { value: string; text: string }[]
  placeholder?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  onChange?: () => void
  fullWidth?: boolean
} & UseControllerProps<T>

function FormSelect<T extends FieldValues>({
  name,
  options,
  label,
  placeholder,
  required = false,
  disabled = false,
  control,
  size = 'small',
  onChange,
  fullWidth = true,
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl fullWidth={fullWidth} variant='outlined'>
          <FormLabel required={required}>{label}</FormLabel>
          <Select
            name={field.name}
            ref={field.ref}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={(e) => {
              field.onChange(e)
              if (onChange) onChange()
            }}
            value={field.value}
            size={size}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
          {/* エラー */}
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]!.message)}</small>
            </Typography>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormSelect
