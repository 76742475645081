import { forwardRef, useImperativeHandle, useState } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

import { FaqCategoryStatus, FaqCategoryTargetType } from '../types'

import type { FaqCategoryTargetTypeType } from '../types';

type FaqCategoryFormValues = {
  name: string
  target: FaqCategoryTargetTypeType
  sort?: string
  status?: FaqCategoryStatus
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface FaqCategoryFormProps {}
export interface FaqCategoryFormRef {
  getFormValues(): FaqCategoryFormValues
  setFormValue(key: keyof FaqCategoryFormValues, value: string): void
  formReset(values: FaqCategoryFormValues): void
}

const FaqCategoryForm = forwardRef<FaqCategoryFormRef, FaqCategoryFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } =
      useForm<FaqCategoryFormValues>({
        defaultValues: {
          name: '',
          target: FaqCategoryTargetType.FAN_ARTIST.value,
          sort: '',
          status: FaqCategoryStatus.PUBLIC.value,
        },
      })

    const [isEdit, setIsEdit] = useState(false)

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
        setIsEdit(true)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item md={5}>
          <FormText
            control={control}
            name='name'
            label='カテゴリー名'
            required
            hint='255文字以内'
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='target'
            label='表示場所'
            required
            options={Object.entries(FaqCategoryTargetType).map(
              ([key, value]) => ({
                value: key,
                text: value.text,
              }),
            )}
          />
          <FormText
            control={control}
            name='sort'
            label='並び順'
            required={isEdit}
            type='number'
            hint={isEdit ? '' : '指定しない場合は最後になります。1以上で指定してください。'}
          />
          <FormSelect
            control={control}
            fullWidth={false}
            name='status'
            label='ステータス'
            required={isEdit}
            options={Object.entries(FaqCategoryStatus).map(
              ([key, value]) => ({
                value: key,
                text: value.text,
              }),
            )}
          />
        </Grid>
      </FormBox>
    )
  },
)

export default FaqCategoryForm
