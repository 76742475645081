import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostSubEventParams = {
  eventId: string
  name: string
  type: string
  eventStartAt: string
  eventEndAt: string
  cheerFactor: number
  viewersCountFactor: number
  durationFactor: number
  postCountFactor: number
  postUuFactor: number
  rewards?: {
    line: number
    title: string
  }[]
  descriptions?: {
    title: string
    description: string
  }[]
}

export type PostSubEventResponse = APIResponse<{
  subEventId: number
}>

export const postSubEventPath = '/admin/v1/sub-event'

export const postSubEvent = async (params: PostSubEventParams) => {
  return await http<PostSubEventResponse>(postSubEventPath, {
    method: 'POST',
    body: JSON.stringify({
      event_id: params.eventId,
      name: params.name,
      type: params.type,
      event_start_at: params.eventStartAt,
      event_end_at: params.eventEndAt,
      cheer_factor: params.cheerFactor,
      viewers_count_factor: params.viewersCountFactor,
      duration_factor: params.durationFactor,
      post_count_factor: params.postCountFactor,
      post_uu_factor: params.postUuFactor,
      rewards: params.rewards,
      descriptions: params.descriptions,
    }),
  })
}
