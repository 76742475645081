import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

type AgencyJoinFormValues = {
  artistId: string
  agencyId: number
}

interface AgencyJoinFormProps {}
export interface AgencyJoinFormRef {
  getFormValues(): AgencyJoinFormValues
  setFormValue(key: keyof AgencyJoinFormValues, value: number): void
  formReset(values: AgencyJoinFormValues): void
}

const AgencyJoinForm = forwardRef<
  AgencyJoinFormRef,
  AgencyJoinFormProps
>((_, ref) => {
  const { control, getValues, setValue, reset } = useForm<AgencyJoinFormValues>(
    {
      defaultValues: {
        artistId: '',
        agencyId: 0,
      },
    },
  )

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={5}>
        <FormText
          control={control}
          name='agencyId'
          label='代理店ID'
          type='number'
          required
        />
        <FormText
          control={control}
          name='artistId'
          label='アーティストID'
          hint='カンマ区切りで複数指定可能'
          required
        />
      </Grid>
    </FormBox>
  )
})

export default AgencyJoinForm
