import { http } from '@/lib/http'

export type PostFanWithdrawalParams = {
  fanId: string
}

export const postFanWithdrawalPath = '/admin/v1/fan/:fanId/withdrawal'

export const postFanWithdrawal = async (params: PostFanWithdrawalParams) => {
  return await http<null>(postFanWithdrawalPath, {
    method: 'POST',
    params: {
      fanId: params.fanId,
    },
  })
}
