import { http } from '@/lib/http'

export type PostOrganizationJoinParams = {
  artistId: string
  organizationId: number
}

export const postOrganizationJoinPath = '/admin/v1/artist/join'

export const postOrganizationJoin = async (params: PostOrganizationJoinParams) => {
  return await http<null>(postOrganizationJoinPath, {
    method: 'POST',
    body: JSON.stringify({
      artist_id: params.artistId,
      organization_id: params.organizationId,
    }),
  })
}
