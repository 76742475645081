import type { DeviceListItem, FanDeviceTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanDeviceParams = {
  page: number
  id?: string
  fanId?: string
  fanName?: string
  deviceCode?: string
  deviceType?: FanDeviceTypeType
  osVersion?: string
  appVersion?: string
}

export type GetFanDeviceResponse = APIResponse<
  APIResponseResultPagination<DeviceListItem>
>

export const getFanDevicePath = '/admin/v1/fan-device'

export const getFanDevice = async (params: GetFanDeviceParams) => {
  return await http<GetFanDeviceResponse>(getFanDevicePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      fan_id: params.fanId,
      fan_name: params.fanName,
      device_code: params.deviceCode,
      device_type: params.deviceType,
      os_version: params.osVersion,
      app_version: params.appVersion,
    },
  })
}
