import type { FanPaymentStatementItemDetail } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetFanPaymentStatementDetailParams = {
  unifiedOrderId: number
}

export type GetFanPaymentStatementDetailResponse =
  APIResponse<FanPaymentStatementItemDetail>

export const getFanPaymentStatementDetailPath =
  '/admin/v1/fan-payment-statement/:unifiedOrderId'

export const getFanPaymentStatementDetail = async (
  params: GetFanPaymentStatementDetailParams,
) => {
  return await http<GetFanPaymentStatementDetailResponse>(
    getFanPaymentStatementDetailPath,
    {
      method: 'GET',
      params: {
        unifiedOrderId: params.unifiedOrderId,
      },
    },
  )
}
