import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostBanDeviceParams = {
  deviceId: string
  expiredAt: string
}

export const postBanDevicePath = '/admin/v1/fan-device-ban'

export const postBanDevice = async (
  params: PostBanDeviceParams,
) => {
  return await http<APIResponse<null>>(postBanDevicePath, {
    method: 'POST',
    body: JSON.stringify({
      device_id: params.deviceId,
      expired_at: params.expiredAt,
    }),
  })
}
