import type { OrganizationItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetOrganizationDetailParams = {
  id: number
}

export type GetOrganizationDetailResponse = APIResponse<OrganizationItem>

export const getOrganizationDetailPath = '/admin/v1/organization/:id'

export const getOrganizationDetail = async (
  params: GetOrganizationDetailParams,
) => {
  return await http<GetOrganizationDetailResponse>(getOrganizationDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
