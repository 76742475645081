import { forwardRef, useImperativeHandle } from 'react'

import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { FormLabel } from '@mui/material'
import { useForm } from 'react-hook-form'

type ReplyFormValues = {
  replyText: string
  files?: Array<File | null>
}

interface ReplyFormProps {}
export interface ReplyFormRef {
  getFormValues(): ReplyFormValues
  setFormValue(key: keyof ReplyFormValues, value: string): void
  formReset(values: ReplyFormValues): void
}

const ReplyForm = forwardRef<
  ReplyFormRef,
  ReplyFormProps
  // eslint-disable-next-line no-empty-pattern
>(({}, ref) => {
  const { control, getValues, setValue, reset } = useForm<ReplyFormValues>({
    defaultValues: {
      replyText: '',
      files: [null, null, null],
    },
  })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  return (
    <>
      <FormText
        control={control}
        name='replyText'
        label='◼️ 返信フォーム'
        multiline
      />
      <FormLabel>◼️ 添付ファイル（最大3つ）</FormLabel>
      <FormFile control={control} name='files.0' showImagePreview></FormFile>
      <FormFile control={control} name='files.1' showImagePreview></FormFile>
      <FormFile control={control} name='files.2' showImagePreview></FormFile>
    </>
  )
})

export default ReplyForm
