import { http } from '@/lib/http'

export type PutLiveForceStopParams = {
  id: number
}

export const putLiveForceStopPath = '/admin/v1/post/:postId/live-force-stop'

export const putLiveForceStop = async (params: PutLiveForceStopParams) => {
  return await http<null>(putLiveForceStopPath, {
    method: 'PUT',
    params: { postId: params.id },
  })
}
