import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteOrganizationUserParams = {
  companyAccountId: number
}

export const deleteOrganizationUserPath =
  '/admin/v1/organization-user/:companyAccountId'

export const deleteOrganizationUser = async (
  params: DeleteOrganizationUserParams,
) => {
  return await http<APIResponse<null>>(deleteOrganizationUserPath, {
    method: 'DELETE',
    params: {
      companyAccountId: params.companyAccountId,
    },
  })
}
