import type { FanPaymentStatementItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanPaymentStatementParams = {
  page: number
  fanId?: string
  fanName?: string
  platform?: Array<string>
  status?: Array<string>
  type?: Array<string>
  orderId?: string
  startAt?: string
  endAt?: string
}

export type GetFanPaymentStatementResponse = APIResponse<
  APIResponseResultPagination<FanPaymentStatementItem>
>

export const GetFanPaymentStatementPath = '/admin/v1/fan-payment-statement'

export const getFanPaymentStatement = async (
  params: GetFanPaymentStatementParams,
) => {
  return await http<GetFanPaymentStatementResponse>(
    GetFanPaymentStatementPath,
    {
      method: 'GET',
      searchParams: {
        page: params.page,
        fan_id: params.fanId,
        fan_name: params.fanName,
        platform: params.platform,
        status: params.status,
        type: params.type,
        order_id: params.orderId,
        start_at: params.startAt,
        end_at: params.endAt,
      },
    },
  )
}
