import { http } from '@/lib/http'

export type DeletePostCommentParams = {
  postId: number
  commentId: number
}

export const deletePostCommentPath = '/admin/v1/post/:postId/comment/:commentId'

export const deletePostComment = async (params: DeletePostCommentParams) => {
  return await http<null>(deletePostCommentPath, {
    method: 'DELETE',
    params: {
      postId: params.postId,
      commentId: params.commentId,
    },
  })
}
