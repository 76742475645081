import type { APIResponse, APIResponseResultPagination, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetDailyEventReportParams = {
  page: number
  dateFrom?: string
  dateTo?: string
  eventId?: string
  eventName?: string
}

export type GetDailyEventReportResponse = APIResponse<
  APIResponseResultPagination<{
    date: string
    eventId: number
    eventName: string
    eventType: EventTypeType
    activityUU: number
    postUU: number
    liveUU: number
    postCount: number
    liveCount: number
    liveDuration: string
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUU: number
    pointCheerUU: number
    freeCheerUU: number
    limitedCheerUU: number
    createdAt: string
    updatedAt: string
  }>
>

export const getDailyEventReportPath = '/admin/v1/daily-event-report'

export const getDailyEventReport = async (params: GetDailyEventReportParams) => {
  return await http<GetDailyEventReportResponse>(getDailyEventReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      date_from: params.dateFrom,
      date_to: params.dateTo,
      event_id: params.eventId,
      event_name: params.eventName,
    },
  })
}
