import type { APIResponse, FanStatusType, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetArtistDetailParams = {
  id: number
}

export type GetArtistDetailResponse = APIResponse<{
  id: number
  fanId: number
  artistName: string
  status: FanStatusType
  revenueShareRate: number
  isNewcomer: boolean
  isFirstGachi: boolean
  organizationId: number | null
  organizationName: string | null
  agencyId: number | null
  agencyName: string | null
  agencyRevenueShareType: RevenueShareTypeType | null
  agencyRevenueShareRate: number | null
  createdAt: string
  updatedAt: string
}>

export const getArtistDetailPath = '/admin/v1/artist/:id'

export const getArtistDetail = async (params: GetArtistDetailParams) => {
  return await http<GetArtistDetailResponse>(getArtistDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
