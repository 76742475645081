import { httpRaw } from '@/lib/httpRaw'

export type GetMonthlyPointReportDownloadCsvParams = {
  year?: number
  month?: number
}

export const getMonthlyPointReportDownloadCsvPath =
  '/admin/v1/monthly-point-report/download-csv'

export const getMonthlyPointReportDownloadCsv = async (
  params: GetMonthlyPointReportDownloadCsvParams,
) => {
  return await httpRaw(getMonthlyPointReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      year: params.year,
      month: params.month,
    },
  })
}
