import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box } from '@mui/material'

import type { EventEntryItem } from '@/features/event/api/getEventEntryDetail'
import type { EventEntryFormRef } from '@/features/event/components/EventEntryForm'
import type { Path } from '@/router'

import { deleteEventEntry } from '@/features/event/api/deleteEventEntry'
import { getEventEntryDetail } from '@/features/event/api/getEventEntryDetail'
import { patchEventEntry } from '@/features/event/api/patchEventEntry'
import EventEntryForm from '@/features/event/components/EventEntryForm'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/events/:eventId/entries/:entryId')
  const pageTitle = 'エントリー詳細'
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const [searchParams] = useSearchParams({
    eventType: '',
  })
  const [entryData, setEntryData] = useState<EventEntryItem>()

  const eventType = searchParams.get('eventType') || ''

  const EventEntryFormRef = useRef<EventEntryFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getEventEntryDetail({
        entryId: Number(params.entryId),
      })

      setEntryData(result)

      EventEntryFormRef.current?.formReset({
        eventId: result.eventId,
        eventName: result.eventName,
        fanId: result.fanId,
        artistId: result.artistId,
        artistName: result.artistName,
        agencyId: result.agencyId ?? '',
        agencyName: result.agencyName ?? '',
        artistRevenueShareRate: result.artistRevenueShareRate,
        agencyRevenueShareType: result.agencyRevenueShareType.key ?? '',
        agencyRevenueShareRate: result.agencyRevenueShareRate ?? '',
        isDeleted: result.isDeleted,
        isNewcomer: result.isNewcomer,
        isFirstGachi: result.isFirstGachi,
        entryAt: result.entryAt,
        updatedAt: result.updatedAt,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate(`/events/${params.eventId}/entries?eventType=${eventType}` as Path)
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = EventEntryFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await patchEventEntry({
        entryId: Number(params.entryId),
        agencyId: formValues.agencyId || undefined,
        artistRevenueShareRate: formValues.artistRevenueShareRate,
        agencyRevenueShareType: formValues.agencyRevenueShareType || undefined,
        agencyRevenueShareRate: formValues.agencyRevenueShareRate || undefined,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 辞退
  const deleteAction = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '辞退しますか？',
        text: '辞退すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteEventEntry({
        entryId: Number(params.entryId),
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsDeleteLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: 'エントリー一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box pb={2}>
        {entryData?.isDeleted && (
          <Alert severity='error'>辞退済みのエントリー情報です。</Alert>
        )}
      </Box>
      <EventEntryForm ref={EventEntryFormRef} isCreate={false} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        disabled={!!entryData?.isDeleted}
        onClick={submit}
      >
        更新する
      </ActionButton>
      <Box my={8}>
        <ActionButton
          size='large'
          isLoading={isDeleteLoading}
          fullWidth
          disabled={!!entryData?.isDeleted}
          color='error'
          variant='outlined'
          onClick={deleteAction}
        >
          辞退する
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
