import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetPointPurchaseParams = {
  page: number
  id?: string
  fanId?: string
  fanName?: string
}

export type GetPointPurchaseResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    unifiedOrderId: number
    point: number
    usedPoint: number
    expiredAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getPointPurchasePath = '/admin/v1/fan-point-balance'

export const getPointPurchase = async (params: GetPointPurchaseParams) => {
  return await http<GetPointPurchaseResponse>(getPointPurchasePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      fan_id: params.fanId,
      fan_name: params.fanName,
    },
  })
}
