import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box } from '@mui/material'

import type { OrganizationUserFormRef } from '@/features/organization/components/OrganizationUserForm'

import { getOrganizationUserDetail } from '@/features/organization/api/getOrganizationUserDetail'
import { putOrganizationUser } from '@/features/organization/api/putOrganizationUser'
import OrganizationUserForm from '@/features/organization/components/OrganizationUserForm'
import { useNavigate, useParams } from '@/router'
import { decomposeToBits, pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/organizations/accounts/:companyAccountId')
  const pageTitle = '事務所ユーザー詳細'
  const [isLoading, setIsLoading] = useState(false)
  const [deletedAt, setIsDeletedAt] = useState('')

  usePageInfo({
    title: pageTitle,
  })

  const organizationUserFormRef = useRef<OrganizationUserFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getOrganizationUserDetail({
        id: Number(params.companyAccountId),
      })
      organizationUserFormRef.current?.formReset({
        name: result.name,
        organizationId: result.organizationId,
        email: result.email,
        password: '',
        permission: decomposeToBits(result.permission),
      })
      setIsDeletedAt(result.deletedAt)
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/organizations/accounts')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = organizationUserFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await putOrganizationUser({
        id: Number(params.companyAccountId),
        name: formValues.name,
        organizationId: formValues.organizationId,
        password: formValues.password || undefined,
        email: formValues.email,
        permission: formValues.permission
          ? formValues.permission.reduce((sum, bit) => sum + bit, 0)
          : 0,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box pb={2}>
        {deletedAt && (
          <Alert severity='error'>
            このデータは
            <strong>{deletedAt}</strong>
            に削除されています。
          </Alert>
        )}
      </Box>
      <OrganizationUserForm ref={organizationUserFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
        disabled={!!deletedAt}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
