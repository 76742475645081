import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { PieChartOutlineOutlined } from '@mui/icons-material'
import { Box, Chip, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { deleteAgencyArtist } from '@/features/organization/api/deleteAgencyArtist'
import { getArtist, getArtistPath } from '@/features/organization/api/getArtist'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { FanStatus, RevenueShareType } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '代理店所属アーティスト一覧'

  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    agencyId: '',
    agencyName: '',
    id: '',
    fanId: '',
    artistName: '',
    isNewcomer: '',
    isFirstGachi: '',
    status: '',
    createdFrom: '',
    createdTo: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const agencyId = searchParams.get('agencyId') || ''
  const agencyName = searchParams.get('agencyName') || ''
  const id = searchParams.get('id') || ''
  const fanId = searchParams.get('fanId') || ''
  const artistName = searchParams.get('artistName') || ''
  const isNewcomer = searchParams.get('isNewcomer') || ''
  const isFirstGachi = searchParams.get('isFirstGachi') || ''
  const status = searchParams.get('status') || ''
  const createdFromParam = searchParams.get('createdFrom')
  const createdFrom = createdFromParam ? newDate(createdFromParam) : null
  const createdToParam = searchParams.get('createdTo')
  const createdTo = createdToParam ? newDate(createdToParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      fanId: fanId,
      artistName: artistName,
      isNewcomer: isNewcomer,
      isFirstGachi: isFirstGachi,
      status: status,
      agencyId: agencyId,
      agencyName: agencyName,
      createdFrom: createdFrom,
      createdTo: createdTo,
    },
  })

  const { data, isValidating, mutate } = useSWR(getArtistPath, () =>
    getArtist({
      page: page,
      agencyId: agencyId || undefined,
      agencyName: agencyName || undefined,
      id: id || undefined,
      fanId: fanId || undefined,
      artistName: artistName || undefined,
      isNewcomer: isNewcomer ? Boolean(isNewcomer === 'true') : undefined,
      isFirstGachi: isFirstGachi ? Boolean(isFirstGachi === 'true') : undefined,
      status: status || undefined,
      isBelongAgency: true,
      createdFrom: createdFrom
        ? formatDateTime(createdFrom, Format.dateRequest)
        : undefined,
      createdTo: createdTo
        ? formatDateTime(createdTo, Format.dateRequest)
        : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'agencyId', value: getValues('agencyId') },
        { key: 'agencyName', value: getValues('agencyName') },
        { key: 'id', value: getValues('id') },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'isNewcomer', value: getValues('isNewcomer') },
        { key: 'isFirstGachi', value: getValues('isFirstGachi') },
        { key: 'status', value: getValues('status') },
        {
          key: 'createdFrom',
          value: formatDateTime(getValues('createdFrom'), Format.dateRequest),
        },
        {
          key: 'createdTo',
          value: formatDateTime(getValues('createdTo'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getArtist({
        page: page,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        id: getValues('id') || undefined,
        fanId: getValues('fanId') || undefined,
        artistName: getValues('artistName') || undefined,
        isNewcomer: getValues('isNewcomer')
          ? Boolean(getValues('isNewcomer'))
          : undefined,
        isFirstGachi: getValues('isFirstGachi')
          ? Boolean(getValues('isFirstGachi'))
          : undefined,
        status: getValues('status') || undefined,
        createdFrom: getValues('createdFrom')
          ? formatDateTime(getValues('createdFrom'), Format.dateRequest)
          : undefined,
        createdTo: getValues('createdTo')
          ? formatDateTime(getValues('createdTo'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  // 遷移
  const goDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goAgencyDetail = (agencyId = '') => {
    navigate('/agencies/:agencyId', {
      params: {
        agencyId: agencyId,
      },
    })
  }

  const submitDelete = async (id: number) => {
    try {
      const { isConfirmed } = await swal.confirm({
        text: '本当に解除しますか？',
      })
      if (!isConfirmed) return

      await deleteAgencyArtist({ agencyArtistId: id })
      mutate()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      artistName: item.artistName,
      agencyId: item.agencyId,
      agencyName: item.agencyName,
      status: {
        text: FanStatus[item.status].text,
        color: FanStatus[item.status].color,
      },
      revenueShareRate: item.revenueShareRate,
      agencyRevenueShareType: item.agencyRevenueShareType
        ? RevenueShareType[item.agencyRevenueShareType].text
        : '-',
      agencyRevenueShareRate: item.agencyRevenueShareRate || '-',
      isNewcomer: item.isNewcomer ? '◯' : '-',
      isFirstGachi: item.isFirstGachi ? '◯' : '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const statusOptions = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(FanStatus).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const goArtistRevenueShareCreate = (artistId = '') => {
    navigate({
      pathname: '/artists/revenue-share/create',
      search: `?artistId=${artistId}`,
    })
  }

  const goAgencyJoin = () => {
    navigate({
      pathname: '/agencies/join',
      search: `?agencyId=${agencyId}&artistId=${id}`,
    })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'アーティストを所属させる', onClick: goAgencyJoin }]}
      >
        {pageTitle}
      </PageTitle>
      <Box
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='agencyId'
            label='代理店ID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='agencyName'
            label='代理店名'
            fullWidth={false}
            hint='部分一致'
          />
          <FormText
            control={control}
            name='id'
            label='アーティストID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
          <FormText
            control={control}
            name='artistName'
            label='アーティスト名'
            fullWidth={false}
            hint='部分一致'
          />
          <FormText
            control={control}
            name='fanId'
            label='ファンID'
            fullWidth={false}
            hint={'カンマ区切りで複数指定可能'}
          />
        </Box>
        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Box display={'flex'} gap={2} alignItems={'flex-start'}>
            <FormSelect
              control={control}
              name='status'
              label='ステータス'
              fullWidth={false}
              options={statusOptions()}
            />
            <FormSelect
              control={control}
              name='isNewcomer'
              label='新人'
              fullWidth={false}
              options={[
                { value: '', text: '選択してください' },
                { value: 'true', text: '新人のみ' },
                { value: 'false', text: '新人以外' },
              ]}
            />
            <FormSelect
              control={control}
              name='isFirstGachi'
              label='ガチ初挑戦'
              fullWidth={false}
              options={[
                { value: '', text: '選択してください' },
                { value: 'true', text: 'ガチ初挑戦のみ' },
                { value: 'false', text: 'ガチ初挑戦以外' },
              ]}
            />
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <FormDate
                control={control}
                name='createdFrom'
                label='アーティスト登録'
                views={['year', 'month', 'day']}
                fullWidth={false}
              />
              <Typography>〜</Typography>
              <FormDate
                control={control}
                name='createdTo'
                label='　' // 余白揃えるため
                views={['year', 'month', 'day']}
                fullWidth={false}
              />
            </Box>
          </Box>
          <ActionButton type='submit'>検索</ActionButton>
        </Box>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 50,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: '_rs',
              headerName: 'RS更新',
              width: 70,
              align: 'center',
              renderCell: (params) => {
                return (
                  <PieChartOutlineOutlined
                    onClick={() => {
                      goArtistRevenueShareCreate(params.row.id)
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                )
              },
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'revenueShareRate',
              headerName: 'RS率(%)',
              width: 80,
            },
            {
              field: 'agencyId',
              headerName: '代理店ID',
              width: 80,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'agencyName',
              headerName: '代理店名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goAgencyDetail(params.row.agencyId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'agencyRevenueShareRate',
              headerName: '代理店RS率(%)',
              width: 120,
            },
            {
              field: 'agencyRevenueShareType',
              headerName: 'RSタイプ',
              width: 120,
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 130,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'isNewcomer',
              headerName: '新人',
              width: 90,
            },
            {
              field: 'isFirstGachi',
              headerName: 'ガチ初挑戦',
              width: 90,
            },
            {
              field: 'createdAt',
              headerName: '登録日時',
              width: 150,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 150,
            },
            {
              field: 'delete',
              headerName: '所属解除',
              width: 100,
              renderCell: (params) => {
                return (
                  <ActionButton
                    type='button'
                    size='small'
                    color='error'
                    variant='outlined'
                    onClick={() => {
                      submitDelete(params.row.id)
                    }}
                  >
                    解除
                  </ActionButton>
                )
              },
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'fanId'}
        />
      </Box>
    </Box>
  )
}

export default Page
