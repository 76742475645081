import type { FaqCategoryAllItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetFaqCategoryAllParams = {
  name?: string
  status?: string
  target?: string
}

export type GetFaqCategoryAllResponse = APIResponse<Array<FaqCategoryAllItem>>

export const getFaqCategoryAllPath = '/admin/v1/faq-category/all'

export const getFaqCategoryAll = async (params: GetFaqCategoryAllParams) => {
  return await http<GetFaqCategoryAllResponse>(getFaqCategoryAllPath, {
    method: 'GET',
    searchParams: {
      name: params.name,
      status: params.status,
      target: params.target,
    },
  })
}
