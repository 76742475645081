import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { BanAccountFormRef } from '@/features/ban/components/BanAccountForm';

import { postBanAccount } from '@/features/ban/api/postBanAccount'
import BanAccountForm from '@/features/ban/components/BanAccountForm'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'BANアカウント登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const BanAccountFormRef = useRef<BanAccountFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = BanAccountFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      await postBanAccount({
        fanId: formValues.fanId,
        expiredAt: formatDateTime(formValues.expiredAt, Format.dateTimeRequest),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/bans/account')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <BanAccountForm ref={BanAccountFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
