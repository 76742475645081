import { useState } from 'react'
import { ContentCopyOutlined } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'

export default function CopyClipboard({
  text,
  displayText,
  textColor = 'default',
  isIcon = false,
}: {
  text: string // コピーしたいテキスト
  displayText?: string // コピーしたいテキストの表示名
  textColor?: string // 表示名のテキストカラー
  isIcon?: boolean
}) {
  const [isHovered, setIsHovered] = useState(false)
  const [message, setMessage] = useState('Copy')

  const handleClick = (text: string) => {
    navigator.clipboard.writeText(text)
    setMessage('Copied!')
  }

  const handleMouseEnter = () => {
    setMessage('Copy')
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Typography
      component={'span'}
      onClick={() => handleClick(text)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip title={message}>
        {isIcon ? (
          <ContentCopyOutlined
            sx={{
              cursor: 'pointer',
              opacity: isHovered ? 0.5 : 1,
              width: '1.2rem',
            }}
          ></ContentCopyOutlined>
        ) : (
          <Typography
            sx={{
              cursor: 'pointer',
              opacity: isHovered ? 0.5 : 1,
              display: 'inline-block',
              color: textColor,
            }}
          >
            {displayText ?? text}
          </Typography>
        )}
      </Tooltip>
    </Typography>
  )
}