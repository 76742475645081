import type { FaqCategoryItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFaqCategoryParams = {
  page: number
  name?: string
  status?: string
  target?: string
}

export type GetFaqCategoryResponse = APIResponse<
  APIResponseResultPagination<FaqCategoryItem>
>

export const getFaqCategoryPath = '/admin/v1/faq-category'

export const getFaqCategory = async (params: GetFaqCategoryParams) => {
  return await http<GetFaqCategoryResponse>(getFaqCategoryPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      name: params.name,
      status: params.status,
      target: params.target,
    },
  })
}
