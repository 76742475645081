import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteBanDeviceParams = {
  id: number
}

export const deleteBanDevicePath = '/admin/v1/fan-device-ban/:id'

export const deleteBanDevice = async (
  params: DeleteBanDeviceParams,
) => {
  return await http<APIResponse<null>>(deleteBanDevicePath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
