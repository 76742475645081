import type {
  InquiryFile,
  InquiryFrom,
  MessageTypeType,
  MessageEvent,
} from '../types'
import type { APIResponse, CheerTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetMessageBoxDetailParams = {
  id: string
  fanId: string
}

export type ReplyItem = {
  from: InquiryFrom
  sort: number
  body: string
  inquiryFile: InquiryFile[]
  createdAt: string
}

export type GetMessageBoxDetailResponse = APIResponse<{
  id: string
  fanId: number
  fanName: string
  messageBoxTemplateId: number
  title: string
  body: string
  imageUrl: string
  favoriteFlag: boolean
  importantFlag: boolean
  readFlag: boolean
  messageType: MessageTypeType
  replyCount: number
  createdAt: string
  updatedAt: string
  lastMessagedAt: string
  from: InquiryFrom
  inquiryFile: InquiryFile[]
  reply: ReplyItem[]
  cheer: {
    cheer: number
    type: CheerTypeType
    receivedFlag: boolean
    expiredAt: string
    event: MessageEvent
  }

  ttl: string
}>

export const getMessageBoxDetailPath = '/admin/v1/message-box/:messageBoxId/:fanId'

export const getMessageBoxDetail = async (
  params: GetMessageBoxDetailParams,
) => {
  return await http<GetMessageBoxDetailResponse>(getMessageBoxDetailPath, {
    method: 'GET',
    params: {
      messageBoxId: params.id,
      fanId: params.fanId,
    },
  })
}
