import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMonthlyCoinCheerReportParams = {
  page: number
  year?: number
  month?: number
}

export type GetMonthlyCoinCheerReportResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    year: number
    month: number
    issuedCoin: number
    exchangedCoin: number
    expiredCoin: number
    cashoutCoin: number
    depositCoin: number
    issuedFreeCheer: number
    usedFreeCheer: number
    depositFreeCheer: number
  }>
>

export const getMonthlyCoinCheerReportPath =
  '/admin/v1/monthly-coin-cheer-report'

export const getMonthlyCoinCheerReport = async (
  params: GetMonthlyCoinCheerReportParams,
) => {
  return await http<GetMonthlyCoinCheerReportResponse>(
    getMonthlyCoinCheerReportPath,
    {
      method: 'GET',
      searchParams: {
        page: params.page,
        year: params.year,
        month: params.month,
      },
    },
  )
}
