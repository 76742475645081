import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getPointPurchaseMenu } from '@/features/purchase/api/getPointPurchaseMenu'
import { PurchaseMenuType } from '@/features/purchase/types'
import { useNavigate } from '@/router'
import { PlatFormType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '課金メニュー一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    platform: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const platform = searchParams.get('platform') || ''

  const { data, isValidating, mutate } = useSWR(`${getPointPurchaseMenu}`, () =>
    getPointPurchaseMenu({
      page: page,
      platform: platform,
    }),
  )
  const { control } = useForm({
    defaultValues: {
      platform: platform,
    },
  })

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'platform', value: String(platform) },
      ])
      return prev
    })
    mutate(
      getPointPurchaseMenu({
        page: page,
        platform: platform,
      }),
    )
  }

  const handleSearch = () => {
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  const goDetail = (id = '') => {
    navigate('/purchase-menus/:menuId', {
      params: {
        menuId: id,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      title: item.name,
      platform: item.platform,
      type: PurchaseMenuType[item.type].text,
      price: item.taxIncludedPrice,
      point: item.point,
      publishedAt: item.publishedAt,
      closedAt: item.closedAt || '-',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const platforms = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(PlatFormType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const goCreate = () => {
    navigate('/purchase-menus/create')
  }
  return (
    <Box className='page'>
      <Box>
        <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
          {pageTitle}
        </PageTitle>
        <Box
          width={1 / 4}
          display={'flex'}
          gap={1}
          alignItems={'center'}
          onSubmit={handleSearch}
          component='form'
        >
          <FormSelect
            control={control}
            name='platform'
            label='プラットフォーム'
            options={platforms()}
          />
          <ActionButton type='submit'>検索</ActionButton>
        </Box>

        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'title', headerName: 'メニュー名', width: 200 },
            { field: 'platform', headerName: 'プラットフォーム', width: 150 },
            { field: 'type', headerName: '種別', width: 120 },
            { field: 'price', headerName: '金額(税込)', width: 150 },
            {
              field: 'point',
              headerName: 'ポイント',
              width: 150,
            },
            {
              field: 'publishedAt',
              headerName: '公開日時',
              width: 180,
            },
            {
              field: 'closedAt',
              headerName: '終了日時',
              width: 180,
            },
            { field: 'createdAt', headerName: '作成日時', width: 180 },
            { field: 'updatedAt', headerName: '更新日時', width: 180 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
        />
      </Box>
    </Box>
  )
}

export default Page
