import React from 'react'

import { Box, FormLabel } from '@mui/material'

function FormValue({
  label,
  children,
  sx,
}: {
  label: string
  children: React.ReactNode
  sx?: React.CSSProperties
}) {
  return (
    <Box mb={2} width={'100%'} sx={sx}>
      <FormLabel>{label}</FormLabel>
      <Box
        maxWidth={'100%'}
        sx={{ wordBreak: 'break-all' }}
        borderBottom={'solid 1px'}
        borderColor={'grey.500'}
        pb={1}
      >
        {children}
      </Box>
    </Box>
  )
}

export default FormValue
