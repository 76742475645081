import { Box, Grid } from '@mui/material'

function FormBox({
  onSubmit,
  children,
  sx,
  autoComplete = 'off',
}: {
  onSubmit?: React.FormEventHandler | undefined
  children: React.ReactNode
  sx?: React.CSSProperties
  autoComplete?: string
}) {
  return (
    <Box
      component={'form'}
      onSubmit={onSubmit}
      sx={sx}
      autoComplete={autoComplete}
    >
      <Grid container bgcolor={'white'} p={2} spacing={10}>
        {children}
      </Grid>
    </Box>
  )
}

export default FormBox
