import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { FaqCategoryFormRef } from '@/features/faq/components/FaqCategoryForm'

import { getFaqCategoryDetail } from '@/features/faq/api/getFaqCategoryDetail'
import { putFaqCategory } from '@/features/faq/api/putFaqCategory'
import FaqCategoryForm from '@/features/faq/components/FaqCategoryForm'
import { FaqCategoryStatus } from '@/features/faq/types'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/faqs/categories/:faqCategoryId')
  const pageTitle = 'FAQカテゴリー詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const createFaqCategoryFormRef = useRef<FaqCategoryFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getFaqCategoryDetail({
        id: Number(params.faqCategoryId),
      })
      createFaqCategoryFormRef.current?.formReset({
        name: result.name,
        target: result.target.key,
        sort: result.sort ? String(result.sort) : '',
        status: result.status.key,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/faqs/categories')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = createFaqCategoryFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await putFaqCategory({
        id: Number(params.faqCategoryId),
        name: formValues.name,
        sort: Number(formValues.sort),
        target: formValues.target,
        status: formValues.status || FaqCategoryStatus.PUBLIC.value,
      })
      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <FaqCategoryForm ref={createFaqCategoryFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
