import React from 'react'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Delete } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  OutlinedInput,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import type { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
} & UseControllerProps<T>

export type DESCRIPTION_INPUT_TYPE = {
  title: string
  description: string
}

interface SortableItemProps {
  id: number
  value: DESCRIPTION_INPUT_TYPE
  onChange: (newValue: DESCRIPTION_INPUT_TYPE) => void
  onDelete: () => void
  disabled: boolean
  isFirst: boolean
}

interface ItemContentProps extends SortableItemProps {
  dragHandle?: React.ReactNode
}

const SortableItem = ({
  id,
  value,
  onChange,
  onDelete,
  disabled,
  isFirst,
}: SortableItemProps): JSX.Element => {
  const { attributes, listeners, setNodeRef } = useSortable({ id })

  return (
    <Box
      ref={setNodeRef}
      mb={2}
      gap={1}
      width={'100%'}
      border={1}
      borderRadius={1}
      p={1}
    >
      <ItemContent
        value={value}
        onChange={onChange}
        onDelete={onDelete}
        disabled={disabled}
        isFirst={isFirst}
        dragHandle={
          <Box
            {...attributes}
            {...listeners}
            sx={{ cursor: 'move', mr: 1 }}
            visibility={isFirst ? 'hidden' : 'visible'}
          >
            <DragIndicatorIcon />
          </Box>
        }
        id={id}
      />
    </Box>
  )
}

const ItemContent = ({
  value,
  onChange,
  onDelete,
  disabled,
  isFirst,
  dragHandle,
}: ItemContentProps): JSX.Element => (
  <Box display={'flex'} alignItems={'center'}>
    {dragHandle}
    <Box flexShrink={0} width={'90%'}>
      <FormLabel>タイトル</FormLabel>
      <OutlinedInput
        fullWidth
        value={value.title}
        disabled={disabled}
        onChange={(e) => onChange({ ...value, title: e.target.value })}
      />
      <FormLabel sx={{ marginTop: '10px' }}>本文</FormLabel>
      <OutlinedInput
        fullWidth
        value={value.description}
        disabled={disabled}
        onChange={(e) => onChange({ ...value, description: e.target.value })}
        rows={4}
        multiline
        sx={{ height: '100%' }}
      />
    </Box>
    {!isFirst && (
      <Box>
        <FormLabel>&nbsp;</FormLabel>
        <Button type='button' onClick={onDelete}>
          <Delete />
        </Button>
      </Box>
    )}
  </Box>
)

function EventDescriptionsForm<T extends FieldValues>({
  name,
  label,
  required = false,
  disabled = false,
  control,
}: Props<T>) {
  const [activeId, setActiveId] = React.useState<number | null>(null)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => {
        const handleDragStart = (event: DragStartEvent) => {
          setActiveId(Number(event.active.id))
        }

        const handleDragEnd = (event: DragEndEvent) => {
          const { active, over } = event

          if (active.id !== over?.id) {
            const oldIndex = field.value.findIndex(
              (_: DESCRIPTION_INPUT_TYPE, index: number) => index === active.id,
            )
            const newIndex = field.value.findIndex(
              (_: DESCRIPTION_INPUT_TYPE, index: number) => index === over?.id,
            )

            field.onChange(arrayMove(field.value, oldIndex, newIndex))
          }

          setActiveId(null)
        }

        return (
          <Box mb={2} width={'100%'}>
            <FormLabel required={required}>{label}</FormLabel>
            <FormGroup ref={field.ref}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={field.value.map(
                    (_: DESCRIPTION_INPUT_TYPE, index: number) => index,
                  )}
                  strategy={verticalListSortingStrategy}
                >
                  {field.value.map((v: DESCRIPTION_INPUT_TYPE, k: number) => (
                    <SortableItem
                      // 別途ユニークなIDを割り当てると挙動が怪しくなるので、indexをそのまま使う
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      key={k}
                      id={k}
                      value={v}
                      onChange={(newValue) => {
                        const newValues = [...field.value]
                        newValues[k] = newValue
                        field.onChange(newValues)
                      }}
                      onDelete={() => {
                        const newValues = field.value.filter(
                          (_: DESCRIPTION_INPUT_TYPE, index: number) =>
                            index !== k,
                        )
                        field.onChange(newValues)
                      }}
                      disabled={disabled}
                      isFirst={k === 0}
                    />
                  ))}
                </SortableContext>
                <DragOverlay>
                  {activeId !== null && (
                    <Box
                      mb={2}
                      gap={1}
                      width={'100%'}
                      border={1}
                      borderRadius={1}
                      p={1}
                      bgcolor='background.paper'
                      sx={{ opacity: 0.8 }}
                    >
                      <ItemContent
                        value={field.value[activeId]}
                        onChange={() => {}}
                        onDelete={() => {}}
                        disabled={disabled}
                        isFirst={activeId === 0}
                        id={activeId}
                      />
                    </Box>
                  )}
                </DragOverlay>
              </DndContext>
            </FormGroup>
            <Box>
              <Button
                type='button'
                onClick={() => {
                  field.onChange([
                    ...field.value,
                    { title: '', description: '' },
                  ])
                }}
              >
                <AddIcon />
                <Typography variant='body1'>段落を追加</Typography>
              </Button>
            </Box>
            {errors[name] && (
              <Typography variant='body1' color='error'>
                <small>{String(errors[name]?.message)}</small>
              </Typography>
            )}
          </Box>
        )
      }}
    />
  )
}

export default EventDescriptionsForm
