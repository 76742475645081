import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteBanAccountParams = {
  id: number
}

export const deleteBanAccountPath = '/admin/v1/fan-account-ban/:id'

export const deleteBanAccount = async (
  params: DeleteBanAccountParams,
) => {
  return await http<APIResponse<null>>(deleteBanAccountPath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
