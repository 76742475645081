import { http } from '@/lib/http'

export type PostArtistPickupParams = {
  artistId: number
  startAt: string
  endAt: string
  memo?: string
}

export const postArtistPickupPath = '/admin/v1/pickup-artist'

export const postArtistPickup = async (params: PostArtistPickupParams) => {
  return await http<null>(postArtistPickupPath, {
    method: 'POST',
    body: JSON.stringify({
      artist_id: params.artistId,
      start_at: params.startAt,
      end_at: params.endAt,
      memo: params.memo,
    }),
  })
}
