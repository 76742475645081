import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Alert, Box } from '@mui/material'

import type { ArtistRevenueShareFormRef } from '@/features/artists/components/ArtistRevenueShareForm';
import type { ArtistHistoryItemType } from '@/features/artists/types'

import { deleteArtistRevenueShare } from '@/features/artists/api/deleteArtistRevenueShare'
import { getArtistRevenueShareDetail } from '@/features/artists/api/getArtistRevenueShareDetail'
import { patchArtistRevenueShare } from '@/features/artists/api/patchArtistRevenueShare'
import ArtistRevenueShareForm from '@/features/artists/components/ArtistRevenueShareForm'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'


function Page() {
  const navigate = useNavigate()
  const params = useParams('/artists/revenue-share/:revenueShareId')
  const pageTitle = 'アーティストRS履歴詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const [artistRevenueShareData, setArtistRevenueShareData] =
    useState<ArtistHistoryItemType>()
  const ArtistRevenueShareFormRef = useRef<ArtistRevenueShareFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getArtistRevenueShareDetail({
        id: Number(params.revenueShareId),
      })

      setArtistRevenueShareData(result)

      ArtistRevenueShareFormRef.current?.formReset({
        id: result.id,
        fanId: result.fanId,
        artistId: result.artistId,
        artistName: result.artistName,
        revenueShareRate: result.revenueShareRate,
        organizationId: result.organizationId || '',
        organizationName: result.organizationName || '',
        agencyId: result.agencyId || '',
        agencyName: result.agencyName || '',
        agencyRevenueShareType: result.agencyRevenueShareType.key || '',
        agencyRevenueShareRate: result.agencyRevenueShareRate || '',
        startAt: newDate(result.startAt),
        isEditable: result.isEditable,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/artists/revenue-share')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = ArtistRevenueShareFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await patchArtistRevenueShare({
        id: Number(params.revenueShareId),
        revenueShareRate: formValues.revenueShareRate,
        organizationId: formValues.organizationId || undefined,
        agencyId: formValues.agencyId || undefined,
        agencyRevenueShareType:
          (formValues.agencyId && formValues.agencyRevenueShareType) ||
          undefined,
        agencyRevenueShareRate:
          (formValues.agencyId && formValues.agencyRevenueShareRate) ||
          undefined,
        startAt: formatDateTime(formValues.startAt, Format.dateRequest),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 削除
  const deleteAction = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteArtistRevenueShare({
        id: Number(params.revenueShareId),
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    }
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <Box pb={2}>
        {artistRevenueShareData?.isEditable === false && (
          <Alert severity='error'>
            アーティスト登録時の履歴、または、収益確定済みのデータのため編集できません。
          </Alert>
        )}
      </Box>
      <ArtistRevenueShareForm ref={ArtistRevenueShareFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
        disabled={artistRevenueShareData?.isEditable === false}
      >
        更新する
      </ActionButton>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        color='error'
        variant='outlined'
        onClick={deleteAction}
        disabled={artistRevenueShareData?.isEditable === false}
        sx={{ marginTop: 10 }}
      >
        削除する
      </ActionButton>
    </Box>
  )
}

export default Page
