import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Grid } from '@mui/material'
import { startOfDay } from 'date-fns'
import { useForm, useWatch } from 'react-hook-form'

import { PurchaseMenuType } from '../types'

import { newDate } from '@/lib/dateFns'
import { PlatFormType } from '@/types'

type PointPurchaseMenuFormValues = {
  name: string
  description: string
  type: string
  platform: string
  productId: string
  taxIncludedPrice: number
  point: number
  publishedAt: Date
  closedAt: Date | null
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface PostPurchaseMenuFormProps {}
export interface PostPurchaseMenuFormRef {
  getFormValues(): PointPurchaseMenuFormValues
  setFormValue(key: keyof PointPurchaseMenuFormValues, value: string): void
  formReset(values: PointPurchaseMenuFormValues): void
}

const PointPurchaseMenuForm = forwardRef<
  PostPurchaseMenuFormRef,
  PostPurchaseMenuFormProps
>((_, ref) => {
  const { control, getValues, setValue, reset } =
    useForm<PointPurchaseMenuFormValues>({
      defaultValues: {
        name: '',
        description: '',
        type: PurchaseMenuType.ONE_TIME.value,
        platform: '',
        productId: '',
        taxIncludedPrice: 0,
        point: 0,
        publishedAt: startOfDay(newDate()),
        closedAt: null,
      },
    })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const platforms = () => {
    const empty = [{ value: '', text: '選択してください' }]
    const base = Object.entries(PlatFormType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
    return empty.concat(base)
  }

  const types = () => {
    return Object.entries(PurchaseMenuType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
  }

  const watchPlatform = useWatch({ control, name: 'platform' })

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={6}>
        <FormText control={control} name='name' label='メニュー名' required />

        <FormText
          control={control}
          name='description'
          label='説明'
          multiline={true}
          required
        />

        <FormSelect
          control={control}
          name='platform'
          label='プラットフォーム'
          fullWidth={true}
          options={platforms()}
          required
        />

        <FormSelect
          control={control}
          name='type'
          label='種別'
          fullWidth={true}
          options={types()}
          required
        />

        {watchPlatform !== PlatFormType.WEB.value && (
          <FormText
            control={control}
            name='productId'
            label='ストア課金メニュー識別ID'
            required
          />
        )}

        <FormText
          control={control}
          name='taxIncludedPrice'
          label='金額(税込）'
          required
          type='number'
        />

        <FormText
          control={control}
          name='point'
          label='ポイント数'
          required
          type='number'
        />

        <FormDate
          control={control}
          name='publishedAt'
          label='公開日時'
          views={['year', 'month', 'day', 'hours', 'minutes']}
          fullWidth
          sx={{ width: '100%' }}
          required
        />

        <FormDate
          control={control}
          name='closedAt'
          label='終了日時'
          views={['year', 'month', 'day', 'hours', 'minutes']}
          fullWidth
          sx={{ width: '100%' }}
        />
      </Grid>
    </FormBox>
  )
})

export default PointPurchaseMenuForm
