import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchAdminUserParams = {
  id: number
  name: string
  email: string
  password?: string
}

export type PatchAdminUserResponse = APIResponse<null>

export const patchAdminUserPath = '/admin/v1/admin-user/:id'

export const patchAdminUser = async (params: PatchAdminUserParams) => {
  return await http<PatchAdminUserResponse>(patchAdminUserPath, {
    method: 'PATCH',
    params: {
      id: params.id,
    },
    body: JSON.stringify({
      name: params.name,
      email: params.email,
      password: params.password,
    }),
  })
}
