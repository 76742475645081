import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import ImageView from '@cz_frontend/ui/components/common/ImageView/ImageView'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { RecentActorsOutlined } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getEvent, getEventPath } from '@/features/event/api/getEvent'
import { EntryType } from '@/features/event/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'ゆるイベ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    eventId: '',
    name: '',
    startAt: '',
    endAt: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const eventId = searchParams.get('eventId') || ''
  const name = searchParams.get('name') || ''
  const startAtParam = searchParams.get('startAt')
  const startAt = startAtParam ? newDate(startAtParam) : null
  const endAtParam = searchParams.get('endAt')
  const endAt = endAtParam ? newDate(endAtParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      eventId: eventId,
      name: name,
      startAt: startAt,
      endAt: endAt,
    },
  })

  const { data, isValidating, mutate } = useSWR(`${getEventPath}-loose`, () =>
    getEvent({
      page: page,
      type: EventType.YURU.value,
      eventId: eventId || undefined,
      name: name || undefined,
      startAt: startAt
        ? formatDateTime(startAt, Format.dateRequest)
        : undefined,
      endAt: endAt ? formatDateTime(endAt, Format.dateRequest) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'eventId', value: getValues('eventId') },
        { key: 'name', value: getValues('name') },
        {
          key: 'startAt',
          value: formatDateTime(getValues('startAt'), Format.dateRequest),
        },
        {
          key: 'endAt',
          value: formatDateTime(getValues('endAt'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getEvent({
        page: page,
        eventId: getValues('eventId') || undefined,
        type: EventType.YURU.value,
        name: getValues('name') || undefined,
        startAt: getValues('startAt')
          ? formatDateTime(getValues('startAt'), Format.dateRequest)
          : undefined,
        endAt: getValues('endAt')
          ? formatDateTime(getValues('endAt'), Format.dateRequest)
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/events/loose/:eventId', {
      params: {
        eventId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/events/loose/create')
  }

  const goPreview = (url: string) => {
    window.open(url, '_blank')
  }

  const goEntryList = (eventId: number, eventType: string) => {
    navigate(
      {
        pathname: '/events/:eventId/entries',
        search: `?eventType=${eventType}`,
      },
      {
        params: {
          eventId: String(eventId),
        },
      },
    )
  }

  const goCopy = (eventId: number) => {
    navigate('/events/loose/copy/:originEventId', {
      params: {
        originEventId: String(eventId),
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      eventId: item.eventId,
      eventDetailId: item.eventDetailId,
      name: item.name,
      bannerUrl: item.bannerUrl,
      type: {
        key: item.type,
        value: EventType[item.type].text,
      },
      entryType: EntryType[item.entryType].text,
      entryStartAt: item.entryStartAt || '-',
      entryEndAt: item.entryEndAt || '-',
      entryPeriodColor:
        (item.entryStartAt &&
          item.entryEndAt &&
          newDate(item.entryStartAt) <= newDate() &&
          newDate() <= newDate(item.entryEndAt)) ||
        (!item.entryStartAt && !item.entryEndAt)
          ? 'success.main'
          : 'default',
      eventStartAt: item.eventStartAt,
      eventEndAt: item.eventEndAt,
      eventPeriodColor:
        newDate(item.eventStartAt) <= newDate() &&
        newDate() <= newDate(item.eventEndAt)
          ? 'success.main'
          : 'default',
      eventDisplayStartAt: item.eventDisplayStartAt,
      eventDisplayEndAt: item.eventDisplayEndAt,
      eventDisplayPeriodColor:
        (item.eventDisplayStartAt &&
          item.eventDisplayEndAt &&
          newDate(item.eventDisplayStartAt) <= newDate() &&
          newDate() <= newDate(item.eventDisplayEndAt)) ||
        (!item.eventDisplayStartAt && !item.eventDisplayEndAt)
          ? 'success.main'
          : 'default',
      entryCount: item.entryCount,
      previewUrl: item.previewUrl,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='name'
              label='イベント名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='startAt'
              label='イベント期間開始日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormDate
              control={control}
              name='endAt'
              label='イベント期間終了日'
              views={['year', 'month', 'day']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'entry',
              headerName: 'エントリー数',
              width: 120,
              align: 'center',
              renderCell: (params) => (
                <Box display={'flex'}>
                  <RecentActorsOutlined
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      goEntryList(params.row.eventId, params.row.type.key)
                    }}
                  />
                  <Typography>（{params.row.entryCount}）</Typography>
                </Box>
              ),
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 100,
              align: 'right',
            },
            {
              field: 'eventDetailId',
              headerName: 'イベント詳細ID',
              width: 120,
              align: 'right',
            },
            {
              field: 'bannerUrl',
              headerName: 'バナー',
              width: 150,
              align: 'center',
              renderCell: (params) => {
                return (
                  <Box my={3}>
                    <ImageView
                      imageSrc={params.value}
                      imageAlt='バナー画像'
                      style={{ height: '80px' }}
                    />
                  </Box>
                )
              },
            },
            {
              field: 'name',
              headerName: 'イベント名',
              width: 300,
            },
            {
              field: 'entryStartAt',
              headerName: '募集開始日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.entryPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'entryEndAt',
              headerName: '募集終了日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.entryPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'eventStartAt',
              headerName: 'イベント開始日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.eventPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'eventEndAt',
              headerName: 'イベント終了日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.eventPeriodColor}>{params.value}</Box>
              ),
            },
            {
              field: 'eventDisplayStartAt',
              headerName: 'イベント表示開始日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.eventDisplayPeriodColor}>
                  {params.value}
                </Box>
              ),
            },
            {
              field: 'eventDisplayEndAt',
              headerName: 'イベント表示終了日時',
              width: 200,
              renderCell: (params) => (
                <Box color={params.row.eventDisplayPeriodColor}>
                  {params.value}
                </Box>
              ),
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
            {
              field: 'action',
              headerName: '操作',
              width: 300,
              renderCell: (params) => (
                <Box display={'flex'} gap={1}>
                  <ActionButton
                    onClick={() => {
                      goPreview(params.row.previewUrl)
                    }}
                  >
                    LP確認
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      goCopy(params.row.eventId)
                    }}
                  >
                    コピー
                  </ActionButton>
                </Box>
              ),
            },
          ]}
          idPropertyName={'eventId'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'eventId'}
          rowHeight={90}
        />
      </Box>
    </Box>
  )
}

export default Page
