import type {
  BankAccountTypeType,
  InvoiceNumberEnabledType,
} from '../types'
import type { APIResponse, APIResponseResultPagination, InvoiceNumberStatusType, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetAgencyParams = {
  page: number
  name?: string
}

export type GetAgencyResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    name: string
    zipCode: string
    province: string
    city: string
    address1: string
    address2: string
    department: string
    person: string
    phoneNumber: string
    notificationEmail: string
    invoiceNumberStatus: InvoiceNumberStatusType
    invoiceNumber: string
    invoiceNumberEnabled: InvoiceNumberEnabledType
    bankName: string
    bankCode: string
    branchName: string
    branchCode: string
    bankAccountType: BankAccountTypeType
    bankAccountNumber: string
    bankAccountHolder: string
    defaultRevenueShareType: RevenueShareTypeType
    defaultRevenueShareRate: number
    createdAt: string
    updatedAt: string
  }>
>

export const getAgencyPath = '/admin/v1/agency'

export const getAgency = async (params: GetAgencyParams) => {
  return await http<GetAgencyResponse>(getAgencyPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      name: params.name,
    },
  })
}
