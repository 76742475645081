import type { APIResponse, APIResponseResultPagination, EventTypeType, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type getArtistEventRevenueShareParams = {
  page: number
  year?: number
  month?: number
  eventId?: string
  eventName?: string
  artistId?: string
  artistName?: string
  agencyId?: string
  agencyName?: string
}

export type GetArtistEventRevenueShareResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    year: number
    month: number
    eventId: number
    eventName: string
    eventType: EventTypeType
    fanId: number
    artistId: number
    artistName: string
    agencyId: number | null
    agencyName: string | null
    eventRevenueShareRate: number
    agencyRevenueShareType: {
      key: RevenueShareTypeType | null
      value: string | null
    }
    agencyRevenueShareRate: number | null
    isDeleted: boolean
    createdAt: string
    updatedAt: string
  }>
>

export const getArtistEventRevenueSharePath = '/admin/v1/event-entry/history'

export const getArtistEventRevenueShare = async (
  params: getArtistEventRevenueShareParams,
) => {
  return await http<GetArtistEventRevenueShareResponse>(
    getArtistEventRevenueSharePath,
    {
      method: 'GET',
      searchParams: {
        page: params.page,
        year: params.year,
        month: params.month,
        event_id: params.eventId,
        event_name: params.eventName,
        artist_id: params.artistId,
        artist_name: params.artistName,
        agency_id: params.agencyId,
        agency_name: params.agencyName,
      },
    },
  )
}
