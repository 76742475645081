import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetMeResponse = APIResponse<{
  id: string
  name: string
  email: string
}>

export const getMePath = '/admin/v1/me'

export const getMe = async () => {
  return await http<GetMeResponse>(getMePath, {
    method: 'GET',
  })
}
