import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { ArtistPickupFormRef } from '@/features/artists/components/ArtistPickupForm';

import { postArtistPickup } from '@/features/artists/api/postArtistPickup'
import ArtistPickupForm from '@/features/artists/components/ArtistPickupForm'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'アーティストピックアップ登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const ArtistPickupFormRef = useRef<ArtistPickupFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = ArtistPickupFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      await postArtistPickup({
        artistId: formValues.artistId,
        startAt: formatDateTime(formValues.startAt, Format.dateTimeRequest),
        endAt: formatDateTime(formValues.endAt, Format.dateTimeRequest),
        memo: formValues.memo,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/artists/pick-ups')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <ArtistPickupForm ref={ArtistPickupFormRef} isCreate={true} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
