import type {
  APIResponse,
  APIResponseResultPagination,
  CheerTypeType,
  EventTypeType,
} from '@/types'

import { http } from '@/lib/http'

export type GetCheerCollectParams = {
  page: number
  id?: string
  fanId?: string
  name?: string
  eventId?: string
  eventName?: string
  type?: string
}

export type GetCheerCollectResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    eventId: number | null
    eventName: string | null
    eventType: EventTypeType | null
    cheerType: CheerTypeType
    cheerCount: number
    usedCheerCount: number
    expiredAt: string | null
    createdAt: string
  }>
>

export const getCheerCollectPath = '/admin/v1/cheer/receive-history'

export const getCheerCollect = async (params: GetCheerCollectParams) => {
  return await http<GetCheerCollectResponse>(getCheerCollectPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      fan_id: params.fanId,
      name: params.name,
      event_id: params.eventId,
      event_name: params.eventName,
      type: params.type,
    },
  })
}
