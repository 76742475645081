import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { ArtistIdGroupFormRef } from '@/features/artists/components/ArtistIdGroupForm';

import { getArtistIdGroupDetail } from '@/features/artists/api/getArtistIdGroupDetail'
import { patchArtistIdGroup } from '@/features/artists/api/patchArtistIdGroup'
import ArtistIdGroupForm from '@/features/artists/components/ArtistIdGroupForm'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/artist-id-groups/:artistGroupId')
  const pageTitle = 'アーティストIDグループ更新'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const ArtistIdGroupFormRef = useRef<ArtistIdGroupFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getArtistIdGroupDetail({
        id: Number(params.artistGroupId),
      })
      const artistIds = result.artists
        .map((artist) => artist.artistId)
        .join(',')
      ArtistIdGroupFormRef.current?.formReset({
        name: result.name,
        artistIds: artistIds,
        artists: result.artists,
        artistCount: result.artistCount,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/artist-id-groups')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = ArtistIdGroupFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      const artistIds = formValues.artistIds
        .split(',')
        .map((artistId) => Number(artistId))
      await patchArtistIdGroup({
        id: Number(params.artistGroupId),
        name: formValues.name,
        artistIds: artistIds.length > 0 ? artistIds : undefined,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <ArtistIdGroupForm ref={ArtistIdGroupFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
