import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Box, Divider, FormLabel, Grid, Typography } from '@mui/material'
import { endOfMonth } from 'date-fns'
import { useForm } from 'react-hook-form'

import { AggregationTarget } from '../types'

import EventDescriptionsForm from './EventDescriptionsForm'
import EventRewardForm from './EventRewardForm'

import type { AggregationTargetType } from '../types'

import { newDate } from '@/lib/dateFns'
import { INPUT_BASE_HEIGHT } from '@/theme'
import { EventType } from '@/types'

type EventSubFormValues = {
  eventId: string
  name: string
  eventStartAt: Date
  eventEndAt: Date
  aggregationTarget: AggregationTargetType | ''
  rewards: { line: number; title: string }[]
  // スコアロジックは全て少数
  scoreLogicFactor: {
    cheer: number
    viewersCount: number
    viewDuration: number
    postCount: number
    postUu: number
  }
  descriptions: { title: string; description: string }[]
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface EventSubFormProps {}
export interface EventSubFormRef {
  getFormValues(): EventSubFormValues
  setFormValue(key: keyof EventSubFormValues, value: string): void
  formReset(values: EventSubFormValues): void
}

const EventSubForm = forwardRef<EventSubFormRef, EventSubFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } = useForm<EventSubFormValues>(
      {
        defaultValues: {
          eventId: '',
          name: '',
          eventStartAt: newDate(),
          eventEndAt: endOfMonth(newDate()),
          aggregationTarget: '',
          rewards: [],
          scoreLogicFactor: {
            cheer: 1.0,
            viewersCount: 0.0,
            viewDuration: 1.0,
            postCount: 1.4,
            postUu: 1.4,
          },
          descriptions: [{ title: '', description: '' }],
        },
      },
    )

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item>
          {/* イベント基本情報 */}
          <Box mb={6}>
            <Typography>イベント基本情報</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <FormText
              control={control}
              name='eventId'
              label='親イベントID'
              hint='親イベント名かIDで指定。イベント開始後は変更できません'
              required
            />
            <FormText
              control={control}
              name='name'
              label='イベント名'
              hint='255文字以内'
              required
            />
            <FormLabel>イベント期間</FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'1em'}>
              <FormDate
                control={control}
                name='eventStartAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:00:00</Typography>
              <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
              <FormDate
                control={control}
                name='eventEndAt'
                views={['year', 'month', 'day', 'hours']}
                fullWidth={false}
              />
              <Typography height={INPUT_BASE_HEIGHT}>:59:59</Typography>
            </Box>
            <FormRadio
              label='集計対象'
              control={control}
              name='aggregationTarget'
              disabled
              options={Object.entries(AggregationTarget).map(([, item]) => ({
                value: item.value,
                text: item.text,
              }))}
              hint='親イベントの集計対象に準拠します'
            />
          </Box>
          {/* スコアランキング特典 */}
          <Box mb={6} mt={6}>
            <Typography>スコアランキング特典</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <EventRewardForm
              control={control}
              name='rewards'
              eventType={EventType.GACHI.value} // サブイベはランキングが存在するため表示はガチイベに準拠
            />
          </Box>
          {/* スコアロジック */}
          <Box mb={6} mt={6}>
            <Typography>スコアロジック</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
            <Typography color='textSecondary'>
              <small>イベント開始後は変更できません</small>
            </Typography>
          </Box>
          <Box pl={2}>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <FormText
                control={control}
                name='scoreLogicFactor.cheer'
                fullWidth={false}
                type='number'
                label='CHEER'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                +
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.viewersCount'
                fullWidth={false}
                type='number'
                label='視聴人数'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                &times;
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.viewDuration'
                fullWidth={false}
                type='number'
                label='視聴時間'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                +
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.postCount'
                fullWidth={false}
                type='number'
                label='投稿数'
              />
              <Typography fontWeight={'bold'} height={INPUT_BASE_HEIGHT}>
                &times;
              </Typography>
              <FormText
                control={control}
                name='scoreLogicFactor.postUu'
                fullWidth={false}
                type='number'
                label='CHEER UU'
              />
            </Box>
          </Box>
          {/* スコアランキング特典 */}
          <Box mb={6} mt={6}>
            <Typography>イベント概要</Typography>
            <Divider
              component={'hr'}
              sx={{ backgroundColor: 'black', marginTop: '6px' }}
            />
          </Box>
          <Box pl={2}>
            <EventDescriptionsForm control={control} name='descriptions' />
          </Box>
        </Grid>
      </FormBox>
    )
  },
)

export default EventSubForm
