import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { MessageBoxTemplateFormRef } from '@/features/message-boxes/components/MessageBoxTemplateForm'

import { postMessageBoxTemplate } from '@/features/message-boxes/api/postMessageBoxTemplate'
import MessageBoxTemplateForm from '@/features/message-boxes/components/MessageBoxTemplateForm'
import { SendToType } from '@/features/message-boxes/types'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { CheerType } from '@/types'
import { pickErrorMessages, postUploadMedia } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = '運営メッセージ作成'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const messageBoxTemplateFormRef = useRef<MessageBoxTemplateFormRef>(null)

  const isSetCheer = () => {
    const formValues = messageBoxTemplateFormRef.current!.getFormValues()
    const cheer = formValues.cheer
    if (!cheer) return false
    return cheer > 0
  }

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = messageBoxTemplateFormRef.current!.getFormValues()
      const cheerType = formValues.cheerType
      const { result } = await postMessageBoxTemplate({
        sendToType: formValues.sendToType,
        fanIds:
          formValues.sendToType === SendToType.FANID.value
            ? formValues.fanIds
            : null,
        fanGroupId:
          formValues.sendToType === SendToType.FANID_GROUP.value
            ? Number(formValues.fanGroupId)
            : null,
        sendToEventId:
          formValues.sendToType === SendToType.EVENT_ID.value
            ? formValues.sendToEventId
            : null,
        title: formValues.title,
        body: formValues.body,
        fileName: formValues.file ? formValues.file.name : undefined,
        cheer: isSetCheer() ? formValues.cheer : undefined,
        cheerExpiredAt: isSetCheer()
          ? formatDateTime(formValues.cheerExpiredAt, Format.dateTimeRequest)
          : undefined, //cheer設定時のみ必須
        cheerType: isSetCheer() ? formValues.cheerType : undefined, //cheer設定時のみ必須
        eventId:
          isSetCheer() && cheerType === CheerType.LIMITED.value
            ? formValues.eventId
            : undefined, //cheer設定時 && cheerTypeがLIMITEDの場合のみ必須
        publishStatus: formValues.publishStatus ?? undefined,
        isReply: formValues.flags.includes('isReply'),
        importantFlag: formValues.flags.includes('importantFlag'),
        reservedAt: formatDateTime(
          formValues.reservedAt,
          Format.dateTimeRequest,
        ),
      })

      // 添付ファイルがある場合はアップロード
      if (formValues.file) {
        const uploadRes = await postUploadMedia({
          url: result.fileUrl,
          file: formValues.file,
        })
        if (!uploadRes) {
          await swal.messages({
            messages: ['ファイルのアップロードに失敗しました。'],
          })
          return
        }
      }

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/message-boxes/admin')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <MessageBoxTemplateForm
        ref={messageBoxTemplateFormRef}
      ></MessageBoxTemplateForm>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
