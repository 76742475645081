import React from "react";

import { LoadingButton } from "@mui/lab";

function ActionButton({
  children,
  onClick,
  type = "button",
  isLoading = false,
  disabled = false,
  fullWidth = false,
  size = "medium",
  variant = "contained",
  color = "primary",
  sx,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  sx?: React.CSSProperties;
}) {
  return (
    <LoadingButton
      type={type}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
      fullWidth={fullWidth}
      sx={{
        backgroundColor: variant === "outlined" ? "white" : color,
        ...sx,
      }}
    >
      {children}
    </LoadingButton>
  );
}

export default ActionButton;
