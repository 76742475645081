import type {
  APIResponse,
  APIResponseResultPagination,
  CheerTypeType,
  EventTypeType,
} from '@/types'

import { http } from '@/lib/http'

export type GetCheerUseParams = {
  page: number
  fanId?: string
  name?: string
  postId?: string
  eventId?: string
  eventName?: string
  type?: string
}

export type GetCheerUseResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    postId: number
    eventId: number | null
    eventName: string | null
    eventType: EventTypeType | null
    cheerType: CheerTypeType
    cheerCount: number
    createdAt: string
  }>
>

export const getCheerUsePath = '/admin/v1/cheer/use-history'

export const getCheerUse = async (params: GetCheerUseParams) => {
  return await http<GetCheerUseResponse>(getCheerUsePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      name: params.name,
      post_id: params.postId,
      event_id: params.eventId,
      event_name: params.eventName,
      type: params.type,
    },
  })
}
