import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteAdminUserParams = {
  id: number
}

export type DeleteAdminUserResponse = APIResponse<null>

export const deleteAdminUserPath = '/admin/v1/admin-user/:id'

export const deleteAdminUser = async (params: DeleteAdminUserParams) => {
  return await http<DeleteAdminUserResponse>(deleteAdminUserPath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
