import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

export default function MediaModal({
  isShow,
  onCallback,
  children,
  title = '',
}: {
  isShow: boolean
  onCallback?: () => void
  children: React.ReactNode
  title?: string
}) {
  const handleCallback = () => {
    if (onCallback) {
      onCallback()
    }
  }

  const close = (_event: {}, reason: string) => {
    if (reason === 'backdropClick') {
      handleCallback()
    }
  }

  return (
    <Dialog open={isShow} onClose={close}>
      <DialogContent>
        {title && (
          <Box mb={3}>
            <Typography component='h1' variant='h6'>
              {title}
            </Typography>
          </Box>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCallback}>閉じる</Button>
      </DialogActions>
    </Dialog>
  )
}
