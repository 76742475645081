import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostAdminUserParams = {
  name: string
  email: string
  password?: string
}

export type PostAdminUserResponse = APIResponse<null>

export const postAdminUserPath = '/admin/v1/admin-user'

export const postAdminUser = async (params: PostAdminUserParams) => {
  return await http<PostAdminUserResponse>(postAdminUserPath, {
    method: 'POST',
    body: JSON.stringify({
      name: params.name,
      email: params.email,
      password: params.password,
    }),
  })
}
