import type { BankCodeClientBranchesType } from "@/types"

type BranchesResponse = {
  bank: object
  branches: BankCodeClientBranchesType[]
  hasNext: boolean
  nextCursor?: string // hasNextがfalseの場合はundefined
  hasPrev: boolean
  prevCursor?: string // hasPrevがfalseの場合はundefined
}

export const branchesHttp = async (
  bankCode: string,
  name?: string,
  cursor?: string,
): Promise<BranchesResponse> => {
  const baseUrl = import.meta.env.VITE_BANKCODEJP_BASE_URL
  let url = `${baseUrl}/banks/${bankCode}/branches?fields=code,name&limit=100&sort=hiragana`
  if (name) {
    url += `&filter=name==*${name}*`
  }
  if (cursor) {
    url += `&cursor=${cursor}`
  }

  const res = await fetch(url, {
    method: 'GET',
    headers: {
      apiKey: import.meta.env.VITE_BANKCODEJP_API_KEY,
    },
  })

  const responseJson = await res.json()

  // レスポンスが200でない、レスポンスにmessageが含まれていればエラーを投げる
  if (res.status !== 200 || responseJson.message) {
    throw new Error(responseJson.message)
  }

  return responseJson
}
