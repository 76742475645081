import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostNgWordParams = {
  word: string
}

export const postNgWordPath = '/admin/v1/ng-word'

export const postNgWord = async (
  params: PostNgWordParams,
) => {
  return await http<APIResponse<null>>(postNgWordPath, {
    method: 'POST',
    body: JSON.stringify({
      word: params.word,
    }),
  })
}
