import { http } from '@/lib/http'

export type PostSamlLoginParams = {
  saml: string
}

export const postLoginPath = '/admin/v1/saml-login'

export const postSamlLogin = async (params: PostSamlLoginParams) => {
  return await http<null>(postLoginPath, {
    method: 'POST',
    body: JSON.stringify(params),
  })
}
