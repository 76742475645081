import type { FaqCategoryItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetFaqCategoryDetailParams = {
  id: number
}

export type GetFaqCategoryDetailResponse = APIResponse<FaqCategoryItem>

export const getFaqCategoryDetailPath = '/admin/v1/faq-category/:id'

export const getFaqCategoryDetail = async (
  params: GetFaqCategoryDetailParams,
) => {
  return await http<GetFaqCategoryDetailResponse>(getFaqCategoryDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
