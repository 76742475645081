import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { deleteBanDevice } from '@/features/ban/api/deleteBanDevice'
import { getBanDevice, getBanDevicePath } from '@/features/ban/api/getBanDevice'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'BANデバイス一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
    deviceId: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''
  const deviceId = searchParams.get('deviceId') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName: fanName,
      deviceId: deviceId,
    },
  })

  const { data, isValidating, mutate } = useSWR(getBanDevicePath, () =>
    getBanDevice({
      page: page,
      fanId: fanId ? Number(fanId) : undefined,
      fanName: fanName || undefined,
      deviceId: deviceId || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
        { key: 'deviceId', value: getValues('deviceId') },
      ])
      return prev
    })
    mutate(
      getBanDevice({
        page: page,
        fanId: getValues('fanId') ? Number(getValues('fanId')) : undefined,
        fanName: getValues('fanName') || undefined,
        deviceId: getValues('deviceId') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goCreate = () => {
    navigate('/bans/device/create')
  }

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goDeviceDetail = (deviceId = '') => {
    navigate({
      pathname: '/fans/device',
      search: `?deviceId=${deviceId}`,
    })
  }

  // 削除
  const deleteAction = async (id: number) => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '解除しますか？',
        text: '解除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteBanDevice({
        id: id,
      })

      swal.toastSuccess()
      mutate()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      // 同じデバイスIDに対して別のファンアカウントが紐づく場合、IDが重複しているのにファン情報が異なる場合があるため、
      // IDは使用せず、ファンIDとデバイスIDを結合して一意にする
      id: `${item.fanId}-${item.deviceId}`,
      originId: item.id,
      fanId: item.fanId,
      fanName: item.fanName,
      deviceId: item.deviceId,
      expiredAt: item.expiredAt,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='fanId'
            label='ファンID'
            fullWidth={false}
            type='number'
          />
          <FormText
            control={control}
            fullWidth={false}
            name='fanName'
            label='ファン名'
            hint='部分一致'
          />
          <FormText
            control={control}
            fullWidth={false}
            name='deviceId'
            label='デバイスID'
            hint='部分一致'
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 80,
              renderCell: (params) =>
                params.value ? (
                  <CopyClipboard text={String(params.value)} />
                ) : (
                  '-'
                ),
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 250,
              renderCell: (params) =>
                params.value ? (
                  <Link
                    onClick={() => goFanDetail(params.row.fanId)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value}
                  </Link>
                ) : (
                  '-'
                ),
            },
            {
              field: 'deviceId',
              headerName: 'デバイスID',
              width: 380,
              renderCell: (params) => (
                <Link
                  onClick={() => goDeviceDetail(params.value)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'expiredAt',
              headerName: 'BAN期限',
              width: 200,
            },
            {
              field: 'createdAt',
              headerName: '登録日時',
              width: 200,
            },
            {
              field: '_delete',
              headerName: '解除',
              width: 100,
              renderCell: (params) => (
                <ActionButton
                  size='small'
                  color='error'
                  variant='outlined'
                  onClick={() => {
                    deleteAction(params.row.originId)
                  }}
                >
                  解除
                </ActionButton>
              ),
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
