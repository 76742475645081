import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PutOrganizationUserParams = {
  id: number
  name: string
  email: string
  password?: string
  organizationId: number
  permission: number
}

export const postOrganizationUserPath = '/admin/v1/organization-user/:id'

export const putOrganizationUser = async (
  params: PutOrganizationUserParams,
) => {
  return await http<APIResponse<null>>(postOrganizationUserPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      name: params.name,
      email: params.email,
      password: params.password,
      organization_id: params.organizationId,
      permission: params.permission,
    }),
  })
}
