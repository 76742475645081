import type { APIResponse, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type PatchEventEntryParams = {
  entryId: number
  agencyId?: number
  artistRevenueShareRate: number
  agencyRevenueShareType?: RevenueShareTypeType
  agencyRevenueShareRate?: number
}

export const PatchEventEntryPath = '/admin/v1/event-entry/:entryId'

export const patchEventEntry = async (params: PatchEventEntryParams) => {
  return await http<APIResponse<null>>(PatchEventEntryPath, {
    method: 'PATCH',
    params: {
      entryId: params.entryId,
    },
    body: JSON.stringify({
      agency_id: params.agencyId,
      artist_revenue_share_rate: params.artistRevenueShareRate,
      agency_revenue_share_type: params.agencyRevenueShareType,
      agency_revenue_share_rate: params.agencyRevenueShareRate,
    }),
  })
}
