import type { BankAccountTypeType } from '../types'
import type { InvoiceNumberStatusType } from '@/types'

import { http } from '@/lib/http'

export type PatchFanArtistParams = {
  artistId: number
  fullName?: string
  country?: string
  zipCode?: string
  province?: string
  city?: string
  address1?: string
  address2?: string
  isEntryRestricted: boolean
  invoiceNumberStatus: InvoiceNumberStatusType
  invoiceNumber?: string | null
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  bankAccountType?: BankAccountTypeType
  bankAccountNumber?: string
  bankAccountHolder?: string
}

export const patchFanArtistPath = '/admin/v1/artist/:artistId'

export const patchFanArtist = async (params: PatchFanArtistParams) => {
  return await http<null>(patchFanArtistPath, {
    method: 'PATCH',
    params: {
      artistId: params.artistId,
    },
    body: JSON.stringify({
      full_name: params.fullName,
      country: params.country,
      zip_code: params.zipCode,
      province: params.province,
      city: params.city,
      address1: params.address1,
      address2: params.address2,
      is_entry_restricted: params.isEntryRestricted,
      invoice_number_status: params.invoiceNumberStatus,
      invoice_number: params.invoiceNumber,
      bank_name: params.bankName,
      bank_code: params.bankCode,
      branch_name: params.branchName,
      branch_code: params.branchCode,
      bank_account_type: params.bankAccountType,
      bank_account_number: params.bankAccountNumber,
      bank_account_holder: params.bankAccountHolder,
    }),
  })
}
