import type { BankAccountTypeType, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type PutAgencyParams = {
  id: number
  name: string
  zipCode?: string
  province?: string
  city?: string
  address1?: string
  address2?: string
  department?: string
  person?: string
  phoneNumber?: string
  notificationEmail?: string
  invoiceNumberStatus: string
  invoiceNumber?: string | null
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  bankAccountType?: BankAccountTypeType
  bankAccountNumber?: string
  bankAccountHolder?: string
  defaultRevenueShareType: RevenueShareTypeType
  defaultRevenueShareRate: number
}

export const putAgencyPath = '/admin/v1/agency/:agencyId'

export const putAgency = async (params: PutAgencyParams) => {
  return await http<null>(putAgencyPath, {
    method: 'PUT',
    params: { agencyId: params.id },
    body: JSON.stringify({
      name: params.name,
      zip_code: params.zipCode,
      province: params.province,
      city: params.city,
      address1: params.address1,
      address2: params.address2,
      department: params.department,
      person: params.person,
      phone_number: params.phoneNumber,
      notification_email: params.notificationEmail,
      invoice_number_status: params.invoiceNumberStatus,
      invoice_number: params.invoiceNumber,
      bank_name: params.bankName,
      bank_code: params.bankCode,
      branch_name: params.branchName,
      branch_code: params.branchCode,
      bank_account_type: params.bankAccountType,
      bank_account_number: params.bankAccountNumber,
      bank_account_holder: params.bankAccountHolder,
      default_revenue_share_type: params.defaultRevenueShareType,
      default_revenue_share_rate: params.defaultRevenueShareRate,
    }),
  })
}
