import type { OrganizationStatusType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetOrganizationParams = {
  page: number
  name?: string
  status?: string
}

export type GetOrganizationResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    name: string
    addRate: number
    zipCode: string
    province: string
    city: string
    address1: string
    address2: string
    department: string
    person: string
    phoneNumber: string
    notificationEmail: string
    invoiceNumberStatus: string
    invoiceNumber: string
    invoiceNumberEnabled: string
    bankName: string
    bankCode: string
    branchName: string
    branchCode: string
    bankAccountType: string
    bankAccountNumber: string
    bankAccountHolder: string
    inviteUrl: string
    status: OrganizationStatusType
    defaultIsEntryRestricted: boolean
    createdAt: string
    updatedAt: string
  }>
>

export const getOrganizationPath = '/admin/v1/organization'

export const getOrganization = async (params: GetOrganizationParams) => {
  return await http<GetOrganizationResponse>(getOrganizationPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      name: params.name,
      status: params.status,
    },
  })
}
