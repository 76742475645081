import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetArtistPickupDetailParams = {
  id: number
}

export type GetArtistPickupDetailResponse = APIResponse<{
  id: number
  artist: {
    id: number
  }
  fan: {
    name: string
  }
  startAt: string
  endAt: string
  memo: string|null
  createdAt: string
  updatedAt: string
}>

export const getArtistPickupDetailPath = '/admin/v1/pickup-artist/:id'

export const getArtistPickupDetail = async (params: GetArtistPickupDetailParams) => {
  return await http<GetArtistPickupDetailResponse>(getArtistPickupDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
