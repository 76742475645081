import { http } from '@/lib/http'

export type PostAgencyJoinParams = {
  artistId: string
  agencyId: number
}

export const postAgencyJoinPath = '/admin/v1/artist/join'

export const postAgencyJoin = async (params: PostAgencyJoinParams) => {
  return await http<null>(postAgencyJoinPath, {
    method: 'POST',
    body: JSON.stringify({
      artist_id: params.artistId,
      agency_id: params.agencyId,
    }),
  })
}
