import type { NgWordItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetNgWordDetailParams = {
  id: number
}

export type GetNgWordDetailResponse = APIResponse<NgWordItem>

export const getNgWordDetailPath = '/admin/v1/ng-word/:id'

export const getNgWordDetail = async (params: GetNgWordDetailParams) => {
  return await http<GetNgWordDetailResponse>(getNgWordDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
