/**
 * catchしたエラーを渡して、エラーメッセージを得る
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pickErrorMessages = (error: any): Array<string> => {
  if (!error) {
    return ['エラーが発生しました']
  }

  // APIエラー
  if (error.errors && error.errors.length !== 0) {
    return Object.entries(error.errors)
      .map((obj) => {
        return obj[1]
      })
      .flat() as Array<string>
  }

  return [error.message]
}

/**
 * ページ遷移
 */
export const locationHref = (url: string, newTab: boolean) => {
  if (newTab) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).open('about:blank', '_blank').location.href = url
  } else {
    location.href = url
  }
}

/**
 * 任意の要素までスクロール
 */
export const scrollIntoTheView = (id: string) => {
  const element = document.getElementById(id) as HTMLElement
  if (!element) return

  const position = element.getBoundingClientRect()
  window.scrollTo({
    left: 0,
    top: position.top + window.scrollY - 100, //ヘッダー分ずらす
    behavior: 'smooth',
  })
}

/**
 * パスを置換する
 * @param path
 * @param params
 * @returns
 */
export const pathReplace = <T = string>(
  path: T,
  params: Record<string, string | number>,
) => {
  let _path = path as string
  Object.keys(params).forEach((k) => {
    const val = params[k]
    _path = _path.replace(`:${k}`, String(val))
  })
  return _path
}

/**
 * オブジェクトの空判定
 * オブジェクトのプロパティが未設定の場合空配列で帰ってくるため、まとめてobject型で判定
 * 空配列の場合はtrueになる
 * @param target
 */
export const isEmptyObj = (target?: object) => {
  if (!target) return true
  return Object.keys(target).length === 0
}

// 改行をHTMLタグに変換
export const convertStr2HtmlTag = (text: string) => {
  let result = text
  const brPattern = /\r\n|\n|\r/g
  const brs = text.match(brPattern)
  if (brs) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    brs.forEach(
      (br) =>
        // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
        (result = text.replace(br, '<br>')),
    )
  }

  return result
}

/**
 * ファイルをアップロード
 * フロントからS3へ直接アップロードする
 * @param target
 */
export const postUploadMedia = async (params: { url: string; file: File }) => {
  return await fetch(params.url, {
    method: 'PUT',
    body: params.file,
    headers: {
      'content-type': params.file.type,
    },
  }).then((res) => res.ok)
}

/**
 * BlobをCSVとしてダウンロード
 * @param blob Blob ファイル
 * @param fileName string ファイル名
 */
export const downloadCsv = (blob: Blob, fileName: string) => {
  const fileUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = fileUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 数値をビット分解する
 * @param num
 * @returns
 */
export const decomposeToBits = (num: number) => {
  const bits = []
  let bit = 1

  while (num > 0) {
    if (num & 1) {
      bits.push(bit)
    }
    bit *= 2
    num >>= 1
  }

  return bits
}
