import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetArtistIdGroupParams = {
  page: number
  id?: number
  artistId?: string
  artistName?: string
  name?: string
}

export type GetArtistIdGroupResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    name: string
    artistCount: number
    createdAt: string
    updatedAt: string
  }>
>

export const getArtistIdGroupPath = '/admin/v1/artist-id-group'

export const getArtistIdGroup = async (params: GetArtistIdGroupParams) => {
  return await http<GetArtistIdGroupResponse>(getArtistIdGroupPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      artist_id: params.artistId,
      artist_name: params.artistName,
      name: params.name,
    },
  })
}
