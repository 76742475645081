import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getMonthlyPointUnitPrice, getMonthlyPointUnitPricePath } from '@/features/report/api/getMonthlyPointUnitPrice'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '月別ポイント単価'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const dateParam = searchParams.get('date')
  const date = dateParam ? newDate(dateParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      date: date,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    getMonthlyPointUnitPricePath,
    () =>
      getMonthlyPointUnitPrice({
        page: page,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'date',
          value: formatDateTime(getValues('date'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getMonthlyPointUnitPrice({
        page: page,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: `${item.year}-${item.month}`,
      year: String(item.year),
      month: String(item.month),
      netPrice: item.netPrice,
      pointCheerCount: item.pointCheerCount,
      pointUnitPrice: item.pointUnitPrice,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} alignItems={'center'}>
          <Grid item>
            <FormDate
              control={control}
              name='date'
              views={['year', 'month']}
              fullWidth={false}
              label='対象年月'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'year',
              headerName: '年',
              width: 50,
            },
            {
              field: 'month',
              headerName: '月',
              width: 50,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'netPrice',
              headerName: '課金総額（消費税・決済手数料 控除後）',
              width: 300,
              headerAlign: 'right',
            },
            {
              field: 'pointCheerCount',
              headerName: 'ポイントCHEER総数',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'pointUnitPrice',
              headerName: 'ポイント単価',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'updatedAt',
              headerName: '最終集計日時',
              width: 220,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'createdAt',
              headerName: '初回集計日時',
              width: 220,
              headerAlign: 'right',
              align: 'right',
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
