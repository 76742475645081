import type { UnifiedOrderIdItemDetail } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetUnifiedOrderIdDetailParams = {
  unifiedOrderId: number
}

export type GetUnifiedOrderIdDetailResponse =
  APIResponse<UnifiedOrderIdItemDetail>

export const GetUnifiedOrderIdDetailPath =
  '/admin/v1/unified-order-id/:unifiedOrderId'

export const getUnifiedOrderIdDetail = async (
  params: GetUnifiedOrderIdDetailParams,
) => {
  return await http<GetUnifiedOrderIdDetailResponse>(
    GetUnifiedOrderIdDetailPath,
    {
      method: 'GET',
      params: {
        unifiedOrderId: params.unifiedOrderId,
      },
    },
  )
}
