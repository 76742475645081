import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMonthlyPointUnitPriceParams = {
  page: number
  year?: number
  month?: number
}

export type GetMonthlyPointUnitPriceResponse = APIResponse<
  APIResponseResultPagination<{
    year: number
    month: number
    netPrice: number
    pointCheerCount: number
    pointUnitPrice: number
    createdAt: string
    updatedAt: string
  }>
>

export const getMonthlyPointUnitPricePath = '/admin/v1/monthly-point-unit-price'

export const getMonthlyPointUnitPrice = async (params: GetMonthlyPointUnitPriceParams) => {
  return await http<GetMonthlyPointUnitPriceResponse>(getMonthlyPointUnitPricePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
    },
  })
}
