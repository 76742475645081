import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Grid, Box, Typography } from '@mui/material'

import { getCsrf } from '@/features/auth/api/getCsrf'
import { postSamlLogin } from '@/features/auth/api/postSamlLogin'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'SAMLサインイン'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams] = useSearchParams({
    saml: '',
  })

  const saml = searchParams.get('saml') || ''

  const [isLoading] = useState(false)

  const refFirstRef = useRef(true)

  const navigate = useNavigate()

  useEffect(() => {
    const submit = async () => {
      try {
        // CSRFトークンを取得
        await getCsrf()

        // ログイン
        await postSamlLogin({
          saml: saml,
        })

        // 遷移
        navigate('/')
      } catch (error) {
        await swal.messages({ messages: pickErrorMessages(error) })
      }
    }

    if (refFirstRef.current) {
      refFirstRef.current = false
      submit()
      return
    }
  }, [saml, navigate])

  return (
    <Grid
      container
      component='main'
      sx={{
        height: '100vh',
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            component='h1'
            variant='h4'
            fontWeight='bold'
            color='primary'
            sx={{
              mb: 3,
            }}
          >
            CHEERZ ADMIN
          </Typography>

          <Typography
            component='h2'
            variant='h4'
            fontWeight='bold'
            color='primary'
            sx={{
              mb: 3,
            }}
          >
            サインイン
          </Typography>
          <Box>
            {isLoading && <Box sx={{ my: 2 }}>認証中・・・</Box>}
            <Typography component='p' align='center'>
              <small>&copy; 2023 CHEERZ</small>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Page
