import type { AdminListItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetAdminUserParams = {
  page: number
}

export type GetAdminUserResponse = APIResponse<
  APIResponseResultPagination<AdminListItem>
>

export const getAdminUserPath = '/admin/v1/admin-user'

export const getAdminUser = async (params: GetAdminUserParams) => {
  // ５秒待つ
  return await http<GetAdminUserResponse>(getAdminUserPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
    },
  })
}
