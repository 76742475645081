import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { OrganizationJoinFormRef } from '@/features/organization/components/OrganizationJoinForm';

import { postOrganizationJoin } from '@/features/organization/api/postOrganizationJoin'
import OrganizationJoinForm from '@/features/organization/components/OrganizationJoinForm'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'アーティストを事務所に所属させる'
  const [isLoading, setIsLoading] = useState(false)
  usePageInfo({
    title: pageTitle,
  })

  const organizationJoinFormRef = useRef<OrganizationJoinFormRef>(null)

  const [searchParams] = useSearchParams({
    artistId: '',
    organizationId: '',
  })
  const artistId = searchParams.get('artistId') || ''
  const organizationId = searchParams.get('organizationId') || ''

  useEffect(() => {
    organizationJoinFormRef.current?.formReset({
      artistId: artistId,
      organizationId: Number(organizationId),
    })
  }, [artistId, organizationId])

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = organizationJoinFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      await postOrganizationJoin({
        artistId: formValues.artistId,
        organizationId: formValues.organizationId,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate({
      pathname: '/organizations/artists',
      search: `?artistId=${artistId}`,
    })
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <OrganizationJoinForm ref={organizationJoinFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
