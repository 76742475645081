import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetBanDeviceParams = {
  page: number
  fanId?: number
  fanName?: string
  deviceId?: string
}

export type GetBanDeviceResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number|null
    fanName: string|null
    deviceId: string
    expiredAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getBanDevicePath = '/admin/v1/fan-device-ban'

export const getBanDevice = async (params: GetBanDeviceParams) => {
  return await http<GetBanDeviceResponse>(getBanDevicePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      device_id: params.deviceId,
    },
  })
}
