import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetOrganizationUserParams = {
  page: number
  name?: string
  email?: string
  organizationId?: number
  organizationName?: string
}

export type GetOrganizationUserResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    organizationId: number
    organizationName: string
    name: string
    email: string
    deletedAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getOrganizationUserPath = '/admin/v1/organization-user'

export const getOrganizationUser = async (
  params: GetOrganizationUserParams,
) => {
  return await http<GetOrganizationUserResponse>(getOrganizationUserPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      name: params.name,
      email: params.email,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
    },
  })
}
