import { http } from '@/lib/http'

export interface PostEventEntryUploadCsvFormData extends FormData {
  append(
    name: keyof {
      _method: string
      event_id: number
      file?: Blob
    },
    value: string | number | boolean | Blob | null,
    fileName?: string,
  ): void
}

export const postEventEntryUploadCsvPath = '/admin/v1/event-entry/upload-csv'

export const postEventEntryUploadCsv = async (
  formData: PostEventEntryUploadCsvFormData,
) => {
  return await http(postEventEntryUploadCsvPath, {
    method: 'POST',
    body: formData,
    headers: {}, // デフォルトのヘッダーを使わない
  })
}
