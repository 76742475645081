import type { SendToTypeType } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchMessageBoxTemplateParams = {
  id: number
  sendToType: SendToTypeType
  fanIds?: string | null
  fanGroupId?: number | null
  sendToEventId?: string | null
  title: string
  body: string
  fileName?: string
  cheer?: number
  cheerExpiredAt?: string
  cheerType?: string
  eventId?: number
  publishStatus: string
  isReply: boolean
  importantFlag: boolean
  reservedAt?: string
}

export type PatchMessageBoxTemplateResponse = APIResponse<{
  fileUrl: string
}>

export const patchMessageBoxTemplatePath = '/admin/v1/message-box-template/:id'

export const patchMessageBoxTemplate = async (
  params: PatchMessageBoxTemplateParams,
) => {
  return await http<PatchMessageBoxTemplateResponse>(
    patchMessageBoxTemplatePath,
    {
      method: 'PATCH',
      params: {
        id: params.id,
      },
      body: JSON.stringify({
        send_to_type: params.sendToType,
        fan_ids: params.fanIds,
        fan_group_id: params.fanGroupId,
        send_to_event_id: params.sendToEventId,
        title: params.title,
        body: params.body,
        file_name: params.fileName,
        cheer: params.cheer,
        cheer_expired_at: params.cheerExpiredAt,
        cheer_type: params.cheerType,
        event_id: params.eventId,
        publish_status: params.publishStatus,
        is_reply: params.isReply,
        important_flag: params.importantFlag,
        reserved_at: params.reservedAt,
      }),
    },
  )
}
