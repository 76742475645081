import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { FormLabel, Grid } from '@mui/material'
import { endOfDay } from 'date-fns'
import { useForm } from 'react-hook-form'

import { newDate } from '@/lib/dateFns'


type BanDeviceFormValues = {
  deviceId: string
  expiredAt: Date
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface BanDeviceFormProps {}
export interface BanDeviceFormRef {
  getFormValues(): BanDeviceFormValues
  setFormValue(key: keyof BanDeviceFormValues, value: string): void
  formReset(values: BanDeviceFormValues): void
}

const BanDeviceForm = forwardRef<BanDeviceFormRef, BanDeviceFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } =
      useForm<BanDeviceFormValues>({
        defaultValues: {
          deviceId: '',
          expiredAt: endOfDay(newDate()),
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item md={5}>
          <FormText
            control={control}
            name='deviceId'
            label='デバイスID'
            required
          />
          <FormLabel required>BAN期限</FormLabel>
          <FormDate
            control={control}
            name='expiredAt'
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            fullWidth={false}
          />
        </Grid>
      </FormBox>
    )
  },
)

export default BanDeviceForm
