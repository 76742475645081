import React from 'react'

import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  label?: string
  options: { value: string; text: React.ReactNode }[]
  placeholder?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  sx?: React.CSSProperties
  hint?: string
  onChange?: () => void
  fullWidth?: boolean
} & UseControllerProps<T>

function FormRadio<T extends FieldValues>({
  name,
  options,
  label,
  required = false,
  disabled = false,
  control,
  sx,
  hint,
  onChange,
  fullWidth = true,
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl fullWidth={fullWidth} variant='outlined' sx={sx}>
          <FormLabel required={required}>{label}</FormLabel>
          {hint && (
            <Typography color='textSecondary'>
              <small>{hint}</small>
            </Typography>
          )}
          <RadioGroup
            name={field.name}
            ref={field.ref}
            onChange={(e) => {
              field.onChange(e)
              if (onChange) onChange()
            }}
            value={field.value}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.text}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
          {/* エラー */}
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]!.message)}</small>
            </Typography>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormRadio
