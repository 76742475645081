import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { PostPurchaseMenuFormRef } from '@/features/purchase/components/PointPurchaseMenuForm'

import { getPointPurchaseMenuDetail } from '@/features/purchase/api/getPointPurchaseMenuDetail'
import { putPointPurchaseMenu } from '@/features/purchase/api/putPointPurchaseMenu'
import PointPurchaseMenuForm from '@/features/purchase/components/PointPurchaseMenuForm'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { PlatFormType } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/purchase-menus/:menuId')
  const pageTitle = '課金メニュー詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const createPointPurchaseMenuFormRef = useRef<PostPurchaseMenuFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getPointPurchaseMenuDetail({
        id: Number(params.menuId),
      })
      createPointPurchaseMenuFormRef.current?.formReset({
        name: result.name,
        description: result.description,
        type: result.type,
        platform: result.platform,
        productId: result.productId,
        taxIncludedPrice: result.taxIncludedPrice,
        point: result.point,
        publishedAt: newDate(result.publishedAt),
        closedAt: result.closedAt ? newDate(result.closedAt) : null,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/purchase-menus')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = createPointPurchaseMenuFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      if (formValues.taxIncludedPrice * 2 <= formValues.point) {
        swal.alert({
          text: 'ポイント数が金額(税込）の2倍より小さくなるように設定してください',
        })
        setIsLoading(false)
        return
      }

      await putPointPurchaseMenu({
        id: Number(params.menuId),
        name: formValues.name,
        description: formValues.description,
        type: formValues.type,
        platform: formValues.platform,
        productId:
          formValues.platform !== PlatFormType.WEB.value
            ? formValues.productId
            : undefined,
        taxIncludedPrice: formValues.taxIncludedPrice,
        point: formValues.point,
        publishedAt: formatDateTime(
          formValues.publishedAt,
          Format.dateTimeRequest,
        ),
        closedAt: formatDateTime(formValues.closedAt, Format.dateTimeRequest),
      })
      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <PointPurchaseMenuForm ref={createPointPurchaseMenuFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
