export const TermCategory = {
  FAN_TERM: { value: 'FAN_TERM', text: '[サービス] 利用規約' },
  PAYMENT_LAW: { value: 'PAYMENT_LAW', text: '[サービス] 資金決済法' },
  TRADE_LAW: { value: 'TRADE_LAW', text: '[サービス] 特定商取引法' },
  PRIVACY_POLICY: {
    value: 'PRIVACY_POLICY',
    text: '[サービス] プライバシーポリシー',
  },
  ORGANIZATION_TERM: { value: 'ORGANIZATION_TERM', text: '[事務所] 利用規約' },
  ORGANIZATION_PRIVACY_POLICY: {
    value: 'ORGANIZATION_PRIVACY_POLICY',
    text: '[事務所] プライバシーポリシー',
  },
} as const
export type TermCategoryType = keyof typeof TermCategory

export type TermListItem = {
  id: number
  category: {
    key: TermCategoryType
    value: string
  }
  content: string
  publishedAt: string
  createdAt: string
  updatedAt: string
}