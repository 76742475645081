import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getMonthlyCoinCheerReport,
  getMonthlyCoinCheerReportPath,
} from '@/features/report/api/getMonthlyCoinCheerReport'
import { getMonthlyCoinCheerReportDownloadCsv } from '@/features/report/api/getMonthlyCoinCheerReportDownloadCsv'
import { newDate } from '@/lib/dateFns'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '月別コイン・CHEER'
  usePageInfo({
    title: pageTitle,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const page = Number(searchParams.get('page') || '1')
  const yearParam = searchParams.get('year')
  const year = yearParam ? newDate(yearParam) : null
  const month = searchParams.get('month') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      year: year,
      month: month,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    getMonthlyCoinCheerReportPath,
    () =>
      getMonthlyCoinCheerReport({
        page: page,
        year: year ? year.getFullYear() : undefined,
        month: month ? Number(month) : undefined,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    const yearValue = getValues('year')
    const year = yearValue ? yearValue.getFullYear().toString() : ''
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'year', value: year },
        { key: 'month', value: String(getValues('month')) },
      ])
      return prev
    })
    mutate(
      getMonthlyCoinCheerReport({
        page: page,
        year: year ? Number(year) : undefined,
        month: getValues('month') ? Number(getValues('month')) : undefined,
      }),
    )
  }

  const monthOptions = [
    { value: '', text: '選択して下さい' },
    ...Array.from({ length: 12 }, (_, i) => {
      const num = i + 1
      return { value: num.toString(), text: num.toString() }
    }),
  ]

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getMonthlyCoinCheerReportDownloadCsv({
        year: year ? year.getFullYear() : undefined,
        month: getValues('month') ? Number(getValues('month')) : undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      year: item.year,
      month: item.month,
      issuedCoin: item.issuedCoin,
      issuedFreeCheer: item.issuedFreeCheer,
      exchangedCoin: item.exchangedCoin,
      expiredCoin: item.expiredCoin,
      cashOutCoin: item.cashoutCoin,
      usedFreeCheer: item.usedFreeCheer,
      depositCoin: item.depositCoin,
      depositFreeCheer: item.depositFreeCheer,
    }
  })

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: 'CSVダウンロード', onClick: handleDownloadCsv }]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='year'
              label='年'
              views={['year']}
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='month'
              label='月'
              fullWidth={false}
              options={monthOptions}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'yearMonth',
              headerName: '日付',
              width: 100,
              renderCell: (params) => {
                return `${params.row.year}/${params.row.month}`
              },
            },
            {
              field: 'issuedCoin',
              headerName: 'コイン(発行)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'exchangedCoin',
              headerName: 'コイン(交換)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'expiredCoin',
              headerName: 'コイン(有効期限切れ)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'cashOutCoin',
              headerName: 'コイン(換金)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'depositCoin',
              headerName: 'コイン(残)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'issuedFreeCheer',
              headerName: 'ボーナスCHEER(発行)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'usedFreeCheer',
              headerName: 'ボーナスCHEER(利用)',
              width: 160,
              headerAlign: 'right',
            },
            {
              field: 'depositFreeCheer',
              headerName: 'ボーナスCHEER(残)',
              width: 160,
              headerAlign: 'right',
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        />
      </Box>
    </Box>
  )
}

export default Page
