import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Avatar, Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getMonthlyArtistReport, getMonthlyArtistReportPath } from '@/features/report/api/getMonthlyArtistReport'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '月次アーティストレポート'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const dateParam = searchParams.get('date')
  const date = dateParam ? newDate(dateParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      date: date,
      artistId: artistId,
      artistName: artistName,
    },
  })

  const { data, isValidating, mutate } = useSWR(
    getMonthlyArtistReportPath,
    () =>
      getMonthlyArtistReport({
        page: page,
        artistId: artistId || undefined,
        artistName: artistName || undefined,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
      }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        {
          key: 'date',
          value: formatDateTime(getValues('date'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getMonthlyArtistReport({
        page: page,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        year: date ? date.getFullYear() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: `${item.year}-${item.month}-${item.artistId}`,
      year: String(item.year),
      month: item.month,
      artistId: item.artistId,
      artistName: item.artistName,
      fanId: item.fanId,
      artistIconUrl: item.artistIconUrl,
      postCount: item.postCount,
      liveCount: item.liveCount,
      liveDuration: item.liveDuration,
      cheerCount: item.cheerCount,
      pointCheerCount: item.pointCheerCount,
      freeCheerCount: item.freeCheerCount,
      limitedCheerCount: item.limitedCheerCount,
      cheerUu: item.cheerUu,
      pointCheerUu: item.pointCheerUu,
      freeCheerUu: item.freeCheerUu,
      limitedCheerUu: item.limitedCheerUu,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormDate
              control={control}
              name='date'
              views={['year', 'month']}
              fullWidth={false}
              label='対象月'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'year',
              headerName: '年',
              width: 50,
            },
            {
              field: 'month',
              headerName: '月',
              width: 50,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
            },
            {
              field: 'artistIconUrl',
              headerName: '',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <Avatar
                    src={params.value}
                    alt='アーティストアイコン'
                    sx={{ width: 40, height: 40 }}
                  />
                )
              },
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 230,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'postCount',
              headerName: '投稿数',
              width: 70,
            },
            {
              field: 'liveCount',
              headerName: 'ライブ配信数',
              width: 110,
            },
            {
              field: 'liveDuration',
              headerName: '配信時間',
              width: 80,
            },
            {
              field: 'freeCheerUu',
              headerName: 'ボーナスCHEER UU数',
              width: 230,
              headerAlign: 'right',
            },
            {
              field: 'pointCheerUu',
              headerName: 'ポイントCHEER UU数',
              width: 160,
            },
            {
              field: 'limitedCheerUu',
              headerName: 'イベント限定CHEER UU数',
              width: 190,
            },
            {
              field: 'cheerUu',
              headerName: 'CHEER UU総数',
              width: 120,
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: '#f8f8f8',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'freeCheerCount',
              headerName: 'ボーナスCHEER数',
              width: 200,
              headerAlign: 'right',
            },
            {
              field: 'pointCheerCount',
              headerName: 'ポイントCHEER数',
              width: 140,
            },
            {
              field: 'limitedCheerCount',
              headerName: 'イベント限定CHEER数',
              width: 170,
            },
            {
              field: 'cheerCount',
              headerName: '獲得CHEER総数',
              width: 130,
              renderCell: (params) => {
                return (
                  <Box
                    px={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: '#f8f8f8',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {params.value}
                  </Box>
                )
              },
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
