import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetSalesReportParams = {
  page: number
  year?: number
  month?: number
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
}

export type GetSalesReportResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    year: number
    month: number
    organizationId: number | null
    organizationName: string | null
    agencyId: number | null
    agencyName: string | null
    totalSales: number
    createdAt: string
    updatedAt: string
  }>
>

export const getSalesReportPath = '/admin/v1/sales-report'

export const getSalesReport = async (params: GetSalesReportParams) => {
  return await http<GetSalesReportResponse>(getSalesReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
    },
  })
}
