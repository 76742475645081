import { httpRaw } from '@/lib/httpRaw'

export type GetEventEntryDownloadCsvParams = {
  eventId: string
  eventName?: string
  artistId?: string
  artistName?: string
  agencyId?: string
  agencyName?: string
  isDeleted?: boolean
  isNewcomer?: boolean
  isFirstGachi?: boolean
}

export const getEventEntryDownloadCsvPath = '/admin/v1/event-entry/download-csv'

export const getEventEntryDownloadCsv = async (
  params: GetEventEntryDownloadCsvParams,
) => {
  return await httpRaw(getEventEntryDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      event_id: params.eventId,
      event_name: params.eventName,
      artist_id: params.artistId,
      artist_name: params.artistName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
      is_deleted: params.isDeleted,
      is_newcomer: params.isNewcomer,
      is_first_gachi: params.isFirstGachi,
    },
  })
}
