import type { PaymentReportDetail } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetPaymentReportDetailParams = {
  id: number
}

export type GetPaymentReportDetailResponse = APIResponse<PaymentReportDetail>

export const getPaymentReportDetailPath = '/admin/v1/payment-report/:id'

export const getPaymentReportDetail = async (
  params: GetPaymentReportDetailParams,
) => {
  return await http<GetPaymentReportDetailResponse>(
    getPaymentReportDetailPath,
    {
      method: 'GET',
      params: {
        id: params.id,
      },
    },
  )
}
