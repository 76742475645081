import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetBlockParams = {
  page: number
  blockerFanId?: string
  blockerName?: string
  blockedFanId?: string
  blockedName?: string
}

export type GetBlockResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    blockerFanId: number
    blockerName: string
    blockedFanId: number
    blockedName: string
    createdAt: string
  }>
>

export const GetBlockPath = '/admin/v1/block'

export const getBlock = async (params: GetBlockParams) => {
  return await http<GetBlockResponse>(GetBlockPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      blocker_fan_id: params.blockerFanId,
      blocker_name: params.blockerName,
      blocked_fan_id: params.blockedFanId,
      blocked_name: params.blockedName,
    },
  })
}
