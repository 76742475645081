import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchEventParams = {
  eventId: number
  name: string
  bannerFileName: string | null
  iconFileName: string | null
  artistGroupId?: string
  type: string
  entryType: string
  entryStartAt?: string | null
  entryEndAt?: string | null
  eventStartAt: string
  eventEndAt: string
  eventDisplayStartAt: string
  eventDisplayEndAt?: string
  aggregationTarget: string
  cheerFactor: number
  viewersCountFactor: number
  durationFactor: number
  postCountFactor: number
  postUuFactor: number
  entryFilterGender?: string | null
  entryFilterAgeUpperLimit?: number | null
  entryFilterAgeLowerLimit?: number | null
  entryFilterAgeCalculationDate?: string
  entryFilterBirthMonth?: number | null
  entryFilterFirstGachi?: boolean
  entryFilterRegStartDate?: string | null
  entryFilterRegEndDate?: string | null
  rewards?: {
    line: number
    title: string
  }[] | null
  descriptions?: {
    title: string
    description: string
  }[]
}

export type PatchEventResponse = APIResponse<{
  bannerFileUrl: string
  iconFileUrl: string
}>

export const patchEventPath = '/admin/v1/event/:eventId'

export const patchEvent = async (params: PatchEventParams) => {
  return await http<PatchEventResponse>(patchEventPath, {
    method: 'PATCH',
    params: {
      eventId: params.eventId,
    },
    body: JSON.stringify({
      name: params.name,
      banner_file_name: params.bannerFileName,
      icon_file_name: params.iconFileName,
      artist_group_id: params.artistGroupId,
      type: params.type,
      entry_type: params.entryType,
      entry_start_at: params.entryStartAt,
      entry_end_at: params.entryEndAt,
      event_start_at: params.eventStartAt,
      event_end_at: params.eventEndAt,
      event_display_start_at: params.eventDisplayStartAt,
      event_display_end_at: params.eventDisplayEndAt,
      aggregation_target: params.aggregationTarget,
      cheer_factor: params.cheerFactor,
      viewers_count_factor: params.viewersCountFactor,
      duration_factor: params.durationFactor,
      post_count_factor: params.postCountFactor,
      post_uu_factor: params.postUuFactor,
      entry_filter_gender: params.entryFilterGender,
      entry_filter_age_upper_limit: params.entryFilterAgeUpperLimit,
      entry_filter_age_lower_limit: params.entryFilterAgeLowerLimit,
      entry_filter_age_calculation_date: params.entryFilterAgeCalculationDate,
      entry_filter_birth_month: params.entryFilterBirthMonth,
      entry_filter_first_gachi: params.entryFilterFirstGachi,
      entry_filter_reg_start_date: params.entryFilterRegStartDate,
      entry_filter_reg_end_date: params.entryFilterRegEndDate,
      rewards: params.rewards,
      descriptions: params.descriptions,
    }),
  })
}
