import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteNgWordParams = {
  id: number
}

export const deleteNgWordPath = '/admin/v1/ng-word/:id'

export const deleteNgWord = async (
  params: DeleteNgWordParams,
) => {
  return await http<APIResponse<null>>(deleteNgWordPath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
