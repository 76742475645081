import type { OrganizationUserPermissionItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetOrganizationUserPermissionResponse =
  APIResponse<OrganizationUserPermissionItem[]>

export const getOrganizationPermissionPath = '/admin/v1/organization-user/permission'

export const getOrganizationUserPermission = async () => {
  return await http<GetOrganizationUserPermissionResponse>(
    getOrganizationPermissionPath,
    {
      method: 'GET',
    },
  )
}
