import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteEventEntryParams = {
  entryId: number
}

export const DeleteEventEntryPath = '/admin/v1/event-entry/:entryId'

export const deleteEventEntry = async (params: DeleteEventEntryParams) => {
  return await http<APIResponse<null>>(DeleteEventEntryPath, {
    method: 'DELETE',
    params: {
      entryId: params.entryId,
    },
  })
}
