import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetEventRankingFanParams = {
  page: number
  eventDetailId: number
  artistId: number
}

export type GetEventRankingFanResponse = APIResponse<
  APIResponseResultPagination<{
    rank: number
    score: number
    cheerCount: number
    fan: {
      id: number
      name: string
      iconUrl: string
    }
  }>
>

export const getEventRankingFanPath =
  '/admin/v1/ranking/event/:eventDetailId/fan-ranking/:artistId'

export const getEventRankingFan = async (params: GetEventRankingFanParams) => {
  return await http<GetEventRankingFanResponse>(getEventRankingFanPath, {
    method: 'GET',
    params: {
      eventDetailId: params.eventDetailId,
      artistId: params.artistId,
    },
    searchParams: {
      page: params.page,
    },
  })
}
