export const FaqCategoryStatus = {
  PUBLIC: { value: 'PUBLIC', text: '公開' },
  PRIVATE: { value: 'PRIVATE', text: '非公開' },
} as const
export type FaqCategoryStatus = keyof typeof FaqCategoryStatus

export const FaqStatus = {
  PUBLIC: { value: 'PUBLIC', text: '公開' },
  PRIVATE: { value: 'PRIVATE', text: '非公開' },
} as const
export type FaqStatusType = keyof typeof FaqStatus

export const FaqCategoryTargetType = {
  FAN_ARTIST: { value: 'FAN_ARTIST', text: 'ファン・アーティスト' },
  ORGANIZATION: { value: 'ORGANIZATION', text: '事務所' },
  AGENCY: { value: 'AGENCY', text: '代理店' },
} as const
export type FaqCategoryTargetTypeType = keyof typeof FaqCategoryTargetType

export type FaqItem = {
  id: number
  title: string
  sort: number
  status: {
    key: string
    value: string
  }
  categoryName: string
  categoryTarget: {
    key: string
    value: string
  }
  createdAt: string
  updatedAt: string
}

export type FaqDetailItem = {
  id: number
  title: string
  description: string
  status: {
    key: FaqStatusType
    value: string
  }
  sort: number
  categoryId: number
  categoryName: string
  categoryTarget: {
    key: string
    value: string
  }
  createdAt: string
  updatedAt: string
}

export type FaqCategoryItem = {
  id: number
  sort: number
  target: {
    key: FaqCategoryTargetTypeType
    value: string
  }
  name: string
  status: {
    key: FaqCategoryStatus
    value: string
  }
  createdAt: string
  updatedAt: string
}

export type FaqCategoryAllItem = {
  id: string
  name: string
  target: {
    key: FaqCategoryTargetTypeType
    value: string
  }
}
