import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PutPurchaseMenuParams = {
  id: number
  name: string
  description: string
  type: string
  platform: string
  productId?: string
  taxIncludedPrice: number
  point: number
  publishedAt: string
  closedAt: string
}

export const putPointPurchaseMenuPath = '/admin/v1/point-purchase-menu/:id'

export type PutPointPurchaseMenuResponse = APIResponse<null>

export const putPointPurchaseMenu = async (params: PutPurchaseMenuParams) => {
  return await http<PutPointPurchaseMenuResponse>(putPointPurchaseMenuPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      name: params.name,
      description: params.description,
      type: params.type,
      platform: params.platform,
      product_id: params.productId,
      tax_included_price: params.taxIncludedPrice,
      point: params.point,
      published_at: params.publishedAt,
      closed_at: params.closedAt,
    }),
  })
}
