import { http } from '@/lib/http'

export type PutPostStatusChangeParams = {
  id: number
  status: string
}

export const putPostStatusChangePath = '/admin/v1/post/:postId/status'

export const putPostStatusChange = async (
  params: PutPostStatusChangeParams,
) => {
  return await http<null>(putPostStatusChangePath, {
    method: 'PUT',
    params: { postId: params.id },
    body: JSON.stringify(params),
  })
}
