import type { DeviceBanListItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanDeviceBanParams = {
  page: number
  fanId?: number
  deviceId?: string
}

export type GetFanDeviceBanResponse = APIResponse<
  APIResponseResultPagination<DeviceBanListItem>
>

export const getFanDeviceBanPath = '/admin/v1/fan-device-ban'

export const getFanDeviceBan = async (params: GetFanDeviceBanParams) => {
  return await http<GetFanDeviceBanResponse>(getFanDeviceBanPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      device_id: params.deviceId,
    },
  })
}
