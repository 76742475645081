import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid } from '@mui/material'

import type { BankFormRef } from '@/components/bank/BankForm'
import type { AgencyFormRef } from '@/features/organization/components/AgencyForm'

import BankForm from '@/components/bank/BankForm'
import { getAgencyDetail } from '@/features/organization/api/getAgencyDetail'
import { putAgency } from '@/features/organization/api/putAgency'
import AgencyForm from '@/features/organization/components/AgencyForm'
import { useNavigate, useParams } from '@/router'
import { BankAccountType, InvoiceNumberStatus } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/agencies/:agencyId')
  const pageTitle = '代理店更新'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const AgencyFormRef = useRef<AgencyFormRef>(null)
  const bankFormRef = useRef<BankFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getAgencyDetail({
        id: Number(params.agencyId),
      })
      AgencyFormRef.current?.formReset({
        name: result.name,
        zipCode: result.zipCode || '',
        province: result.province || '',
        city: result.city || '',
        address1: result.address1 || '',
        address2: result.address2 || '',
        department: result.department || '',
        person: result.person || '',
        phoneNumber: result.phoneNumber || '',
        notificationEmail: result.notificationEmail || '',
        invoiceNumberStatus: result.invoiceNumberStatus,
        invoiceNumber: result.invoiceNumber || '',
        defaultRevenueShareRate: result.defaultRevenueShareRate,
        defaultRevenueShareType: result.defaultRevenueShareType,
      })
      bankFormRef.current?.formReset({
        bankName: result.bankName || '',
        bankCode: result.bankCode || '',
        branchName: result.branchName || '',
        branchCode: result.branchCode || '',
        bankAccountType: result.bankAccountType || '',
        bankAccountNumber: result.bankAccountNumber || '',
        bankAccountHolder: result.bankAccountHolder || '',
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/agencies')
  }

  const goArtistList = () => {
    navigate({
      pathname: '/agencies/artists',
      search: `?agencyId=${params.agencyId}`,
    })
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = AgencyFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      const formBankValues = bankFormRef.current?.getFormValues()
      if (!formBankValues) {
        setIsLoading(false)
        return
      }

      await putAgency({
        id: Number(params.agencyId),
        name: formValues.name,
        zipCode: formValues.zipCode,
        province: formValues.province,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        department: formValues.department,
        person: formValues.person,
        phoneNumber: formValues.phoneNumber,
        notificationEmail: formValues.notificationEmail,
        invoiceNumberStatus: formValues.invoiceNumberStatus,
        invoiceNumber:
          formValues.invoiceNumberStatus ===
          InvoiceNumberStatus.REGISTERED.value
            ? formValues.invoiceNumber
            : null,
        bankName: formBankValues.bankName,
        bankCode: formBankValues.bankCode,
        branchName: formBankValues.branchName,
        branchCode: formBankValues.branchCode,
        bankAccountType: formBankValues.bankAccountType
          ? BankAccountType[formBankValues.bankAccountType].value
          : undefined,
        bankAccountNumber: formBankValues.bankAccountNumber,
        bankAccountHolder: formBankValues.bankAccountHolder,
        defaultRevenueShareType: formValues.defaultRevenueShareType,
        defaultRevenueShareRate: formValues.defaultRevenueShareRate,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: '所属アーティスト一覧へ', onClick: goArtistList },
          { text: '代理店一覧に戻る', onClick: goList },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <Grid container columnSpacing={4} justifyContent={'center'}>
        <Grid item md={5}>
          <AgencyForm ref={AgencyFormRef} />
        </Grid>
        <Grid item md={5}>
          <BankForm ref={bankFormRef} />
        </Grid>
      </Grid>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
