import { Box, Typography } from '@mui/material'

function DevBadge({ isShow }: { isShow: boolean }) {
  return (
    <>
      {isShow && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '10px',
            left: '-30px',
            zIndex: 9999999,
            transform: 'rotate(45deg)',
            backgroundColor: 'red',
            width: '100px',
            textAlign: 'center',
            letterSpacing: '0.1em',
          }}
        >
          <Typography fontSize={16} fontWeight='bold' color='white'>
            DEV
          </Typography>
        </Box>
      )}
    </>
  )
}

export default DevBadge
