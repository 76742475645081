import type { APIResponse, BankAccountTypeType } from '@/types'

import { http } from '@/lib/http'

export type PutOrganizationParams = {
  id: number
  name: string
  addRate: number
  country?: string
  zipCode?: string
  province?: string
  city?: string
  address1?: string
  address2?: string
  department?: string
  person?: string
  phoneNumber?: string
  notificationEmail?: string
  invoiceNumberStatus: string
  invoiceNumber?: string | null
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  bankAccountType?: BankAccountTypeType
  bankAccountNumber?: string
  bankAccountHolder?: string
  status: string
  defaultIsEntryRestricted?: boolean
}

export const postOrganizationPath = '/admin/v1/organization/:organizationId'

export const putOrganization = async (params: PutOrganizationParams) => {
  return await http<APIResponse<null>>(postOrganizationPath, {
    method: 'PUT',
    params: { organizationId: params.id },
    body: JSON.stringify({
      name: params.name,
      add_rate: params.addRate,
      country: params.country,
      zip_code: params.zipCode,
      province: params.province,
      city: params.city,
      address1: params.address1,
      address2: params.address2,
      department: params.department,
      person: params.person,
      phone_number: params.phoneNumber,
      notification_email: params.notificationEmail,
      invoice_number_status: params.invoiceNumberStatus,
      invoice_number: params.invoiceNumber,
      bank_name: params.bankName,
      bank_code: params.bankCode,
      branch_name: params.branchName,
      branch_code: params.branchCode,
      bank_account_type: params.bankAccountType,
      bank_account_number: params.bankAccountNumber,
      bank_account_holder: params.bankAccountHolder,
      status: params.status,
      default_is_entry_restricted: params.defaultIsEntryRestricted,
    }),
  })
}
