import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { PostTermFormRef } from '@/features/terms/components/TermForm'

import { getTermDetail } from '@/features/terms/api/getTermDetail'
import { patchTerm } from '@/features/terms/api/patchTerm'
import TermForm from '@/features/terms/components/TermForm'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/terms/:termId')
  const pageTitle = '利用規約詳細'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const updateTermFormRef = useRef<PostTermFormRef>(null)

  // データ反映
  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getTermDetail({
        id: Number(params.termId),
      })
      updateTermFormRef.current?.formReset({
        category: result.category.key,
        content: result.content,
        publishedAt: newDate(result.publishedAt),
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/terms')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = updateTermFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await patchTerm({
        id: Number(params.termId),
        category: formValues.category,
        content: formValues.content,
        publishedAt: formatDateTime(
          formValues.publishedAt,
          Format.dateTimeRequest,
        ),
      })
      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <TermForm ref={updateTermFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
    </Box>
  )
}

export default Page
