import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostFinishInquiryParams = {
  messageBoxId: string
  fanId: string
}

export type PostFinishInquiryResponse = APIResponse<null>

export const postFinishInquiryPath =
  '/admin/v1/message-box/:messageBoxId/:fanId/finish-inquiry'

export const postFinishInquiry = async (params: PostFinishInquiryParams) => {
  return await http<PostFinishInquiryResponse>(postFinishInquiryPath, {
    method: 'POST',
    params: {
      messageBoxId: params.messageBoxId,
      fanId: params.fanId,
    },
  })
}
