import { forwardRef, useImperativeHandle } from 'react'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { FormLabel, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'

import type { FanIdGroupFanItem } from '../types'

import { useNavigate } from '@/router'

type FanIdGroupFormValues = {
  name: string
  fanIds: string
  fanCount?: number
  fans?: FanIdGroupFanItem[]
}

interface FanIdGroupFormProps {}
export interface FanIdGroupFormRef {
  getFormValues(): FanIdGroupFormValues
  setFormValue(key: keyof FanIdGroupFormValues, value: string): void
  formReset(values: FanIdGroupFormValues): void
}

const FanIdGroupForm = forwardRef<
  FanIdGroupFormRef,
  FanIdGroupFormProps
  // eslint-disable-next-line no-empty-pattern
>(({}, ref) => {
  const { control, getValues, setValue, reset } = useForm<FanIdGroupFormValues>(
    {
      defaultValues: {
        name: '',
        fanIds: '',
      },
    },
  )

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const formatData = getValues('fans')?.map((item) => {
    return {
      id: item.id,
      name: item.name,
    }
  })

  const navigate = useNavigate()
  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={10}>
        <FormText control={control} name='name' label='ファンIDグループ名' />
        <FormText
          control={control}
          name='fanIds'
          label='ファンID'
          hint='カンマ区切りで複数指定可能'
        />
        {getValues('fanCount') && (
          <FormValue label='紐づいているファン総数'>
            {getValues('fanCount')}
          </FormValue>
        )}
        {getValues('fans') && (
          <>
            <FormLabel>紐づいているファン詳細</FormLabel>
            <DataGrid
              columns={[
                {
                  field: 'id',
                  headerName: 'ID',
                  width: 100,
                  renderCell: (params) => <CopyClipboard text={params.value} />,
                },
                {
                  field: 'name',
                  headerName: '名前',
                  width: 300,
                  renderCell: (params) => (
                    <Link
                      onClick={() => goFanDetail(params.row.id)}
                      color='inherit'
                      underline='hover'
                    >
                      {params.value}
                    </Link>
                  ),
                },
              ]}
              rows={formatData ?? []}
              total={formatData?.length ?? 0}
            ></DataGrid>
          </>
        )}
      </Grid>
    </FormBox>
  )
})

export default FanIdGroupForm
