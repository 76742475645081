import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetDailyMissionParams = {
  page: number
  fanId?: string
  fanName?: string
}

export type GetDailyMissionResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    date: string
    artistId: number
    type: string
    fanCheerId: number
    cheer: number
    expiresDate: string
    createdAt: string
    updatedAt: string
  }>
>

export const getDailyMissionPath = '/admin/v1/daily-mission'

export const getDailyMission = async (params: GetDailyMissionParams) => {
  return await http<GetDailyMissionResponse>(getDailyMissionPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
    },
  })
}
