import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { FaqCategoryFormRef } from '@/features/faq/components/FaqCategoryForm'

import { postFaqCategory } from '@/features/faq/api/postFaqCategory'
import FaqCategoryForm from '@/features/faq/components/FaqCategoryForm'
import { FaqCategoryStatus } from '@/features/faq/types'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'FAQカテゴリー登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const createFaqCategoryFormRef = useRef<FaqCategoryFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = createFaqCategoryFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      await postFaqCategory({
        name: formValues.name,
        target: formValues.target,
        sort: formValues.sort ? Number(formValues.sort) : undefined,
        status: formValues.status || FaqCategoryStatus.PUBLIC.value,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/faqs/categories')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <FaqCategoryForm ref={createFaqCategoryFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
