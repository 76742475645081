import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PutFaqCategoryParams = {
  id: number
  sort: number
  target: string
  name: string
  status: string
}

export const putFaqCategoryPath = '/admin/v1/faq-category/:id'

export type PutFaqCategoryResponse = APIResponse<null>

export const putFaqCategory = async (params: PutFaqCategoryParams) => {
  return await http<PutFaqCategoryResponse>(putFaqCategoryPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      sort: params.sort,
      target: params.target,
      name: params.name,
      status: params.status,
    }),
  })
}
