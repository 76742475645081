import { useCallback, useEffect, useRef, useState } from 'react'

import { Box, Typography } from '@mui/material'
import Hls from 'hls.js'

import type { HlsConfig } from 'hls.js'

interface MediaPlayerProps {
  type?: 'MOVIE' | 'AUDIO'
  src: string
  debug?: boolean
  thumbnailUrl?: string
  maxWidth?: string
  maxHeight?: string
  allowOrigin: string
}

export default function MediaPlayer({
  type = 'MOVIE',
  src,
  debug = false,
  thumbnailUrl = '',
  maxWidth = '100%',
  maxHeight = '400px',
  allowOrigin,
}: MediaPlayerProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [hls, setHls] = useState<Hls | null>(null)
  const [initialized, setInitialized] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const initVideo = useCallback(() => {
    const video = videoRef.current
    if (Hls.isSupported() && video) {
      const config: Partial<HlsConfig> = {
        debug: debug,
        xhrSetup: (xhr) => {
          xhr.withCredentials = true
          xhr.setRequestHeader(
            'Access-Control-Allow-Headers',
            'Content-Type, Accept, X-Requested-With',
          )
          xhr.setRequestHeader('Access-Control-Allow-Origin', allowOrigin)
          xhr.setRequestHeader('Cache-Control', 'no-cache')
          xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true')
        },
      }
      const hls = new Hls(config)
      hls.loadSource(src)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {})
      hls.on(Hls.Events.ERROR, (_event, data) => {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            setErrorMessage(
              'ファイルを変換中です。しばらく時間を置いてから開いてください。',
            )
            break
          default:
            break
        }
      })
      setHls(hls)
    } else if (video?.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = src
    }
  }, [src, debug, allowOrigin])

  const init = useCallback(() => {
    if (!src || initialized) {
      return
    }
    if (type === 'MOVIE') {
      initVideo()
    }
    setInitialized(true)
  }, [initVideo, initialized, src, type])

  const disposePlayer = useCallback(() => {
    if (hls) {
      hls.destroy()
    }
  }, [hls])

  useEffect(() => {
    init()
    return () => {
      disposePlayer()
    }
  }, [init, disposePlayer])

  return (
    <Box width={'100%'}>
      {errorMessage && (
        <Typography variant='body1' textAlign={'center'} color={'error.main'}>
          {errorMessage}
        </Typography>
      )}
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        {type === 'MOVIE' ? (
          // biome-ignore lint/a11y/useMediaCaption: <explanation>
          <video
            ref={videoRef}
            controls
            poster={thumbnailUrl}
            style={{ maxWidth, maxHeight }}
          />
        ) : (
          // biome-ignore lint/a11y/useMediaCaption: <explanation>
          <audio
            ref={videoRef}
            controls
            src={src}
            style={{ maxWidth, maxHeight }}
          />
        )}
      </Box>
    </Box>
  )
}
