import type { RevenueShareTypeType } from '@/types'

export const EntryType = {
  GENERAL: { value: 'GENERAL', text: '公開' },
  EXCLUSIVE: { value: 'EXCLUSIVE', text: '非公開（指名制）' },
} as const
export type EntryTypeType = keyof typeof EntryType

export const AggregationTarget = {
  ALL: { value: 'ALL', text: '投稿と配信' },
  POST: { value: 'POST', text: '投稿のみ' },
  LIVE: { value: 'LIVE', text: '配信のみ' },
} as const
export type AggregationTargetType = keyof typeof AggregationTarget

export const EntryFilterGender = {
  FEMALE: { value: 'FEMALE', text: '女性のみ' },
  MALE: { value: 'MALE', text: '男性のみ' },
} as const
export type EntryFilterGenderType = keyof typeof EntryFilterGender

export type EventEntryListItem = {
  id: number
  eventId: number
  eventName: string
  fanId: number
  artistId: number
  artistName: string
  agencyId: number | null
  agencyName: string | null
  artistRevenueShareRate: number
  agencyRevenueShareType: {
    key: RevenueShareTypeType | null
    value: string | null
  }
  agencyRevenueShareRate: number | null
  isDeleted: boolean
  isNewcomer: boolean
  isFirstGachi: boolean
  entryAt: string
  updatedAt: string
}
