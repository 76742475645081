/**APIの基本レスポンス */
export type APIResponse<T> = {
  code: number
  message: string
  errors: Array<{ [key: string]: Array<string> }>
  result: T
}

/**ページネーション時のresult */
export type APIResponseResultPagination<T> = {
  currentPage: number
  lastPage: number
  total: number
  perPage: number
  data: Array<T>
}

export const PlatFormType = {
  IOS: { value: 'IOS', text: 'iOS' },
  ANDROID: { value: 'ANDROID', text: 'Android' },
  WEB: { value: 'WEB', text: 'Web' },
} as const
export type PlatFormType = keyof typeof PlatFormType

export const FanStatus = {
  ACTIVE: { value: 'ACTIVE', text: '有効', color: 'success' },
  WITHDRAWAL: { value: 'WITHDRAWAL', text: '退会', color: 'secondary' },
  FORCED_WITHDRAWAL: { value: 'FORCED_WITHDRAWAL', text: '強制退会', color: 'error' },
  DELETED: { value: 'DELETED', text: 'ユーザ情報削除', color: 'default' },
} as const
export type FanStatusType = keyof typeof FanStatus

export const RevenueShareType = {
  ADD: { value: 'ADD', text: '上乗せ' },
  MARGIN: { value: 'MARGIN', text: '中抜き' },
} as const
export type RevenueShareTypeType = keyof typeof RevenueShareType

export const CheerType = {
  FREE: { value: 'FREE', text: 'ボーナスCHEER' },
  POINT: { value: 'POINT', text: 'ポイントCHEER' },
  LIMITED: { value: 'LIMITED', text: 'イベント限定CHEER' },
} as const
export type CheerTypeType = keyof typeof CheerType

export const BankAccountType = {
  SAVING: { value: 'SAVING', text: '普通' },
  CHECKING: { value: 'CHECKING', text: '当座' },
} as const
export type BankAccountTypeType = keyof typeof BankAccountType

export const EventType = {
  GACHI: { value: 'GACHI', text: 'がちイベ', page: 'serious', color: 'primary' },
  YURU: { value: 'YURU', text: 'ゆるイベ', page: 'loose', color: 'info' },
} as const
export type EventTypeType = keyof typeof EventType

export const InvoiceNumberStatus = {
  UNSET: { value: 'UNSET', text: '未設定' },
  UNREGISTERED: { value: 'UNREGISTERED', text: '登録しない' },
  REGISTERED: { value: 'REGISTERED', text: '登録する' },
} as const
export type InvoiceNumberStatusType = keyof typeof InvoiceNumberStatus

/** BankcodeJP API */
export type BankCodeClientBanksType = {
  code: string
  name: string
}

export type BankCodeClientBranchesType = {
  code: string
  name: string
}
