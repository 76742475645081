import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip } from '@mui/material'
import useSWR from 'swr'

import {
  getAdminUser,
  getAdminUserPath,
} from '@/features/admin/api/getAdminUser'
import { useNavigate } from '@/router'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '管理ユーザー'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')

  const { data, isValidating, mutate } = useSWR(getAdminUserPath, () =>
    getAdminUser({
      page: page,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    mutate(
      getAdminUser({
        page: page,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  const goDetail = (id = '') => {
    navigate('/settings/admin/:adminId', {
      params: {
        adminId: id,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[{ text: '新規作成', onClick: () => goDetail('create') }]}
      >
        {pageTitle}
      </PageTitle>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'ユーザー名', width: 200 },
            { field: 'email', headerName: 'メールアドレス', width: 200 },
            {
              field: 'deletedAt',
              headerName: '削除',
              width: 100,
              renderCell: (params) =>
                params.value && (
                  <Chip
                    size='small'
                    color='error'
                    variant='outlined'
                    label='削除済み'
                  />
                ),
            },
            { field: 'createdAt', headerName: '作成日時', width: 150 },
            { field: 'updatedAt', headerName: '更新日時', width: 150 },
          ]}
          rows={data?.result.data ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
