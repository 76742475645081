import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormCheckbox from '@cz_frontend/ui/components/forms/FormCheckbox/FormCheckbox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  GetUnifiedOrderIdPath,
  getUnifiedOrderId,
} from '@/features/histories/api/getUnifiedOrderId'
import {
  GmoStatus,
  PaymentStatus,
  PaymentType,
} from '@/features/histories/types'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { PlatFormType } from '@/types'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '決済ログ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    fanId: '',
    fanName: '',
    platform: [],
    status: [],
    type: [],
    orderId: '',
    gmoStatus: [],
    startAt: '',
    endAt: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const fanId = searchParams.get('fanId') || ''
  const fanName = searchParams.get('fanName') || ''
  const platform = searchParams.getAll('platform') || []
  const status = searchParams.getAll('status') || []
  const type = searchParams.getAll('type') || []
  const orderId = searchParams.get('orderId') || ''
  const gmoStatus = searchParams.getAll('gmoStatus') || []
  const startAtParam = searchParams.get('startAt')
  const startAt = startAtParam ? newDate(startAtParam) : null
  const endAtParam = searchParams.get('endAt')
  const endAt = endAtParam ? newDate(endAtParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId,
      fanName: fanName,
      platform: platform,
      status: status,
      type: type,
      gmoStatus: gmoStatus,
      orderId: orderId,
      startAt: startAt,
      endAt: endAt,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetUnifiedOrderIdPath, () =>
    getUnifiedOrderId({
      page: page,
      fanId: fanId,
      fanName: fanName,
      platform: platform || [],
      status: status || [],
      type: type || [],
      gmoStatus: gmoStatus || [],
      orderId: orderId,
      startAt: startAt
        ? formatDateTime(startAt, Format.dateTimeRequest)
        : undefined,
      endAt: endAt ? formatDateTime(endAt, Format.dateTimeRequest) : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId').toString() },
        { key: 'fanName', value: getValues('fanName').toString() },
        { key: 'platform', value: getValues('platform').toString() },
        { key: 'status', value: getValues('status').toString() },
        { key: 'type', value: getValues('type').toString() },
        { key: 'gmoStatus', value: getValues('gmoStatus').toString() },
        { key: 'orderId', value: getValues('orderId').toString() },
        {
          key: 'startAt',
          value: formatDateTime(getValues('startAt'), Format.dateTimeRequest),
        },
        {
          key: 'endAt',
          value: formatDateTime(getValues('endAt'), Format.dateTimeRequest),
        },
      ])
      return prev
    })
    mutate(
      getUnifiedOrderId({
        page: page,
        fanId: getValues('fanId'),
        fanName: getValues('fanName') || undefined,
        platform: getValues('platform') || undefined,
        status: getValues('status') || undefined,
        type: getValues('type') || undefined,
        gmoStatus: getValues('gmoStatus') || undefined,
        orderId: getValues('orderId') || undefined,
        startAt: startAt
          ? formatDateTime(getValues('startAt'), Format.dateTimeRequest)
          : undefined,
        endAt: endAt
          ? formatDateTime(getValues('endAt'), Format.dateTimeRequest)
          : undefined,
      }),
    )
  }

  const handleSearch = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  const goDetail = (id = '') => {
    navigate('/histories/payment/log/:logId', {
      params: {
        logId: id,
      },
    })
  }

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.unifiedOrderId,
      orderId: item.orderId,
      subOrderId: item.subOrderId,
      fanId: item.fanId,
      fanName: item.fanName,
      taxIncludedPrice: item.taxIncludedPrice,
      type: PaymentType[item.type].text,
      status: {
        text: PaymentStatus[item.latestStatus].text,
        color: PaymentStatus[item.latestStatus].color,
      },
      gmoStatus: item.gmoStatus ? {
        text: GmoStatus[item.gmoStatus].text,
        color: GmoStatus[item.gmoStatus].color,
      } : null,
      platform: PlatFormType[item.platform].text,
      price: item.taxIncludedPrice,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <Box>
        <PageTitle>{pageTitle}</PageTitle>
        <Box
          onSubmit={(e) => {
            handleSearch(e)
          }}
          component='form'
        >
          <Grid container mb={3}>
            <Grid item>
              <Grid container item md={12} columnSpacing={2}>
                <Grid item>
                  <FormText
                    control={control}
                    name='fanId'
                    label='ファンID'
                    hint='カンマ区切りで複数指定可能'
                  />
                </Grid>
                <Grid item>
                  <FormText
                    control={control}
                    name='fanName'
                    label='ファン名'
                    hint='部分一致'
                  />
                </Grid>
                <Grid item>
                  <FormDate
                    control={control}
                    name='startAt'
                    label='開始日'
                    views={[
                      'year',
                      'month',
                      'day',
                      'hours',
                      'minutes',
                      'seconds',
                    ]}
                    fullWidth={false}
                  />
                </Grid>
                <Grid item>
                  <FormDate
                    control={control}
                    name='endAt'
                    label='終了日'
                    views={[
                      'year',
                      'month',
                      'day',
                      'hours',
                      'minutes',
                      'seconds',
                    ]}
                    fullWidth={false}
                  />
                </Grid>
                <Grid item>
                  <FormText
                    control={control}
                    name='orderId'
                    label='オーダーID'
                    hint='部分一致'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container item md={12} columnSpacing={2}>
                <Grid item md={2}>
                  <FormCheckbox
                    control={control}
                    label='プラットフォーム'
                    name='platform'
                    options={Object.entries(PlatFormType).map(
                      ([key, value]) => ({
                        value: key,
                        text: value.text,
                      }),
                    )}
                    isRow={true}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormCheckbox
                    control={control}
                    label='ステータス'
                    name='status'
                    options={Object.entries(PaymentStatus).map(
                      ([key, value]) => ({
                        value: key,
                        text: value.text,
                      }),
                    )}
                    isRow={true}
                  />
                </Grid>
                <Grid item md={2}>
                  <FormCheckbox
                    control={control}
                    label='タイプ'
                    name='type'
                    options={Object.entries(PaymentType).map(
                      ([key, value]) => ({
                        value: key,
                        text: value.text,
                      }),
                    )}
                    isRow={true}
                  />
                </Grid>
                <Grid item md={5}>
                  <FormCheckbox
                    control={control}
                    label='GMOステータス'
                    name='gmoStatus'
                    options={Object.entries(GmoStatus).map(([key, value]) => ({
                      value: key,
                      text: value.text,
                    }))}
                    isRow={true}
                  />
                </Grid>
                <Grid item>
                  <ActionButton type='submit'>検索</ActionButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'orderId', headerName: 'オーダーID', width: 200 },
            { field: 'subOrderId', headerName: 'サブオーダーID', width: 200 },
            {
              field: 'fanId',
              headerName: 'ファンID',
              width: 80,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: 'ファン名',
              width: 100,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            { field: 'taxIncludedPrice', headerName: '税込価格', width: 100 },
            {
              field: 'type',
              headerName: 'タイプ',
              width: 100,
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 120,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'gmoStatus',
              headerName: 'GMOステータス',
              width: 150,
              renderCell: (params) =>
                params.value === null ? (
                  '-'
                ) : (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                ),
            },
            {
              field: 'platform',
              headerName: 'プラットフォーム',
              width: 150,
            },
            { field: 'createdAt', headerName: '作成日時', width: 200 },
            { field: 'updatedAt', headerName: '更新日時', width: 200 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
        />
      </Box>
    </Box>
  )
}

export default Page
