import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Chip, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getMessageBoxTemplate,
  getMessageBoxTemplatePath,
} from '@/features/message-boxes/api/getMessageBoxTemplate'
import { PublishStatus, SendStatus, TemplateStatus } from '@/features/message-boxes/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'メッセージボックス（アーティスト）'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const title = searchParams.get('title') || ''
  const artistId = Number(searchParams.get('artistId'))

  const { data, isValidating, mutate } = useSWR(
    `${getMessageBoxTemplatePath}-ARTIST`,
    () =>
      getMessageBoxTemplate({
        page: page,
        title: title,
        artistId: artistId > 0 ? artistId : undefined,
        sendFrom: 'ARTIST',
      }),
  )
  const { control, getValues } = useForm({
    defaultValues: {
      title: title,
      artistId: artistId > 0 ? String(artistId) : '',
    },
  })

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'title', value: getValues('title') },
        { key: 'artistId', value: getValues('artistId') },
      ])
      return prev
    })
    mutate(
      getMessageBoxTemplate({
        page: page,
        artistId: getValues('artistId')
          ? Number(getValues('artistId'))
          : undefined,
        sendFrom: 'ARTIST',
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/message-boxes/artist/:messageId', {
      params: {
        messageId: id,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      artistId: item.artist.id,
      artistName: item.artist.name,
      fanIds: item.fanIds || '未設定',
      title: item.title,
      publishStatus: {
        text: PublishStatus[item.publishStatus].text,
        color: PublishStatus[item.publishStatus].color,
      },
      templateStatus: {
        text: TemplateStatus[item.templateStatus].text,
        color: TemplateStatus[item.templateStatus].color,
      },
      sendStatus: {
        text: SendStatus[item.sendStatus].text,
        color: SendStatus[item.sendStatus].color,
      },
      sentAt: item.sentAt.length > 0 ? item.sentAt : '未送信',
    }
  })

  return (
    <Box className='page'>
      <Box>
        <PageTitle>{pageTitle}</PageTitle>
        <Box
          onSubmit={(e) => {
            handleSearchSubmit(e)
          }}
          component='form'
        >
          <Grid container columnSpacing={3} mb={3}>
            <Grid item>
              <FormText
                control={control}
                name='title'
                label='タイトル'
                fullWidth={false}
                hint='部分一致'
              />
            </Grid>
            <Grid item>
              <FormText
                control={control}
                name='artistId'
                label='アーティストID'
                type='number'
                fullWidth={false}
              />
            </Grid>
            <Grid item>
              <ActionButton type='submit'>検索</ActionButton>
            </Grid>
          </Grid>
        </Box>

        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'title', headerName: 'タイトル', width: 200 },
            { field: 'artistId', headerName: 'アーティストID', width: 150 },
            { field: 'artistName', headerName: 'アーティスト名', width: 200 },
            { field: 'fanIds', headerName: '送信対象ファンID', width: 200 },
            {
              field: 'publishStatus',
              headerName: '公開ステータス',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'templateStatus',
              headerName: '処理ステータス',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'sendStatus',
              headerName: '送信ステータス',
              width: 150,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            { field: 'sentAt', headerName: '配信日時', width: 200 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editHeaderName='詳細'
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
