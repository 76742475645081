import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import * as Sentry from '@sentry/browser'

import type { FallbackProps } from 'react-error-boundary'

export default function CommonError({ error }: FallbackProps) {
  const pageTitle = 'エラーが発生しました'
  // 明示的に呼ばないと送信してくれない
  if (error) {
    Sentry.captureException(error)
  }

  return (
    <Box className='page' p={4}>
      <Box display={'flex'} justifyContent={'center'}>
        <PageTitle>{pageTitle}</PageTitle>
      </Box>
      <Box mt={4} display={'flex'} justifyContent={'center'}>
        <Link href={'/'} fontSize={14}>
          TOPに戻る
        </Link>
      </Box>
    </Box>
  )
}
