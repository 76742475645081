import { useEffect, useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Chip, Grid, Link, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import type { EventEntryListItem } from '@/features/event/types'

import {
  getEventDetail,
  getEventDetailPath,
} from '@/features/event/api/getEventDetail'
import {
  GetEventEntryPath,
  getEventEntry,
} from '@/features/event/api/getEventEntry'
import { getEventEntryDownloadCsv } from '@/features/event/api/getEventEntryDownloadCsv'
import { newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { EventType, RevenueShareType } from '@/types'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const params = useParams('/events/:eventId/entries')
  const [isLoading, setIsLoading] = useState(false)
  const [isEventStarted, setIsEventStarted] = useState(false)

  const { data: eventData } = useSWR(getEventDetailPath, () =>
    getEventDetail({
      eventId: Number(eventId),
    }),
  )
  const eventName = eventData?.result.name
  const pageTitle = 'エントリー一覧'
  const eventDetailId = eventData?.result.eventDetailId ?? 0

  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    artistId: '',
    artistName: '',
    agencyId: '',
    agencyName: '',
    isDeleted: 'false',
    isNewcomer: '',
    isFirstGachi: '',
  })

  const eventId = params.eventId // イベントIDはURLから取得で固定
  const eventType = searchParams.get('eventType') || '' // イベント詳細ページから渡される
  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const agencyId = searchParams.get('agencyId') || ''
  const agencyName = searchParams.get('agencyName') || ''
  const isDeleted = searchParams.get('isDeleted') || ''
  const isNewcomer = searchParams.get('isNewcomer') || ''
  const isFirstGachi = searchParams.get('isFirstGachi') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      eventId: eventId,
      artistId: artistId,
      artistName: artistName,
      agencyId: agencyId,
      agencyName: agencyName,
      isDeleted: isDeleted,
      isNewcomer: isNewcomer,
      isFirstGachi: isFirstGachi,
    },
  })

  const { data, isValidating, mutate } = useSWR(GetEventEntryPath, () =>
    getEventEntry({
      page: page,
      eventId: eventId,
      id: id || undefined,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
      agencyId: agencyId || undefined,
      agencyName: agencyName || undefined,
      isDeleted: isDeleted ? Boolean(isDeleted === 'true') : undefined,
      isNewcomer: isNewcomer ? Boolean(isNewcomer === 'true') : undefined,
      isFirstGachi: isFirstGachi ? Boolean(isFirstGachi === 'true') : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'agencyId', value: getValues('agencyId') },
        { key: 'agencyName', value: getValues('agencyName') },
        { key: 'isDeleted', value: getValues('isDeleted') },
        { key: 'isNewcomer', value: getValues('isNewcomer') },
        { key: 'isFirstGachi', value: getValues('isFirstGachi') },
      ])
      return prev
    })
    mutate(
      getEventEntry({
        page: page,
        eventId: eventId,
        id: getValues('id') || undefined,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        isDeleted: getValues('isDeleted')
          ? Boolean(getValues('isDeleted'))
          : undefined,
        isNewcomer: getValues('isNewcomer')
          ? Boolean(getValues('isNewcomer'))
          : undefined,
        isFirstGachi: getValues('isFirstGachi')
          ? Boolean(getValues('isFirstGachi'))
          : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goEventDetail = () => {
    switch (eventType) {
      case EventType.GACHI.value:
        navigate('/events/serious/:eventId', {
          params: {
            eventId: eventId,
          },
        })
        break
      case EventType.YURU.value:
        navigate('/events/loose/:eventId', {
          params: {
            eventId: eventId,
          },
        })
        break
      default:
        navigate('/events/serious')
        break
    }
  }

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goAgencyDetail = (agencyId: number) => {
    navigate('/agencies/:agencyId', {
      params: {
        agencyId: String(agencyId),
      },
    })
  }

  const goDetail = (entryId = '') => {
    navigate(
      {
        pathname: '/events/:eventId/entries/:entryId',
        search: `?eventType=${eventType}`,
      },
      {
        params: {
          eventId: eventId,
          entryId: entryId,
        },
      },
    )
  }

  const goCreate = () => {
    navigate(
      {
        pathname: '/events/:eventId/entries/create',
        search: `?eventType=${eventType}`,
      },
      {
        params: {
          eventId: eventId,
        },
      },
    )
  }

  const goCsvUpload = () => {
    navigate('/events/:eventId/entries/csv-upload', {
      params: { eventId: eventId },
    })
  }

  const goEventArtistRanking = () => {
    navigate(
      {
        pathname: '/events/:eventId/ranking/artists',
        search: `?eventDetailId=${eventDetailId}`,
      },
      {
        params: {
          eventId: eventId,
        },
      },
    )
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getEventEntryDownloadCsv({
        eventId: eventId,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        isDeleted: getValues('isDeleted')
          ? Boolean(isDeleted === 'true')
          : undefined,
        isNewcomer: getValues('isNewcomer')
          ? Boolean(isNewcomer === 'true')
          : undefined,
        isFirstGachi: getValues('isFirstGachi')
          ? Boolean(isFirstGachi === 'true')
          : undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (eventData?.result.eventStartAt) {
      setIsEventStarted(newDate(eventData.result.eventStartAt) <= newDate())
    }
  }, [eventData])

  const formatEntryList = (item?: EventEntryListItem[]) => {
    if (!item) return []
    // ゆるイベは表示項目が異なる
    if (eventType === EventType.YURU.value) {
      return item.map((item) => {
        return {
          id: item.id,
          artist: {
            id: item.artistId,
            name: item.artistName,
            fanId: item.fanId,
          },
          artistId: item.artistId,
          artistName: item.artistName,
          fanId: item.fanId,
          agencyId: item.agencyId,
          agencyName: item.agencyName,
          artistRevenueShareRate: item.artistRevenueShareRate,
          agencyRevenueShareType: item.agencyRevenueShareType.key
            ? RevenueShareType[item.agencyRevenueShareType.key].text
            : '-',
          agencyRevenueShareRate: item.agencyRevenueShareRate ?? '-',
          isDeleted: {
            value: item.isDeleted,
            text: item.isDeleted ? '辞退' : '-',
          },
          isNewcomer: {
            value: item.isNewcomer,
            text: item.isNewcomer ? '新人' : '-',
          },
          entryAt: item.entryAt,
          updatedAt: item.updatedAt,
        }
      })
    }

    return item.map((item) => {
      return {
        id: item.id,
        artist: {
          id: item.artistId,
          name: item.artistName,
          fanId: item.fanId,
        },
        artistId: item.artistId,
        artistName: item.artistName,
        fanId: item.fanId,
        agencyId: item.agencyId,
        agencyName: item.agencyName,
        artistRevenueShareRate: item.artistRevenueShareRate,
        agencyRevenueShareType: item.agencyRevenueShareType.key
          ? RevenueShareType[item.agencyRevenueShareType.key].text
          : '-',
        agencyRevenueShareRate: item.agencyRevenueShareRate ?? '-',
        isDeleted: {
          value: item.isDeleted,
          text: item.isDeleted ? '辞退' : '-',
        },
        isNewcomer: {
          value: item.isNewcomer,
          text: item.isNewcomer ? '新人' : '-',
        },
        isFirstGachi: {
          value: item.isFirstGachi,
          text: item.isFirstGachi ? 'ガチ初' : '-',
        },
        entryAt: item.entryAt,
        updatedAt: item.updatedAt,
      }
    })
  }

  const formatData = formatEntryList(data?.result.data)

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'エントリー登録', onClick: goCreate },
          { text: 'CSVダウンロード', onClick: handleDownloadCsv },
          { text: 'CSVアップロード', onClick: goCsvUpload },
          {
            text: 'アーティストランキング',
            onClick: goEventArtistRanking,
            disabled: !isEventStarted,
          },
          { text: 'イベント詳細へ戻る', onClick: goEventDetail },
        ]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      {eventName && (
        <Box my={3}>
          <Typography>対象のイベント：{eventName}</Typography>
        </Box>
      )}
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='id'
              label='ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyId'
              label='代理店ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyName'
              label='代理店名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='isDeleted'
              label='辞退'
              fullWidth={false}
              options={[
                { value: '', text: '選択してください' },
                { value: 'false', text: '辞退を除く' },
                { value: 'true', text: '辞退のみ' },
              ]}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='isNewcomer'
              label='新人'
              fullWidth={false}
              options={[
                { value: '', text: '選択してください' },
                { value: 'true', text: '新人のみ' },
                { value: 'false', text: '新人を除く' },
              ]}
            />
          </Grid>
          {eventType !== EventType.YURU.value && (
            <Grid item>
              <FormSelect
                control={control}
                name='isFirstGachi'
                label='ガチ初挑戦'
                fullWidth={false}
                options={[
                  { value: '', text: '選択してください' },
                  { value: 'true', text: '初挑戦のみ' },
                  { value: 'false', text: '初挑戦を除く' },
                ]}
              />
            </Grid>
          )}
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={
            eventType === EventType.YURU.value
              ? [
                  { field: 'id', headerName: 'ID', width: 100 },
                  {
                    field: 'artistId',
                    headerName: 'アーティストID',
                    width: 120,
                    renderCell: (params) => (
                      <CopyClipboard text={params.value} />
                    ),
                  },
                  {
                    field: 'artistName',
                    headerName: 'アーティスト名',
                    width: 300,
                    renderCell: (params) => (
                      <Link
                        onClick={() => goFanDetail(params.row.fanId)}
                        color='inherit'
                        underline='hover'
                      >
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: 'isDeleted',
                    headerName: '辞退',
                    width: 80,
                    renderCell: (params) =>
                      params.value.value ? (
                        <Chip
                          size='small'
                          color='error'
                          variant='outlined'
                          label={params.value.text}
                        />
                      ) : (
                        <Typography>{params.value.text}</Typography>
                      ),
                  },
                  {
                    field: 'isNewcomer',
                    headerName: '新人',
                    width: 80,
                    renderCell: (params) =>
                      params.value.value ? (
                        <Chip
                          size='small'
                          color='success'
                          variant='outlined'
                          label={params.value.text}
                        />
                      ) : (
                        <Typography>{params.value.text}</Typography>
                      ),
                  },
                  {
                    field: 'artistRevenueShareRate',
                    headerName: 'アーティストイベントRS率(%)',
                    width: 230,
                  },
                  {
                    field: 'agencyId',
                    headerName: '所属代理店ID',
                    width: 120,
                    renderCell: (params) =>
                      params.value ? (
                        <CopyClipboard text={String(params.value)} />
                      ) : (
                        '-'
                      ),
                  },
                  {
                    field: 'agencyName',
                    headerName: '所属代理店',
                    width: 250,
                    renderCell: (params) =>
                      params.value ? (
                        <Link
                          onClick={() => goAgencyDetail(params.row.agencyId)}
                          color='inherit'
                          underline='hover'
                        >
                          {params.value}
                        </Link>
                      ) : (
                        '-'
                      ),
                  },
                  {
                    field: 'agencyRevenueShareRate',
                    headerName: '代理店イベントRS率(%)',
                    width: 180,
                  },
                  {
                    field: 'agencyRevenueShareType',
                    headerName: 'RSタイプ',
                    width: 120,
                  },
                  {
                    field: 'entryAt',
                    headerName: 'エントリー日時',
                    width: 180,
                  },
                  {
                    field: 'updatedAt',
                    headerName: '更新日時',
                    width: 180,
                  },
                ]
              : [
                  { field: 'id', headerName: 'ID', width: 100 },
                  {
                    field: 'artistId',
                    headerName: 'アーティストID',
                    width: 120,
                    renderCell: (params) => (
                      <CopyClipboard text={params.value} />
                    ),
                  },
                  {
                    field: 'artistName',
                    headerName: 'アーティスト名',
                    width: 300,
                    renderCell: (params) => (
                      <Link
                        onClick={() => goFanDetail(params.row.fanId)}
                        color='inherit'
                        underline='hover'
                      >
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    field: 'isDeleted',
                    headerName: '辞退',
                    width: 80,
                    renderCell: (params) =>
                      params.value.value ? (
                        <Chip
                          size='small'
                          color='error'
                          variant='outlined'
                          label={params.value.text}
                        />
                      ) : (
                        <Typography>{params.value.text}</Typography>
                      ),
                  },
                  {
                    field: 'isNewcomer',
                    headerName: '新人',
                    width: 80,
                    renderCell: (params) =>
                      params.value.value ? (
                        <Chip
                          size='small'
                          color='success'
                          variant='outlined'
                          label={params.value.text}
                        />
                      ) : (
                        <Typography>{params.value.text}</Typography>
                      ),
                  },
                  {
                    field: 'isFirstGachi',
                    headerName: 'ガチ初挑戦',
                    width: 100,
                    renderCell: (params) =>
                      params.value.value ? (
                        <Chip
                          size='small'
                          color='error'
                          variant='outlined'
                          label={params.value.text}
                        />
                      ) : (
                        <Typography>{params.value.text}</Typography>
                      ),
                  },
                  {
                    field: 'artistRevenueShareRate',
                    headerName: 'アーティストイベントRS率(%)',
                    width: 230,
                  },
                  {
                    field: 'agencyId',
                    headerName: '所属代理店ID',
                    width: 120,
                    renderCell: (params) =>
                      params.value ? (
                        <CopyClipboard text={String(params.value)} />
                      ) : (
                        '-'
                      ),
                  },
                  {
                    field: 'agencyName',
                    headerName: '所属代理店',
                    width: 250,
                    renderCell: (params) =>
                      params.value ? (
                        <Link
                          onClick={() => goAgencyDetail(params.row.agencyId)}
                          color='inherit'
                          underline='hover'
                        >
                          {params.value}
                        </Link>
                      ) : (
                        '-'
                      ),
                  },
                  {
                    field: 'agencyRevenueShareRate',
                    headerName: '代理店イベントRS率(%)',
                    width: 180,
                  },
                  {
                    field: 'agencyRevenueShareType',
                    headerName: 'RSタイプ',
                    width: 120,
                  },
                  {
                    field: 'entryAt',
                    headerName: 'エントリー日時',
                    width: 180,
                  },
                  {
                    field: 'updatedAt',
                    headerName: '更新日時',
                    width: 180,
                  },
                ]
          }
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        />
      </Box>
    </Box>
  )
}

export default Page
