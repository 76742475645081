import type { PointDetail, PointUseTypeType } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetPointUseDetailParams = {
  id: string
}

export type GetPointUseDetailResponse = APIResponse<{
  id: number
  fanId: number
  fanName: string
  type: PointUseTypeType
  usedPoint: number
  customInfo: string
  detail: PointDetail[]
  createdAt: string
  updatedAt: string
}>

export const getPointUseDetailPath = '/admin/v1/fan-point-use-history/:id'

export const getPointUseDetail = async (params: GetPointUseDetailParams) => {
  return await http<GetPointUseDetailResponse>(getPointUseDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
