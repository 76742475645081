import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetRankingFanMonthlyParams = {
  artistId: number
  page: number
  year?: number
  month?: number
}

export type GetRankingFanMonthlyResponse = APIResponse<
  APIResponseResultPagination<{
    rank: number
    score: number
    cheerCount: number
    fan: {
      id: number
      name: string
      iconUrl: string
    }
  }>
>

export const getRankingFanMonthlyPath =
  '/admin/v1/ranking/monthly/fan-ranking/:artistId'

export const getRankingFanMonthly = async (params: GetRankingFanMonthlyParams) => {
  return await http<GetRankingFanMonthlyResponse>(getRankingFanMonthlyPath, {
    method: 'GET',
    params: {
      artistId: params.artistId,
    },
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
    },
  })
}
