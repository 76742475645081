import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetEventRankingArtistParams = {
  page: number
  eventDetailId: number
}

export type GetEventRankingArtistResponse = APIResponse<
  APIResponseResultPagination<{
    rank: number
    score: number
    correctionValue: number
    cheerCount: number
    watchingDuration: number
    postCount: number
    postCheerFanCount: number
    artist: {
      id: number
      fanId: number
      name: string
      iconUrl: string
    }
  }>
>

export const getEventRankingArtistPath = '/admin/v1/ranking/event/:eventDetailId'

export const getEventRankingArtist = async (params: GetEventRankingArtistParams) => {
  return await http<GetEventRankingArtistResponse>(getEventRankingArtistPath, {
    method: 'GET',
    params: {
      eventDetailId: params.eventDetailId,
    },
    searchParams: {
      page: params.page,
    },
  })
}
