import type { PurchaseMenuItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetPurchaseMenuDetailParams = {
  id: number
}

export type GetPurchaseMenuResponse = APIResponse<PurchaseMenuItem>

export const getPointPurchaseMenuDetailPath =
  '/admin/v1/point-purchase-menu/:id'

export const getPointPurchaseMenuDetail = async (
  params: GetPurchaseMenuDetailParams,
) => {
  return await http<GetPurchaseMenuResponse>(getPointPurchaseMenuDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
