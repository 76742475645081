import type { PointUseTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetPointUseParams = {
  page: number
  fanId?: string
  fanName?: string
}

export type GetPointUseResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    type: PointUseTypeType
    usedPoint: number
    createdAt: string
    updatedAt: string
  }>
>

export const getPointUsePath = '/admin/v1/fan-point-use-history'

export const getPointUse = async (params: GetPointUseParams) => {
  return await http<GetPointUseResponse>(getPointUsePath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
    },
  })
}
