import { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { EventEntryFormRef } from '@/features/event/components/EventEntryForm';
import type { Path} from '@/router';

import { postEventEntry } from '@/features/event/api/postEventEntry'
import EventEntryForm from '@/features/event/components/EventEntryForm'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/events/:eventId/entries/:entryId')
  const pageTitle = 'エントリー登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const [searchParams] = useSearchParams({
    eventType: '',
  })
  const eventType = searchParams.get('eventType') || ''

  const EventEntryFormRef = useRef<EventEntryFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = EventEntryFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      await postEventEntry({
        eventId: Number(params.eventId),
        artistId: Number(formValues.artistId),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate(`/events/${params.eventId}/entries?eventType=${eventType}` as Path)
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <EventEntryForm ref={EventEntryFormRef} isCreate={true} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
