import { useState, type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getArtistRevenueShare, getArtistRevenueSharePath } from '@/features/artists/api/getArtistRevenueShare'
import { getArtistRevenueShareDownloadCsv } from '@/features/artists/api/getArtistRevenueShareDownloadCsv'
import { ArtistHistoryGetType } from '@/features/artists/types'
import { useNavigate } from '@/router'
import { RevenueShareType } from '@/types'
import { downloadCsv, pickErrorMessages } from '@/utils/functions'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アーティスト基本RS・所属履歴一覧'

  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    artistId: '',
    artistName: '',
    organizationId: '',
    organizationName: '',
    agencyId: '',
    agencyName: '',
    getType: ArtistHistoryGetType.ALL.value,
  })

  const page = Number(searchParams.get('page') || '1')
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const organizationId = searchParams.get('organizationId') || ''
  const organizationName = searchParams.get('organizationName') || ''
  const agencyId = searchParams.get('agencyId') || ''
  const agencyName = searchParams.get('agencyName') || ''
  const getType = searchParams.get('getType') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      artistId: artistId,
      artistName: artistName,
      organizationId: organizationId,
      organizationName: organizationName,
      agencyId: agencyId,
      agencyName: agencyName,
      getType: getType,
    },
  })

  const { data, isValidating, mutate } = useSWR(getArtistRevenueSharePath, () =>
    getArtistRevenueShare({
      page: page,
      artistId: artistId ? Number(artistId) : undefined,
      artistName: artistName || undefined,
      organizationId: organizationId || undefined,
      organizationName: organizationName || undefined,
      agencyId: agencyId || undefined,
      agencyName: agencyName || undefined,
      getType: getType || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'organizationId', value: getValues('organizationId') },
        {
          key: 'organizationName',
          value: getValues('organizationName'),
        },
        { key: 'agencyId', value: getValues('agencyId') },
        { key: 'agencyName', value: getValues('agencyName') },
        { key: 'getType', value: getValues('getType') },
      ])
      return prev
    })
    mutate(
      getArtistRevenueShare({
        page: page,
        artistId: getValues('artistId')
          ? Number(getValues('artistId'))
          : undefined,
        artistName: getValues('artistName') || undefined,
        organizationId: getValues('organizationId') || undefined,
        organizationName: getValues('organizationName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        getType: getValues('getType') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  // 遷移
  const goDetail = (revenueShareId = '') => {
    navigate('/artists/revenue-share/:revenueShareId', {
      params: {
        revenueShareId: revenueShareId,
      },
    })
  }

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goOrganizationDetail = (organizationId = '') => {
    navigate('/organizations/:organizationId', {
      params: {
        organizationId: organizationId,
      },
    })
  }

  const goAgencyDetail = (agencyId = '') => {
    navigate('/agencies/:agencyId', {
      params: {
        agencyId: agencyId,
      },
    })
  }

  const goCreate = () => {
    navigate({
      pathname: '/artists/revenue-share/create',
      search: getValues('artistId') ? `?artistId=${getValues('artistId')}` : '',
    })
  }

  // CSVダウンロード
  const handleDownloadCsv = async () => {
    try {
      const res = await getArtistRevenueShareDownloadCsv({
        artistId: getValues('artistId')
          ? Number(getValues('artistId'))
          : undefined,
        artistName: getValues('artistName') || undefined,
        organizationId: getValues('organizationId') || undefined,
        organizationName: getValues('organizationName') || undefined,
        agencyId: getValues('agencyId') || undefined,
        agencyName: getValues('agencyName') || undefined,
        getType: getValues('getType') || undefined,
      })

      const resBlob = await res.blob()
      const contentDisposition = res.headers.get('content-disposition')
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : ''
      downloadCsv(resBlob, fileName)
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      fanId: item.fanId,
      artistId: item.artistId,
      artistName: item.artistName,
      revenueShareRate: item.revenueShareRate,
      organizationId: item.organizationId || '-',
      organizationName: item.organizationName || '-',
      agencyId: item.agencyId || '-',
      agencyName: item.agencyName || '-',
      agencyRevenueShareType: item.agencyRevenueShareType.key
        ? RevenueShareType[item.agencyRevenueShareType.key].text
        : '-',
      agencyRevenueShareRate: item.agencyRevenueShareRate || '-',
      startAt: item.startAt,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  const getTypeOptions = () => {
    return Object.entries(ArtistHistoryGetType).map(([key, value]) => ({
      value: key,
      text: value.text,
    }))
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: '新規登録', onClick: goCreate },
          { text: 'CSVダウンロード', onClick: handleDownloadCsv },
        ]}
        isLoading={isLoading}
      >
        {pageTitle}
      </PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              type='number'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationId'
              label='事務所ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='organizationName'
              label='事務所名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyId'
              label='代理店ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='agencyName'
              label='代理店名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='getType'
              label='取得方法'
              fullWidth={false}
              options={getTypeOptions()}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 50,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'startAt',
              headerName: '反映開始日',
              width: 100,
            },
            {
              field: 'revenueShareRate',
              headerName: '基本RS率(%)',
              align: 'center',
              width: 120,
            },
            {
              field: 'organizationId',
              headerName: '事務所ID',
              align: 'center',
              width: 80,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'organizationName',
              headerName: '事務所名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() =>
                    goOrganizationDetail(params.row.organizationId)
                  }
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'agencyId',
              headerName: '代理店ID',
              width: 80,
              align: 'center',
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'agencyName',
              headerName: '代理店名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goAgencyDetail(params.row.agencyId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'agencyRevenueShareRate',
              headerName: '代理店RS率(%)',
              align: 'center',
              width: 120,
            },
            {
              field: 'agencyRevenueShareType',
              headerName: 'RSタイプ',
              width: 120,
            },
            {
              field: 'createdAt',
              headerName: '登録日時',
              width: 150,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 150,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
