import React from 'react'
import ReactDOM from 'react-dom/client'

import DevBadge from '@cz_frontend/ui/components/common/DevBadge/DevBadge'
import { ThemeProvider } from '@emotion/react'
import { Routes } from '@generouted/react-router'
import { CssBaseline } from '@mui/material'
import { SWRConfig } from 'swr'

import theme from '@/theme'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SWRConfig
        value={{
          refreshInterval: 0, // n秒ごとに取得する
          revalidateIfStale: false, // キャッシュが古い場合に取得する
          revalidateOnFocus: false, // フォーカスが戻った時に取得する
          refreshWhenHidden: false, // 非表示時に取得する
          refreshWhenOffline: false, // オフライン時に取得する
          revalidateOnReconnect: true, // ネットワークが戻った時に取得する
          revalidateOnMount: true, // 画面表示時に取得する
        }}
      >
        <Routes />
      </SWRConfig>
      <DevBadge
        isShow={import.meta.env.VITE_ENV_NAME !== 'production'}
      ></DevBadge>
    </ThemeProvider>
  </React.StrictMode>,
)
