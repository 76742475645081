import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { FormLabel, Grid } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'

import type { RevenueShareTypeType } from '@/types';

import { newDate } from '@/lib/dateFns'
import { RevenueShareType } from '@/types'


type ArtistRevenueShareFormValues = {
  id: number
  fanId: number
  artistId: number
  artistName: string
  revenueShareRate: number
  organizationId: number | ''
  organizationName: string
  agencyId: number | ''
  agencyName: string | ''
  agencyRevenueShareType: RevenueShareTypeType | ''
  agencyRevenueShareRate: number | ''
  startAt: Date
  isEditable: boolean
  createdAt: string
  updatedAt: string
}

interface ArtistRevenueShareFormProps {
  isCreate?: boolean
}
export interface ArtistRevenueShareFormRef {
  getFormValues(): ArtistRevenueShareFormValues
  setFormValue(key: keyof ArtistRevenueShareFormValues, value: string): void
  formReset(values: ArtistRevenueShareFormValues): void
}

const ArtistRevenueShareForm = forwardRef<
  ArtistRevenueShareFormRef,
  ArtistRevenueShareFormProps
  // eslint-disable-next-line no-empty-pattern
>((props, ref) => {
  const isCreate = props.isCreate || false
  const { control, getValues, setValue, reset } =
    useForm<ArtistRevenueShareFormValues>({
      defaultValues: {
        id: 0,
        artistId: 0,
        artistName: '',
        revenueShareRate: 10, // デフォルト10%
        organizationId: '',
        organizationName: '',
        agencyId: '',
        agencyName: '',
        agencyRevenueShareType: RevenueShareType.ADD.value,
        agencyRevenueShareRate: '',
        startAt: newDate(),
        isEditable: true,
        createdAt: '',
        updatedAt: '',
      },
    })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const [isSetAgencyId, setIsSetAgencyId] = useState(false)
  const watchAgencyId = useWatch({ control, name: 'agencyId' })
  const handleSetAgencyId = useCallback(() => {
    const agencyId = getValues('agencyId')
    if (!agencyId) {
      setIsSetAgencyId(false)
      return
    }
    setIsSetAgencyId(agencyId > 0)
  }, [getValues])

  useEffect(() => {
    handleSetAgencyId()
  }, [handleSetAgencyId, watchAgencyId])

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={10}>
        {isCreate ? (
          <FormText
            control={control}
            name='artistId'
            label='アーティストID'
            type='number'
            required
            disabled={!getValues('isEditable')}
          />
        ) : (
          <FormValue label='アーティストID'>{getValues('artistId')}</FormValue>
        )}
        <FormLabel required>反映開始日</FormLabel>
        <FormDate
          control={control}
          name='startAt'
          views={['year', 'month', 'day']}
          fullWidth={false}
          disabled={!getValues('isEditable')}
        />
        <FormText
          control={control}
          name='revenueShareRate'
          label='アーティストRS率(%)'
          type='number'
          required
          disabled={!getValues('isEditable')}
        />

        <FormText
          control={control}
          name='organizationId'
          label='事務所ID'
          type='number'
          disabled={!getValues('isEditable')}
          hint='代理店と事務所は同時に所属できません。'
        />
        <FormText
          control={control}
          name='agencyId'
          label='代理店ID'
          type='number'
          disabled={!getValues('isEditable')}
          hint='代理店と事務所は同時に所属できません。'
        />
        {isSetAgencyId && (
          <>
            <FormSelect
              control={control}
              name='agencyRevenueShareType'
              label='代理店RSタイプ'
              options={Object.entries(RevenueShareType).map(
                ([key, value]) => ({
                  value: key,
                  text: value.text,
                }),
              )}
              disabled={!getValues('isEditable')}
              required
            />
            <FormText
              control={control}
              name='agencyRevenueShareRate'
              label='代理店RS率(%)'
              type='number'
              disabled={!getValues('isEditable')}
              required
            />
          </>
        )}
        {!isCreate && (
          <FormValue label='作成日時'>{getValues('createdAt')}</FormValue>
        )}
        {!isCreate && (
          <FormValue label='更新日時'>{getValues('updatedAt')}</FormValue>
        )}
      </Grid>
    </FormBox>
  )
})

export default ArtistRevenueShareForm
