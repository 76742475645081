import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormHtml from '@cz_frontend/ui/components/forms/FormHtml/FormHtml'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getFaqCategoryAllPath,
  getFaqCategoryAll,
} from '../api/getFaqCategoryAll'
import { FaqCategoryStatus, FaqStatus } from '../types'

import type { FaqStatusType } from '../types';

type FaqFormValues = {
  title: string
  description: string
  categoryId: number
  target: string
  sort?: number
  status?: FaqStatusType
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface FaqFormProps {
  defaultCategoryId?: number
  isCreate?: boolean
}
export interface FaqFormRef {
  getFormValues(): FaqFormValues
  setFormValue(key: keyof FaqFormValues, value: string): void
  formReset(values: FaqFormValues): void
}

const FaqForm = forwardRef<FaqFormRef, FaqFormProps>((props, ref) => {
  const isCreate = props.isCreate || false

  //FAQカテゴリー一覧
  const { data: faqCategoryList } = useSWR(getFaqCategoryAllPath, () =>
    getFaqCategoryAll({}),
  )

  const categoryOptions = () => {
    let options = [{ value: '', text: '' }]
    if (!faqCategoryList || faqCategoryList.result.length === 0) {
      return options
    }
    options = faqCategoryList.result.map((item) => ({
      value: item.id,
      text: `[${item.target.value}] ${item.name}`,
    }))
    return options
  }

  const { control, getValues, setValue, reset } = useForm<FaqFormValues>({
    defaultValues: {
      title: '',
      description: '',
      target: undefined,
      categoryId: props.defaultCategoryId || Number(categoryOptions()[0].value),
      sort: undefined,
      status: FaqStatus.PUBLIC.value,
    },
  })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={8}>
        <FormText
          control={control}
          name='title'
          label='タイトル'
          required
          hint='255文字以内'
        />
        <FormHtml
          control={control}
          name='description'
          label='説明文'
          required
        />
        <FormSelect
          control={control}
          fullWidth={false}
          name='categoryId'
          label='カテゴリー'
          required
          options={categoryOptions()}
        />
        <FormText
          control={control}
          name='sort'
          label='並び順'
          required={!isCreate}
          type='number'
          hint={
            isCreate
              ? '指定しない場合は最後になります。1以上で指定してください。'
              : ''
          }
        />
        <FormSelect
          control={control}
          fullWidth={false}
          name='status'
          label='ステータス'
          required={!isCreate}
          options={Object.entries(FaqCategoryStatus).map(([key, value]) => ({
            value: key,
            text: value.text,
          }))}
        />
      </Grid>
    </FormBox>
  )
})

export default FaqForm
