import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { Box, FormLabel, Grid, Typography } from '@mui/material'
import { endOfDay, startOfDay } from 'date-fns'
import { useForm } from 'react-hook-form'

import { newDate } from '@/lib/dateFns'
import { INPUT_BASE_HEIGHT } from '@/theme'

type ArtistPickupFormValues = {
  id: number
  artistId: number
  name: string
  startAt: Date
  endAt: Date
  memo: string
  createdAt: string
  updatedAt: string
}

interface ArtistPickupFormProps {
  isCreate?: boolean
}
export interface ArtistPickupFormRef {
  getFormValues(): ArtistPickupFormValues
  setFormValue(key: keyof ArtistPickupFormValues, value: string): void
  formReset(values: ArtistPickupFormValues): void
}

const ArtistPickupForm = forwardRef<ArtistPickupFormRef, ArtistPickupFormProps>(
  (props, ref) => {
    const isCreate = props.isCreate || false
    const { control, getValues, setValue, reset } =
      useForm<ArtistPickupFormValues>({
        defaultValues: {
          id: 0,
          artistId: 0,
          name: '',
          startAt: startOfDay(newDate()),
          endAt: endOfDay(newDate()),
          memo: '',
          createdAt: '',
          updatedAt: '',
        },
      })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item md={10}>
          {isCreate ? (
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              type='number'
            />
          ) : (
            <FormValue label='アーティスト'>
              #{getValues('artistId')} {getValues('name')}
            </FormValue>
          )}
          <FormLabel>表示期間</FormLabel>
          <Box display={'flex'} alignItems={'center'} gap={'1em'}>
            <FormDate
              control={control}
              name='startAt'
              views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
              fullWidth={false}
            />
            <Typography height={INPUT_BASE_HEIGHT}>〜</Typography>
            <FormDate
              control={control}
              name='endAt'
              views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
              fullWidth={false}
            />
          </Box>
          <FormText
            control={control}
            name='memo'
            label='備考'
            multiline
            hint='100文字以下で入力してください'
          />
          {!isCreate && (
            <FormValue label='作成日時'>{getValues('createdAt')}</FormValue>
          )}
          {!isCreate && (
            <FormValue label='更新日時'>{getValues('updatedAt')}</FormValue>
          )}
        </Grid>
      </FormBox>
    )
  },
)

export default ArtistPickupForm
