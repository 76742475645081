import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetBanAccountParams = {
  page: number
  fanId?: number
  fanName?: string
}

export type GetBanAccountResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    expiredAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getBanAccountPath = '/admin/v1/fan-account-ban'

export const getBanAccount = async (params: GetBanAccountParams) => {
  return await http<GetBanAccountResponse>(getBanAccountPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
    },
  })
}
