import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetLoginHistoryParams = {
  page: number
  fanId?: string
  fanName?: string
  ipAddress?: string
}

export type GetLoginHistoryResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    fanId: number
    fanName: string
    ipAddress: string
    host: string
    createdAt: string
    updatedAt: string
  }>
>

export const GetLoginHistoryPath = '/admin/v1/fan-login'

export const getLoginHistory = async (params: GetLoginHistoryParams) => {
  return await http<GetLoginHistoryResponse>(GetLoginHistoryPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      ip_address: params.ipAddress,
    },
  })
}
