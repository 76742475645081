import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostBanAccountParams = {
  fanId: number
  expiredAt: string
}

export const postBanAccountPath = '/admin/v1/fan-account-ban'

export const postBanAccount = async (
  params: PostBanAccountParams,
) => {
  return await http<APIResponse<null>>(postBanAccountPath, {
    method: 'POST',
    body: JSON.stringify({
      fan_id: params.fanId,
      expired_at: params.expiredAt,
    }),
  })
}
