import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormCheckbox from '@cz_frontend/ui/components/forms/FormCheckbox/FormCheckbox'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { Grid } from '@mui/material'
import { useForm } from 'react-hook-form'

import { getOrganizationUserPermission } from '../api/getOrganizationUserPermission'

import type { OrganizationUserPermissionItem } from '../types'

type OrganizationUserFormValues = {
  organizationId: number
  name: string
  email: string
  password: string
  permission: number[]
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface OrganizationUserFormProps {
  isCreate?: boolean
}
export interface OrganizationUserFormRef {
  getFormValues(): OrganizationUserFormValues
  setFormValue(key: keyof OrganizationUserFormValues, value: string): void
  formReset(values: OrganizationUserFormValues): void
}

const OrganizationForm = forwardRef<
  OrganizationUserFormRef,
  OrganizationUserFormProps
>((props, ref) => {
  const isCreate = props.isCreate || false
  const { control, getValues, setValue, reset } =
    useForm<OrganizationUserFormValues>({
      defaultValues: {
        organizationId: undefined,
        name: '',
        email: '',
        password: '',
        permission: [],
      },
    })

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const [permissionList, setPermissionList] = useState<
    OrganizationUserPermissionItem[]
  >([])

  useEffect(() => {
    const fetchPermission = async () => {
      const { result } = await getOrganizationUserPermission()
      setPermissionList(result)
    }

    fetchPermission()
  }, [])

  const permissionOptions = useMemo(() => {
    if (permissionList.length === 0) {
      return []
    }
    return permissionList.map((item) => ({
      value: item.permission,
      text: item.name,
    }))
  }, [permissionList])

  return (
    <FormBox onSubmit={() => false}>
      {isCreate && (
        <Grid item md={12}>
          パスワードは自動生成され、登録したメールアドレス宛にログイン情報を載せたメールを送信します。
        </Grid>
      )}
      <Grid item md={5}>
        <FormText
          control={control}
          name='organizationId'
          label='事業所ID'
          required
          type='number'
        />
        <FormText
          control={control}
          name='name'
          label='名前'
          required
          hint='100文字以内'
        />
        <FormText
          control={control}
          name='email'
          label='メールアドレス'
          required
          type='email'
        />
        {!isCreate && (
          <FormText
            control={control}
            name='password'
            label='パスワード'
            required
            type='password'
            hint='8文字以上'
            placeholder={isCreate ? '' : '変更する場合のみ入力'}
            autocomplete='new-password'
          />
        )}
      </Grid>
      <Grid item md={5}>
        <FormCheckbox
          control={control}
          name='permission'
          label='権限'
          options={permissionOptions}
          required
        />
      </Grid>
    </FormBox>
  )
})

export default OrganizationForm
