import type { FaqStatusType } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostFaqParams = {
  faqCategoryId: number
  title: string
  description: string
  sort?: number
  status?: FaqStatusType
}

export type PostFaqResponse = APIResponse<null>

export const postFaqPath = '/admin/v1/faq'

export const postFaq = async (params: PostFaqParams) => {
  return await http<PostFaqResponse>(postFaqPath, {
    method: 'POST',
    body: JSON.stringify({
      faq_category_id: params.faqCategoryId,
      title: params.title,
      description: params.description,
      sort: params.sort,
      status: params.status,
    }),
  })
}
