import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetArtistCoinParams = {
  page: number
  artistId?: string
  artistName?: string
}

export type GetArtistCoinResponse = APIResponse<
  APIResponseResultPagination<{
    artistId: number
    fanId: number
    artistName: string
    balance: number
  }>
>

export const getArtistCoinPath = '/admin/v1/artist-coin'

export const getArtistCoin = async (params: GetArtistCoinParams) => {
  return await http<GetArtistCoinResponse>(getArtistCoinPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      artist_id: params.artistId,
      artist_name: params.artistName,
    },
  })
}
