import { type FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getArtistPickUp,
  getArtistPickUpPath,
} from '@/features/artists/api/getArtistPickup'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'アーティストピックアップ一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const artistId = searchParams.get('artistId') || ''
  const isDisplayed = searchParams.get('isDisplayed') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      artistId: artistId,
      isDisplayed: isDisplayed,
    },
  })

  const { data, isValidating, mutate } = useSWR(getArtistPickUpPath, () =>
    getArtistPickUp({
      page: page,
      artistId: artistId ? Number(artistId) : undefined,
      isDisplayed: isDisplayed ?? undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'artistId', value: getValues('artistId') || '' },
        { key: 'isDisplayed', value: getValues('isDisplayed') || '' },
      ])
      return prev
    })
    mutate(
      getArtistPickUp({
        page: page,
        artistId: getValues('artistId')
          ? Number(getValues('artistId'))
          : undefined,
        isDisplayed: getValues('isDisplayed') ?? undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goDetail = (id = '') => {
    navigate('/artists/pick-ups/:pickupId', {
      params: {
        pickupId: id,
      },
    })
  }

  const goCreate = () => {
    navigate('/artists/pick-ups/create')
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      artist: {
        id: item.artist.id,
        name: item.fan.name,
      },
      startAt: formatDateTime(item.startAt, Format.dateTimeDefault),
      endAt: formatDateTime(item.endAt, Format.dateTimeDefault),
      memo: item.memo,
      createdAt: formatDateTime(item.createdAt, Format.dateTimeDefault),
      updatedAt: formatDateTime(item.updatedAt, Format.dateTimeDefault),
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            name='artistId'
            label='アーティストID'
            fullWidth={false}
            type='number'
          />
          <FormSelect
            control={control}
            name='isDisplayed'
            label='表示'
            fullWidth={false}
            options={[
              { value: '', text: '選択してください' },
              { value: 'ON', text: '表示中' },
              { value: 'OFF', text: '非表示中' },
            ]}
          />
        </Box>

        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'artist',
              headerName: 'アーティスト',
              width: 300,
              renderCell: (params) => (
                <Typography component='span'>
                  #{params.value.id} {params.value.name}
                </Typography>
              ),
            },
            {
              field: 'startAt',
              headerName: '公開日時',
              width: 200,
            },
            {
              field: 'endAt',
              headerName: '終了日時',
              width: 200,
            },
            {
              field: 'memo',
              headerName: '備考',
              width: 300,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
