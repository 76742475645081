import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { swal } from '@cz_frontend/ui/lib/sweetalert'
import {
  AccountCircleOutlined,
  FeedOutlined,
  LiveTvOutlined,
  NoteOutlined,
  LogoutOutlined,
  ManageAccountsOutlined,
  PersonOutlineOutlined,
  MailOutline,
  ExpandLess,
  ExpandMore,
  MusicNote,
  CurrencyYen,
  CorporateFare,
  People,
  FolderOpen,
  Quiz,
  Payment,
  VolumeOffOutlined,
  DoNotDisturbOnTotalSilenceOutlined,
  ReportProblemOutlined,
  Group,
  SensorOccupiedOutlined,
  FavoriteOutlined,
  FavoriteBorderOutlined,
  LocalParkingOutlined,
  SwapHorizontalCircleOutlined,
  PieChartOutlineOutlined,
  Block,
  LockPerson,
  PhonelinkLock,
  SocialDistanceOutlined,
  EmojiEventsOutlined,
  LocalFireDepartmentOutlined,
  StairsOutlined,
  DoubleArrowOutlined,
  TaskAltOutlined,
  AccountBalanceOutlined,
  DvrOutlined,
  SavingsOutlined,
  ApartmentOutlined,
  VoiceOverOff,
  DevicesOutlined,
  MailLock,
  Login,
  TodayOutlined,
  CalendarMonthOutlined,
  StackedBarChart,
  Timeline,
  MonetizationOnOutlined,
  CurrencyExchangeOutlined,
} from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'

import { postLogout } from '@/features/auth/api/postLogout'

interface Props {
  window?: () => Window
  drawerWidth: number
  mobileOpen: boolean
  toggleDrawer: () => void
}

function CommonDrawer({
  window,
  drawerWidth,
  mobileOpen = false,
  toggleDrawer,
}: Props) {
  const navigate = useNavigate()
  // サブメニュー開閉制御用
  const [openMessageBox, setOpenMessageBox] = useState(false)
  const [openOrganizations, setOpenOrganizations] = useState(false)
  const [openAgencies, setOpenAgencies] = useState(false)
  const [openFaq, setOpenFaq] = useState(false)
  const [openEvent, setOpenEvent] = useState(false)
  const [openFan, setOpenFan] = useState(false)
  const [openRs, setOpenRs] = useState(false)
  const [openHistories, setOpenHistories] = useState(false)
  const [openRsLog, setOpenRsLog] = useState(false)
  const [openPoint, setOpenPoint] = useState(false)
  const [openReportArtist, setOpenReportArtist] = useState(false)
  const [openReportEvent, setOpenReportEvent] = useState(false)
  const [openReportServiceKpi, setOpenReportServiceKpi] = useState(false)
  const [openIdGroup, setOpenIdGroup] = useState(false)
  const [openBan, setOpenBan] = useState(false)
  const toggleSubMenuOpen = (
    currentOpenState: boolean,
    setFunc: (openState: boolean) => void,
  ) => {
    setFunc(!currentOpenState)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined

  // ログアウト
  const logout = () => {
    toggleDrawer()
    swal
      .confirm({ text: 'ログアウトしますか？' })
      .then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          await postLogout().catch()
          navigate('/auth/signin')
        }
      })
  }

  const drawer = (
    <>
      <Box p={1} display='flex'>
        <Typography
          component='p'
          variant='h6'
          color='primary'
          fontWeight='bold'
        >
          CHEERZ ADMIN
        </Typography>
      </Box>
      <List>
        <Typography component='p' variant='body1' sx={{ ml: 1 }}>
          レポート
        </Typography>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openReportArtist, setOpenReportArtist)
            }}
          >
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText primary='アーティスト' />
            {openReportArtist ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openReportArtist} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/reports/artists/daily'>
                <ListItemIcon>
                  <TodayOutlined />
                </ListItemIcon>
                <ListItemText primary={'日次'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/reports/artists/monthly'>
                <ListItemIcon>
                  <CalendarMonthOutlined />
                </ListItemIcon>
                <ListItemText primary={'月次'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openReportEvent, setOpenReportEvent)
            }}
          >
            <ListItemIcon>
              <MusicNote />
            </ListItemIcon>
            <ListItemText primary='イベント' />
            {openReportEvent ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openReportEvent} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/reports/events/daily'>
                <ListItemIcon>
                  <TodayOutlined />
                </ListItemIcon>
                <ListItemText primary={'日次'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/reports/events/total'>
                <ListItemIcon>
                  <StackedBarChart />
                </ListItemIcon>
                <ListItemText primary={'累計'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to='/reports/events/total-artist'
              >
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'累計アーティスト別'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openReportServiceKpi, setOpenReportServiceKpi)
            }}
          >
            <ListItemIcon>
              <Timeline />
            </ListItemIcon>
            <ListItemText primary='KPI' />
            {openReportServiceKpi ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openReportServiceKpi} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/reports/service-kpi/daily'>
                <ListItemIcon>
                  <TodayOutlined />
                </ListItemIcon>
                <ListItemText primary={'日次'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to='/reports/service-kpi/monthly'
              >
                <ListItemIcon>
                  <CalendarMonthOutlined />
                </ListItemIcon>
                <ListItemText primary={'月次'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openRsLog, setOpenRsLog)
            }}
          >
            <ListItemIcon>
              <SavingsOutlined />
            </ListItemIcon>
            <ListItemText primary='日別アーティスト収益' />
            {openRsLog ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openRsLog} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/revenue-share-logs'>
                <ListItemIcon>
                  <SavingsOutlined />
                </ListItemIcon>
                <ListItemText primary={'通常'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/revenue-share-logs/event'>
                <ListItemIcon>
                  <MusicNote />
                </ListItemIcon>
                <ListItemText primary={'イベント'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openPoint, setOpenPoint)
            }}
          >
            <ListItemIcon>
              <LocalParkingOutlined />
            </ListItemIcon>
            <ListItemText primary='ポイント' />
            {openPoint ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openPoint} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to='/reports/points/point-unit-price'
              >
                <ListItemIcon>
                  <CalendarMonthOutlined />
                </ListItemIcon>
                <ListItemText primary={'月別ポイント単価'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/reports/points/deposit'>
                <ListItemIcon>
                  <CalendarMonthOutlined />
                </ListItemIcon>
                <ListItemText primary={'月別未消化ポイント'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/reports/coin-cheer'>
            <ListItemIcon>
              <CurrencyExchangeOutlined />
            </ListItemIcon>
            <ListItemText primary={'月別コイン・CHEER'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/reports/sales-report'>
            <ListItemIcon>
              <CurrencyYen />
            </ListItemIcon>
            <ListItemText primary={'売上レポート'} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to='/reports/payment-report'>
            <ListItemIcon>
              <CurrencyYen />
            </ListItemIcon>
            <ListItemText primary={'支払レポート'} />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openFan, setOpenFan)
            }}
          >
            <ListItemIcon>
              <PersonOutlineOutlined />
            </ListItemIcon>
            <ListItemText primary='ファン管理' />
            {openFan ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openFan} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans'>
                <ListItemIcon>
                  <PersonOutlineOutlined />
                </ListItemIcon>
                <ListItemText primary={'ファン一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans/blocked'>
                <ListItemIcon>
                  <DoNotDisturbOnTotalSilenceOutlined />
                </ListItemIcon>
                <ListItemText primary={'ブロック一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans/muted'>
                <ListItemIcon>
                  <VolumeOffOutlined />
                </ListItemIcon>
                <ListItemText primary={'ミュート一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans/reported'>
                <ListItemIcon>
                  <ReportProblemOutlined />
                </ListItemIcon>
                <ListItemText primary={'通報一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans/follows'>
                <ListItemIcon>
                  <SocialDistanceOutlined />
                </ListItemIcon>
                <ListItemText primary={'フォロー一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans/device'>
                <ListItemIcon>
                  <DevicesOutlined />
                </ListItemIcon>
                <ListItemText primary={'デバイス一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fans/login-history'>
                <ListItemIcon>
                  <Login />
                </ListItemIcon>
                <ListItemText primary={'ログイン履歴一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/artists/fan-ranks'>
                <ListItemIcon>
                  <EmojiEventsOutlined />
                </ListItemIcon>
                <ListItemText primary={'ファンランク一覧'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openEvent, setOpenEvent)
            }}
          >
            <ListItemIcon>
              <MusicNote />
            </ListItemIcon>
            <ListItemText primary='イベント' />
            {openEvent ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openEvent} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/events/serious'>
                <ListItemIcon>
                  <LocalFireDepartmentOutlined />
                </ListItemIcon>
                <ListItemText primary={'がちイベ'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/events/loose'>
                <ListItemIcon>
                  <StairsOutlined />
                </ListItemIcon>
                <ListItemText primary={'ゆるイベ'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/events/sub'>
                <ListItemIcon>
                  <DoubleArrowOutlined />
                </ListItemIcon>
                <ListItemText primary={'サブイベ'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openIdGroup, setOpenIdGroup)
            }}
          >
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary='IDグループ' />
            {openIdGroup ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openIdGroup} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/fan-id-groups'>
                <ListItemIcon>
                  <PersonOutlineOutlined />
                </ListItemIcon>
                <ListItemText primary={'ファン'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/artist-id-groups'>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'アーティスト'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openBan, setOpenBan)
            }}
          >
            <ListItemIcon>
              <Block />
            </ListItemIcon>
            <ListItemText primary='BAN' />
            {openBan ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openBan} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/bans/account'>
                <ListItemIcon>
                  <LockPerson />
                </ListItemIcon>
                <ListItemText primary={'BANアカウント一覧'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/bans/device'>
                <ListItemIcon>
                  <PhonelinkLock />
                </ListItemIcon>
                <ListItemText primary={'BANデバイス一覧'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openRs, setOpenRs)
            }}
          >
            <ListItemIcon>
              <PieChartOutlineOutlined />
            </ListItemIcon>
            <ListItemText primary='アーティストRS管理' />
            {openRs ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openRs} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/artists/revenue-share'>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'基本RS・所属'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to='/artists/revenue-share/events'
              >
                <ListItemIcon>
                  <MusicNote />
                </ListItemIcon>
                <ListItemText primary={'イベントRS'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/artists/pick-ups'>
            <ListItemIcon>
              <SensorOccupiedOutlined />
            </ListItemIcon>
            <ListItemText primary={'アーティストピックアップ'} />
          </ListItemButton>
        </ListItem>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to='/rankings/artists/daily'>
              <ListItemIcon>
                <AccountCircleOutlined />
              </ListItemIcon>
              <ListItemText primary={'アーティストランキング'} />
            </ListItemButton>
          </ListItem>
        </List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/posts'>
            <ListItemIcon>
              <FeedOutlined />
            </ListItemIcon>
            <ListItemText primary={'投稿'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/lives'>
            <ListItemIcon>
              <LiveTvOutlined />
            </ListItemIcon>
            <ListItemText primary={'ライブ配信'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/ng-words'>
            <ListItemIcon>
              <VoiceOverOff />
            </ListItemIcon>
            <ListItemText primary={'NGワード'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/mail-bounces'>
            <ListItemIcon>
              <MailLock />
            </ListItemIcon>
            <ListItemText primary={'バウンスメール'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openMessageBox, setOpenMessageBox)
            }}
          >
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText primary='メッセージボックス' />
            {openMessageBox ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openMessageBox} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/message-boxes/artist'>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'アーティスト'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/message-boxes/admin'>
                <ListItemIcon>
                  <ManageAccountsOutlined />
                </ListItemIcon>
                <ListItemText primary={'運営'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/message-boxes/inquiry'>
                <ListItemIcon>
                  <MailOutline />
                </ListItemIcon>
                <ListItemText primary={'問い合わせ'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <ListItemButton component={Link} to='/terms'>
            <ListItemIcon>
              <NoteOutlined />
            </ListItemIcon>
            <ListItemText primary={'利用規約'} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to='/purchase-menus'>
            <ListItemIcon>
              <CurrencyYen />
            </ListItemIcon>
            <ListItemText primary={'課金メニュー'} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to='/coin-statements'>
            <ListItemIcon>
              <MonetizationOnOutlined />
            </ListItemIcon>
            <ListItemText primary={'コイン残数'} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openOrganizations, setOpenOrganizations)
            }}
          >
            <ListItemIcon>
              <ApartmentOutlined />
            </ListItemIcon>
            <ListItemText primary='事務所管理' />
            {openOrganizations ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openOrganizations} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/organizations'>
                <ListItemIcon>
                  <ApartmentOutlined />
                </ListItemIcon>
                <ListItemText primary={'事務所'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/organizations/accounts'>
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary={'事務所ユーザー'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/organizations/artists'>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'所属アーティスト'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openAgencies, setOpenAgencies)
            }}
          >
            <ListItemIcon>
              <CorporateFare />
            </ListItemIcon>
            <ListItemText primary='代理店管理' />
            {openAgencies ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openAgencies} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/agencies'>
                <ListItemIcon>
                  <CorporateFare />
                </ListItemIcon>
                <ListItemText primary={'代理店'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/agencies/artists'>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'所属アーティスト'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openFaq, setOpenFaq)
            }}
          >
            <ListItemIcon>
              <Quiz />
            </ListItemIcon>
            <ListItemText primary='FAQ' />
            {openFaq ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openFaq} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/faqs/categories'>
                <ListItemIcon>
                  <FolderOpen />
                </ListItemIcon>
                <ListItemText primary={'FAQカテゴリ'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/faqs'>
                <ListItemIcon>
                  <Quiz />
                </ListItemIcon>
                <ListItemText primary={'FAQ'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              toggleSubMenuOpen(openHistories, setOpenHistories)
            }}
          >
            <ListItemIcon>
              <DvrOutlined />
            </ListItemIcon>
            <ListItemText primary='履歴' />
            {openHistories ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openHistories} timeout='auto' unmountOnExit>
          <List sx={{ pl: 4 }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/payment'>
                <ListItemIcon>
                  <Payment />
                </ListItemIcon>
                <ListItemText primary={'決済履歴'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/payment/log'>
                <ListItemIcon>
                  <AccountBalanceOutlined />
                </ListItemIcon>
                <ListItemText primary={'決済ログ'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/mission'>
                <ListItemIcon>
                  <TaskAltOutlined />
                </ListItemIcon>
                <ListItemText primary={'ミッション'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/cheer/collect'>
                <ListItemIcon>
                  <FavoriteOutlined />
                </ListItemIcon>
                <ListItemText primary={'CHEER受取'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/cheer/use'>
                <ListItemIcon>
                  <FavoriteBorderOutlined />
                </ListItemIcon>
                <ListItemText primary={'CHEER利用'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/point/purchase'>
                <ListItemIcon>
                  <LocalParkingOutlined />
                </ListItemIcon>
                <ListItemText primary={'ポイント購入'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/point/use'>
                <ListItemIcon>
                  <SwapHorizontalCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={'ポイント利用'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} to='/histories/coin-statements'>
                <ListItemIcon>
                  <CurrencyExchangeOutlined />
                </ListItemIcon>
                <ListItemText primary={'コイン'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse>
      </List>

      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to='/settings/admin'>
            <ListItemIcon>
              <ManageAccountsOutlined />
            </ListItemIcon>
            <ListItemText primary={'管理ユーザー'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary={'ログアウト'} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  )

  return (
    <>
      <Drawer
        container={container}
        variant='temporary'
        anchor={'right'}
        open={mobileOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default CommonDrawer
