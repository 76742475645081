import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanIdGroupParams = {
  page: number
  id?: number
  name?: string
}

export type GetFanIdGroupResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    name: string
    fanCount: number
    createdAt: string
    updatedAt: string
  }>
>

export const getFanIdGroupPath = '/admin/v1/fan-id-group'

export const getFanIdGroup = async (params: GetFanIdGroupParams) => {
  return await http<GetFanIdGroupResponse>(getFanIdGroupPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      id: params.id,
      name: params.name,
    },
  })
}
