import { http } from '@/lib/http'

export type PostArtistIdGroupParams = {
  name: string
  artistIds?: number[]
}

export const postArtistIdGroupPath = '/admin/v1/artist-id-group'

export const postArtistIdGroup = async (params: PostArtistIdGroupParams) => {
  return await http<null>(postArtistIdGroupPath, {
    method: 'POST',
    body: JSON.stringify({
      name: params.name,
      artist_ids: params.artistIds,
    }),
  })
}
