import type { TermListItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetTermDetailParams = {
  id: number
}

export type GetTermDetailResponse = APIResponse<TermListItem>

export const getTermDetailPath = '/admin/v1/term/:id'

export const getTermDetail = async (params: GetTermDetailParams) => {
  return await http<GetTermDetailResponse>(getTermDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
