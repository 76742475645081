import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMonthlyArtistReportParams = {
  page: number
  year?: number
  month?: number
  artistId?: string
  artistName?: string
}

export type GetMonthlyArtistReportResponse = APIResponse<
  APIResponseResultPagination<{
    year: number
    month: number
    artistId: number
    artistName: string
    fanId: number
    artistIconUrl: string
    postCount: number
    liveCount: number
    liveDuration: string
    cheerCount: number
    pointCheerCount: number
    freeCheerCount: number
    limitedCheerCount: number
    cheerUu: number
    pointCheerUu: number
    freeCheerUu: number
    limitedCheerUu: number
    createdAt: string
    updatedAt: string
  }>
>

export const getMonthlyArtistReportPath = '/admin/v1/monthly-artist-report'

export const getMonthlyArtistReport = async (params: GetMonthlyArtistReportParams) => {
  return await http<GetMonthlyArtistReportResponse>(getMonthlyArtistReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
      artist_id: params.artistId,
      artist_name: params.artistName,
    },
  })
}
