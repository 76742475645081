import type { RsCategoryType } from '../types'
import type { APIResponse, APIResponseResultPagination, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetDailyRevenueReportParams = {
  page: number
  dateFrom?: string
  dateTo?: string
  artistId?: string
  artistName?: string
  eventId?: string
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
  category?: RsCategoryType
}

export type GetDailyRevenueReportResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    date: string
    artistId: number
    artistName: string
    eventId: number
    eventName: string | null
    organizationId: number | null
    organizationName: string | null
    agencyId: number | null
    agencyName: string | null
    artistRevenueShareRate: number
    agencyRevenueShareType: RevenueShareTypeType | null
    agencyRevenueShareRate: number | null
    pointCheerCount: number
    artistRevenue: number
    agencyRevenue: number | null
    createdAt: string
    updatedAt: string
  }>
>

export const getDailyRevenueReportPath = '/admin/v1/daily-revenue-report'

export const getDailyRevenueReport = async (
  params: GetDailyRevenueReportParams,
) => {
  return await http<GetDailyRevenueReportResponse>(getDailyRevenueReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      date_from: params.dateFrom,
      date_to: params.dateTo,
      event_id: params.eventId,
      artist_id: params.artistId,
      artist_name: params.artistName,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
      category: params.category,
    },
  })
}
