import type {
  AggregationTargetType,
  EntryTypeType,
} from '../types'
import type { APIResponse, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetSubEventDetailParams = {
  subEventId: number
}

export type GetSubEventDetailResponse = APIResponse<{
  subEventId: number
  name: string
  isMain: boolean
  eventStartAt: string
  eventEndAt: string
  aggregationTarget: AggregationTargetType
  previewUrl: string
  cheerFactor: number
  viewersCountFactor: number
  durationFactor: number
  postCountFactor: number
  postUuFactor: number
  rewards: {
    line: number
    title: string
  }[]
  descriptions: {
    title: string
    description: string
  }[]
  mainEvent: {
    eventId: number
    name: string
    bannerUrl: string
    iconUrl: string
    type: EventTypeType
    artistGroupId: number | null
    entryType: EntryTypeType
    entryStartAt?: string
    entryEndAt?: string
    eventStartAt: string
    eventEndAt: string
    eventDisplayStartAt: string
    eventDisplayEndAt: string
    previewUrl: string
  }
  createdAt: string
  updatedAt: string
}>

export const getSubEventDetailPath = '/admin/v1/sub-event/:eventId'

export const getSubEventDetail = async (params: GetSubEventDetailParams) => {
  return await http<GetSubEventDetailResponse>(getSubEventDetailPath, {
    method: 'GET',
    params: {
      eventId: params.subEventId,
    },
  })
}
