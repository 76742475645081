import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getArtistDetail,
  getArtistDetailPath,
} from '@/features/artists/api/getArtistDetail'
import {
  getRankingFanMonthly,
  getRankingFanMonthlyPath,
} from '@/features/artists/api/getRankingFanMonthly'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const params = useParams('/artists/:artistId/ranking/fans/daily')
  const { data: artistData } = useSWR(getArtistDetailPath, () =>
    getArtistDetail({
      id: Number(params.artistId),
    }),
  )
  const artistName = artistData?.result.artistName ?? ''
  const pageTitle = artistName
    ? `${artistName}の月別ファンランキング`
    : '月別ファンランキング'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    date: formatDateTime(newDate(), Format.dateRequest),
  })

  const page = Number(searchParams.get('page') || '1')
  const dateParam = searchParams.get('date')
  const date = dateParam ? newDate(dateParam) : null

  const { control, getValues } = useForm({
    defaultValues: {
      date: date,
    },
  })

  const { data, isValidating, mutate } = useSWR(getRankingFanMonthlyPath, () =>
    getRankingFanMonthly({
      artistId: Number(params.artistId),
      page: page,
      year: date?.getFullYear(),
      month: date ? date.getMonth() + 1 : undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        {
          key: 'date',
          value: formatDateTime(getValues('date'), Format.dateRequest),
        },
      ])
      return prev
    })
    mutate(
      getRankingFanMonthly({
        artistId: Number(params.artistId),
        page: page,
        year: date?.getFullYear(),
        month: date ? date.getMonth() + 1 : undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      rank: item.rank,
      score: item.score,
      cheerCount: item.cheerCount,
      fan: {
        id: item.fan.id,
        name: item.fan.name,
      },
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  const goFanList = () => {
    navigate('/fans')
  }

  const goRankingDaily = () => {
    navigate('/artists/:artistId/ranking/fans/daily', {
      params: {
        artistId: params.artistId,
      },
    })
  }

  return (
    <Box className='page'>
      <PageTitle
        actions={[
          { text: 'ファン一覧へ戻る', onClick: goFanList },
          { text: '日別ランキングへ', onClick: goRankingDaily },
        ]}
      >
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormDate
            control={control}
            name='date'
            label='日付'
            views={['year', 'month']}
            fullWidth={false}
          />
        </Box>
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'rank', headerName: '順位', width: 100 },
            {
              field: 'fan',
              headerName: 'ファン',
              width: 300,
              renderCell: (params) => (
                <Box>
                  #<CopyClipboard text={params.value.id} isIcon={false} />
                  &nbsp;
                  <Link
                    onClick={() => goFanDetail(params.value.id)}
                    color='inherit'
                    underline='hover'
                  >
                    {params.value.name}
                  </Link>
                </Box>
              ),
            },
            { field: 'score', headerName: 'スコア', width: 130 },
            { field: 'cheerCount', headerName: '総CHEER数', width: 140 },
          ]}
          idPropertyName={'rank'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          editReferProp={'rank'}
        />
      </Box>
    </Box>
  )
}

export default Page
