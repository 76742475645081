import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMonthlyPointReportParams = {
  page: number
  year?: number
  month?: number
}

type detail = {
  creditcard: number
  ios: number
  android: number
  coin: number
  total: number
}

export type GetMonthlyPointReportResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    year: number
    month: number
    issued: detail
    used: detail
    deposit: detail
    createdAt: string
    updatedAt: string
  }>
>

export const getMonthlyPointReportPath = '/admin/v1/monthly-point-report'

export const getMonthlyPointReport = async (params: GetMonthlyPointReportParams) => {
  return await http<GetMonthlyPointReportResponse>(getMonthlyPointReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
    },
  })
}
