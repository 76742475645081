import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { FanIdGroupFormRef } from '@/features/fan/components/FanIdGroupForm'

import { postFanIdGroup } from '@/features/fan/api/postFanIdGroup'
import FanIdGroupForm from '@/features/fan/components/FanIdGroupForm'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'ファンIDグループ登録'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const FanIdGroupFormRef = useRef<FanIdGroupFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = FanIdGroupFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      const fanIds = formValues.fanIds.split(',').map((fanId) => Number(fanId))
      await postFanIdGroup({
        name: formValues.name,
        fanIds: fanIds.length > 0 ? fanIds : undefined,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/fan-id-groups')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <FanIdGroupForm ref={FanIdGroupFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
