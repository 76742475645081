import type { PurchaseMenuItem } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetMenuParams = {
  page: number
  platform?: string
  status?: string
}

export type GetPurchaseMenuResponse = APIResponse<
  APIResponseResultPagination<PurchaseMenuItem>
>

export const getPurchaseMenuPath = '/admin/v1/point-purchase-menu'

export const getPointPurchaseMenu = async (params: GetMenuParams) => {
  return await http<GetPurchaseMenuResponse>(getPurchaseMenuPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      platform: params.platform,
      status: params.status,
    },
  })
}
