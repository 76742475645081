import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { SpeakerNotesOutlined } from '@mui/icons-material'
import { Box, Chip, Grid } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getPost, getPostPath } from '@/features/post/api/getPost'
import { LiveTargetAudience, PostStatus, PostType } from '@/features/post/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '投稿一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const id = searchParams.get('id') || ''
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''
  const eventId = searchParams.get('eventId') || ''
  const eventName = searchParams.get('eventName') || ''
  const status = searchParams.get('status') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      id: id,
      artistId: artistId,
      artistName: artistName,
      eventId: eventId,
      eventName: eventName,
      status: status,
    },
  })

  const { data, isValidating, mutate } = useSWR(getPostPath, () =>
    getPost({
      page: page,
      id: id || undefined,
      type: PostType.POST.value,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
      eventId: eventId || undefined,
      eventName: eventName || undefined,
      status: status || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'id', value: getValues('id') },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
        { key: 'eventId', value: getValues('eventId') },
        { key: 'eventName', value: getValues('eventName') },
        { key: 'status', value: getValues('status') },
      ])
      return prev
    })
    mutate(
      getPost({
        page: page,
        id: getValues('id') || undefined,
        type: PostType.POST.value,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
        eventId: getValues('eventId') || undefined,
        eventName: getValues('eventName') || undefined,
        status: getValues('status') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  const goDetail = (id = '') => {
    navigate('/posts/:postId', {
      params: {
        postId: id,
      },
    })
  }

  const goPostComments = (postId = '') => {
    navigate(
      {
        pathname: '/posts/:postId/comments',
      },
      {
        params: {
          postId: postId,
        },
      },
    )
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      artistId: item.artistId,
      artistName: item.artistName,
      eventId: item.eventId,
      eventName: item.eventName ? item.eventName : '-',
      body: item.body,
      targetAudience: LiveTargetAudience[item.targetAudience].text,
      publishedAt: item.publishedAt || '-',
      status: {
        text: PostStatus[item.status].text,
        color: PostStatus[item.status].color,
      },
      imageUrl: item.imageUrl,
      movieUrl: item.movieUrl,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='id'
              label='投稿ID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventId'
              label='イベントID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='eventName'
              label='イベント名'
              fullWidth={false}
            />
          </Grid>
          <Grid item>
            <FormSelect
              control={control}
              name='status'
              label='投稿ステータス'
              fullWidth={false}
              options={[{ value: '', text: '選択してください' }].concat(
                Object.entries(PostStatus).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 100 },
            {
              field: '_comments',
              headerName: 'コメント',
              width: 80,
              align: 'center',
              renderCell: (params) => {
                return (
                  <SpeakerNotesOutlined
                    onClick={() => {
                      goPostComments(params.row.id)
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                )
              },
            },
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 200,
            },
            {
              field: 'status',
              headerName: 'ステータス',
              width: 100,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            {
              field: 'targetAudience',
              headerName: '配信対象',
              width: 120,
            },
            {
              field: 'publishedAt',
              headerName: '公開日時',
              width: 200,
            },
            {
              field: 'eventId',
              headerName: 'イベントID',
              width: 100,
              renderCell: (params) =>
                params.value ? <CopyClipboard text={params.value} /> : '-',
            },
            {
              field: 'eventName',
              headerName: 'イベント名',
              width: 200,
            },
            {
              field: 'body',
              headerName: '本文',
              width: 400,
            },
            {
              field: 'createdAt',
              headerName: '作成日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editHeaderName='詳細'
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
