import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteArtistRevenueShareParams = {
  id: number
}

export const deleteArtistRevenueSharePath = '/admin/v1/artist-history/:id'

export const deleteArtistRevenueShare = async (
  params: DeleteArtistRevenueShareParams,
) => {
  return await http<APIResponse<null>>(deleteArtistRevenueSharePath, {
    method: 'DELETE',
    params: {
      id: params.id,
    },
  })
}
