import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PutFaqParams = {
  id: number
  title: string
  faqCategoryId: number
  description: string
  sort: number
  status: string
}

export const putFaqPath = '/admin/v1/faq/:id'

export type PutFaqResponse = APIResponse<null>

export const putFaq = async (params: PutFaqParams) => {
  return await http<PutFaqResponse>(putFaqPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      title: params.title,
      faq_category_id: params.faqCategoryId,
      description: params.description,
      sort: params.sort,
      status: params.status,
    }),
  })
}
