import type { EntryTypeType } from '../types'
import type { APIResponse, APIResponseResultPagination, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetEventParams = {
  page: number
  eventId?: string
  name?: string
  type?: string
  startAt?: string
  endAt?: string
}

export type GetEventResponse = APIResponse<
  APIResponseResultPagination<{
    eventId: number
    eventDetailId: number
    name: string
    bannerUrl: string
    iconUrl: string
    type: EventTypeType
    entryType: EntryTypeType
    entryStartAt: string | null
    entryEndAt: string | null
    eventStartAt: string
    eventEndAt: string
    eventDisplayStartAt: string
    eventDisplayEndAt: string
    entryCount: number
    previewUrl: string
    createdAt: string
    updatedAt: string
  }>
>

export const getEventPath = '/admin/v1/event'

export const getEvent = async (params: GetEventParams) => {
  return await http<GetEventResponse>(getEventPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      type: params.type,
      event_id: params.eventId,
      name: params.name,
      start_at: params.startAt,
      end_at: params.endAt,
    },
  })
}
