import type {
  AggregationTargetType,
  EntryTypeType,
} from '../types'
import type { APIResponse, EventTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetEventDetailParams = {
  eventId: number
}

export type GetEventDetailResponse = APIResponse<{
  eventDetailId: number
  name: string
  bannerUrl: string
  iconUrl: string
  artistGroupId: number | null
  type: EventTypeType
  entryType: EntryTypeType
  entryStartAt?: string
  entryEndAt?: string
  eventStartAt: string
  eventEndAt: string
  eventDisplayStartAt: string
  eventDisplayEndAt: string
  aggregationTarget: AggregationTargetType
  previewUrl: string
  cheerFactor: number
  viewersCountFactor: number
  durationFactor: number
  postCountFactor: number
  postUuFactor: number
  entryFilterGender: string | null
  entryFilterAgeUpperLimit: number | null
  entryFilterAgeLowerLimit: number | null
  entryFilterAgeCalculationDate: string | null
  entryFilterBirthMonth: number | null
  entryFilterFirstGachi: boolean
  entryFilterRegStartDate: string | null
  entryFilterRegEndDate: string | null
  rewards: {
    line: number
    title: string
  }[]
  descriptions: {
    title: string
    description: string
  }[]
}>

export const getEventDetailPath = '/admin/v1/event/:eventId'

export const getEventDetail = async (params: GetEventDetailParams) => {
  return await http<GetEventDetailResponse>(getEventDetailPath, {
    method: 'GET',
    params: {
      eventId: params.eventId,
    },
  })
}
