import { forwardRef, useImperativeHandle } from 'react'

import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { Grid } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'

import type { RevenueShareTypeType } from '@/types'

import { RevenueShareType } from '@/types'

type EventEntryFormValues = {
  eventId: number
  eventName: string
  fanId: number
  artistId: number
  artistName: string
  agencyId: number | ''
  agencyName: string | ''
  artistRevenueShareRate: number
  agencyRevenueShareType: RevenueShareTypeType | ''
  agencyRevenueShareRate: number | ''
  isDeleted: boolean
  isNewcomer: boolean
  isFirstGachi: boolean
  entryAt: string
  updatedAt: string
}

interface EventEntryFormProps {
  isCreate?: boolean
}
export interface EventEntryFormRef {
  getFormValues(): EventEntryFormValues
  setFormValue(key: keyof EventEntryFormValues, value: string): void
  formReset(values: EventEntryFormValues): void
}

const EventEntryForm = forwardRef<EventEntryFormRef, EventEntryFormProps>(
  (props, ref) => {
    const isCreate = props.isCreate || false
    const { control, getValues, setValue, reset } =
      useForm<EventEntryFormValues>({
        defaultValues: {
          eventId: 0,
          eventName: '',
          fanId: 0,
          artistId: 0,
          artistName: '',
          agencyId: '',
          agencyName: '',
          artistRevenueShareRate: 0,
          agencyRevenueShareType: RevenueShareType.ADD.value,
          agencyRevenueShareRate: '',
          isDeleted: false,
          isNewcomer: false,
          isFirstGachi: false,
          entryAt: '',
          updatedAt: '',
        },
      })

    // agencyIdを監視する
    const agencyId = useWatch({
      control,
      name: 'agencyId',
      defaultValue: '',
    })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    return (
      <FormBox onSubmit={() => false}>
        <Grid item md={5}>
          <FormSectionContainer sectionTitle='基本情報'>
            {!isCreate && (
              <FormValue label='イベントID'>{getValues('eventId')}</FormValue>
            )}
            {!isCreate && (
              <FormValue label='イベント名'>{getValues('eventName')}</FormValue>
            )}
            {isCreate ? (
              <FormText
                control={control}
                required
                name='artistId'
                label='アーティストID'
                type='number'
              />
            ) : (
              <FormValue label='アーティストID'>
                {getValues('artistId')}
              </FormValue>
            )}
            {!isCreate && (
              <FormValue label='アーティスト'>
                {getValues('artistName')}
              </FormValue>
            )}

            {!isCreate && (
              <FormValue label='新人かどうか'>
                {getValues('isNewcomer') ? '新人' : '--'}
              </FormValue>
            )}

            {!isCreate && (
              <FormValue label='ガチ初挑戦'>
                {getValues('isFirstGachi') ? '初挑戦' : '--'}
              </FormValue>
            )}
          </FormSectionContainer>
        </Grid>
        {!isCreate && (
          <Grid item md={5}>
            <FormSectionContainer sectionTitle='設定情報'>
              <FormText
                control={control}
                name='artistRevenueShareRate'
                label='アーティストイベントRS率(%)'
                hint='0〜100%'
                type='number'
                required
                disabled={getValues('isDeleted')}
              />
              <FormText
                control={control}
                name='agencyId'
                label='代理店ID'
                type='number'
                required
                disabled={getValues('isDeleted')}
              />
              <FormSelect
                control={control}
                fullWidth={false}
                name='agencyRevenueShareType'
                label='RSタイプ'
                options={Object.entries(RevenueShareType).map(([, value]) => ({
                  value: value.value,
                  text: value.text,
                }))}
                required={!!agencyId}
                disabled={getValues('isDeleted')}
              />
              <FormText
                control={control}
                name='agencyRevenueShareRate'
                label='代理店イベントRS率(%)'
                type='number'
                hint='0〜100%'
                required={!!agencyId}
                disabled={getValues('isDeleted')}
              />
            </FormSectionContainer>
          </Grid>
        )}
      </FormBox>
    )
  },
)

export default EventEntryForm
