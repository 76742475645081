import { http } from '@/lib/http'

export type PatchArtistPickupParams = {
  id: number
  startAt: string
  endAt: string
  memo?: string
}

export const patchArtistPickupPath = '/admin/v1/pickup-artist/:id'

export const patchArtistPickup = async (params: PatchArtistPickupParams) => {
  return await http<null>(patchArtistPickupPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      start_at: params.startAt,
      end_at: params.endAt,
      memo: params.memo,
    }),
  })
}
