import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { ArtistRevenueShareFormRef } from '@/features/artists/components/ArtistRevenueShareForm';

import { getArtistLatestRevenueShareDetail } from '@/features/artists/api/getArtistLatestRevenueShareDetail'
import { postArtistRevenueShare } from '@/features/artists/api/postArtistRevenueShare'
import ArtistRevenueShareForm from '@/features/artists/components/ArtistRevenueShareForm'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'アーティストRS登録'
  const [isLoading, setIsLoading] = useState(false)
  usePageInfo({
    title: pageTitle,
  })

  const ArtistRevenueShareFormRef = useRef<ArtistRevenueShareFormRef>(null)

  const [searchParams] = useSearchParams({
    artistId: '',
  })
  const artistId = searchParams.get('artistId') || ''

  // アーティストIDが取得できれば、そのアーティストの現在のRS情報を取得してフォームにセットする
  useEffect(() => {
    const fetchData = async () => {
      if (!artistId) {
        return
      }
      const { result } = await getArtistLatestRevenueShareDetail({
        artistId: Number(artistId),
      })
      ArtistRevenueShareFormRef.current?.formReset({
        id: result.id,
        fanId: result.fanId,
        artistId: result.artistId,
        artistName: result.artistName,
        revenueShareRate: result.revenueShareRate,
        organizationId: result.organizationId || '',
        organizationName: result.organizationName || '',
        agencyId: result.agencyId || '',
        agencyName: result.agencyName || '',
        agencyRevenueShareType: result.agencyRevenueShareType.key || '',
        agencyRevenueShareRate: result.agencyRevenueShareRate || '',
        startAt: newDate(),
        isEditable: true,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
      })
    }
    fetchData()
  })

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = ArtistRevenueShareFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }
      await postArtistRevenueShare({
        artistId: formValues.artistId,
        revenueShareRate: formValues.revenueShareRate,
        organizationId: formValues.organizationId || undefined,
        agencyId: formValues.agencyId || undefined,
        agencyRevenueShareType:
          (formValues.agencyId && formValues.agencyRevenueShareType) ||
          undefined,
        agencyRevenueShareRate:
          (formValues.agencyId && formValues.agencyRevenueShareRate) ||
          undefined,
        startAt: formatDateTime(formValues.startAt, Format.dateRequest),
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate({
      pathname: '/artists/revenue-share',
      search: `?artistId=${artistId}`,
    })
  }

  return (
    <Box className='page' mb={5}>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <ArtistRevenueShareForm ref={ArtistRevenueShareFormRef} isCreate={true} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
