export default function ImageView({
  imageSrc,
  imageAlt = '',
  objectFit = 'contain',
  style,
}: {
  imageSrc: string
  imageAlt?: string
  objectFit?: 'cover' | 'contain'
  style?: React.CSSProperties
}) {
  return (
    <img
      src={imageSrc}
      alt={imageAlt}
      style={{
        objectFit: objectFit,
        width: '100%',
        height: 'auto',
        ...style,
      }}
    />
  )
}
