import React from 'react'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url'
  label?: string
  placeholder?: string
  autocomplete?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  sx?: React.CSSProperties
  buttonText?: string
  showImagePreview?: boolean
  imagePreviewStyle?: React.CSSProperties
  initialImageSrc?: string
  accept?: string
} & UseControllerProps<T>

function FormFile<T extends FieldValues>({
  name,
  label,
  required = false,
  disabled = false,
  control,
  size = 'small',
  buttonText = 'ファイルを選択',
  sx,
  showImagePreview = false,
  imagePreviewStyle,
  initialImageSrc = '',
  accept,
}: Props<T>) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [imageSrc, setImageSrc] = React.useState<string>('')

  React.useEffect(() => {
    if (initialImageSrc) {
      setImageSrc(initialImageSrc)
    }
  }, [initialImageSrc])

  const selectFile = () => {
    inputRef.current?.click()
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl fullWidth variant='outlined' sx={sx}>
          <FormLabel required={required}>{label}</FormLabel>
          {showImagePreview && (
            <PreviewImage
              imageSrc={imageSrc}
              imagePreviewStyle={imagePreviewStyle}
            />
          )}
          {field.value ? (
            /* 選択時 */
            <OutlinedInput
              size={size}
              readOnly
              value={field.value.name}
              onClick={selectFile}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    // 選択クリアボタンクリック
                    e.stopPropagation()
                    field.onChange(null)
                    setImageSrc(initialImageSrc)
                  }}
                  color='error'
                  size='small'
                >
                  <DeleteOutlineIcon />
                </IconButton>
              }
            />
          ) : (
            /* 未選択時 */
            <Button onClick={selectFile} disabled={disabled} variant='outlined'>
              {buttonText}
            </Button>
          )}
          <input
            type='file'
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) return
              field.onChange(e.target.files[0])
              // 画像プレビュー
              if (showImagePreview) {
                setImageSrc(URL.createObjectURL(e.target.files[0]))
              }
            }}
            ref={inputRef}
            style={{ display: 'none' }}
            accept={accept}
          />
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]?.message)}</small>
            </Typography>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormFile

function PreviewImage({
  imageSrc,
  imagePreviewStyle,
}: {
  imageSrc: string | null
  imagePreviewStyle?: React.CSSProperties
}) {
  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        p={1}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt=''
            style={{
              objectFit: 'cover',
              width: '100%',
              height: 'auto',
              minHeight: '100px',
              ...imagePreviewStyle,
            }}
          />
        ) : (
          <Typography
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={'grey.300'}
            sx={{
              width: '100%',
              height: 'auto',
              minHeight: '100px',
              ...imagePreviewStyle,
            }}
          >
            ファイル未選択
          </Typography>
        )}
      </Box>
    </>
  )
}
