import { forwardRef, useImperativeHandle } from 'react'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormBox from '@cz_frontend/ui/components/forms/FormBox/FormBox'
import FormDate from '@cz_frontend/ui/components/forms/FormDate/FormDate'
import FormFile from '@cz_frontend/ui/components/forms/FormFile/FormFile'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import FormValue from '@cz_frontend/ui/components/forms/FormValue/FormValue'
import { Box, Chip, FormLabel, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import {
  BirthdayVisibility,
  Gender,
  type BirthdayVisibilityType,
  type FanLinkItem,
  type GenderType,
} from '../types'

import type {
  AccountBanListItem,
  AuthProviderItem,
  DeviceBanListItem,
  DeviceListItem,
} from '../types'
import type { CheerTypeType, FanStatusType } from '@/types'

import { useNavigate } from '@/router'
import { CheerType, FanStatus } from '@/types'

type FanFormValues = {
  fanId: number
  artistId: number
  name: string
  profile: string
  iconUrl: string
  iconFile: File | null
  level: number
  birthdayVisibility: BirthdayVisibilityType
  birthday: Date | null
  gender: GenderType
  loginCount: number
  deletedAt: string
  status: FanStatusType
  links: FanLinkItem[]
  createdAt: string
  lastLoginAt: string
  points: {
    id: number //DataGridのkeyに使うため。TODO: APIから返して欲しいなあ。。
    platform: string
    expiredAt: string
    deposit: number
  }[]
  freeCheer: {
    id: number //DataGridのkeyに使うため。TODO: APIから返して欲しいなあ。。
    cheer: number
    expiredAt: string
  }[]
  limitedCheer: {
    id: number //DataGridのkeyに使うため。TODO: APIから返して欲しいなあ。。
    cheer: number
    eventId: number
    eventName: string
    expiredAt: string
  }[]
  authProviders: AuthProviderItem[]
  permissions: {
    text: string
    isBlock: boolean
  }[]
  deviceList: DeviceListItem[]
  deviceBanList: DeviceBanListItem[]
  accountBanList: AccountBanListItem[]
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface FanFormProps {}
export interface FanFormRef {
  getFormValues(): FanFormValues
  setFormValue(key: keyof FanFormValues, value: string): void
  formReset(values: FanFormValues): void
}

const FanForm = forwardRef<FanFormRef, FanFormProps>((_, ref) => {
  const { control, getValues, setValue, reset } = useForm<FanFormValues>({
    defaultValues: {
      fanId: 0,
      artistId: 0,
      name: '',
      profile: '',
      iconUrl: '',
      iconFile: null,
      level: 0,
      birthdayVisibility: BirthdayVisibility.PUBLIC.value,
      birthday: null,
      gender: Gender.MALE.value,
      loginCount: 0,
      deletedAt: '',
      status: FanStatus.ACTIVE.value,
      links: [],
      createdAt: '',
      lastLoginAt: '',
    },
  })

  const navigate = useNavigate()

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
    },
  }))

  const totalPoint = getValues('points')?.reduce(
    (sum, point) => sum + point.deposit,
    0,
  )

  const totalFreeCheer = getValues('freeCheer')?.reduce(
    (sum, cheer) => sum + cheer.cheer,
    0,
  )

  const totalLimitedCheer = getValues('limitedCheer')?.reduce(
    (sum, cheer) => sum + cheer.cheer,
    0,
  )

  const goPointUseHistory = () => {
    navigate({
      pathname: '/histories/point/use',
      search: `?fanId=${getValues('fanId')}`,
    })
  }

  const goPointPurchaseHistory = () => {
    navigate({
      pathname: '/histories/point/purchase',
      search: `?fanId=${getValues('fanId')}`,
    })
  }

  const goFreeCheerUseHistory = (cheerType: CheerTypeType) => {
    navigate({
      pathname: '/histories/cheer/use',
      search: `?fanId=${getValues('fanId')}&type=${cheerType}`,
    })
  }

  const goFreeCheerCollectHistory = (cheerType: CheerTypeType) => {
    navigate({
      pathname: '/histories/cheer/collect',
      search: `?fanId=${getValues('fanId')}&type=${cheerType}`,
    })
  }

  const goLoginHistory = () => {
    navigate({
      pathname: '/fans/login-history',
      search: `?fanId=${getValues('fanId')}`,
    })
  }

  const goDeviceList = () => {
    navigate({
      pathname: '/fans/device',
      search: `?fanId=${getValues('fanId')}`,
    })
  }

  const goBanDeviceList = () => {
    navigate({
      pathname: '/bans/device',
      search: `?fanId=${getValues('fanId')}`,
    })
  }

  const goBanAccountList = () => {
    navigate({
      pathname: '/bans/account',
      search: `?fanId=${getValues('fanId')}`,
    })
  }

  return (
    <FormBox onSubmit={() => false}>
      <Grid item md={5}>
        <FormSectionContainer sectionTitle='基本情報'>
          <FormValue label={'ファンID'}>{getValues('fanId')}</FormValue>
          <FormText control={control} name='name' label='名前' />
          <FormFile
            label='アイコン画像'
            control={control}
            name='iconFile'
            showImagePreview
            initialImageSrc={getValues('iconUrl')}
            required
          />
          <FormValue label={'サービスレベル'}>{getValues('level')}</FormValue>
          <FormSelect
            control={control}
            name='birthdayVisibility'
            label='誕生日公開設定'
            options={Object.entries(BirthdayVisibility).map(([, value]) => ({
              value: value.value,
              text: value.text,
            }))}
          />
          <FormDate
            control={control}
            name='birthday'
            label='誕生日'
            views={['year', 'month', 'day']}
          />
          <FormSelect
            control={control}
            name='gender'
            label='性別'
            options={Object.entries(Gender).map(([, value]) => ({
              value: value.value,
              text: value.text,
            }))}
          />
          <FormText
            control={control}
            name='profile'
            label='自己紹介文'
            multiline
          />
          {getValues('deletedAt') && (
            <FormValue label={'削除日時'}>{getValues('deletedAt')}</FormValue>
          )}
          <FormValue label={'ステータス'}>{getValues('status')}</FormValue>
          <FormLabel>リンク</FormLabel>
          <Box pl={4} mb={2}>
            {getValues('links') && getValues('links').length > 0
              ? getValues('links').map((link) => (
                  <Box key={link.sort}>
                    <Box display={'flex'} alignItems={'center'} gap={4}>
                      <FormValue label={link.title}>{link.url}</FormValue>
                    </Box>
                  </Box>
                ))
              : '登録されているリンクはありません'}
          </Box>

          <FormValue label={'登録日時'}>{getValues('createdAt')}</FormValue>
          <FormValue label={'ログイン回数'}>
            {getValues('loginCount')}
          </FormValue>
          <FormValue label={'最終ログイン日時'}>
            {getValues('lastLoginAt') ? getValues('lastLoginAt') : '-'}
          </FormValue>
          <Box mt={1}>
            <ActionButton onClick={goLoginHistory}>ログイン履歴</ActionButton>
          </Box>
        </FormSectionContainer>
      </Grid>
      <Grid item md={5}>
        <FormSectionContainer sectionTitle='ポイント内訳'>
          {getValues('points') && (
            <>
              <Box mb={2} display={'flex'} gap={1} alignItems={'flex-end'}>
                <Typography>ポイント合計：</Typography>
                <Typography fontWeight={'bold'} fontSize={22}>
                  {totalPoint}
                </Typography>
              </Box>
              <DataGrid
                columns={[
                  {
                    field: 'id',
                    headerName: 'ID',
                    width: 50,
                  },
                  {
                    field: 'platform',
                    headerName: 'プラットフォーム',
                    width: 200,
                  },
                  { field: 'expiredAt', headerName: '有効期限', width: 200 },
                  { field: 'deposit', headerName: '残ポイント数', width: 200 },
                ]}
                rows={getValues('points') ?? []}
              />
              <Box display={'flex'} alignItems={'center'} mt={1} gap={2}>
                <ActionButton onClick={goPointUseHistory}>
                  利用履歴
                </ActionButton>
                <ActionButton onClick={goPointPurchaseHistory}>
                  購入履歴
                </ActionButton>
              </Box>
            </>
          )}
        </FormSectionContainer>
        <FormSectionContainer sectionTitle='CHEER内訳'>
          {getValues('freeCheer') && (
            <>
              <FormLabel>◼️ {CheerType.FREE.text}</FormLabel>
              <Box mb={2} display={'flex'} gap={1} alignItems={'flex-end'}>
                <Typography>合計：</Typography>
                <Typography fontWeight={'bold'} fontSize={22}>
                  {totalFreeCheer}
                </Typography>
              </Box>
              <DataGrid
                columns={[
                  {
                    field: 'id',
                    headerName: 'ID',
                    width: 50,
                  },
                  {
                    field: 'cheer',
                    headerName: 'cheer',
                    width: 100,
                  },
                  { field: 'expiredAt', headerName: '有効期限', width: 200 },
                ]}
                rows={getValues('freeCheer') ?? []}
              />
              <Box display={'flex'} alignItems={'center'} mt={1} gap={2}>
                <ActionButton
                  onClick={() => {
                    goFreeCheerUseHistory(CheerType.FREE.value)
                  }}
                >
                  利用履歴
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    goFreeCheerCollectHistory(CheerType.FREE.value)
                  }}
                >
                  受け取り履歴
                </ActionButton>
              </Box>
              <Box mt={6}>
                <FormLabel>◼️ {CheerType.LIMITED.text}</FormLabel>
                <Box mb={2} display={'flex'} gap={1} alignItems={'flex-end'}>
                  <Typography>合計：</Typography>
                  <Typography fontWeight={'bold'} fontSize={22}>
                    {totalLimitedCheer}
                  </Typography>
                </Box>
                <DataGrid
                  columns={[
                    {
                      field: 'id',
                      headerName: 'ID',
                      width: 50,
                    },
                    {
                      field: 'cheer',
                      headerName: 'cheer',
                      width: 100,
                    },
                    {
                      field: 'eventId',
                      headerName: 'イベントID',
                      width: 100,
                    },
                    {
                      field: 'eventName',
                      headerName: 'イベント名',
                      width: 200,
                    },
                    { field: 'expiredAt', headerName: '有効期限', width: 200 },
                  ]}
                  rows={getValues('limitedCheer') ?? []}
                />
                <Box display={'flex'} alignItems={'center'} mt={1} gap={2}>
                  <ActionButton
                    onClick={() => {
                      goFreeCheerUseHistory(CheerType.LIMITED.value)
                    }}
                  >
                    利用履歴
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      goFreeCheerCollectHistory(CheerType.LIMITED.value)
                    }}
                  >
                    受け取り履歴
                  </ActionButton>
                </Box>
              </Box>
            </>
          )}
        </FormSectionContainer>
        <FormSectionContainer sectionTitle='SNS連携情報'>
          <DataGrid
            columns={[
              {
                field: 'provider',
                headerName: 'プロバイダ',
                width: 100,
              },
              {
                field: 'linked',
                headerName: '有効/無効',
                width: 100,
                renderCell: (params) => (
                  <Typography>{params.row.linked ? '有効' : '無効'}</Typography>
                ),
              },
            ]}
            rows={getValues('authProviders') ?? []}
            idPropertyName='provider'
          />
        </FormSectionContainer>
        <FormSectionContainer sectionTitle='通知設定'>
          <DataGrid
            columns={[
              {
                field: 'isBlock',
                headerName: '設定状況',
                width: 100,
                renderCell: (params) => {
                  return (
                    <>
                      {params.value ? (
                        <Chip
                          size='small'
                          color={'error'}
                          variant='outlined'
                          label='ブロック'
                        />
                      ) : (
                        <Chip
                          size='small'
                          color={'success'}
                          variant='outlined'
                          label='許可'
                        />
                      )}
                    </>
                  )
                },
              },
              {
                field: 'text',
                headerName: '項目',
                width: 400,
              },
            ]}
            rows={getValues('permissions') ?? []}
            idPropertyName='text'
          />
        </FormSectionContainer>
        <FormSectionContainer sectionTitle='デバイス一覧'>
          <DataGrid
            columns={[
              {
                field: 'id',
                headerName: 'ID',
                width: 50,
              },
              {
                field: 'deviceId',
                headerName: 'デバイスID',
                width: 200,
                renderCell: (params) => <CopyClipboard text={params.value} />,
              },
              {
                field: 'deviceCode',
                headerName: '機種',
                width: 100,
              },
              {
                field: 'osVersion',
                headerName: 'OSver',
                width: 80,
              },
              {
                field: 'appVersion',
                headerName: 'アプリver',
                width: 90,
              },
              {
                field: 'pushToken',
                headerName: 'プッシュトークン',
                width: 200,
              },
              {
                field: 'createdAt',
                headerName: '作成日時',
                width: 200,
              },
            ]}
            rows={getValues('deviceList') ?? []}
          />
          <Box mt={1}>
            <ActionButton onClick={goDeviceList}>デバイス一覧</ActionButton>
          </Box>
        </FormSectionContainer>
        <FormSectionContainer sectionTitle='BAN'>
          <FormLabel>◼️ デバイス</FormLabel>
          <DataGrid
            columns={[
              {
                field: 'id',
                headerName: 'ID',
                width: 50,
              },
              {
                field: 'deviceId',
                headerName: 'デバイスID',
                width: 200,
                renderCell: (params) => <CopyClipboard text={params.value} />,
              },
              {
                field: 'expiredAt',
                headerName: 'BAN期限',
                width: 200,
              },
              {
                field: 'createdAt',
                headerName: '登録日時',
                width: 200,
              },
            ]}
            rows={getValues('deviceBanList') ?? []}
          />
          <Box mt={1}>
            <ActionButton onClick={goBanDeviceList}>
              BANデバイス一覧
            </ActionButton>
          </Box>
          <Box mt={2}>
            <FormLabel>◼️ アカウント</FormLabel>
            <DataGrid
              columns={[
                {
                  field: 'id',
                  headerName: 'ID',
                  width: 50,
                },
                {
                  field: 'expiredAt',
                  headerName: 'BAN期限',
                  width: 200,
                },
                {
                  field: 'createdAt',
                  headerName: '登録日時',
                  width: 200,
                },
              ]}
              rows={getValues('accountBanList') ?? []}
            />
          </Box>
          <Box mt={1}>
            <ActionButton onClick={goBanAccountList}>
              BANアカウント履歴
            </ActionButton>
          </Box>
        </FormSectionContainer>
      </Grid>
    </FormBox>
  )
})

export default FanForm
