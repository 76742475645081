import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { NgWordFormRef } from '@/features/ng-word/components/NgWordForm';

import { deleteNgWord } from '@/features/ng-word/api/deleteNgWord'
import { getNgWordDetail } from '@/features/ng-word/api/getNgWordDetail'
import { patchNgWord } from '@/features/ng-word/api/patchNgWord'
import NgWordForm from '@/features/ng-word/components/NgWordForm'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'


function Page() {
  const navigate = useNavigate()
  const params = useParams('/ng-words/:ngWordId')
  const pageTitle = 'NGワード更新'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const NgWordFormRef = useRef<NgWordFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getNgWordDetail({
        id: Number(params.ngWordId),
      })
      NgWordFormRef.current?.formReset({
        word: result.word,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/ng-words')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = NgWordFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await patchNgWord({
        id: Number(params.ngWordId),
        word: formValues.word,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 削除
  const deleteAction = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteNgWord({
        id: Number(params.ngWordId),
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <NgWordForm ref={NgWordFormRef} />
      <ActionButton
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
      <Box mt={10}>
        <ActionButton
          size='small'
          fullWidth
          color='error'
          variant='outlined'
          onClick={deleteAction}
        >
          削除する
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
