import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import CopyClipboard from '@cz_frontend/ui/components/common/CopyClipboard/CopyClipboard'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import { Box, Chip, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import {
  getMessageBox,
  getMessageBoxPath,
} from '@/features/message-boxes/api/getMessageBox'
import { MessageStatus, MessageType } from '@/features/message-boxes/types'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = '問い合わせ'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const allOptionKey = 'ALL'
  const page = Number(searchParams.get('page') || '1')
  const fanId = Number(searchParams.get('fanId'))
  const fanName = searchParams.get('fanName') || ''
  const inquiryStatus = searchParams.get('inquiryStatus') ?? allOptionKey

  // option: stringを"IN_PROGRESS" | "COMPLETED" | undefinedに縛るため
  const inquiryStatusQuery = (status: string) => {
    if (status === allOptionKey) {
      return undefined
    }

    if (status === MessageStatus.COMPLETED.value) {
      return MessageStatus.COMPLETED.value
    }
    return MessageStatus.IN_PROGRESS.value
  }

  const { data, isValidating, mutate } = useSWR(getMessageBoxPath, () =>
    getMessageBox({
      page: page,
      fanId: fanId > 0 ? fanId : undefined,
      fanName: fanName,
      inquiryStatus: inquiryStatusQuery(inquiryStatus),
    }),
  )

  const { control, getValues } = useForm({
    defaultValues: {
      fanId: fanId > 0 ? String(fanId) : '',
      fanName: fanName,
      inquiryStatus: inquiryStatus,
    },
  })

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'fanId', value: getValues('fanId') },
        { key: 'fanName', value: getValues('fanName') },
        { key: 'inquiryStatus', value: getValues('inquiryStatus') },
      ])
      return prev
    })
    mutate(
      getMessageBox({
        page: page,
        fanId:
          Number(getValues('fanId')) > 0
            ? Number(getValues('fanId'))
            : undefined,
        fanName: getValues('fanName'),
        inquiryStatus: inquiryStatusQuery(inquiryStatus),
      }),
    )
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    })
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    setSearchParams({ page: value.toString() })
    search({ page: value })
  }

  const goDetail = (id = '', fanId = '') => {
    navigate({
      pathname: '/message-boxes/inquiry/:messageBoxId',
      search: `?fanId=${fanId}`,
    }, {
      params: {
        messageBoxId: id,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      messageBoxId: item.messageBoxId,
      fanId: item.fanId,
      fanName: item.fanName,
      title: item.title,
      body: item.body,
      messageType: MessageType[item.messageType].text,
      status: {
        text: MessageStatus[item.status].text,
        color: MessageStatus[item.status].color,
      },
      lastMessagedAt: item.lastMessagedAt,
    }
  })

  const goFanDetail = (fanId: number) => {
    navigate('/fans/:fanId', {
      params: {
        fanId: String(fanId),
      },
    })
  }

  return (
    <Box className='page'>
      <Box>
        <PageTitle>{pageTitle}</PageTitle>
        <Box
          display={'flex'}
          gap={4}
          alignItems={'center'}
          onSubmit={handleSearchSubmit}
          component='form'
        >
          <Box display={'flex'} gap={2} alignItems={'flex-start'}>
            <FormText
              control={control}
              name='fanId'
              label='ファンID'
              fullWidth={false}
              type='number'
            />
            <FormText
              control={control}
              name='fanName'
              label='ファン名'
              fullWidth={false}
              hint='部分一致'
            />
            <FormSelect
              control={control}
              name='inquiryStatus'
              label='対応ステータス'
              fullWidth={false}
              options={[
                {
                  value: 'ALL',
                  text: 'すべて',
                },
              ].concat(
                Object.entries(MessageStatus).map(([, item]) => ({
                  value: item.value,
                  text: item.text,
                })),
              )}
            />
          </Box>
          <ActionButton type='submit'>検索</ActionButton>
        </Box>

        <DataGrid
          columns={[
            {
              field: '_edit',
              headerName: '編集',
              width: 50,
              align: 'center',
              renderCell: (params) => {
                return (
                  <EditNoteOutlinedIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      goDetail(params.row.messageBoxId, params.row.fanId)
                    }
                  />
                )
              },
            },
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'status',
              headerName: '対応ステータス',
              width: 120,
              renderCell: (params) => {
                return (
                  <Chip
                    size='small'
                    color={params.value.color}
                    variant='outlined'
                    label={params.value.text}
                  />
                )
              },
            },
            { field: 'title', headerName: '問い合わせ種別', width: 180 },
            { field: 'body', headerName: '本文（冒頭50文字まで）', width: 300 },
            {
              field: 'messageBoxId',
              headerName: 'メッセージボックスID',
              width: 200,
            },
            {
              field: 'fanId',
              headerName: '送信者ファンID',
              width: 120,
              renderCell: (params) => <CopyClipboard text={params.value} />,
            },
            {
              field: 'fanName',
              headerName: '送信者ファン名',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            { field: 'messageType', headerName: 'メッセージ種別', width: 200 },
            { field: 'lastMessagedAt', headerName: '最終送信日時', width: 200 },
          ]}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
