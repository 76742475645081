import { httpRaw } from '@/lib/httpRaw'

export type GetTotalEventReportDownloadCsvParams = {
  eventId?: string
  eventName?: string
}

export const getTotalEventReportDownloadCsvPath =
  '/admin/v1/total-event-report/download-csv'

export const getTotalEventReportDownloadCsv = async (
  params: GetTotalEventReportDownloadCsvParams,
) => {
  return await httpRaw(getTotalEventReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      event_id: params.eventId,
      event_name: params.eventName,
    },
  })
}
