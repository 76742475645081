import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { EventSubFormRef } from '@/features/event/components/EventSubForm'

import { getSubEventDetail } from '@/features/event/api/getSubEventDetail'
import { patchSubEvent } from '@/features/event/api/patchSubEvent'
import EventSubForm from '@/features/event/components/EventSubForm'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/events/sub/:eventId')
  const pageTitle = 'サブイベ編集'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const EventSubFormRef = useRef<EventSubFormRef>(null)

  // 現在データの反映
  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getSubEventDetail({
        subEventId: Number(params.eventId),
      })
      EventSubFormRef.current?.formReset({
        eventId: String(result.mainEvent.eventId),
        name: result.name,
        eventStartAt: newDate(result.eventStartAt),
        eventEndAt: newDate(result.eventEndAt),
        aggregationTarget: result.aggregationTarget,
        rewards: result.rewards,
        scoreLogicFactor: {
          cheer: result.cheerFactor,
          viewersCount: result.viewersCountFactor,
          viewDuration: result.durationFactor,
          postCount: result.postCountFactor,
          postUu: result.postUuFactor,
        },
        descriptions: result.descriptions,
      })
    }
    fetchData()
  }, [params])

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = EventSubFormRef.current!.getFormValues()
      // rewardsとdescriptionsは空のものを除外
      const rewardsParam = formValues.rewards.filter(
        (reward) => reward.line && reward.title,
      )
      const descriptionsParam = formValues.descriptions.filter(
        (description) => description.title && description.description,
      )
      await patchSubEvent({
        subEventId: Number(params.eventId),
        eventId: formValues.eventId,
        name: formValues.name,
        eventStartAt: formatDateTime(
          formValues.eventStartAt,
          Format.dateTimeRequest,
        ),
        eventEndAt: formatDateTime(
          formValues.eventEndAt,
          Format.dateTimeRequest,
        ),
        cheerFactor: formValues.scoreLogicFactor.cheer,
        viewersCountFactor: formValues.scoreLogicFactor.viewersCount,
        durationFactor: formValues.scoreLogicFactor.viewDuration,
        postCountFactor: formValues.scoreLogicFactor.postCount,
        postUuFactor: formValues.scoreLogicFactor.postUu,
        rewards: rewardsParam.length > 0 ? rewardsParam : undefined,
        descriptions:
          descriptionsParam.length > 0 ? descriptionsParam : undefined,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/events/sub')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <EventSubForm ref={EventSubFormRef}></EventSubForm>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
