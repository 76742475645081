import type { SortType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanRankHistoryParams = {
  page: number
  fanId?: string
  fanName?: string
  artistId?: string
  artistName?: string
  startAt?: string
  endAt?: string
  rankSort?: SortType
}

export type GetFanRankHistoryResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    artistId: number
    artistName: string
    fanId: number
    fanName: string
    rank: string
    createdAt: string
    updatedAt: string
  }>
>

export const getFanRankHistoryPath = '/admin/v1/fan-rank-history'

export const getFanRankHistory = async (params: GetFanRankHistoryParams) => {
  return await http<GetFanRankHistoryResponse>(getFanRankHistoryPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      artist_id: params.artistId,
      artist_name: params.artistName,
      start_at: params.startAt,
      end_at: params.endAt,
      sort: params.rankSort,
    },
  })
}
