import type { InvoiceNumberStatusType, RevenueShareTypeType } from "@/types"

export const BirthdayVisibility = {
  PUBLIC: { value: 'PUBLIC', text: '全公開' },
  HIDDEN: { value: 'HIDDEN', text: '非公開' },
  HIDDEN_YEAR_ONLY: { value: 'HIDDEN_YEAR_ONLY', text: '月と日のみ公開' },
} as const
export type BirthdayVisibilityType = keyof typeof BirthdayVisibility

export const Gender = {
  MALE: { value: 'MALE', text: '男性' },
  FEMALE: { value: 'FEMALE', text: '女性' },
  OTHER: { value: 'OTHER', text: 'その他' },
} as const
export type GenderType = keyof typeof Gender

export const InvoiceNumberEnabled = {
  ENABLED: { value: 'ENABLED', text: '有効' },
  DISABLED: { value: 'DISABLED', text: '無効' },
} as const
export type InvoiceNumberEnabledType = keyof typeof InvoiceNumberEnabled

export const BankAccountType = {
  SAVING: { value: 'SAVING', text: '普通' },
  CHECKING: { value: 'CHECKING', text: '当座' },
} as const
export type BankAccountTypeType = keyof typeof BankAccountType

// 通知パーミッション
// 配列なのはループで回すため（Object.keyof().mapだと型が合わなくて各要素を取得できない）
export const NotificationSetting = [
  { key: 1, text: 'プッシュを受け取る' },
  { key: 2, text: 'フォローしているアーティストがタイムラインに投稿' },
  { key: 4, text: 'フォローしているアーティストが配信を開始' },
  { key: 8, text: 'フォローしているアーティストからメッセージを受信' },
  { key: 16, text: '投稿にファンからコメント' },
  { key: 32, text: 'イベントのお知らせ' },
]

export const FanDeviceType = {
  ios: { value: 'ios', text: 'ios' },
  android: { value: 'android', text: 'android' },
} as const
export type FanDeviceTypeType = keyof typeof FanDeviceType

export type FanLinkItem = {
  title: string
  url: string
  sort: number
}

export type BlockItem = {
  id: number
  blockerFanId: number
  blockerName: string
  blockedFanId: number
  blockedName: string
  createdAt: string
}

export type PointHistoryItem = {
  platform: string
  expiredAt: string
  deposit: number
}

export type AuthProviderItem = {
  provider: string
  linked: boolean
}

export type DeviceListItem = {
  id: number
  fanId: number
  fanName: string
  deviceId: string
  deviceCode: string
  deviceType: FanDeviceTypeType
  osVersion: string
  appVersion: string
  pushToken: string
  createdAt: string
  updatedAt: string
}

export type DeviceBanListItem = {
  id: number
  fanId: number
  deviceId: string
  expiredAt: string
  createdAt: string
  updatedAt: string
}

export type AccountBanListItem = {
  id: number
  fanId: number
  expiredAt: string
  createdAt: string
  updatedAt: string
}

export type FanDetailArtistAgency = {
  id: number
  name: string
  revenueShareType: RevenueShareTypeType
  revenueShareRate: number
}

export type FanDetailArtistJoinEvent = {
  id: number
  name: string
  artistRevenueShareRate: number
  agencyRevenueShareType: RevenueShareTypeType
  agencyRevenueShareRate: number
  createdAt: string
  updatedAt: string
}
export const ReportType = {
  FAN: { value: 'FAN', text: 'ファン' },
  ARTIST: { value: 'ARTIST', text: 'アーティスト' },
  POST: { value: 'POST', text: '投稿' },
  POST_COMMENT: { value: 'POST_COMMENT', text: '投稿コメント' },
  LIVE: { value: 'LIVE', text: 'ライブ配信' },
  LIVE_COMMENT: { value: 'LIVE_COMMENT', text: 'ライブ配信コメント' },
} as const
export type ReportTypeType = keyof typeof ReportType

export const ReportReason = {
  SLANDER: { value: 'SLANDER', text: '誹謗中傷' },
  MORALS: { value: 'MORALS', text: '公序良俗に反する内容' },
  OBSCENE: { value: 'OBSCENE', text: 'わいせつな内容' },
  THREATENING: { value: 'THREATENING', text: '攻撃的・脅迫' },
  SPAM: { value: 'SPAM', text: 'スパム・勧誘' },
  NUISANCE: { value: 'NUISANCE', text: '迷惑行為' },
  OTHER: { value: 'OTHER', text: 'その他' },
} as const
export type ReportReasonType = keyof typeof ReportReason

export const DeviceType = {
  ios: { value: 'ios' },
  android: { value: 'android' },
} as const
export type DeviceTypeType = keyof typeof DeviceType

export type FanIdGroupFanItem = { id: number; name: string }

export type ArtistDetail = {
  id: number
  revenueShareRate: number
  isNewcomer: boolean
  isFirstGachi: boolean
  fullName: string
  country: string
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string
  invoiceNumberStatus: InvoiceNumberStatusType
  invoiceNumberEnabled: InvoiceNumberEnabledType | null
  invoiceNumber: string
  bankName: string
  bankCode: string
  branchName: string
  branchCode: string
  bankAccountType: BankAccountTypeType | ''
  bankAccountNumber: string
  bankAccountHolder: string
  isEntryRestricted: boolean
  createdAt: string
  updatedAt: string
  organization: {
    id: number
    name: string
  }
  agency: FanDetailArtistAgency
  events: {
    id: number
    name: string
    artistRevenueShareRate: number
    agencyRevenueShareType: RevenueShareTypeType | null
    agencyRevenueShareRate: number | null
    createdAt: string
    updatedAt: string
  }[]
}
