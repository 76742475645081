import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box, Grid, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getArtistCoin, getArtistCoinPath } from '@/features/artists/api/getArtistCoin'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'コイン残数一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')
  const artistId = searchParams.get('artistId') || ''
  const artistName = searchParams.get('artistName') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      artistId: artistId,
      artistName: artistName,
    },
  })

  const { data, isValidating, mutate } = useSWR(getArtistCoinPath, () =>
    getArtistCoin({
      page: page,
      artistId: artistId || undefined,
      artistName: artistName || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'artistId', value: getValues('artistId') },
        { key: 'artistName', value: getValues('artistName') },
      ])
      return prev
    })
    mutate(
      getArtistCoin({
        page: page,
        artistId: getValues('artistId') || undefined,
        artistName: getValues('artistName') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: 1,
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      artistId: item.artistId,
      artistName: item.artistName,
      fanId: item.fanId,
      balance: item.balance,
    }
  })

  const goFanDetail = (fanId = '') => {
    navigate('/fans/:fanId', {
      params: {
        fanId: fanId,
      },
    })
  }

  const goHistory = (artistId = '') => {
    navigate({
      pathname: '/histories/coin-statements',
      search: `?artistId=${artistId}`,
    })
  }

  return (
    <Box className='page'>
      <PageTitle>{pageTitle}</PageTitle>
      <Box
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Grid container columnSpacing={3} mb={3}>
          <Grid item>
            <FormText
              control={control}
              name='artistId'
              label='アーティストID'
              fullWidth={false}
              hint={'カンマ区切りで複数指定可能'}
            />
          </Grid>
          <Grid item>
            <FormText
              control={control}
              name='artistName'
              label='アーティスト名'
              fullWidth={false}
              hint='部分一致'
            />
          </Grid>
          <Grid item>
            <ActionButton type='submit'>検索</ActionButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          columns={[
            {
              field: 'artistId',
              headerName: 'アーティストID',
              width: 120,
            },
            {
              field: 'artistName',
              headerName: 'アーティスト名',
              width: 250,
              renderCell: (params) => (
                <Link
                  onClick={() => goFanDetail(params.row.fanId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
            {
              field: 'balance',
              headerName: 'コイン残数',
              headerAlign: 'right',
              width: 200,
              renderCell: (params) => (
                <Link
                  onClick={() => goHistory(params.row.artistId)}
                  color='inherit'
                  underline='hover'
                >
                  {params.value}
                </Link>
              ),
            },
          ]}
          idPropertyName={'artistId'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
