import type { RevenueShareTypeType } from "@/types"

export const SortTarget = {
  RANK: { value: 'rank', text: 'ランク' },
} as const
export type SortTargetType = keyof typeof SortTarget

export const Sort = {
  ASC: { value: 'ASC', text: '昇順' },
  DESC: { value: 'DESC', text: '降順' },
} as const
export type SortType = keyof typeof Sort

export const ArtistHistoryGetType = {
  ALL: { value: 'ALL', text: 'すべて' },
  CURRENT: { value: 'CURRENT', text: '現在設定中の最新履歴のみ' },
  FUTURE_INCLUDE_CURRENT: {
    value: 'FUTURE_INCLUDE_CURRENT',
    text: '未来の設定予定を含む最新履歴のみ',
  },
} as const
export type ArtistHistoryGetTypeType = keyof typeof ArtistHistoryGetType

export type ArtistHistoryItemType = {
  id: number
  fanId: number
  artistId: number
  artistName: string
  revenueShareRate: number
  organizationId: number | null
  organizationName: string | null
  agencyId: number | null
  agencyName: string | null
  agencyRevenueShareType: {
    key: RevenueShareTypeType | null
    value: string | null
  }
  agencyRevenueShareRate: number | null
  startAt: string
  isEditable: boolean
  createdAt: string
  updatedAt: string
}

export type ArtistIdGroupArtistItem = {
  artistId: number
  fanId: number
  name: string
}