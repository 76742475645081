import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'

import MediaPlayer from '../MediaPlayer/MediaPlayer'

export default function MediaModal({
  isShow,
  onCallback,
  type,
  url,
  allowOrigin,
}: {
  isShow: boolean
  onCallback?: () => void
  type?: 'MOVIE' | 'AUDIO' | 'IMAGE'
  url: string
  allowOrigin: string
}) {
  const handleCallback = () => {
    if (onCallback) {
      onCallback()
    }
  }

  const close = () => {
    handleCallback()
  }

  return (
    <Dialog open={isShow}>
      <DialogContent>
        {type === 'IMAGE' ? (
          /* 画像 */
          <img src={url} alt='' width={'100%'} />
        ) : (
          /* 動画 */
          <MediaPlayer
            type={type}
            src={url}
            allowOrigin={allowOrigin}
          ></MediaPlayer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>閉じる</Button>
      </DialogActions>
    </Dialog>
  )
}
