import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type DeleteMailBounceParams = {
  email: string
}

export const deleteMailBouncePath = '/admin/v1/mail-bounce'

export const deleteMailBounce = async (
  params: DeleteMailBounceParams,
) => {
  return await http<APIResponse<null>>(deleteMailBouncePath, {
    method: 'DELETE',
    body: JSON.stringify({
      email: params.email,
    }),
  })
}
