import type {
  BankAccountTypeType,
} from '../types'
import type { APIResponse, InvoiceNumberStatusType, RevenueShareTypeType } from '@/types'

import { http } from '@/lib/http'

export type GetAgencyDetailParams = {
  id: number
}

export type GetAgencyDetailResponse = APIResponse<{
  id: number
  name: string
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string
  department: string
  person: string
  phoneNumber: string
  notificationEmail: string
  invoiceNumberStatus: InvoiceNumberStatusType
  invoiceNumber: string
  invoiceNumberEnabled: string
  bankName: string
  bankCode: string
  branchName: string
  branchCode: string
  bankAccountType: BankAccountTypeType
  bankAccountNumber: string
  bankAccountHolder: string
  defaultRevenueShareType: RevenueShareTypeType
  defaultRevenueShareRate: number
  createdAt: string
  updatedAt: string
}>

export const getAgencyDetailPath = '/admin/v1/agency/:id'

export const getAgencyDetail = async (params: GetAgencyDetailParams) => {
  return await http<GetAgencyDetailResponse>(getAgencyDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
