import type { FormEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { getNgWord, getNgWordPath } from '@/features/ng-word/api/getNgWord'
import { useNavigate } from '@/router'
import { setUrlSearchParams } from '@/utils/setUrlSearchParams'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const pageTitle = 'NGワード一覧'
  usePageInfo({
    title: pageTitle,
  })

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    word: '',
  })

  const page = Number(searchParams.get('page') || '1')
  const word = searchParams.get('word') || ''

  const { control, getValues } = useForm({
    defaultValues: {
      word: word,
    },
  })

  const { data, isValidating, mutate } = useSWR(getNgWordPath, () =>
    getNgWord({
      page: page,
      word: word || undefined,
    }),
  )

  // 検索
  const search = ({ page = 1 }) => {
    setSearchParams((prev) => {
      setUrlSearchParams(prev, [
        { key: 'page', value: String(page) },
        { key: 'word', value: getValues('word') || '' },
      ])
      return prev
    })
    mutate(
      getNgWord({
        page: page,
        word: getValues('word') || undefined,
      }),
    )
  }

  // ページ変更
  const handlePageChange = (value: number) => {
    search({ page: value })
  }

  // 検索フォーム入力
  const handleSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    search({
      page: Number(page),
    })
  }

  const goCreate = () => {
    navigate('/ng-words/create')
  }

  const goDetail = (id = '') => {
    navigate('/ng-words/:ngWordId', {
      params: {
        ngWordId: id,
      },
    })
  }

  const formatData = data?.result.data.map((item) => {
    return {
      id: item.id,
      word: item.word,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    }
  })

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '新規作成', onClick: goCreate }]}>
        {pageTitle}
      </PageTitle>
      <Box
        display={'flex'}
        gap={4}
        alignItems={'center'}
        onSubmit={(e) => {
          handleSearchSubmit(e)
        }}
        component='form'
      >
        <Box display={'flex'} gap={2} alignItems={'flex-start'}>
          <FormText
            control={control}
            fullWidth={false}
            name='word'
            label='NGワード'
            hint='部分一致'
          />
        </Box>
        <ActionButton type='submit'>検索</ActionButton>
      </Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'id', headerName: 'ID', width: 50 },
            {
              field: 'word',
              headerName: 'NGワード',
              width: 300,
            },
            {
              field: 'createdAt',
              headerName: '登録日時',
              width: 200,
            },
            {
              field: 'updatedAt',
              headerName: '更新日時',
              width: 200,
            },
          ]}
          idPropertyName={'id'}
          rows={formatData ?? []}
          total={data?.result.total ?? 0}
          lastPage={data?.result.lastPage ?? 0}
          page={data?.result.currentPage ?? 1}
          onPageChange={handlePageChange}
          isLoading={isValidating}
          onEditClick={goDetail}
          editReferProp={'id'}
        ></DataGrid>
      </Box>
    </Box>
  )
}

export default Page
