import { useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'
import { endOfHour, startOfHour } from 'date-fns'

import type { EventSubFormRef } from '@/features/event/components/EventSubForm'

import { postSubEvent } from '@/features/event/api/postSubEvent'
import EventSubForm from '@/features/event/components/EventSubForm'
import { Format, formatDateTime } from '@/lib/dateFns'
import { useNavigate } from '@/router'
import { EventType } from '@/types'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const pageTitle = 'サブイベ作成'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const EventSubFormRef = useRef<EventSubFormRef>(null)

  // 登録
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = EventSubFormRef.current!.getFormValues()
      // rewardsとdescriptionsは空のものを除外
      const rewardsParam = formValues.rewards.filter(
        (reward) => reward.line && reward.title,
      )
      const descriptionsParam = formValues.descriptions.filter(
        (description) => description.title && description.description,
      )
      await postSubEvent({
        eventId: formValues.eventId,
        name: formValues.name,
        type: EventType.YURU.value,
        eventStartAt: formatDateTime(
          startOfHour(formValues.eventStartAt),
          Format.dateTimeRequest,
        ),
        eventEndAt: formatDateTime(
          endOfHour(formValues.eventEndAt),
          Format.dateTimeRequest,
        ),
        cheerFactor: formValues.scoreLogicFactor.cheer,
        viewersCountFactor: formValues.scoreLogicFactor.viewersCount,
        durationFactor: formValues.scoreLogicFactor.viewDuration,
        postCountFactor: formValues.scoreLogicFactor.postCount,
        postUuFactor: formValues.scoreLogicFactor.postUu,
        rewards: rewardsParam.length > 0 ? rewardsParam : undefined,
        descriptions:
          descriptionsParam.length > 0 ? descriptionsParam : undefined,
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
    } finally {
      setIsLoading(false)
    }
  }

  // 遷移
  const goList = () => {
    navigate('/events/sub')
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <EventSubForm ref={EventSubFormRef}></EventSubForm>
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        登録する
      </ActionButton>
    </Box>
  )
}

export default Page
