import { useEffect, useRef, useState } from 'react'

import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import { PageTitle } from '@cz_frontend/ui/components/layouts'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { Box } from '@mui/material'

import type { ArtistPickupFormRef } from '@/features/artists/components/ArtistPickupForm';

import { deleteArtistPickup } from '@/features/artists/api/deleteArtistPickup'
import { getArtistPickupDetail } from '@/features/artists/api/getArtistPickupDetail'
import { patchArtistPickup } from '@/features/artists/api/patchArtistPickup'
import ArtistPickupForm from '@/features/artists/components/ArtistPickupForm'
import { Format, formatDateTime, newDate } from '@/lib/dateFns'
import { useNavigate, useParams } from '@/router'
import { pickErrorMessages } from '@/utils/functions'
import { usePageInfo } from '@/utils/usePageInfo'

function Page() {
  const navigate = useNavigate()
  const params = useParams('/artists/pick-ups/:pickupId')
  const pageTitle = 'アーティストピックアップ更新'
  const [isLoading, setIsLoading] = useState(false)

  usePageInfo({
    title: pageTitle,
  })

  const ArtistPickupFormRef = useRef<ArtistPickupFormRef>(null)

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getArtistPickupDetail({
        id: Number(params.pickupId),
      })
      ArtistPickupFormRef.current?.formReset({
        id: result.id,
        artistId: result.artist.id,
        name: result.fan.name,
        startAt: newDate(result.startAt),
        endAt: newDate(result.endAt),
        memo: result.memo || '',
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
      })
    }
    fetchData()
  }, [params])

  // 遷移
  const goList = () => {
    navigate('/artists/pick-ups')
  }

  // 更新
  const submit = async () => {
    try {
      setIsLoading(true)
      const formValues = ArtistPickupFormRef.current?.getFormValues()
      if (!formValues) {
        setIsLoading(false)
        return
      }

      await patchArtistPickup({
        id: Number(params.pickupId),
        startAt: formatDateTime(formValues.startAt, Format.dateTimeRequest),
        endAt: formatDateTime(formValues.endAt, Format.dateTimeRequest),
        memo: formValues.memo,
      })

      swal.toastSuccess()
      setIsLoading(false)
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  // 削除
  const deleteAction = async () => {
    try {
      const { isConfirmed } = await swal.confirm({
        title: '削除しますか？',
        text: '削除すると元に戻せません。',
      })

      if (!isConfirmed) return

      await deleteArtistPickup({
        id: Number(params.pickupId),
      })

      swal.toastSuccess()
      goList()
    } catch (error) {
      await swal.messages({ messages: pickErrorMessages(error) })
      setIsLoading(false)
    }
  }

  return (
    <Box className='page'>
      <PageTitle actions={[{ text: '一覧に戻る', onClick: goList }]}>
        {pageTitle}
      </PageTitle>
      <ArtistPickupForm ref={ArtistPickupFormRef} />
      <ActionButton
        size='large'
        isLoading={isLoading}
        fullWidth
        onClick={submit}
      >
        更新する
      </ActionButton>
      <Box my={10}>
        <ActionButton
          size='large'
          isLoading={isLoading}
          fullWidth
          color='error'
          variant='outlined'
          onClick={deleteAction}
        >
          削除する
        </ActionButton>
      </Box>
    </Box>
  )
}

export default Page
