export const RsCategory = {
  BASIC: { value: 'BASIC', text: '通常' },
  EVENT: { value: 'EVENT', text: 'イベント' },
} as const
export type RsCategoryType = keyof typeof RsCategory

export type SalesReportDetail = {
  id: number
  year: number
  month: number
  organizationId: number | null
  organizationName: string | null
  addRate: number | null
  agencyId: number | null
  agencyName: string | null
  totalSales: number
  paymentStatus: string
  errorReason: string | null
  details: {
    id: number
    fanId: number
    iconUrl: string
    artistId: number
    artistName: string
    salesAmount: number
  }[]
  createdAt: string
  updatedAt: string
}

export type PaymentReportDetail = {
  id: number
  year: number
  month: number
  status: PaymentReportStatusType
  target: {
    type: PaymentTargetTypeType | null
    id: number
    name: string
  }
  issueDate: string | null
  paymentDate: string | null
  netAmount: number
  amount: number
  amountTax: number
  withholdingTax: number
  transferFee: number
  transferFeeTax: number
  name: string
  email: string
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string | null
  bankName: string
  bankCode: string
  branchName: string
  branchCode: string
  bankAccountType: PaymentBankAccountTypeType
  bankAccountNumber: string
  bankAccountHolder: string
  invoiceNumber: string | null
  issuerName: string
  issuerZipCode: string
  issuerProvince: string
  issuerCity: string
  issuerAddress1: string
  issuerAddress2: string | null
  issuerInvoiceNumber: string
  details: {
    id: number
    description: string
    amount: number
  }[]
  createdAt: string
  updatedAt: string
}

export type PaymentTarget = {
  type: PaymentTargetTypeType
  id: number
  fanId: number | null
  name: string
}

export const PaymentReportStatus = {
  PROCESSING: { value: 'PROCESSING', text: '処理中', color: 'info' },
  TRANSFER_SUCCESS: {
    value: 'TRANSFER_SUCCESS',
    text: '成功',
    color: 'success',
  },
  TRANSFER_FAILURE: {
    value: 'TRANSFER_FAILURE',
    text: '振込失敗',
    color: 'error',
  },
  ERROR: { value: 'ERROR', text: 'エラー', color: 'error' },
} as const
export type PaymentReportStatusType = keyof typeof PaymentReportStatus

export const PaymentTargetType = {
  ORGANIZATION: { value: 'ORGANIZATION', text: '事務所' },
  AGENCY: { value: 'AGENCY', text: '代理店' },
  ARTIST: { value: 'ARTIST', text: 'アーティスト' },
} as const
export type PaymentTargetTypeType = keyof typeof PaymentTargetType

export const PaymentBankAccountType = {
  SAVING: { value: 'SAVING', text: '普通' },
  CHECKING: { value: 'CHECKING', text: '当座' },
} as const
export type PaymentBankAccountTypeType = keyof typeof PaymentBankAccountType
