// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/agencies`
  | `/agencies/:agencyId`
  | `/agencies/artists`
  | `/agencies/create`
  | `/agencies/join`
  | `/artist-id-groups`
  | `/artist-id-groups/:artistGroupId`
  | `/artist-id-groups/create`
  | `/artists/:artistId/ranking/fans/daily`
  | `/artists/:artistId/ranking/fans/monthly`
  | `/artists/fan-ranks`
  | `/artists/fan-ranks/histories`
  | `/artists/pick-ups`
  | `/artists/pick-ups/:pickupId`
  | `/artists/pick-ups/create`
  | `/artists/revenue-share`
  | `/artists/revenue-share/:revenueShareId`
  | `/artists/revenue-share/create`
  | `/artists/revenue-share/events`
  | `/auth/saml`
  | `/auth/signin`
  | `/bans/account`
  | `/bans/account/create`
  | `/bans/device`
  | `/bans/device/create`
  | `/coin-statements`
  | `/events/:eventId/entries`
  | `/events/:eventId/entries/:entryId`
  | `/events/:eventId/entries/create`
  | `/events/:eventId/entries/csv-upload`
  | `/events/:eventId/ranking/artists`
  | `/events/:eventId/ranking/artists/:artistId/fans`
  | `/events/loose`
  | `/events/loose/:eventId`
  | `/events/loose/copy/:originEventId`
  | `/events/loose/create`
  | `/events/serious`
  | `/events/serious/:eventId`
  | `/events/serious/copy/:originEventId`
  | `/events/serious/create`
  | `/events/sub`
  | `/events/sub/:eventId`
  | `/events/sub/copy/:originEventId`
  | `/events/sub/create`
  | `/fan-id-groups`
  | `/fan-id-groups/:fanGroupId`
  | `/fan-id-groups/create`
  | `/fans`
  | `/fans/:fanId`
  | `/fans/blocked`
  | `/fans/device`
  | `/fans/follows`
  | `/fans/login-history`
  | `/fans/muted`
  | `/fans/reported`
  | `/faqs`
  | `/faqs/:faqId`
  | `/faqs/categories`
  | `/faqs/categories/:faqCategoryId`
  | `/faqs/categories/create`
  | `/faqs/create`
  | `/histories/cheer/collect`
  | `/histories/cheer/use`
  | `/histories/coin-statements`
  | `/histories/mission`
  | `/histories/payment`
  | `/histories/payment/:paymentId`
  | `/histories/payment/log`
  | `/histories/payment/log/:logId`
  | `/histories/point/purchase`
  | `/histories/point/use`
  | `/histories/point/use/:id`
  | `/lives`
  | `/lives/:liveId`
  | `/lives/:liveId/comments`
  | `/mail-bounces`
  | `/message-boxes/admin`
  | `/message-boxes/admin/:messageId`
  | `/message-boxes/admin/create`
  | `/message-boxes/artist`
  | `/message-boxes/artist/:messageId`
  | `/message-boxes/inquiry`
  | `/message-boxes/inquiry/:messageBoxId`
  | `/ng-words`
  | `/ng-words/:ngWordId`
  | `/ng-words/create`
  | `/organizations`
  | `/organizations/:organizationId`
  | `/organizations/accounts`
  | `/organizations/accounts/:companyAccountId`
  | `/organizations/accounts/create`
  | `/organizations/artists`
  | `/organizations/create`
  | `/organizations/join`
  | `/posts`
  | `/posts/:postId`
  | `/posts/:postId/comments`
  | `/purchase-menus`
  | `/purchase-menus/:menuId`
  | `/purchase-menus/create`
  | `/rankings/artists/daily`
  | `/rankings/artists/monthly`
  | `/reports/artists/daily`
  | `/reports/artists/monthly`
  | `/reports/coin-cheer`
  | `/reports/events/daily`
  | `/reports/events/total`
  | `/reports/events/total-artist`
  | `/reports/payment-report`
  | `/reports/payment-report/:reportId`
  | `/reports/payment-report/csv-upload`
  | `/reports/points/deposit`
  | `/reports/points/point-unit-price`
  | `/reports/sales-report`
  | `/reports/sales-report/:reportId`
  | `/reports/service-kpi/daily`
  | `/reports/service-kpi/monthly`
  | `/revenue-share-logs`
  | `/revenue-share-logs/event`
  | `/settings/admin`
  | `/settings/admin/:adminId`
  | `/terms`
  | `/terms/:termId`
  | `/terms/create`

export type Params = {
  '/agencies/:agencyId': { agencyId: string }
  '/artist-id-groups/:artistGroupId': { artistGroupId: string }
  '/artists/:artistId/ranking/fans/daily': { artistId: string }
  '/artists/:artistId/ranking/fans/monthly': { artistId: string }
  '/artists/pick-ups/:pickupId': { pickupId: string }
  '/artists/revenue-share/:revenueShareId': { revenueShareId: string }
  '/events/:eventId/entries': { eventId: string }
  '/events/:eventId/entries/:entryId': { eventId: string; entryId: string }
  '/events/:eventId/entries/create': { eventId: string }
  '/events/:eventId/entries/csv-upload': { eventId: string }
  '/events/:eventId/ranking/artists': { eventId: string }
  '/events/:eventId/ranking/artists/:artistId/fans': { eventId: string; artistId: string }
  '/events/loose/:eventId': { eventId: string }
  '/events/loose/copy/:originEventId': { originEventId: string }
  '/events/serious/:eventId': { eventId: string }
  '/events/serious/copy/:originEventId': { originEventId: string }
  '/events/sub/:eventId': { eventId: string }
  '/events/sub/copy/:originEventId': { originEventId: string }
  '/fan-id-groups/:fanGroupId': { fanGroupId: string }
  '/fans/:fanId': { fanId: string }
  '/faqs/:faqId': { faqId: string }
  '/faqs/categories/:faqCategoryId': { faqCategoryId: string }
  '/histories/payment/:paymentId': { paymentId: string }
  '/histories/payment/log/:logId': { logId: string }
  '/histories/point/use/:id': { id: string }
  '/lives/:liveId': { liveId: string }
  '/lives/:liveId/comments': { liveId: string }
  '/message-boxes/admin/:messageId': { messageId: string }
  '/message-boxes/artist/:messageId': { messageId: string }
  '/message-boxes/inquiry/:messageBoxId': { messageBoxId: string }
  '/ng-words/:ngWordId': { ngWordId: string }
  '/organizations/:organizationId': { organizationId: string }
  '/organizations/accounts/:companyAccountId': { companyAccountId: string }
  '/posts/:postId': { postId: string }
  '/posts/:postId/comments': { postId: string }
  '/purchase-menus/:menuId': { menuId: string }
  '/reports/payment-report/:reportId': { reportId: string }
  '/reports/sales-report/:reportId': { reportId: string }
  '/settings/admin/:adminId': { adminId: string }
  '/terms/:termId': { termId: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
