import React from 'react'

import {
  FormControl,
  FormLabel,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  label?: string
  options: { value: string | number; text: React.ReactNode }[]
  placeholder?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  sx?: React.CSSProperties
  isRow?: boolean
  fullWidth?: boolean
} & UseControllerProps<T>

function FormCheckbox<T extends FieldValues>({
  name,
  options,
  label,
  required = false,
  disabled = false,
  control,
  sx,
  isRow,
  fullWidth = true,
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl fullWidth={fullWidth} variant='outlined' sx={sx}>
          <FormLabel required={required}>{label}</FormLabel>
          <FormGroup ref={field.ref} row={isRow}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        field.onChange([...field.value, option.value])
                      } else {
                        field.onChange(
                          field.value.filter(
                            (v: string | number) => v !== option.value,
                          ),
                        )
                      }
                    }}
                  />
                }
                label={option.text}
                disabled={disabled}
              />
            ))}
          </FormGroup>
          {/* エラー */}
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]!.message)}</small>
            </Typography>
          )}
        </FormControl>
      )}
    />
  )
}

export default FormCheckbox
