import React from 'react'

import { Delete } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  OutlinedInput,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import type { EventTypeType } from '@/types';
import type { FieldValues, UseControllerProps } from 'react-hook-form'

import { EventType } from '@/types'

type Props<T extends FieldValues> = {
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  prefix?: React.ReactNode
  size?: 'small' | 'medium'
  eventType: EventTypeType
} & UseControllerProps<T>

export type REWARD_INPUT_TYPE = {
  line: number
  title: string
}

function EventRewardForm<T extends FieldValues>({
  name,
  label,
  required = false,
  disabled = false,
  control,
  eventType,
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <Box mb={2} width={'100%'}>
          <FormLabel required={required}>{label}</FormLabel>
          <FormGroup ref={field.ref}>
            {field.value.map((v: REWARD_INPUT_TYPE, k: string) => (
              <Box
                key={k}
                display={'flex'}
                alignItems={'center'}
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                mb={2}
                gap={1}
                width={'100%'}
              >
                <Box>
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <OutlinedInput
                      fullWidth
                      value={v.line}
                      disabled={disabled}
                      onChange={(e) => {
                        field.value[k].line = e.target.value
                        field.onChange(field.value)
                      }}
                      type='number'
                    />
                    <Typography>
                      {eventType === EventType.GACHI.value
                        ? '位以上'
                        : 'スコア以上'}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: '100%' }} mx={3}>
                  <OutlinedInput
                    fullWidth
                    value={v.title}
                    disabled={disabled}
                    onChange={(e) => {
                      field.value[k].title = e.target.value
                      field.onChange(field.value)
                    }}
                    placeholder='特典タイトルを入力（20文字以内）'
                  />
                </Box>
                <Box>
                  <Button
                    type='button'
                    onClick={() => {
                      field.value.splice(k, 1)
                      field.onChange(field.value)
                    }}
                  >
                    <Delete></Delete>
                  </Button>
                </Box>
              </Box>
            ))}
          </FormGroup>
          <Box>
            <Button
              type='button'
              onClick={() => {
                field.value.push({ line: 0, title: '' })
                field.onChange(field.value)
              }}
            >
              <AddIcon />
              <Typography variant='body1'>特典を追加</Typography>
            </Button>
          </Box>
          {/* エラー */}
          {errors[name] && (
            <Typography variant='body1' color='error'>
              <small>{String(errors[name]!.message)}</small>
            </Typography>
          )}
        </Box>
      )}
    />
  )
}

export default EventRewardForm
