import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostMessageBoxFileUploadUrlParams = {
  messageBoxId: string
  fanId: string
  sort: number
  replyCount: number
  fileName: string
}

export type PostMessageBoxFileUploadUrlResponse = APIResponse<{
  fileUrl: string
}>

export const postMessageBoxReplyPath =
  '/admin/v1/message-box/:messageBoxId/:fanId/upload-url'

export const postMessageBoxFileUploadUrl = async (
  params: PostMessageBoxFileUploadUrlParams,
) => {
  return await http<PostMessageBoxFileUploadUrlResponse>(
    postMessageBoxReplyPath,
    {
      method: 'POST',
      params: {
        messageBoxId: params.messageBoxId,
        fanId: params.fanId,
      },
      body: JSON.stringify({
        sort: params.sort,
        reply_count: params.replyCount,
        file_name: params.fileName,
      }),
    },
  )
}
