import type { FormEvent} from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import DataModal from '@cz_frontend/ui/components/common/DataModal/DataModal'
import { DataGrid } from '@cz_frontend/ui/components/data'
import ActionButton from '@cz_frontend/ui/components/forms/ActionButton/ActionButton'
import FormRadio from '@cz_frontend/ui/components/forms/FormRadio/FormRadio'
import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { swal } from '@cz_frontend/ui/lib/sweetalert'
import { ArrowDropUp, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Box, FormLabel, Grid, IconButton, OutlinedInput, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'



import type { BankAccountTypeType} from '@/types';

import { banksHttp } from '@/lib/bankCodeClient/banksHttp'
import { branchesHttp } from '@/lib/bankCodeClient/branchesHttp'
import { BankAccountType, type BankCodeClientBanksType, type BankCodeClientBranchesType } from '@/types'


type BankFormValues = {
  bankName: string
  bankCode: string
  branchName: string
  branchCode: string
  bankAccountType: BankAccountTypeType | ''
  bankAccountNumber: string
  bankAccountHolder: string
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface BankFormProps {}
export interface BankFormRef {
  getFormValues(): BankFormValues
  setFormValue(key: keyof BankFormValues, value: string): void
  formReset(values: BankFormValues): void
}

const BankForm = forwardRef<BankFormRef, BankFormProps>(
  (_, ref) => {
    const { control, getValues, setValue, reset } = useForm<BankFormValues>({
      defaultValues: {
        bankName: '',
        bankCode: '',
        branchName: '',
        branchCode: '',
        bankAccountType: '',
        bankAccountNumber: '',
        bankAccountHolder: '',
      },
    })

    // 親から呼べる関数を公開
    useImperativeHandle(ref, () => ({
      getFormValues: getValues,
      setFormValue: (key, value) => {
        setValue(key, value)
      },
      formReset: (values) => {
        reset(values)
      },
    }))

    const [isLoading, setIsLoading] = useState(false)

    /** 金融機関 */
    const [isShowBankModal, setIsShowBankModal] = useState(false)
    const [inputBankName, setInputBankName] = useState('')
    const [bankList, setBankList] = useState<BankCodeClientBanksType[]>([])
    const [bankHasNext, setBankHasNext] = useState(false)
    const [bankNextCursor, setBankNextCursor] = useState<string | undefined>(
      undefined,
    )
    const [bankHasPrev, setBankHasPrev] = useState(false)
    const [bankPrevCursor, setBankPrevCursor] = useState<string | undefined>(
      undefined,
    )

    const openBankModal = () => {
      setIsShowBankModal(true)
    }

    const closeBankModal = () => {
      setIsShowBankModal(false)
    }

    const fetchBanks = async (cursor?: string) => {
      try {
        setIsLoading(true)

        const res = await banksHttp(inputBankName, cursor)
        setBankList(res.banks)
        setBankHasNext(res.hasNext)
        setBankNextCursor(res.nextCursor ? res.nextCursor : undefined)
        setBankHasPrev(res.hasPrev)
        setBankPrevCursor(res.prevCursor ? res.prevCursor : undefined)

        // "API rate limit exceeded" になるため、1秒後にローディングを解除
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      } catch (error) {
        closeBankModal()
        setIsLoading(false)
        await swal.messages({ messages: [String((error as Error).message)] })
      }
    }

    const bankSubmit = async (e: FormEvent) => {
      e.preventDefault()
      fetchBanks()
    }

    const handleBankNameClick = async (name: string, code: string) => {
      setValue('bankName', name)
      setValue('bankCode', code)

      // 銀行名を選んだ時点で他の項目はリセット
      setValue('branchName', '')
      setValue('branchCode', '')
      setValue('bankAccountType', '')
      setValue('bankAccountNumber', '')
      setValue('bankAccountHolder', '')
      setInputBranchName('')

      fetchBranches(code)

      closeBankModal()
    }

    const handleBankPagination = async (cursor: 'prev' | 'next') => {
      const cursorValue = cursor === 'prev' ? bankPrevCursor : bankNextCursor
      fetchBanks(cursorValue)
    }

    /** 支店 */
    const [isShowBranchModal, setIsShowBranchModal] = useState(false)
    const [inputBranchName, setInputBranchName] = useState('')
    const [branchList, setBranchList] = useState<BankCodeClientBranchesType[]>(
      [],
    )
    const [branchHasNext, setBranchHasNext] = useState(false)
    const [branchNextCursor, setBranchNextCursor] = useState<
      string | undefined
    >(undefined)
    const [branchHasPrev, setBranchHasPrev] = useState(false)
    const [branchPrevCursor, setBranchPrevCursor] = useState<
      string | undefined
    >(undefined)

    const openBranchModal = () => {
      setIsShowBranchModal(true)
    }

    const closeBranchModal = () => {
      setIsShowBranchModal(false)
    }

    const fetchBranches = async (bankCode: string, cursor?: string) => {
      try {
        setIsLoading(true)

        const res = await branchesHttp(bankCode, inputBranchName, cursor)
        setBranchList(res.branches)
        setBranchHasNext(res.hasNext)
        setBranchNextCursor(res.nextCursor ? res.nextCursor : undefined)
        setBranchHasPrev(res.hasPrev)
        setBranchPrevCursor(res.prevCursor ? res.prevCursor : undefined)

        // "API rate limit exceeded" になるため、1秒後にローディングを解除
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      } catch (error) {
        closeBranchModal()
        setIsLoading(false)
        await swal.messages({ messages: [String((error as Error).message)] })
      }
    }

    const branchSubmit = async (e: FormEvent) => {
      e.preventDefault()
      fetchBranches(getValues('bankCode'))
    }

    const handleBranchNameClick = async (name: string, code: string) => {
      setValue('branchName', name)
      setValue('branchCode', code)
      closeBranchModal()
    }

    const handleBranchPagination = async (cursor: 'prev' | 'next') => {
      const cursorValue =
        cursor === 'prev' ? branchPrevCursor : branchNextCursor
      fetchBranches(getValues('bankCode'), cursorValue)
    }

    /** 他の項目は金融機関、支店が入力されていたら入力可能 */
    const [isAccountFieldsDisabled, setAccountFieldsDisabled] = useState(true)

    const watchBankName = useWatch({ control, name: 'bankName' })
    const watchBankCode = useWatch({ control, name: 'bankCode' })
    const watchBranchCode = useWatch({ control, name: 'branchCode' })
    const watchBranchName = useWatch({ control, name: 'branchName' })

    useEffect(() => {
      if (
        watchBankName &&
        watchBankCode &&
        watchBranchCode &&
        watchBranchName
      ) {
        setAccountFieldsDisabled(false)
      } else {
        setAccountFieldsDisabled(true)
      }
    }, [watchBankName, watchBankCode, watchBranchCode, watchBranchName])

    return (
      <>
        <FormSectionContainer sectionTitle='口座情報'>
          <Box mb={2}>
            <ActionButton
              size='large'
              variant='outlined'
              fullWidth
              onClick={openBankModal}
            >
              銀行名を検索する
            </ActionButton>
          </Box>

          <FormText
            control={control}
            name='bankName'
            label='銀行名'
            placeholder='銀行名を検索してください'
            disabled
          />

          <FormText
            control={control}
            name='bankCode'
            label='銀行コード'
            hint='4桁の番号'
            placeholder='銀行名を選択してください'
            disabled
          />

          <Box my={5}>
            <Box mb={2}>
              <ActionButton
                size='large'
                variant='outlined'
                fullWidth
                onClick={openBranchModal}
              >
                支店名を選択する
              </ActionButton>
            </Box>

            <FormText
              control={control}
              name='branchName'
              label='支店名'
              placeholder='支店名を選択してください'
              disabled
            />

            <FormText
              control={control}
              name='branchCode'
              label='支店コード'
              hint='3桁の番号'
              placeholder='支店名を選択してください'
              disabled
            />
          </Box>

          <FormRadio
            label='預金種別'
            control={control}
            name='bankAccountType'
            options={Object.entries(BankAccountType).map(([, item]) => ({
              value: item.value,
              text: item.text,
            }))}
            disabled={isAccountFieldsDisabled}
          />

          <FormText
            control={control}
            name='bankAccountNumber'
            label='口座番号'
            hint='4桁から7桁の間で入力してください'
            placeholder={
              isAccountFieldsDisabled
                ? '銀行と支店を先に選択してください'
                : ''
            }
            disabled={isAccountFieldsDisabled}
          />

          <FormText
            control={control}
            name='bankAccountHolder'
            label='口座名義'
            hint='半角カタカナ (例)ﾌｫｯｸﾞ'
            placeholder={
              isAccountFieldsDisabled
                ? '銀行と支店を先に選択してください'
                : ''
            }
            disabled={isAccountFieldsDisabled}
          />

          <Box mt={5}>
            <ActionButton
              size='large'
              variant='outlined'
              color='error'
              fullWidth
              onClick={reset}
            >
              <ArrowDropUp />
              口座情報をリセット
            </ActionButton>
          </Box>
        </FormSectionContainer>

        {/* 銀行名モーダル */}
        <DataModal
          title='銀行名の検索'
          isShow={isShowBankModal}
          onCallback={closeBankModal}
        >
          <Grid container spacing={4} p={5}>
            <Grid item md={10}>
              <Box
                component='form'
                onSubmit={(e) => bankSubmit(e)}
                display={'flex'}
                alignItems={'center'}
                gap={2}
                mb={2}
              >
                <Box minWidth={250}>
                  <FormLabel>銀行名</FormLabel>
                  <OutlinedInput
                    fullWidth
                    value={inputBankName}
                    onChange={(e) => setInputBankName(e.target.value)}
                  />
                </Box>
                <ActionButton type='submit' isLoading={isLoading} fullWidth>
                  検索
                </ActionButton>
              </Box>
              <Box
                display={'flex'}
                minWidth={'100%'}
                justifyContent={'space-between'}
                my={2}
              >
                <IconButton
                  sx={{
                    cursor: 'pointer',
                  }}
                  disabled={isLoading || !bankHasPrev}
                  onClick={() => handleBankPagination('prev')}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  sx={{
                    cursor: 'pointer',
                  }}
                  disabled={isLoading || !bankHasNext}
                  onClick={() => handleBankPagination('next')}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Box>
              <DataGrid
                columns={[
                  {
                    field: 'name',
                    headerName: '銀行名',
                    width: 200,
                    renderCell: (params) => (
                      <Typography
                        onClick={() =>
                          handleBankNameClick(params.value, params.row.code)
                        }
                        sx={{
                          cursor: 'pointer',
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                ]}
                idPropertyName={'code'}
                rows={bankList ?? []}
              ></DataGrid>
            </Grid>
          </Grid>
        </DataModal>

        {/* 支店名モーダル */}
        <DataModal
          title='支店名の選択'
          isShow={isShowBranchModal}
          onCallback={closeBranchModal}
        >
          <Grid container spacing={4} p={5}>
            <Grid item md={10}>
              {bankList.length === 0 ? (
                <Typography>先に銀行名を検索してください</Typography>
              ) : (
                <>
                  <Box
                    component='form'
                    onSubmit={(e) => branchSubmit(e)}
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                    mb={2}
                  >
                    <Box minWidth={250}>
                      <FormLabel>支店名</FormLabel>
                      <OutlinedInput
                        fullWidth
                        value={inputBranchName}
                        onChange={(e) => setInputBranchName(e.target.value)}
                      />
                    </Box>
                    <ActionButton type='submit' isLoading={isLoading} fullWidth>
                      検索
                    </ActionButton>
                  </Box>
                  <Box
                    display={'flex'}
                    minWidth={'100%'}
                    justifyContent={'space-between'}
                    my={2}
                  >
                    <IconButton
                      sx={{
                        cursor: 'pointer',
                      }}
                      disabled={isLoading || !branchHasPrev}
                      onClick={() => handleBranchPagination('prev')}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      sx={{
                        cursor: 'pointer',
                      }}
                      disabled={isLoading || !branchHasNext}
                      onClick={() => handleBranchPagination('next')}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                  <DataGrid
                    columns={[
                      {
                        field: 'name',
                        headerName: '支店名',
                        width: 200,
                        renderCell: (params) => (
                          <Typography
                            onClick={() =>
                              handleBranchNameClick(
                                params.value,
                                params.row.code,
                              )
                            }
                            sx={{
                              cursor: 'pointer',
                              width: '100%',
                              display: 'block',
                            }}
                          >
                            {params.value}
                          </Typography>
                        ),
                      },
                    ]}
                    idPropertyName={'code'}
                    rows={branchList ?? []}
                  ></DataGrid>
                </>
              )}
            </Grid>
          </Grid>
        </DataModal>
      </>
    )
  },
)

export default BankForm
