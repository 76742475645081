import { httpRaw } from '@/lib/httpRaw'

export type GetEventRankingArtistDownloadCsvParams = {
  eventDetailId: number
}

export const getEventRankingArtistDownloadCsvPath =
  '/admin/v1/ranking/event/:eventDetailId/download-csv'

export const getEventRankingArtistDownloadCsv = async (
  params: GetEventRankingArtistDownloadCsvParams,
) => {
  return await httpRaw(getEventRankingArtistDownloadCsvPath, {
    method: 'GET',
    params: {
      eventDetailId: params.eventDetailId,
    },
  })
}
