import { FormControl, FormLabel, Typography } from "@mui/material";
import { useController } from "react-hook-form";
// TODO: react-quillは非推奨のため代替案を検討する
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import type { FieldValues, UseControllerProps } from "react-hook-form";

type Props<T extends FieldValues> = {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  prefix?: React.ReactNode;
} & UseControllerProps<T>;

function FormHtml<T extends FieldValues>({
  name,
  label,
  required = false,
  disabled = false,
  control,
}: Props<T>) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <FormControl fullWidth variant="outlined">
      <FormLabel required={required}>{label}</FormLabel>
      <ReactQuill
        onChange={field.onChange}
        value={field.value}
        readOnly={disabled}
      />
      {/* エラー */}
      {fieldState.error && (
        <Typography variant="body1" color="error">
          <small>{String(fieldState.error.message)}</small>
        </Typography>
      )}
    </FormControl>
  );
}

export default FormHtml;
