import type { FanIdGroupFanItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetFanIdGroupDetailParams = {
  id: number
}

export type GetFanIdGroupDetailResponse = APIResponse<{
  id: number
  name: string
  fanCount: number
  fans: FanIdGroupFanItem[]
  createdAt: string
  updatedAt: string
}>

export const getFanIdGroupDetailPath = '/admin/v1/fan-id-group/:id'

export const getFanIdGroupDetail = async (
  params: GetFanIdGroupDetailParams,
) => {
  return await http<GetFanIdGroupDetailResponse>(getFanIdGroupDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
