import { http } from '@/lib/http'

export type PatchEventRankingArtistCorrectionValueParams = {
  eventDetailId: number
  artistId: number
  correctionValue: number
}

export const patchEventRankingArtistCorrectionValuePath =
  '/admin/v1/ranking/event/:eventDetailId/correction-value'

export const patchEventRankingArtistCorrectionValue = async (
  params: PatchEventRankingArtistCorrectionValueParams,
) => {
  return await http<null>(patchEventRankingArtistCorrectionValuePath, {
    method: 'PUT',
    params: {
      eventDetailId: params.eventDetailId,
    },
    body: JSON.stringify({
      artist_id: params.artistId,
      correction_value: params.correctionValue,
    }),
  })
}
