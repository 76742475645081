import { forwardRef, useImperativeHandle, useState } from 'react'

import FormSectionContainer from '@cz_frontend/ui/components/forms/FormSectionContainer/FormSectionContainer'
import FormSelect from '@cz_frontend/ui/components/forms/FormSelect/FormSelect'
import FormText from '@cz_frontend/ui/components/forms/FormText/FormText'
import { useForm } from 'react-hook-form'

import {
  PrefectureList,
} from '../types'

import type { InvoiceNumberStatusType, RevenueShareTypeType } from '@/types';

import { InvoiceNumberStatus, RevenueShareType } from '@/types'

type AgencyFormValues = {
  name: string
  zipCode: string
  province: string
  city: string
  address1: string
  address2: string
  department: string
  person: string
  phoneNumber: string
  notificationEmail: string
  invoiceNumberStatus: InvoiceNumberStatusType
  invoiceNumber: string
  defaultRevenueShareType: RevenueShareTypeType
  defaultRevenueShareRate: number
}

// biome-ignore lint/suspicious/noEmptyInterface: <explanation>
interface AgencyFormProps {}
export interface AgencyFormRef {
  getFormValues(): AgencyFormValues
  setFormValue(key: keyof AgencyFormValues, value: string): void
  formReset(values: AgencyFormValues): void
}

const AgencyForm = forwardRef<AgencyFormRef, AgencyFormProps>((_, ref) => {
  const { control, getValues, setValue, reset } = useForm<AgencyFormValues>({
    defaultValues: {
      name: '',
      zipCode: '',
      province: '',
      city: '',
      address1: '',
      address2: '',
      department: '',
      person: '',
      phoneNumber: '',
      notificationEmail: '',
      invoiceNumberStatus: InvoiceNumberStatus.UNSET.value,
      invoiceNumber: '',
      defaultRevenueShareType: RevenueShareType.ADD.value,
      defaultRevenueShareRate: 0,
    },
  })

  const [isInputInvoice, setIsInputInvoice] = useState(false)

  // 親から呼べる関数を公開
  useImperativeHandle(ref, () => ({
    getFormValues: getValues,
    setFormValue: (key, value) => {
      setValue(key, value)
    },
    formReset: (values) => {
      reset(values)
      if (
        values['invoiceNumberStatus'] === InvoiceNumberStatus.REGISTERED.value
      ) {
        setIsInputInvoice(true)
      }
    },
  }))

  return (
    <>
      <FormSectionContainer sectionTitle='基本情報'>
        <FormText
          control={control}
          name='name'
          label='名前'
          required
          hint='100文字以内'
        />
        <FormText
          control={control}
          name='zipCode'
          label='郵便番号'
          hint='ハイフンなし'
        />
        <FormSelect
          control={control}
          fullWidth={false}
          name='province'
          label='都道府県'
          options={Object.entries(PrefectureList).map(([, value]) => ({
            value: value.value,
            text: value.text,
          }))}
        />
        <FormText control={control} name='city' label='市町村' />
        <FormText control={control} name='address1' label='住所1' />
        <FormText control={control} name='address2' label='住所2' />
        <FormText control={control} name='department' label='部署' />
        <FormText control={control} name='person' label='担当者名' />
        <FormText
          control={control}
          name='phoneNumber'
          label='連絡先電話番号'
          hint='ハイフンなし / 10桁から11桁の間で入力してください'
        />
        <FormText
          control={control}
          name='notificationEmail'
          label='通知メールアドレス'
        />
        <FormSelect
          control={control}
          fullWidth={false}
          name='invoiceNumberStatus'
          label='インボイス登録番号ステータス'
          options={Object.entries(InvoiceNumberStatus).map(([, value]) => ({
            value: value.value,
            text: value.text,
          }))}
          onChange={() => {
            if (
              getValues('invoiceNumberStatus') ===
              InvoiceNumberStatus.REGISTERED.value
            ) {
              setIsInputInvoice(true)
            } else {
              setIsInputInvoice(false)
            }
          }}
          required
        />
        <FormText
          control={control}
          name='invoiceNumber'
          label='インボイス登録番号'
          hint='T + 13桁の番号 / インボイス登録番号ステータスで未設定・登録しない場合は値がクリアされます。'
          disabled={!isInputInvoice}
        />
      </FormSectionContainer>
      <FormSectionContainer sectionTitle='RS'>
        <FormSelect
          control={control}
          fullWidth={false}
          name='defaultRevenueShareType'
          label='基本RSタイプ'
          options={Object.entries(RevenueShareType).map(([, value]) => ({
            value: value.value,
            text: value.text,
          }))}
          required
        />
        <FormText
          control={control}
          name='defaultRevenueShareRate'
          label='基本RS率(%)'
          hint='0 ~ 100の範囲で入力してください'
        />
      </FormSectionContainer>
    </>
  )
})

export default AgencyForm
