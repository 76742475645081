import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PostMessageBoxReplyParams = {
  messageBoxId: string
  fanId: string
  body: string
}

export type PostMessageBoxReplyResponse = APIResponse<{
  replyCount: number
}>

export const postMessageBoxReplyPath =
  '/admin/v1/message-box/:messageBoxId/:fanId/reply'

export const postMessageBoxReply = async (
  params: PostMessageBoxReplyParams,
) => {
  return await http<PostMessageBoxReplyResponse>(postMessageBoxReplyPath, {
    method: 'POST',
    params: {
      messageBoxId: params.messageBoxId,
      fanId: params.fanId,
    },
    body: JSON.stringify({
      body: params.body,
    }),
  })
}
