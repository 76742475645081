import type { SortTargetType, SortType } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetFanRankParams = {
  page: number
  fanId?: string
  fanName?: string
  artistId?: string
  artistName?: string
  sortTarget?: SortTargetType
  sort?: SortType
}

export type GetFanRankResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    artistId: number
    artistName: string
    fanId: number
    fanName: string
    rank: string
    cheerCountA: number
    cheerCountB: number
    watchingDuration: number
    monthlyCheerCount: number
    rankUpdatedAt: string
    createdAt: string
    updatedAt: string
  }>
>

export const getFanRankPath = '/admin/v1/fan-rank'

export const getFanRank = async (params: GetFanRankParams) => {
  return await http<GetFanRankResponse>(getFanRankPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      fan_id: params.fanId,
      fan_name: params.fanName,
      artist_id: params.artistId,
      artist_name: params.artistName,
      sort_target: params.sortTarget,
      sort: params.sort,
    },
  })
}
