import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetRankingArtistDailyParams = {
  page: number
  date? : string
}

export type GetRankingArtistDailyResponse = APIResponse<
  APIResponseResultPagination<{
    rank: number
    score: number
    cheerCount: number
    watchingDuration: number
    postCount: number
    postCheerFanCount: number
    artist: {
      id: number
      fanId: number
      name: string
      iconUrl: string
    }
  }>
>

export const getRankingArtistDailyPath = '/admin/v1/ranking/daily'

export const getRankingArtistDaily = async (params: GetRankingArtistDailyParams) => {
  return await http<GetRankingArtistDailyResponse>(getRankingArtistDailyPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      date: params.date,
    },
  })
}
