import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type PatchSubEventParams = {
  subEventId: number
  eventId: string
  name: string
  eventStartAt: string
  eventEndAt: string
  cheerFactor: number
  viewersCountFactor: number
  durationFactor: number
  postCountFactor: number
  postUuFactor: number
  rewards?: {
    line: number
    title: string
  }[]
  descriptions?: {
    title: string
    description: string
  }[]
}

export type PatchSubEventResponse = APIResponse<null>

export const patchSubEventPath = '/admin/v1/sub-event/:subEventId'

export const patchSubEvent = async (params: PatchSubEventParams) => {
  return await http<PatchSubEventResponse>(patchSubEventPath, {
    method: 'PATCH',
    params: {
      subEventId: params.subEventId,
    },
    body: JSON.stringify({
      event_id: params.eventId,
      name: params.name,
      event_start_at: params.eventStartAt,
      event_end_at: params.eventEndAt,
      cheer_factor: params.cheerFactor,
      viewers_count_factor: params.viewersCountFactor,
      duration_factor: params.durationFactor,
      post_count_factor: params.postCountFactor,
      post_uu_factor: params.postUuFactor,
      rewards: params.rewards,
      descriptions: params.descriptions,
    }),
  })
}
