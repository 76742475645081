import { http } from '@/lib/http'

export type PatchArtistIdGroupParams = {
  id: number
  name: string
  artistIds?: number[]
}

export const patchArtistIdGroupPath = '/admin/v1/artist-id-group/:id'

export const patchArtistIdGroup = async (params: PatchArtistIdGroupParams) => {
  return await http<null>(patchArtistIdGroupPath, {
    method: 'PUT',
    params: { id: params.id },
    body: JSON.stringify({
      name: params.name,
      artist_ids: params.artistIds,
    }),
  })
}
