import type { AdminListItem } from '../types'
import type { APIResponse } from '@/types'

import { http } from '@/lib/http'

export type GetAdminUserDetailParams = {
  id: number
}

export type GetAdminUserDetailResponse = APIResponse<AdminListItem>

export const getAdminUserDetailPath = '/admin/v1/admin-user/:id'

export const getAdminUserDetail = async (params: GetAdminUserDetailParams) => {
  return await http<GetAdminUserDetailResponse>(getAdminUserDetailPath, {
    method: 'GET',
    params: {
      id: params.id,
    },
  })
}
