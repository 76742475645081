import type { PaymentReportStatusType, PaymentTarget } from '../types'
import type { APIResponse, APIResponseResultPagination } from '@/types'

import { http } from '@/lib/http'

export type GetPaymentReportParams = {
  page?: number
  year?: number
  month?: number
  name?: string
  organizationId?: string
  agencyId?: string
  artistId?: string
  targetType?: string
  status?: string
}

export type GetPaymentReportResponse = APIResponse<
  APIResponseResultPagination<{
    id: number
    paymentReportId: number | null
    year: number
    month: number
    status: PaymentReportStatusType
    errorReason: string | null
    issueDate: string | null
    paymentDate: string | null
    target: PaymentTarget
    netAmount: number | null
    amount: number | null
    amountTax: number | null
    withholdingTax: number | null
    transferFee: number | null
    transferFeeTax: number | null
    invoiceNumber: string | null
    createdAt: string
    updatedAt: string
  }>
>

export const getPaymentReportPath = '/admin/v1/payment-report'

export const getPaymentReport = async (params: GetPaymentReportParams) => {
  return await http<GetPaymentReportResponse>(getPaymentReportPath, {
    method: 'GET',
    searchParams: {
      page: params.page,
      year: params.year,
      month: params.month,
      name: params.name,
      organization_id: params.organizationId,
      agency_id: params.agencyId,
      artist_id: params.artistId,
      target_type: params.targetType,
      status: params.status,
    },
  })
}
