import { http } from '@/lib/http'

export type PostFanForceWithdrawalParams = {
  fanId: string
}

export const postFanForceWithdrawalPath = '/admin/v1/fan/:fanId/force-withdrawal'

export const postFanForceWithdrawal = async (params: PostFanForceWithdrawalParams) => {
  return await http<null>(postFanForceWithdrawalPath, {
    method: 'POST',
    params: {
      fanId: params.fanId,
    },
  })
}
