import { httpRaw } from '@/lib/httpRaw'

export type GetSalesReportDownloadCsvParams = {
  year?: number
  month?: number
  organizationId?: string
  organizationName?: string
  agencyId?: string
  agencyName?: string
}

export const getSalesReportDownloadCsvPath = '/admin/v1/sales-report/download-csv'

export const getSalesReportDownloadCsv = async (
  params: GetSalesReportDownloadCsvParams,
) => {
  return await httpRaw(getSalesReportDownloadCsvPath, {
    method: 'GET',
    searchParams: {
      year: params.year,
      month: params.month,
      organization_id: params.organizationId,
      organization_name: params.organizationName,
      agency_id: params.agencyId,
      agency_name: params.agencyName,
    },
  })
}
